import { Tag, TagLabel, Text } from '@chakra-ui/react';
import {
  type Participant,
  type PiccoloError,
  type User,
  renderParticipantName,
} from '@piccolohealth/pbs-common';
import {
  DataTable,
  Empty,
  type PaginationOptions,
  type RowSelectionOptions,
  createColumnHelper,
} from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { LabelsStack } from '../../components/generic/LabelsStack';
import { ParticipantStatusTag } from '../participant/ParticipantStatusTag';

interface Props {
  participants: Participant[];
  user: User;
  isLoading: boolean;
  error: PiccoloError | null;
  refetch: () => Promise<void>;
  pagination?: PaginationOptions;
  rowSelection?: RowSelectionOptions<Participant>;
}

export const UserParticipantsTable = (props: Props) => {
  const { user } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Participant>();

    return [
      columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
        id: 'name',
        header: 'Name',
        minSize: 160,
        maxSize: 200,
        cell: (ps) => <Text>{renderParticipantName(ps.row.original)}</Text>,
      }),
      columnHelper.accessor('location.name', {
        header: 'Location',
        minSize: 200,
        maxSize: 240,
        cell: (ps) => (
          <Tag size='sm' colorScheme={ps.row.original.location.color}>
            <TagLabel>{ps.row.original.location.name}</TagLabel>
          </Tag>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        minSize: 100,
        maxSize: 130,
        cell: (ps) => <ParticipantStatusTag status={ps.row.original.status} size='sm' />,
      }),
      columnHelper.accessor('label.name', {
        header: 'User labels',
        cell: (ps) => {
          const labels =
            ps.row.original.assignedUsers.find((u) => u.user.id === user.id)?.labels ?? [];
          return <LabelsStack labels={labels} />;
        },
      }),
    ];
  }, [user.id]);

  return (
    <DataTable
      columns={columns}
      data={props.participants}
      size='sm'
      error={props.error}
      isLoading={props.isLoading}
      pagination={props.pagination}
      rowSelection={props.rowSelection}
      renderError={(error) => <Error error={error} />}
      renderEmpty={() => <Empty title='No participants allocated' />}
    />
  );
};
