export const interpolate = (envValue: string, env: Record<string, string>): string => {
  const matches: string[] = envValue.match(/(.?\${?(?:[a-zA-Z0-9_]+)?}?)/g) || [];

  return matches.reduce((newEnv, match) => {
    const parts = /(.?)\${?([a-zA-Z0-9_]+)?}?/g.exec(match) || [];
    const prefix = parts[1];

    let value: string;
    let replacePart: string;

    if (prefix === '\\') {
      replacePart = parts[0] ?? '';
      value = replacePart?.replace('\\$', '$');
    } else {
      const key = parts[2];
      replacePart = parts[0]?.substring(prefix.length) ?? '';
      value = env[key];

      if (value !== undefined) {
        value = interpolate(value, env);
      }
    }

    return newEnv.replace(replacePart, value || '');
  }, envValue);
};
