import { Stack, Wrap, WrapItem } from '@chakra-ui/react';
import {
  type ParticipantRestrictedPractice,
  RestrictedPracticeStatus,
} from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import { P, matchSorter } from '@piccolohealth/util';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { useParticipantRestrictedPracticesQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantRestrictedPracticeCard } from './components/ParticipantRestrictedPracticeCard';
import { ParticipantRestrictedPracticeCreateButton } from './components/ParticipantRestrictedPracticeCreateButton';
import { ParticipantRestrictedPracticeCreateModal } from './components/ParticipantRestrictedPracticeCreateModal';
import { ParticipantRestrictedPracticeFilterControls } from './components/ParticipantRestrictedPracticeFilterControls';
import { useParticipantRestrictedPracticesFilter } from './hooks/useParticipantRestrictedPracticesFilter';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantRestrictedPractices = (props: Props) => {
  const { participantId, isDisabled } = props;

  const { organization } = useAppContext();

  const filter = useParticipantRestrictedPracticesFilter();

  const { data, isLoading, error } = useParticipantRestrictedPracticesQuery({
    organizationId: organization.id,
    participantId,
  });

  const restrictedPractices = React.useMemo(() => {
    const results =
      (data?.organization?.participant?.restrictedPractices as ParticipantRestrictedPractice[]) ??
      [];

    const descriptionFiltered = matchSorter(results, filter.descriptionFilter, {
      keys: ['description'],
    });

    const typeFiltered = !P.isEmpty(filter.typeFilter)
      ? descriptionFiltered.filter((v) => filter.typeFilter.includes(v.type))
      : descriptionFiltered;

    const statusFiltered = !P.isEmpty(filter.statusFilter)
      ? typeFiltered.filter((v) => filter.statusFilter.includes(v.status))
      : typeFiltered;

    // I want to sort by the status of the restricted practice. Ongoing always in front of ceased
    const sorted = statusFiltered.sort((a, b) => {
      if (
        a.status === RestrictedPracticeStatus.Ongoing &&
        b.status === RestrictedPracticeStatus.Ceased
      ) {
        return -1;
      }
      if (
        a.status === RestrictedPracticeStatus.Ceased &&
        b.status === RestrictedPracticeStatus.Ongoing
      ) {
        return 1;
      }
      return 0;
    });

    return sorted;
  }, [
    data?.organization?.participant?.restrictedPractices,
    filter.descriptionFilter,
    filter.statusFilter,
    filter.typeFilter,
  ]);

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error error={error} />;
    }

    return (
      <Wrap spacing={4}>
        {restrictedPractices.map((restrictedPractice) => (
          <WrapItem key={restrictedPractice.id} w='full' maxW='lg' minH='xs'>
            <ParticipantRestrictedPracticeCard
              isDisabled={isDisabled}
              participantId={participantId}
              participantRestrictedPractice={restrictedPractice}
            />
          </WrapItem>
        ))}
        <WrapItem w='full' maxW='lg' minH='xs'>
          <ParticipantRestrictedPracticeCreateButton
            isDisabled={isDisabled}
            onClick={() => {
              showModal(ParticipantRestrictedPracticeCreateModal, {
                participantId,
              });
            }}
          />
        </WrapItem>
      </Wrap>
    );
  });

  return (
    <Stack px={6} py={4} spacing={4}>
      <ParticipantRestrictedPracticeFilterControls filter={filter} />
      {content}
    </Stack>
  );
};
