import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateIncident } from '../mutations/createIncident.mutation';
import type { CreateIncidentMutation, CreateIncidentMutationVariables } from '../types';

export const useCreateIncidentMutation = (
  options?: MutationOptions<CreateIncidentMutation, CreateIncidentMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateIncident), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('incident'),
      });
    },
  });
};
