import { Tag, type TagProps, Tooltip } from '@chakra-ui/react';
import { IncidentSeverity } from '@piccolohealth/pbs-common';
import React from 'react';

export const incidentSeverityDetails = (severity: IncidentSeverity) => {
  switch (severity) {
    case IncidentSeverity.Low:
      return [
        'yellow',
        'Low',
        'Minor issue with minimal impact, e.g. Small tripping hazard or injury not requiring first aid',
      ];
    case IncidentSeverity.Medium:
      return [
        'orange',
        'Medium',
        'Significant problem or impact needing timely but not urgent action, e.g. Injury requiring first aid or damage to property',
      ];
    case IncidentSeverity.High:
      return [
        'red',
        'High',
        'Critical issue requiring immediate attention and resolution, e.g. Severe injury requiring hospitalisation, death or environmental disaster management',
      ];
  }
};

interface Props extends TagProps {
  severity: IncidentSeverity;
}

export const IncidentSeverityTag = (props: Props) => {
  const { severity, ...rest } = props;

  const [color, name, description] = incidentSeverityDetails(severity);

  return (
    <Tooltip label={description} fontSize='xs'>
      <Tag colorScheme={color} {...rest}>
        {name}
      </Tag>
    </Tooltip>
  );
};
