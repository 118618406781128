import { Box, Flex, type FlexProps } from '@chakra-ui/react';
import React from 'react';
import { Breadcrumbs } from '../generic/Breadcrumbs';

interface Props extends FlexProps {
  headerContent?: JSX.Element;
}

export const ContentLayout = (props: React.PropsWithChildren<Props>) => {
  const { children, headerContent, ...rest } = props;
  return (
    <Flex flexDir='column' h='full' {...rest}>
      <Box px={6} py={4}>
        <Breadcrumbs />
        {headerContent && <Box mt={2}>{headerContent}</Box>}
      </Box>
      <Box h='full' minH='0'>
        {children}
      </Box>
    </Flex>
  );
};
