import { HStack, Icon, IconButton, Input, Stack, type StackProps, Tooltip } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { FaCheck, FaUndo } from 'react-icons/fa';
import { useAppContext } from '../../../../hooks/useAppContext';
import { showModal } from '../../../generic/Modal';
import type { CommentData, CommentThread } from '../../extensions/comment/Comment';
import { CommentDataView } from './CommentDataView';
import { CommentDeleteModal } from './CommentDeleteModal';

interface CommentThreadProps extends StackProps {
  editor: Editor;
  commentThread: CommentThread;
}

export const CommentThreadContent = (props: CommentThreadProps) => {
  const { editor, commentThread, ...rest } = props;

  const { user } = useAppContext();

  const [text, setText] = React.useState('');

  const commentThreadStatus = commentThread.status ?? 'open';

  const onAddComment = React.useCallback(() => {
    if (!commentThread) {
      return null;
    }

    const updatedCommentThread: CommentThread = {
      ...commentThread,
      comments: [
        ...commentThread.comments,
        {
          username: user.name,
          userId: user.id,
          picture: user.picture ?? undefined,
          time: Date.now(),
          content: text,
        },
      ],
    };

    editor.chain().updateCommentThread(updatedCommentThread).run();

    setText('');
  }, [commentThread, user.name, user.id, user.picture, text, editor]);

  const onRemoveComment = React.useCallback(
    (comment: CommentData) => {
      if (!commentThread) {
        return null;
      }

      const updatedCommentThread = {
        ...commentThread,
        comments: commentThread.comments.filter((c) => c.time !== comment.time),
      };

      // If there are no comments left, resolve the comment thread
      if (P.isEmpty(updatedCommentThread.comments)) {
        editor.chain().removeCommentThread(commentThread).run();
      } else {
        // Otherwise, update the comment thread
        editor.chain().updateCommentThread(updatedCommentThread).run();
      }
    },
    [editor, commentThread],
  );

  const onEditComment = React.useCallback(
    (updatedComment: CommentData) => {
      if (!commentThread) {
        return null;
      }

      const updatedCommentThread: CommentThread = {
        ...commentThread,
        comments: commentThread.comments.map((c) =>
          c.time === updatedComment.time ? { ...updatedComment } : c,
        ),
      };

      editor.chain().updateCommentThread(updatedCommentThread).run();
    },
    [editor, commentThread],
  );

  const onResolveComment = () => {
    if (!commentThread) {
      return null;
    }

    editor.chain().resolveCommentThread(commentThread).run();
  };

  const onUnresolveComment = () => {
    if (!commentThread) {
      return null;
    }

    editor.chain().unresolveCommentThread(commentThread).run();
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onAddComment();
    }
  };

  return (
    <Stack {...rest}>
      {commentThread.comments.map((comment) => (
        <CommentDataView
          key={comment.time}
          comment={comment}
          onRemoveComment={() =>
            showModal(CommentDeleteModal, { onDelete: () => onRemoveComment(comment) })
          }
          onEditComment={onEditComment}
          isDisabled={commentThreadStatus === 'resolved'}
        />
      ))}
      <HStack>
        <Input
          size='sm'
          variant='unstyled'
          placeholder='Add a comment...'
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={onKeyDown}
          isDisabled={commentThreadStatus === 'resolved'}
        />

        <Tooltip label={commentThreadStatus === 'open' ? 'Mark as resolved' : 'Mark as unresolved'}>
          <IconButton
            icon={commentThreadStatus === 'open' ? <Icon as={FaCheck} /> : <Icon as={FaUndo} />}
            variant='ghost'
            size='sm'
            aria-label='changeStatus'
            onClick={commentThreadStatus === 'open' ? onResolveComment : onUnresolveComment}
          />
        </Tooltip>
      </HStack>
    </Stack>
  );
};
