import { type Method, ParticipantBehaviourStatus } from '@piccolohealth/pbs-common';
import React from 'react';

const DEFAULT_NAME_FILTER: string | undefined = '';
const DEFAULT_STATUS_FILTER: ParticipantBehaviourStatus[] = [ParticipantBehaviourStatus.Active];
const DEFAULT_METHOD_FILTER: Method['__typename'][] = [];

export interface ParticipantBehavioursFilter {
  nameFilter: string;
  statusFilter: ParticipantBehaviourStatus[];
  methodFilter: Method['__typename'][];
  activeFilterCount: number;
  onNameFilter: (name: string) => void;
  onStatusFilter: (statuses: ParticipantBehaviourStatus[]) => void;
  onMethodFilter: (methods: Method['__typename'][]) => void;
  reset: () => void;
}

export const useParticipantBehavioursFilter = (): ParticipantBehavioursFilter => {
  const [params, setParams] = React.useState({
    name: DEFAULT_NAME_FILTER,
    status: DEFAULT_STATUS_FILTER,
    method: DEFAULT_METHOD_FILTER,
  });

  const onNameFilter = (value: string) =>
    setParams((prev) => ({
      ...prev,
      name: value,
    }));

  const onStatusFilter = (value: ParticipantBehaviourStatus[]) =>
    setParams((prev) => ({
      ...prev,
      status: value,
    }));

  const onMethodFilter = (value: Method['__typename'][]) =>
    setParams((prev) => ({
      ...prev,
      method: value,
    }));

  const reset = () =>
    setParams({
      name: DEFAULT_NAME_FILTER,
      status: DEFAULT_STATUS_FILTER,
      method: DEFAULT_METHOD_FILTER,
    });

  const activeFilterCount = [
    params.name.length > 0,
    params.status.length > 0,
    params.method.length > 0,
  ].filter(Boolean).length;

  return {
    nameFilter: params.name,
    statusFilter: params.status,
    methodFilter: params.method,
    activeFilterCount,
    onNameFilter,
    onStatusFilter,
    onMethodFilter,
    reset,
  };
};
