import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  LabelType,
  type ParticipantDocument,
  type UpdateParticipantDocumentRequest,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormStack } from '../../components/forms/FormStack';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedLabelChooser } from '../../components/forms/HookedLabelChooser';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useUpdateParticipantDocumentMutation } from '../../graphql/hooks/useUpdateParticipantDocumentMutation';
import { useAppContext } from '../../hooks/useAppContext';

type FormValues = UpdateParticipantDocumentRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string().nullish(),
  labelIds: z.array(z.string()).nullish(),
});

interface Props {
  document: ParticipantDocument;
}

export const ParticipantDocumentEditModal = createModal<Props>((props) => {
  const { document, modal } = props;

  const { hide, visible } = modal;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateParticipantDocumentMutation();

  const initialValues: Partial<FormValues> = {
    name: document.name,
    labelIds: document.labels.map((label) => label.id),
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantDocumentId: document.id,
          participantId: document.participantId,
          request: values,
        })
        .then(async () => {
          hide();
          successToast('Document updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating document: ${err.message}`);
        });
    },
    [
      mutation,
      organization.id,
      document.id,
      document.participantId,
      hide,
      successToast,
      errorToast,
    ],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Edit a document</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <FormStack>
                <HookedFormItem name='name' label='Name' h='full'>
                  <HookedInput name='name' />
                </HookedFormItem>

                <HookedFormItem name='labelIds' label='Labels'>
                  <HookedLabelChooser name='labelIds' type={LabelType.ParticipantDocument} />
                </HookedFormItem>
              </FormStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size='sm' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
