import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type CreateTemplateRequest,
  TemplateStatus,
  TemplateType,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useCreateTemplateMutation } from '../../graphql/hooks/useCreateTemplateMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { TemplateForm } from './TemplateForm';

type FormValues = CreateTemplateRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  type: z.nativeEnum(TemplateType),
  name: z.string(),
  labelIds: z.array(z.string()),
  status: z.nativeEnum(TemplateStatus),
});

export const TemplateCreateModal = createModal<{}>((props) => {
  const { modal } = props;
  const { errorToast, successToast, organization } = useAppContext();

  const { hide, remove, visible } = modal;

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      type: TemplateType.ParticipantDocument,
      labelIds: [],
      status: TemplateStatus.Draft,
    },
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useCreateTemplateMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Template created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating template: ${err.message}`);
        });
    },
    [mutation, organization.id, hide, successToast, errorToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='md'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Add a template</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <TemplateForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size='sm' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size='sm'>Create</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
