import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Stack,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import type { Editor, Range } from '@tiptap/core';
import React from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { FormItem } from '../../forms/FormItem';

interface Props {
  editor: Editor;
}

export const SearchAndReplaceMenu = (props: Props) => {
  const { editor } = props;
  const disclosure = useDisclosure();
  const findInputRef = React.useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [replaceTerm, setReplaceTerm] = React.useState<string>('');
  const [matchCase, setMatchCase] = React.useState<boolean>(false);

  const goToSelection = React.useCallback(() => {
    if (!editor) {
      return;
    }

    const { results, resultIndex } = editor.storage.searchAndReplace;
    const position: Range = results[resultIndex];

    if (!position) {
      return;
    }

    editor.commands.setTextSelection(position);

    const { node } = editor.view.domAtPos(editor.state.selection.anchor);

    if (node instanceof HTMLElement) {
      node.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [editor]);

  const onClear = React.useCallback(() => {
    setSearchTerm('');
    editor.commands.setSearchTerm('');
    setReplaceTerm('');
    editor.commands.setReplaceTerm('');
    editor.commands.resetIndex();
  }, [editor.commands]);

  const onSearchChange = React.useCallback((value: string) => {
    setSearchTerm(value);
  }, []);

  const onReplaceChange = React.useCallback((value: string) => {
    setReplaceTerm(value);
  }, []);

  const onMatchCaseChange = React.useCallback(
    (value: boolean) => {
      setMatchCase(value);
      editor.commands.setCaseSensitive(value);
    },
    [editor.commands],
  );

  const onFind = React.useCallback(() => {
    editor.commands.setSearchTerm(searchTerm);
    goToSelection();
  }, [editor.commands, goToSelection, searchTerm]);

  const onReplace = React.useCallback(() => {
    editor.commands.setReplaceTerm(replaceTerm);

    editor
      .chain()
      .command(({ state, dispatch }) => {
        const { results, resultIndex } = editor.storage.searchAndReplace;
        const result = results[resultIndex];

        if (!dispatch) {
          return false;
        }

        if (!result) {
          return false;
        }

        const { from, to } = result;

        dispatch(state.tr.insertText(replaceTerm, from, to));

        return true;
      })
      .run();

    goToSelection();
  }, [editor, goToSelection, replaceTerm]);

  const onReplaceAll = React.useCallback(() => {
    editor.commands.setReplaceTerm(replaceTerm);
    editor.commands.replaceAll();
  }, [editor.commands, replaceTerm]);

  const onNext = React.useCallback(() => {
    editor.commands.nextSearchResult();
    goToSelection();
  }, [editor.commands, goToSelection]);

  const onPrevious = React.useCallback(() => {
    editor.commands.previousSearchResult();
    goToSelection();
  }, [editor.commands, goToSelection]);

  const onOpen = React.useCallback(() => {
    disclosure.onOpen();
    findInputRef.current?.focus();
  }, [disclosure]);

  const onClose = React.useCallback(() => {
    onClear();
    disclosure.onClose();
  }, [disclosure, onClear]);

  React.useEffect(() => {
    const onHotkey = (e: KeyboardEvent) => {
      if (
        e.keyCode === 114 ||
        (e.ctrlKey && e.keyCode === 70) ||
        e.keyCode === 114 ||
        (e.ctrlKey && e.keyCode === 70) ||
        (e.metaKey && e.keyCode === 70)
      ) {
        e.preventDefault();
        onOpen();
      }
    };

    window.addEventListener('keydown', onHotkey);

    return () => {
      window.removeEventListener('keydown', onHotkey);
    };
  });

  return (
    <Popover
      returnFocusOnClose={false}
      initialFocusRef={findInputRef}
      isOpen={disclosure.isOpen}
      onClose={onClose}
      placement='right'
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box pos='absolute' top={4} right={4} />
      </PopoverTrigger>
      <PopoverContent w='sm'>
        <PopoverHeader fontWeight='semibold'>Search and replace</PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody px={4}>
          <Stack spacing={2}>
            <FormItem label='Find'>
              <HStack>
                <Input
                  ref={findInputRef}
                  value={searchTerm}
                  onChange={(e) => onSearchChange(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && onFind()}
                  placeholder='Find in text...'
                  size='sm'
                />
                <Tooltip label='Previous'>
                  <IconButton
                    size='sm'
                    aria-label='Previous'
                    icon={<FaAngleUp />}
                    onClick={onPrevious}
                    tabIndex={-1}
                  />
                </Tooltip>
                <Tooltip label='Next'>
                  <IconButton
                    size='sm'
                    aria-label='Next'
                    icon={<FaAngleDown />}
                    onClick={onNext}
                    tabIndex={-1}
                  />
                </Tooltip>
              </HStack>
            </FormItem>
            <FormItem label='Replace with'>
              <Input
                value={replaceTerm}
                onChange={(e) => onReplaceChange(e.target.value)}
                placeholder='Replace with term...'
                size='sm'
              />
            </FormItem>

            <FormItem label='Settings'>
              <HStack>
                <Text fontSize='sm'>Match case</Text>
                <Spacer />
                <Switch
                  checked={matchCase}
                  onChange={(e) => onMatchCaseChange(e.target.checked)}
                  size='sm'
                  colorScheme='purple'
                />
              </HStack>
            </FormItem>

            <HStack py={2} mt={2}>
              <Text fontSize='sm' color='secondary'>
                {editor.storage.searchAndReplace.results.length > 0 &&
                  `${editor.storage.searchAndReplace.resultIndex + 1} / ${
                    editor.storage.searchAndReplace.results.length
                  }`}
              </Text>
              <Spacer />
              <Button size='sm' variant='outline' onClick={onReplace} tabIndex={-1}>
                Replace
              </Button>
              <Button size='sm' variant='outline' onClick={onReplaceAll} tabIndex={-1}>
                Replace all
              </Button>
              <Button size='sm' colorScheme='purple' onClick={onFind}>
                Find
              </Button>
            </HStack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
