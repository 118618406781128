import { Heading, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import React from 'react';
import { TabsRouter } from '../../components/generic/TabsRouter';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { BasicSettings } from './BasicSettings';
import { NotificationsSettings } from './NotificationsSettings';
import { SecuritySettings } from './SecuritySettings';

export const Profile = () => {
  return (
    <ContentLayout headerContent={<Heading size='lg'>Profile</Heading>}>
      <TabsRouter
        variant='soft-rounded'
        orientation='vertical'
        defaultIndex={0}
        size='sm'
        w='full'
        h='full'
        isLazy
        lazyBehavior='keepMounted'
        routes={['basic', 'security', 'notifications']}
        px={6}
        py={2}
      >
        <TabList w='120px' flexShrink={0}>
          <Tab key='basic'>Basic</Tab>
          <Tab key='security'>Security</Tab>
          <Tab key='security'>Notifications</Tab>
        </TabList>
        <TabPanels overflowY='auto' h='full' w='full' px={6}>
          <TabPanel>
            <BasicSettings />
          </TabPanel>
          <TabPanel>
            <SecuritySettings />
          </TabPanel>
          <TabPanel>
            <NotificationsSettings />
          </TabPanel>
        </TabPanels>
      </TabsRouter>
    </ContentLayout>
  );
};
