import {
  Box,
  Button,
  Icon,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Tag,
  Text,
} from '@chakra-ui/react';
import type { Label } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import debounce from 'debounce-promise';
import React from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { FaCheck, FaEllipsisH, FaRegTrashAlt } from 'react-icons/fa';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedTextArea } from '../../components/forms/HookedTextArea';
import { showModal } from '../../components/generic/Modal';
import { useUpdateLabelMutation } from '../../graphql/hooks/useUpdateLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { LabelDeleteModal } from './LabelDeleteModal';

interface FormValues {
  name: string;
  description?: string | null;
  color: string;
}

interface Props {
  label: Label;
  onDelete?: (labelId: string) => void;
}

const HookedLabelColorItems = (props: { name: string }) => {
  const { name } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const colors = ['blue', 'cyan', 'green', 'orange', 'pink', 'purple', 'red', 'teal', 'yellow'];

  return (
    <List display='inline-block' overflowY='auto' w='full'>
      {colors.map((item, index) => {
        return (
          <ListItem
            key={`${item}${index}`}
            px={3}
            py={1}
            display='flex'
            flexDir='row'
            alignItems='center'
            _hover={{ bg: 'gray.100' }}
            onClick={() => inputProps.onChange(item)}
          >
            <Tag colorScheme={item} size='sm'>
              {P.upperFirst(item)}
            </Tag>
            <Spacer />
            {item === inputProps.value ? <Icon as={FaCheck} /> : null}
          </ListItem>
        );
      })}
    </List>
  );
};

const LabelMenuContents = (props: Props) => {
  const { label, onDelete } = props;
  const { organization, errorToast } = useAppContext();
  const updateLabelMutation = useUpdateLabelMutation();

  const initialValues: FormValues = {
    name: label.name,
    description: label.description,
    color: label.color,
  };

  const methods = useForm({
    defaultValues: initialValues,
  });

  const watch = methods.watch;

  const autosave = debounce(async (values: Partial<FormValues>) => {
    const request = P.pickBy(values, (value, key) => {
      return !P.isEqual(value, initialValues[key as keyof FormValues]);
    });

    await updateLabelMutation
      .mutateAsync({
        organizationId: organization.id,
        labelId: label.id,
        request,
      })
      .catch((err: Error) => errorToast(`Error updating label: ${err.message}`));
  }, 1000);

  // Watch the form
  React.useEffect(() => {
    const subscription = watch(autosave);
    return () => subscription.unsubscribe();
  }, [watch, autosave]);

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <FormProvider {...methods}>
        <MenuGroup title='Name' color='gray.500'>
          <Box px={3} pt={1} pb={2}>
            <HookedInput name='name' fontWeight='normal' />
          </Box>
        </MenuGroup>
        <MenuGroup title='Description' color='gray.500'>
          <Box px={3} pt={1} pb={2}>
            <HookedTextArea
              name='description'
              fontSize='sm'
              py={3 / 2}
              px={3}
              rows={2}
              fontWeight='normal'
            />
          </Box>
        </MenuGroup>
        <MenuDivider />
        <MenuItem
          icon={<FaRegTrashAlt />}
          onClick={() => showModal(LabelDeleteModal, { labelId: label.id, onDelete })}
          fontSize='sm'
        >
          <Text color='danger'>Delete</Text>
        </MenuItem>
        <MenuDivider />
        <MenuGroup title='Color' color='gray.500'>
          <HookedLabelColorItems name='color' />
        </MenuGroup>
      </FormProvider>
    </Box>
  );
};

export const ManageLabelMenu = (props: Props) => {
  const { label, onDelete } = props;

  return (
    <Menu closeOnSelect={false} strategy='fixed' isLazy autoSelect={false}>
      <MenuButton
        as={Button}
        variant='ghost'
        _hover={{ bg: 'gray.200' }}
        _active={{ bg: 'gray.200' }}
        size='xs'
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Icon as={FaEllipsisH} />
      </MenuButton>
      <MenuList zIndex={1900}>
        <LabelMenuContents label={label} onDelete={onDelete} />
      </MenuList>
    </Menu>
  );
};
