import {
  Button,
  type ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import type { ParticipantRestrictedPractice } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../../components/generic/Modal';
import { ParticipantRestrictedPracticeDeleteModal } from './ParticipantRestrictedPracticeDeleteModal';
import { ParticipantRestrictedPracticeEditModal } from './ParticipantRestrictedPracticeEditModal';

interface Props extends ButtonProps {
  participantId: string;
  participantRestrictedPractice: ParticipantRestrictedPractice;
  isDisabled?: boolean;
}

export const ParticipantRestrictedPracticeActionMenu = (props: Props) => {
  const { participantId, participantRestrictedPractice, isDisabled, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior='unmount' size='sm'>
      <MenuButton
        as={Button}
        isDisabled={isDisabled}
        variant='link'
        size='sm'
        rightIcon={<Icon as={FaCaretDown} />}
        {...rest}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<FaPencilAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantRestrictedPracticeEditModal, {
              participantId,
              participantRestrictedPractice,
            })
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          color='danger'
          icon={<FaRegTrashAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantRestrictedPracticeDeleteModal, {
              participantId,
              participantRestrictedPracticeId: participantRestrictedPractice.id,
            })
          }
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
