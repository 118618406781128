import { Box } from '@chakra-ui/react';
import type { LooseObject } from '@piccolohealth/util';
import React from 'react';
import { useStack } from '../../hooks/useStack';
import { ActionsPageContent } from './ActionsPageContent';
import { FreePageContent } from './FreePageContent';

export type ImageTooltip = {
  kind: 'image';
  src: string;
  description: string;
};

export type ContentTooltip = {
  kind: 'content';
  content: React.ReactNode;
};

export type CommandTooltip = ImageTooltip | ContentTooltip;

export type CommandActionInfo<A> = {
  id: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  raw: string;
  category?: string;
  leftIcon: (context: CommandMenuContext<A>) => React.ReactNode;
  tooltip?: (context: CommandMenuContext<A>) => CommandTooltip;
};

export type GotoPageAction<A> = CommandActionInfo<A> & {
  kind: 'goto';
  id: PageId;
  args?: LooseObject;
};

export type VoidAction<A> = CommandActionInfo<A> & {
  kind: 'void';
  action: (props: A) => void;
};

export type Action<A> = GotoPageAction<A> | VoidAction<A>;

export type FreePage<A> = {
  kind: 'free';
  id: PageId;
  title: string;
  body: (context: CommandMenuContext<A>, args?: LooseObject) => React.ReactNode;
};

export type ActionsPage<A> = {
  kind: 'actions';
  id: PageId;
  title: string;
  actions: Action<A>[];
  searchable: boolean;
};

type PageId = 'ROOT' | string;

export type PageIdWithArgs = {
  id: PageId;
  args?: LooseObject;
};

export type Page<A> = FreePage<A> | ActionsPage<A>;

export interface ActionItemProps {
  ref: React.Ref<HTMLLIElement>;
  onClick: () => void;
  onFocus: () => void;
  onMouseMove: () => void;
  onPointerLeave: () => void;
}

export type CommandMenuContext<A> = {
  ctx: A;
  currentPagesLength: number;
  inputRef: React.Ref<HTMLInputElement>;
  pushPage: (id: PageId, args?: LooseObject) => void;
  popPage: () => void;
  onExit: () => void;
};

export interface CommandMenuProps<A> {
  getPages: (ctx: A) => Page<A>[];
  ctx: A;
  rootPageId?: PageIdWithArgs;
  onOpenChange: (value: boolean) => void;
}

export const CommandMenu = <A,>(props: CommandMenuProps<A>) => {
  const { getPages, ctx, rootPageId, onOpenChange } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [pageIds, pageIdActions] = useStack<PageIdWithArgs>([rootPageId ?? { id: 'ROOT' }]);

  const onExit = React.useCallback(() => {
    onOpenChange(false);
    pageIdActions.reset();
  }, [pageIdActions, onOpenChange]);

  const pages = React.useMemo(() => getPages(ctx), [getPages, ctx]);

  const { currentPage, args } = React.useMemo(() => {
    const pageIdWithArgs = pageIdActions.peek();

    const page = pages.find((p) => p.id === pageIdWithArgs?.id);

    if (page === undefined) {
      throw new Error(`Page ${pageIdWithArgs?.id} not found`);
    }

    return { currentPage: page, args: pageIdWithArgs?.args };
  }, [pages, pageIdActions]);

  const commandMenuContext: CommandMenuContext<A> = {
    ctx,
    currentPagesLength: pageIdActions.length,
    onExit,
    pushPage: (id: PageId, args?: LooseObject) => {
      pageIdActions.push({ id, args });
    },
    popPage: () => {
      pageIdActions.pop();
    },
    inputRef,
  };

  return (
    <Box
      rounded='xl'
      borderRadius='md'
      borderColor='gray.300'
      borderWidth='1px'
      bg='white'
      shadow='2xl'
    >
      {currentPage.kind === 'free' ? (
        <FreePageContent page={currentPage} context={commandMenuContext} args={args} />
      ) : (
        <ActionsPageContent page={currentPage} context={commandMenuContext} />
      )}
    </Box>
  );
};
