import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateParticipantRestrictedPractice } from '../mutations/createParticipantRestrictedPractice.mutation';
import type {
  CreateParticipantRestrictedPracticeMutation,
  CreateParticipantRestrictedPracticeMutationVariables,
} from '../types';

export const useCreateParticipantRestrictedPracticeMutation = (
  options?: MutationOptions<
    CreateParticipantRestrictedPracticeMutation,
    CreateParticipantRestrictedPracticeMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantRestrictedPractice), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
