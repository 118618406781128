import { HStack, Spacer } from '@chakra-ui/react';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { AddNodeButton } from './AddNodeMenu';
import { AlignmentSelect } from './AlignmentSelect';
import { IndentSelect } from './IndentSelect';
import { MarkSelect } from './MarkSelect';
import { NodeTypeSelect } from './NodeTypeSelect';
import { TextColorSelect } from './TextColorSelect';
import { UndoRedo } from './UndoRedo';
import { VerticalDivider } from './VerticalDivider';
import { CommentButton } from './comment/CommentButton';

interface Props {
  editor: Editor;
}

export const EditorMenu = (props: Props) => {
  const { editor } = props;

  return (
    <HStack spacing={2} w='full' borderTopWidth='1px' borderBottomWidth='1px' py={1}>
      <AddNodeButton editor={editor} />
      <VerticalDivider />
      <NodeTypeSelect editor={editor} />
      <VerticalDivider />
      <MarkSelect editor={editor} />
      <VerticalDivider />
      <AlignmentSelect editor={editor} />
      <VerticalDivider />
      <IndentSelect editor={editor} />
      <VerticalDivider />
      <TextColorSelect editor={editor} />
      <VerticalDivider />
      <CommentButton editor={editor} />
      <Spacer />
      <UndoRedo editor={editor} />
    </HStack>
  );
};
