import { Box, HStack, IconButton, Tooltip } from '@chakra-ui/react';
import type { Editor } from '@tiptap/react';
import React from 'react';
import type { IconType } from 'react-icons';
import { MdFormatColorFill, MdGridOff } from 'react-icons/md';
import {
  RiDeleteColumn,
  RiDeleteRow,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiInsertRowBottom,
  RiInsertRowTop,
  RiMergeCellsHorizontal,
  RiSplitCellsHorizontal,
} from 'react-icons/ri';
import { TableBackgroundSelect } from './TableBackgroundSelect';

interface Item {
  icon: IconType;
  title: string;
  action: () => void;
  isDisabled: () => boolean;
  isActive?: () => boolean;
}

interface TooltipButtonProps {
  item: Item;
}

export const TooltipButton = (props: TooltipButtonProps) => {
  const { item } = props;
  return (
    <Tooltip key={item.title} label={item.title}>
      <IconButton
        icon={<item.icon />}
        aria-label={item.title}
        variant='ghost'
        size='sm'
        bg='white'
        rounded='md'
        onClick={item.action}
        isDisabled={item.isDisabled()}
        isActive={item.isActive?.()}
        fontSize='18px'
        fontWeight='light'
      />
    </Tooltip>
  );
};

interface Props {
  editor: Editor;
}

export const TableMenu = (props: Props) => {
  const { editor } = props;

  if (!editor?.isEditable) {
    return null;
  }

  const group1 = [
    {
      icon: RiInsertColumnLeft,
      title: 'Insert column before',
      action: () => editor?.chain().focus().addColumnBefore().run(),
      isDisabled: () => !editor?.can().addColumnBefore(),
    },
    {
      icon: RiInsertColumnRight,
      title: 'Insert column after',
      action: () => editor?.chain().focus().addColumnAfter().run(),
      isDisabled: () => !editor?.can().addColumnAfter(),
    },
    {
      icon: RiDeleteColumn,
      title: 'Delete column',
      action: () => editor?.chain().focus().deleteColumn().run(),
      isDisabled: () => !editor?.can().deleteColumn(),
    },
    {
      icon: RiInsertRowTop,
      title: 'Add row above',
      action: () => editor?.chain().focus().addRowBefore().run(),
      isDisabled: () => !editor?.can().addRowBefore(),
    },
    {
      icon: RiInsertRowBottom,
      title: 'Add row below',
      action: () => editor?.chain().focus().addRowAfter().run(),
      isDisabled: () => !editor?.can().addRowAfter(),
    },
    {
      icon: RiDeleteRow,
      title: 'Delete row',
      action: () => editor?.chain().focus().deleteRow().run(),
      isDisabled: () => !editor?.can().deleteRow(),
    },
  ];

  const group2 = [
    {
      icon: RiMergeCellsHorizontal,
      title: 'Merge cells',
      action: () => editor?.chain().focus().mergeCells().run(),
      isDisabled: () => !editor?.can().mergeCells(),
    },
    {
      icon: RiSplitCellsHorizontal,
      title: 'Split cells',
      action: () => editor?.chain().focus().splitCell().run(),
      isDisabled: () => !editor?.can().splitCell(),
    },
    {
      icon: MdFormatColorFill,
      title: 'Toggle header cell',
      action: () => editor?.chain().focus().toggleHeaderCell().run(),
      isDisabled: () => !editor?.can().toggleHeaderCell(),
      isActive: () => editor?.isActive('tableHeader') ?? false,
    },
  ];

  const group3 = [
    {
      icon: MdGridOff,
      title: 'Delete table',
      action: () => editor?.chain().focus().deleteTable().run(),
      isDisabled: () => !editor?.can().deleteTable(),
    },
  ];

  return (
    <HStack w='full' bg='white' shadow='popover' rounded='md' overflow='hidden' p={1} spacing={1}>
      <HStack spacing={0} align='stretch' w='fit-content' bg='white'>
        {group1.map((item) => (
          <TooltipButton key={item.title} item={item} />
        ))}
      </HStack>
      <Box h={6} w='1px' bg='gray.200' />
      <HStack spacing={0} align='stretch' w='fit-content' bg='white'>
        {group2.map((item) => (
          <TooltipButton key={item.title} item={item} />
        ))}
        <Tooltip label='Cell background color'>
          <Box>
            <TableBackgroundSelect editor={editor} />
          </Box>
        </Tooltip>
      </HStack>

      <Box h={6} w='1px' bg='gray.200' />
      <HStack spacing={0} align='stretch' w='fit-content' bg='white'>
        {group3.map((item) => (
          <TooltipButton key={item.title} item={item} />
        ))}
      </HStack>
    </HStack>
  );
};
