import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { ParticipantDocument, ParticipantFile } from '@piccolohealth/pbs-common';
import { Empty, FancyDate, FileIcon, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaEye, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Error } from '../../components/generic/Error';
import { LabelsStack } from '../../components/generic/LabelsStack';
import { ListItem } from '../../components/generic/ListItem';
import { showModal } from '../../components/generic/Modal';
import { TextLink } from '../../components/generic/TextLink';
import {
  useParticipantDocumentsQuery,
  useParticipantFilesQuery,
} from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { type ParticipantFileOrDocument, isParticipantFile } from '../../utils/fileOrDocument';
import { ParticipantFileActionMenu } from '../files/ParticipantFileActionMenu';
import { ParticipantFileUploadModal } from '../files/ParticipantFileUploadModal';
import { ParticipantDocumentActionMenu } from '../participantDocument/ParticipantDocumentActionMenu';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantDocumentsCard = (props: Props) => {
  const { participantId, isDisabled } = props;

  const { organization } = useAppContext();
  const navigate = useNavigate();

  const onViewAllFiles = () => {
    navigate(`/organizations/${organization.id}/participants/${participantId}/documents`);
  };

  const onAddFile = () => {
    showModal(ParticipantFileUploadModal, {
      participantId,
    });
  };

  const documentsQuery = useParticipantDocumentsQuery({
    organizationId: organization.id,
    participantId,
  });

  const filesQuery = useParticipantFilesQuery({
    organizationId: organization.id,
    participantId,
  });

  const isLoading = filesQuery.isLoading || documentsQuery.isLoading;
  const error = filesQuery.error || documentsQuery.error;

  const files = (filesQuery.data?.organization?.participant?.files as ParticipantFile[]) ?? [];
  const documents =
    (documentsQuery.data?.organization?.participant?.documents as ParticipantDocument[]) ?? [];
  const combined = P.orderBy([...files, ...documents], (f) => f.createdAt, 'desc');
  const recentFileOrDocuments: ParticipantFileOrDocument[] = P.take(combined, 3);

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error h='full' error={error} />;
    }

    if (P.isEmpty(combined)) {
      return <Empty h='full' title='No documents found' p={0} />;
    }

    return recentFileOrDocuments.map((fileOrDocument) => {
      const { to, isExternal } = isParticipantFile(fileOrDocument)
        ? { to: fileOrDocument.viewUrl, isExternal: true }
        : {
            to: `/organizations/${organization.id}/participants/${fileOrDocument.participantId}/documents/${fileOrDocument.id}`,
            isExternal: false,
          };

      return (
        <ListItem
          key={fileOrDocument.id}
          icon={<FileIcon name={fileOrDocument.name} w={10} h={10} />}
          title={fileOrDocument.name}
          secondary={
            <FancyDate
              date={fileOrDocument.createdAt}
              showHumanized={false}
              color='secondary'
              fontSize='xs'
            />
          }
          tertiary={<LabelsStack labels={fileOrDocument.labels} />}
          right={
            <HStack>
              <Button
                as={TextLink}
                to={to}
                isExternal={isExternal}
                size='xs'
                variant='link'
                w='fit-content'
              >
                View
              </Button>
              {isParticipantFile(fileOrDocument) ? (
                <ParticipantFileActionMenu
                  file={fileOrDocument}
                  size='xs'
                  isDisabled={isDisabled}
                />
              ) : (
                <ParticipantDocumentActionMenu
                  participantDocument={fileOrDocument}
                  size='xs'
                  isDisabled={isDisabled}
                />
              )}
            </HStack>
          }
        />
      );
    });
  });

  return (
    <Card h='full'>
      <CardHeader>
        <Stack spacing={0}>
          <Heading size='md'>Documents</Heading>
          <Text fontSize='sm' color='secondary'>
            Recent documents
          </Text>
        </Stack>
      </CardHeader>
      <CardBody as={Stack} spacing={6}>
        {content}
        <HStack w='full'>
          <Button w='full' size='sm' leftIcon={<Icon as={FaEye} />} onClick={onViewAllFiles}>
            View all documents
          </Button>
          <Button
            w='full'
            size='sm'
            leftIcon={<Icon as={FaPlus} />}
            isDisabled={isDisabled}
            onClick={onAddFile}
          >
            Upload
          </Button>
        </HStack>
      </CardBody>
    </Card>
  );
};
