import { type Label, LabelType, type ParticipantNote } from '@piccolohealth/pbs-common';
import type { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useCreateParticipantNoteLabelMutation } from '../../graphql/hooks/useCreateParticipantNoteLabelMutation';
import { useDeleteParticipantNoteLabelMutation } from '../../graphql/hooks/useDeleteParticipantNoteLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { LabelsControl } from '../labels/LabelsControl';

export interface ManageParticipantNoteLabelsControlProps {
  participantNote: ParticipantNote;
  isDisabled?: boolean;
}

export const ManageParticipantNoteLabelsControl = (
  props: ManageParticipantNoteLabelsControlProps,
) => {
  const { participantNote, isDisabled } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const updateParticipantNoteMutation = useCreateParticipantNoteLabelMutation({ retry: false });
  const deleteParticipantNoteLabelMutation = useDeleteParticipantNoteLabelMutation({
    retry: false,
  });

  const labelManagePermission = usePermission('manage', 'label');
  const participantLabelManagePermission = usePermission('manage', 'participantNoteLabel');

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type: LabelType.ParticipantNote,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label ${err.message}`));
        }
        case 'ADD': {
          return updateParticipantNoteMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId: participantNote.participantId,
              participantNoteId: participantNote.id,
              labelId: request.value.value,
            })
            .catch((err: Error) => errorToast(`Error adding label to participant: ${err.message}`));
        }
        case 'REMOVE': {
          return deleteParticipantNoteLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId: participantNote.participantId,
              participantNoteId: participantNote.id,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error removing label from participant: ${err.message}`),
            );
        }
      }
    },
    [
      organization,
      participantNote.id,
      participantNote.participantId,
      createLabelMutation,
      updateParticipantNoteMutation,
      deleteParticipantNoteLabelMutation,
      errorToast,
    ],
  );

  return (
    <LabelsControl
      onChange={onChange}
      value={participantNote.labels.map((label) => label.id)}
      isDisabled={isDisabled || !participantLabelManagePermission}
      canManage={labelManagePermission}
      variant='selectGhost'
      type={LabelType.ParticipantNote}
    />
  );
};
