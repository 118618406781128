import { Heading } from '@chakra-ui/react';
import type * as Common from '@piccolohealth/pbs-common';
import { PiccoloError } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import {
  getDocumentVariables,
  getParticipantDocumentTemplateExtensions,
} from '../../components/tiptap/utils';
import { useParticipantQuery } from '../../graphql/hooks/useParticipantQuery';
import { useTemplateQuery } from '../../graphql/hooks/useTemplateQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { DocumentTemplateEditor } from './DocumentTemplateEditor';

interface Props {
  participantId: string;
  templateId: string;
}

export const ParticipantDocumentTemplatePreview = (props: Props) => {
  const { participantId, templateId } = props;
  const { organization, user } = useAppContext();

  const templateQuery = useTemplateQuery({
    templateId,
    organizationId: organization.id,
  });

  const participantQuery = useParticipantQuery({
    participantId,
    organizationId: organization.id,
  });

  const template = templateQuery.data?.organization?.template as Common.Template | null;

  const participant = participantQuery.data?.organization?.participant as Common.Participant | null;

  const variables = React.useMemo(() => {
    if (!template || !participant) {
      return [];
    }

    return getDocumentVariables({
      user,
      organization,
      participant,
    });
  }, [template, participant, user, organization]);

  if (templateQuery.isLoading || participantQuery.isLoading) {
    return <Spin />;
  }

  if (templateQuery.error) {
    return <Error error={templateQuery.error} />;
  }

  if (participantQuery.error) {
    return <Error error={participantQuery.error} />;
  }

  if (!participant) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ParticipantNotFound',
            message: 'Participant not found',
          })
        }
      />
    );
  }

  if (!template) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'TemplateNotFound',
            message: 'Template not found',
          })
        }
      />
    );
  }

  const extensions = getParticipantDocumentTemplateExtensions({ variables });

  return (
    <DocumentTemplateEditor
      key={template.id}
      title={() => (
        <Heading size='lg' mb={8}>
          {template.name}
        </Heading>
      )}
      documentId={`template/${template.id}`}
      extensions={extensions}
      isEditable={false}
    />
  );
};
