import { Box, type BoxProps } from '@chakra-ui/react';
import React from 'react';
import { useDrop } from 'react-use';

interface Props extends BoxProps {
  onFilesChange: (files: File[]) => void;
}

export const FileUploadDropZone = (props: React.PropsWithChildren<Props>) => {
  const { onFilesChange, children, ...rest } = props;

  const state = useDrop({
    onFiles: (files) => onFilesChange(files),
  });

  return (
    <Box
      display={state.over ? 'block' : 'none'}
      position='fixed'
      width='100%'
      height='100%'
      left='0'
      top='0'
      boxSizing='border-box'
      zIndex='99999'
      background='rgba(0, 0, 255, 0.1)'
      borderWidth='11px'
      borderStyle='dashed'
      borderColor='purple.400'
      {...rest}
    />
  );
};
