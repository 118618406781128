import { Grid, GridItem, Stack } from '@chakra-ui/react';
import { type Participant, PiccoloError } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { useParticipantQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantQRCard } from './ParticipantQRCard';
import { ParticipantTeamCard } from './ParticipantTeamCard';

interface Props {
  participantId: string;
  isDisabled: boolean;
}

export const ParticipantTeam = (props: Props) => {
  const { participantId, isDisabled } = props;

  const { organization } = useAppContext();

  const { data, error, isLoading } = useParticipantQuery({
    organizationId: organization.id,
    participantId,
  });

  const participant = data?.organization?.participant as Participant | undefined;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!participant) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ParticipantNotFound',
            message: 'Participant not found',
          })
        }
      />
    );
  }

  return (
    <Grid
      h='auto'
      templateRows='repeat(1, 1fr)'
      templateColumns={{ sm: 'repeat(1, 1fr)', '2xl': 'repeat(3, 1fr)' }}
      gap={4}
      pb={4}
      px={6}
      py={4}
      alignItems='start'
    >
      <GridItem colSpan={1}>
        <Stack>
          <ParticipantTeamCard participant={participant} isDisabled={isDisabled} />
        </Stack>
      </GridItem>
      <GridItem colSpan={{ sm: 1, '2xl': 2 }}>
        <Stack>
          <ParticipantQRCard participant={participant} isDisabled={isDisabled} />
        </Stack>
      </GridItem>
    </Grid>
  );
};
