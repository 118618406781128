import { type Label, LabelType } from '@piccolohealth/pbs-common';
import type { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useCreateLabelMutation } from '../../../graphql/hooks/useCreateLabelMutation';
import { useCreateParticipantRestrictedPracticeLabelMutation } from '../../../graphql/hooks/useCreateParticipantRestrictedPracticeLabelMutation';
import { useDeleteParticipantRestrictedPracticeLabelMutation } from '../../../graphql/hooks/useDeleteParticipantRestrictedPracticeLabelMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { usePermission } from '../../../hooks/usePermission';
import { LabelsControl } from '../../labels/LabelsControl';

export interface ManageLabelsRestrictedPracticeControlProps {
  participantId: string;
  restrictedPracticeId: string;
  value: string[];
  isDisabled?: boolean;
}

export const ManageParticipantRestrictedPracticeLabelsControl = (
  props: ManageLabelsRestrictedPracticeControlProps,
) => {
  const { restrictedPracticeId, participantId, isDisabled, value } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const createParticipantRestrictedPracticeLabelMutation =
    useCreateParticipantRestrictedPracticeLabelMutation({ retry: false });
  const deleteParticipantRestrictedPracticeLabelMutation =
    useDeleteParticipantRestrictedPracticeLabelMutation({ retry: false });

  const labelManagePermission = usePermission('manage', 'label');
  const participantRestrictedPracticeLabelManagePermission = usePermission(
    'manage',
    'participantRestrictedPracticeLabel',
  );

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type: LabelType.ParticipantRestrictedPractice,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label ${err.message}`));
        }
        case 'ADD': {
          return createParticipantRestrictedPracticeLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              restrictedPracticeId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error adding label to participant restricted practice: ${err.message}`),
            );
        }

        case 'REMOVE': {
          return deleteParticipantRestrictedPracticeLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              restrictedPracticeId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(
                `Error removing label from participant restricted practice: ${err.message}`,
              ),
            );
        }
      }
    },
    [
      createLabelMutation,
      organization.id,
      errorToast,
      createParticipantRestrictedPracticeLabelMutation,
      participantId,
      restrictedPracticeId,
      deleteParticipantRestrictedPracticeLabelMutation,
    ],
  );

  return (
    <LabelsControl
      onChange={onChange}
      value={value}
      isDisabled={isDisabled || !participantRestrictedPracticeLabelManagePermission}
      canManage={labelManagePermission}
      variant='selectGhost'
      type={LabelType.ParticipantRestrictedPractice}
    />
  );
};
