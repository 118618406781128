import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import type { ParticipantBehaviour, ParticipantQr } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ParticipantQRDeleteModal } from './ParticipantQRDeleteModal';
import { ParticipantQREditModal } from './ParticipantQREditModal';

interface Props {
  isDisabled?: boolean;
  qr: ParticipantQr;
  participantBehaviours: ParticipantBehaviour[];
  participantId: string;
}

export const ParticipantQRActionsMenu = (props: Props) => {
  const { isDisabled, qr, participantBehaviours, participantId, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior='unmount' size='sm'>
      <MenuButton
        as={Button}
        isDisabled={isDisabled}
        variant='link'
        size='sm'
        rightIcon={<Icon as={FaCaretDown} />}
        {...rest}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<FaPencilAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantQREditModal, {
              participantId,
              participantBehaviours,
              qr,
            })
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          color='danger'
          icon={<FaRegTrashAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantQRDeleteModal, {
              participantId,
              qrId: qr.id,
            })
          }
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
