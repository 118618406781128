import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import type { Role, User } from '@piccolohealth/pbs-common';
import {
  PaginationSelectFooter,
  Select,
  SelectCheck,
  type SelectComponents,
  type SelectOption,
  type SelectProps,
  useDebouncedCallback,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useUsersInfiniteQuery } from '../../graphql/hooks/useUsersQuery';
import type { UsersQueryVariables } from '../../graphql/types';
import { useAppContext } from '../../hooks/useAppContext';
import { UserAvatar } from '../user/UserAvatar';

export const UserChooserSelectComponents: SelectComponents<User, User> = {
  Option: (props) => {
    const { name, picture, title } = props.option.raw;

    return (
      <HStack spacing={2} py={1} fontSize='sm' w='full'>
        <UserAvatar
          secondary={title}
          picture={picture}
          name={name}
          w='30px'
          h='30px'
          showTooltip={false}
        />
        <Stack spacing={1} lineHeight={1} align='start'>
          <Text fontWeight='semibold'>{name}</Text>
          <Text fontSize='xs' color='secondary'>
            {title}
          </Text>
        </Stack>
        <Spacer />
        <SelectCheck />
      </HStack>
    );
  },
  Value: (props) => {
    const { name, title, picture } = props.option.raw;
    return (
      <HStack spacing={2} fontSize='sm' w='full' lineHeight='normal'>
        <UserAvatar
          secondary={title}
          picture={picture}
          name={name}
          w='18px'
          h='18px'
          showTooltip={false}
        />
        <Text>{name}</Text>
      </HStack>
    );
  },
};

const DEBOUNCE_MS = 750;

export interface UserChooserProps
  extends Omit<SelectProps<User, unknown>, 'value' | 'onChange' | 'options' | 'selectedValues'> {
  value: User | null;
  onChange: (value: User | undefined) => void;
  roles?: Role[];
  participantId?: string;
}

export const UserChooser = (props: UserChooserProps) => {
  const { value, onChange, roles, participantId, isDisabled, ...rest } = props;
  const { organization } = useAppContext();

  const [inputValue, setInputValue] = React.useState('');

  const req: UsersQueryVariables = {
    organizationId: organization.id,
    request: {
      name: inputValue,
      roles,
      participantId,
      pagination: {
        limit: 10000,
      },
    },
  };
  const query = useUsersInfiniteQuery(req, {
    enabled: false,
    keepPreviousData: true,
    cacheTime: 0,
  });

  const isLoading = query.isFetching;
  const hasMore = query.hasNextPage ?? false;

  const pagination = P.first(query.data?.pages ?? [])?.organization?.users?.pagination;

  const options: SelectOption<User>[] = React.useMemo(() => {
    return (query.data?.pages ?? [])
      .flatMap((page) => (page.organization?.users.results as User[]) ?? [])
      .map((user) => {
        return {
          value: user.id,
          label: user.name,
          raw: user,
        };
      });
  }, [query.data?.pages]);

  const debouncedRefetch = useDebouncedCallback(query.refetch, DEBOUNCE_MS);

  const onChangePrime = React.useCallback(
    (value: SelectOption<User> | null) => {
      onChange(value?.raw ?? undefined);
      debouncedRefetch();
    },
    [debouncedRefetch, onChange],
  );

  const onInputChangePrime = React.useCallback(
    (value: string) => {
      setInputValue(value);
      debouncedRefetch();
    },
    [debouncedRefetch],
  );

  const selectedValue = React.useMemo(() => {
    if (!value) {
      return null;
    }

    return {
      value: value.id,
      label: value.name,
      raw: value,
    };
  }, [value]);

  const onOpen = React.useCallback(() => {
    if (!query.isFetched) {
      query.refetch();
    }
  }, [query]);

  return (
    <Select
      value={selectedValue}
      inputValue={inputValue}
      isLoading={isLoading}
      options={options}
      onChange={onChangePrime}
      onInputChange={onInputChangePrime}
      onOpen={onOpen}
      {...rest}
      components={{
        ...UserChooserSelectComponents,
        Footer: () =>
          pagination ? (
            <PaginationSelectFooter
              items='user'
              hasMore={hasMore}
              total={pagination.total}
              isLoading={isLoading}
              fetchNextPage={query.fetchNextPage}
            />
          ) : null,
      }}
    />
  );
};
