import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useFastForwardParticipantMutation } from '../../graphql/hooks/useFastForwardParticipantMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';

export interface Props {
  participantId: string;
}

export const ParticipantVersionStatus = (props: Props) => {
  const { participantId } = props;

  const { organization, successToast, errorToast } = useAppContext();
  const disclosure = useDisclosure();
  const fastForwardParticipantMutation = useFastForwardParticipantMutation();
  const hasPermission = usePermission('fastForward', 'participant');

  const onFastForward = React.useCallback(async () => {
    await fastForwardParticipantMutation
      .mutateAsync({
        organizationId: organization.id,
        participantId: participantId,
      })
      .then(() => {
        successToast('Participant updated successfully');
        disclosure.onClose();
      })
      .catch((err) => {
        errorToast(`Error updating participant: ${err.message}`);
      });
  }, [
    fastForwardParticipantMutation,
    organization.id,
    participantId,
    successToast,
    errorToast,
    disclosure,
  ]);

  return (
    <Alert status='info'>
      <AlertIcon />
      <Text fontSize='sm' fontWeight='semibold'>
        This participant is using fields from an older version because it was archived. If you would
        like to use the latest version{' '}
        <Popover
          returnFocusOnClose={false}
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          closeOnBlur={true}
          variant='responsive'
          placement='right'
        >
          <PopoverTrigger>
            <span>
              <Tooltip
                label='Only admin users can update a participant to the latest fields'
                isDisabled={hasPermission}
              >
                <Button
                  variant='link'
                  display='inline-block'
                  size='sm'
                  colorScheme='purple'
                  onClick={disclosure.onOpen}
                  isDisabled={!hasPermission}
                >
                  click here.
                </Button>
              </Tooltip>
            </span>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverHeader fontWeight='semibold'>Confirmation</PopoverHeader>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody fontSize='sm'>
                Updating to the latest version may result in data loss if fields are not compatible
                or deleted. Are you sure you want to continue?
              </PopoverBody>
              <PopoverFooter display='flex' justifyContent='flex-end'>
                <ButtonGroup size='sm'>
                  <Button variant='outline'>Cancel</Button>
                  <Button
                    colorScheme='red'
                    onClick={onFastForward}
                    isLoading={fastForwardParticipantMutation.isLoading}
                  >
                    Apply
                  </Button>
                </ButtonGroup>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      </Text>
    </Alert>
  );
};
