import { HStack, Spacer, Switch, Text } from '@chakra-ui/react';
import { TrendlineType } from '@piccolohealth/pbs-common';
import { P, uuid } from '@piccolohealth/util';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSection } from '../../../../components/forms/FormSection';
import { FormStack } from '../../../../components/forms/FormStack';
import { HookedColorPickerHex } from '../../../../components/forms/HookedColorPickerHex';
import { HookedFormItem } from '../../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../../components/forms/HookedInput';
import { HookedSelect } from '../../../../components/forms/HookedSelect';
import { BadgeItem } from '../../../../components/generic/BadgeItem';
import { COLORS } from '../../../participantData/utils';
import { TrendlineTypeIcon } from './TrendlineTypeIcon';

export const TrendlineForm = () => {
  const { watch, setValue } = useFormContext();
  const trendline = watch('trendline');

  const [isTrendlineVisible, setIsTrendlineVisible] = React.useState(!P.isNil(trendline));

  const onToggleTrendline = (checked: boolean) => {
    if (checked) {
      setIsTrendlineVisible(true);
      setValue(
        'trendline',
        {
          __typename: 'Trendline',
          id: uuid(),
          name: 'Trendline',
          type: TrendlineType.Linear,
          color: COLORS.blue,
        },
        {
          shouldDirty: true,
        },
      );
    } else {
      setIsTrendlineVisible(false);
      setValue('trendline', null, {
        shouldDirty: true,
      });
    }
  };

  return (
    <FormSection
      heading={
        <HStack>
          <Text>Trendline</Text>
          <Spacer />
          <Switch
            isChecked={isTrendlineVisible}
            onChange={(e) => onToggleTrendline(e.target.checked)}
          />
        </HStack>
      }
      description='Add a trendline to show the general direction of the data'
    >
      {isTrendlineVisible && (
        <>
          <FormStack orientation='horizontal'>
            <HookedFormItem name='trendline.name' label='Name'>
              <HookedInput name='trendline.name' placeholder='Trendline name' />
            </HookedFormItem>
          </FormStack>
          <FormStack orientation='horizontal'>
            <HookedFormItem name='trendline.type' label='Type'>
              <HookedSelect
                name='trendline.type'
                options={[
                  {
                    label: 'Linear',
                    value: TrendlineType.Linear,
                    raw: TrendlineType.Linear,
                  },
                  {
                    label: 'Polynomial',
                    value: TrendlineType.Polynomial,
                    raw: TrendlineType.Polynomial,
                  },
                ]}
                components={{
                  Value: (ps) => (
                    <BadgeItem
                      label={ps.option.label}
                      color={ps.option.color}
                      icon={<TrendlineTypeIcon trendlineType={ps.option.raw} />}
                    />
                  ),
                  Option: (ps) => (
                    <BadgeItem
                      label={ps.option.label}
                      color={ps.option.color}
                      icon={<TrendlineTypeIcon trendlineType={ps.option.raw} />}
                    />
                  ),
                }}
              />
            </HookedFormItem>
            <HookedFormItem name='trendline.color' label='Color' alignItems='middle'>
              <HStack h='full' justify='middle'>
                <HookedColorPickerHex name='trendline.color' />
              </HStack>
            </HookedFormItem>
          </FormStack>
        </>
      )}
    </FormSection>
  );
};
