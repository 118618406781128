import type { Participant } from '@piccolohealth/pbs-common';
import type { RowSelectionOptions } from '@piccolohealth/ui';
import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import type { ParticipantsFilter } from '../../hooks/useParticipantsFilter';
import { type InfiniteQueryOptions, createGqlQuery, createPaginatedGqlQuery } from '../fetcher';
import { Participants } from '../queries/participants.query';
import type * as Types from '../types';

export const getParticipantsBaseKey = (variables: Types.ParticipantsQueryVariables) => [
  'participant',
  variables,
];

export const useParticipantsQuery = createGqlQuery<
  Types.ParticipantsQueryVariables,
  Types.ParticipantsQuery
>(getParticipantsBaseKey, Participants);

export const useParticipantsFilterQuery = (filter: ParticipantsFilter) => {
  const { organization } = useAppContext();
  const [selectedRows, onRowsSelect] = React.useState<Participant[]>([]);

  const query = createPaginatedGqlQuery<Types.ParticipantsQueryVariables, Types.ParticipantsQuery>(
    getParticipantsBaseKey,
    Participants,
    {
      filter,
      getPaginationResponse: (response) => {
        return response?.organization?.participants?.pagination;
      },
    },
  );

  const rowSelection: RowSelectionOptions<Participant> = {
    selectedRows,
    onRowsSelect,
  };

  const resp = query({
    organizationId: organization.id,
    request: {
      pagination: {
        offset: filter.currentPageNumber * filter.pageSize,
        limit: filter.pageSize,
      },
      name: filter.nameFilter,
      ndisNumber: filter.ndisNumberFilter,
      location: filter.locationFilter,
      status: filter.statusFilter,
      assignedTo: filter.assignedToFilter,
      label: filter.labelFilter,
      start: filter.startDateFilter,
      end: filter.endDateFilter,
    },
  });

  const participants = (resp.data?.organization?.participants?.results as Participant[]) ?? [];

  return {
    ...resp,
    participants,
    rowSelection,
  };
};

export const useParticipantsInfiniteQuery = (
  variables: Types.ParticipantsQueryVariables,
  options?: InfiniteQueryOptions<Types.ParticipantsQuery>,
) => {
  const fetcher = useParticipantsQuery.getFetcher();

  const query = useInfiniteQuery(
    useParticipantsQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage?.organization?.participants?.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );

  return query;
};
