import { P } from '@piccolohealth/util';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormItem, type FormItemProps } from './FormItem';

interface Props extends Omit<FormItemProps, 'id'> {
  name: string;
  label?: string;
  helperText?: string;
}

export const HookedFormItem = (props: React.PropsWithChildren<Props>) => {
  const { name, ...rest } = props;
  const { formState } = useFormContext();

  const field = P.get(formState.errors, `${name}`) ?? undefined;

  const message: string | undefined = P.isArray(field)
    ? field.map(({ message }) => message).join(', ')
    : field?.message?.toString();

  return <FormItem {...rest} id={name} error={message} />;
};
