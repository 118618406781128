import { Tag, TagLabel, type TagProps } from '@chakra-ui/react';
import type { Method } from '@piccolohealth/pbs-common';
import type { SelectOption } from '@piccolohealth/ui';
import React from 'react';

export const methodOptions: Record<
  NonNullable<Method['__typename']>,
  SelectOption<Method['__typename']>
> = {
  AbcMethod: {
    label: 'ABC',
    value: 'AbcMethod',
    raw: 'AbcMethod',
    color: 'blue',
  },
  DurationMethod: {
    label: 'Duration',
    value: 'DurationMethod',
    raw: 'DurationMethod',
    color: 'yellow',
  },
  EpisodicSeverityMethod: {
    label: 'Episodic Severity',
    value: 'EpisodicSeverityMethod',
    raw: 'EpisodicSeverityMethod',
    color: 'green',
  },
  FrequencyMethod: {
    label: 'Frequency',
    value: 'FrequencyMethod',
    raw: 'FrequencyMethod',
    color: 'red',
  },
} as const;

interface Props extends TagProps {
  method: Pick<Method, '__typename'>;
}

export const MethodTag = (props: Props) => {
  const { method, ...rest } = props;

  const option = methodOptions[method.__typename ?? 'FrequencyMethod'];

  return (
    <Tag size='sm' colorScheme={option.color} {...rest}>
      <TagLabel>{option.label}</TagLabel>
    </Tag>
  );
};
