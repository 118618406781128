import { Link as ChakraLink, forwardRef } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

export const TextLink = forwardRef((props, ref) => {
  const { children, ...rest } = props;
  return (
    <ChakraLink ref={ref} as={ReactRouterLink} {...rest}>
      {children}
    </ChakraLink>
  );
});
