import { Divider, Stack, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { TableSeries } from '@piccolohealth/pbs-common';
import { FloatingPopover } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormSection } from '../../../../components/forms/FormSection';
import { HookedSubmitButton } from '../../../../components/forms/HookedSubmitButton';
import type { UseTableReturn } from '../../hooks/useTable';
import { tableSeriesSchema } from './TableSeriesAddControl';
import { TableSeriesButtonGroup } from './TableSeriesButtonGroup';
import { TableSeriesForm } from './TableSeriesForm';

type FormValues = TableSeries;

interface TableSeriesEditFormProps {
  table: UseTableReturn;
  series: TableSeries;
  onClose: () => void;
}

export const TableSeriesEditForm = (props: TableSeriesEditFormProps) => {
  const { table, series, onClose } = props;

  const methods = useForm<TableSeries>({
    defaultValues: series,
    resolver: zodResolver(tableSeriesSchema),
  });

  const onSubmit = (values: FormValues) => {
    table.onEditSeries(values.id, values);
    onClose();
  };

  return (
    <Stack
      bg='white'
      spacing={0}
      w='md'
      layerStyle='bordered'
      shadow='popover'
      rounded='md'
      overflow='hidden'
      p={4}
    >
      <FormProvider {...methods}>
        <FormSection as='form' heading='Edit series' onSubmit={methods.handleSubmit(onSubmit)}>
          <Divider />
          <TableSeriesForm table={table} />
          <HookedSubmitButton w='full' size='sm' colorScheme='purple'>
            Save
          </HookedSubmitButton>
        </FormSection>
      </FormProvider>
    </Stack>
  );
};

interface Props {
  table: UseTableReturn;
  series: TableSeries;
}

export const TableSeriesEditControl = (props: Props) => {
  const { series, table } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FloatingPopover
      open={isOpen}
      setOpen={(v) => (v ? onOpen() : onClose())}
      render={() => <TableSeriesEditForm table={table} series={series} onClose={onClose} />}
    >
      <TableSeriesButtonGroup table={table} series={series} onClick={onOpen} />
    </FloatingPopover>
  );
};
