import {
  Box,
  Button,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { LabelType, type Participant, type User } from '@piccolohealth/pbs-common';
import { type Row, ScrollArea } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormStack } from '../../components/forms/FormStack';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedLabelChooser } from '../../components/forms/HookedLabelChooser';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useCreateParticipantUsersMutation } from '../../graphql/hooks/useCreateParticipantUsersMutation';
import { useParticipantsFilterQuery } from '../../graphql/hooks/useParticipantsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { useParticipantsFilter } from '../../hooks/useParticipantsFilter';
import { ParticipantsFilterControls } from '../participant/ParticipantsFilterControls';
import { UserParticipantsAddTable } from './UserParticipantsAddTable';

type FormValues = {
  participantIds: string[];
  labelIds: string[];
};

interface Props {
  user: User;
}

export const UserParticipantAddModal = createModal<Props>((props) => {
  const { user, modal } = props;
  const { visible, hide, remove } = modal;

  const { organization, successToast, errorToast } = useAppContext();

  const filter = useParticipantsFilter();
  const { isLoading, error, participants, rowSelection, refetch, pagination } =
    useParticipantsFilterQuery(filter);

  const { selectedRows } = rowSelection;

  const methods = useForm<FormValues>({
    defaultValues: {
      participantIds: [],
      labelIds: [],
    },
  });

  const { setValue, watch, reset } = methods;

  const selectedParticipants = watch('participantIds');

  const mutation = useCreateParticipantUsersMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          requests: values.participantIds.map((participantId) => ({
            participantId,
            userId: user.id,
            labelIds: values.labelIds,
          })),
        })
        .then(() => {
          rowSelection.onRowsSelect([]);
          reset();
          successToast('Participants added to user successfully');
        })
        .catch((err) => {
          errorToast(`Error adding participants to user: ${err.message}`);
        });

      return;
    },
    [errorToast, mutation, organization.id, reset, rowSelection, successToast, user.id],
  );

  const enableRowSelection = React.useCallback(
    (row: Row<Participant>) =>
      P.isEmpty(row.original.assignedUsers) ||
      row.original.assignedUsers.every((u) => u.user.id !== user.id),
    [user.id],
  );

  // https://github.com/biomejs/biome/issues/630
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to run this effect when the participants change
  React.useEffect(() => {
    setValue(
      'participantIds',
      selectedRows.map((participant) => participant.id),
      {
        shouldDirty: true,
      },
    );
  }, [participants, selectedRows, setValue]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='32'>
      <ModalOverlay />
      <ModalContent maxW='1200px'>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <HStack>
                <Box>
                  <Heading size='md'>Add participants to user</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </HStack>
            </ModalHeader>
            <ModalBody overflowY='auto' pb={4} px={0}>
              <Stack h='full' spacing={6}>
                <Box px={6}>
                  <ParticipantsFilterControls filter={filter} />
                </Box>
                <ScrollArea overflowY='auto' pl={6} minH='420px'>
                  <UserParticipantsAddTable
                    user={user}
                    participants={participants}
                    isLoading={isLoading}
                    error={error}
                    refetch={refetch}
                    pagination={pagination}
                    rowSelection={{ ...rowSelection, enableRowSelection }}
                  />
                </ScrollArea>
                <Box px={6}>
                  <HStack
                    w='full'
                    bg='gray.50'
                    layerStyle='bordered'
                    px={8}
                    py={4}
                    rounded='md'
                    align='end'
                  >
                    <FormStack w='4xl' orientation='horizontal'>
                      <HookedFormItem
                        name='participantids'
                        label='Participants'
                        helperText='The participants to assign this user'
                      >
                        <Text fontSize='sm'>
                          {P.isEmpty(selectedParticipants)
                            ? 'No participants selected'
                            : `${selectedParticipants.length} ${inflection.inflect(
                                'participant',
                                selectedParticipants.length,
                              )} selected`}
                        </Text>
                      </HookedFormItem>
                      <HookedFormItem
                        name='labelIds'
                        label='Labels'
                        helperText='The labels to assign this user'
                        flexGrow={1}
                      >
                        <HookedLabelChooser
                          name='labelIds'
                          type={LabelType.ParticipantUser}
                          bg='white'
                        />
                      </HookedFormItem>
                    </FormStack>
                    <Spacer />
                    <HStack>
                      <Button size='sm' onClick={hide}>
                        Close
                      </Button>
                      <HookedSubmitButton size='sm'>Add participants</HookedSubmitButton>
                    </HStack>
                  </HStack>
                </Box>
              </Stack>
            </ModalBody>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
