import {
  Button,
  type ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import type { ParticipantFile } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ParticipantFileDeleteModal } from './ParticipantFileDeleteModal';
import { ParticipantFileEditModal } from './ParticipantFileEditModal';

interface Props extends ButtonProps {
  file: ParticipantFile;
  isDisabled?: boolean;
}

export const ParticipantFileActionMenu = (props: Props) => {
  const { file, isDisabled, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior='unmount' size='sm'>
      <MenuButton
        as={Button}
        isDisabled={isDisabled}
        variant='link'
        size='sm'
        rightIcon={<Icon as={FaCaretDown} />}
        {...rest}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<FaPencilAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantFileEditModal, {
              file,
            })
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          color='danger'
          icon={<FaRegTrashAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantFileDeleteModal, {
              participantId: file.participantId,
              participantFileId: file.id,
            })
          }
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
