import { Box, HStack, Spacer, Stack } from '@chakra-ui/react';
import type { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import { ScrollArea, Spin } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../../components/generic/Error';
import { useParticipantBehavioursRecordingsQuery } from '../../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ParticipantBehaviourRecordingCreateButton } from '../../behaviours/components/ParticipantBehaviourRecordingCreateButton';
import { ParticipantBehaviourRecordingsTable } from '../../behaviours/components/ParticipantBehaviourRecordingsTable';

interface Props {
  participantId: string;
  tabButtons: React.ReactNode;
  isDisabled?: boolean;
}

export const ParticipantDataTablesTab = (props: Props) => {
  const { participantId, tabButtons, isDisabled } = props;

  const { organization } = useAppContext();

  const { isLoading, error, data } = useParticipantBehavioursRecordingsQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviours =
    (data?.organization?.participant?.behaviours as ParticipantBehaviour[]) ?? [];

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Stack w='full' h='full' spacing={4} overflowY='hidden'>
      <HStack w='full' px={6} pt={4}>
        {tabButtons}
        <Box w='full' pb={1}>
          <Box h='34px' />
        </Box>
        <Spacer />
        <HStack flexShrink={1}>
          <ParticipantBehaviourRecordingCreateButton
            participantId={participantId}
            participantBehaviours={participantBehaviours}
            colorScheme='purple'
            isDisabled={isDisabled}
          >
            Add recording
          </ParticipantBehaviourRecordingCreateButton>
        </HStack>
      </HStack>
      <ScrollArea w='full' h='full' bg='white' overflowY='auto' px={6}>
        <ParticipantBehaviourRecordingsTable
          participantId={participantId}
          participantBehaviours={participantBehaviours}
        />
      </ScrollArea>
    </Stack>
  );
};
