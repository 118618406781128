import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateParticipantNote } from '../mutations/createParticipantNote.mutation';
import type {
  CreateParticipantNoteMutation,
  CreateParticipantNoteMutationVariables,
} from '../types';

export const useCreateParticipantNoteMutation = (
  options?: MutationOptions<CreateParticipantNoteMutation, CreateParticipantNoteMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantNote), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('participant') || query.queryKey.includes('recording'),
      });
    },
  });
};
