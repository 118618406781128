import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type CreateParticipantRequest,
  Gender,
  ParticipantStatus,
} from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useCreateParticipantMutation } from '../../graphql/hooks/useCreateParticipantMutation';
import { useParticipantFieldGroupTemplatesQuery } from '../../graphql/hooks/useParticipantFieldGroupTemplatesQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { dateTimeSchema } from '../../utils/zod';
import { ParticipantForm } from './ParticipantForm';

type FormValues = CreateParticipantRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  organizationId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  dob: dateTimeSchema,
  gender: z.nativeEnum(Gender),
  address: z.string().nullish(),
  postcode: z.string().nullish(),
  suburb: z.string().nullish(),
  state: z.string().nullish(),
  locationId: z.string(),
  labelIds: z.array(z.string()),
  status: z.nativeEnum(ParticipantStatus),
  ndisNumber: z.string().nullish(),
  email: z.string().email().nullish(),
  phone: z.string().nullish(),
  picture: z.string().nullish(),
  keyContacts: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      phone: z.string().nullish(),
      email: z.string().email().nullish(),
      description: z.string().nullish(),
      labelIds: z.array(z.string()),
    }),
  ),
  fieldGroups: z.array(
    z.object({
      participantFieldGroupTemplateId: z.string(),
      fields: z.array(
        z.object({
          participantFieldTemplateId: z.string(),
          value: z.any(),
        }),
      ),
    }),
  ),
});

export const ParticipantCreateModal = createModal<{}>((props) => {
  const { modal } = props;
  const { errorToast, successToast, organization } = useAppContext();

  const { data } = useParticipantFieldGroupTemplatesQuery({
    organizationId: organization.id,
  });

  const { hide, remove, visible } = modal;

  const methods = useForm<FormValues>({
    defaultValues: {
      organizationId: organization.id,
      firstName: undefined,
      lastName: undefined,
      dob: undefined,
      gender: undefined,
      address: undefined,
      postcode: undefined,
      suburb: undefined,
      state: undefined,
      locationId: undefined,
      labelIds: [],
      status: ParticipantStatus.New,
      ndisNumber: undefined,
      email: undefined,
      phone: undefined,
      picture: undefined,
      fieldGroups: [],
      keyContacts: [],
    },
    mode: 'all',
    resolver: zodResolver(schema),
  });

  React.useEffect(() => {
    const participantFieldGroupTemplates = data?.organization?.participantFieldGroupTemplates ?? [];
    const fieldGroups = participantFieldGroupTemplates.map((group) => {
      return {
        participantFieldGroupTemplateId: group.id,
        template: group,
        fields: group.fields.map((field) => {
          return {
            participantFieldTemplateId: field.id,
            template: field,
          };
        }),
      };
    });

    methods.setValue('fieldGroups', fieldGroups);
  }, [data, methods]);

  const mutation = useCreateParticipantMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const fieldGroups = values.fieldGroups.map((group) => {
        return {
          participantFieldGroupTemplateId: group.participantFieldGroupTemplateId,
          fields: group.fields.map((field) => {
            return {
              participantFieldTemplateId: field.participantFieldTemplateId,
              value: field.value,
            };
          }),
        };
      });

      await mutation
        .mutateAsync({
          request: {
            ...P.deepTrim(values),
            fieldGroups,
          },
        })
        .then(() => {
          hide();
          successToast('Participant created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating participant: ${err.message}`);
        });
    },
    [mutation, hide, errorToast, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Add a participant</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <ParticipantForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size='sm' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size='sm'>Create</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
