import { createGqlQuery } from '../fetcher';
import { ParticipantFieldGroupTemplate } from '../queries/participantFieldGroupTemplate.query';
import type * as Types from '../types';

export const useParticipantFieldGroupTemplateQuery = createGqlQuery<
  Types.ParticipantFieldGroupTemplateQueryVariables,
  Types.ParticipantFieldGroupTemplateQuery
>(
  (variables) => [
    'organization',
    variables.organizationId,
    'participantFieldGroupTemplate',
    variables.participantFieldGroupTemplateId,
  ],
  ParticipantFieldGroupTemplate,
);
