import {
  Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Grid,
  HStack,
  Heading,
  Icon,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { type Participant, renderGender, renderParticipantName } from '@piccolohealth/pbs-common';
import { Empty, FancyDate } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import AvatarMaleImage from '../../assets/avatar-male.png';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { LabelsStack } from '../../components/generic/LabelsStack';
import { ListItem } from '../../components/generic/ListItem';
import { showModal } from '../../components/generic/Modal';
import { ProfileItem } from '../../components/generic/ProfileItem';
import { UserAvatar } from '../../components/user/UserAvatar';
import { gridItemSize } from '../../utils/gridItemSize';
import { ParticipantEditModal } from '../participant/ParticipantEditModal';
import { ParticipantStatusTag } from '../participant/ParticipantStatusTag';
import { ParticipantFieldValue } from './ParticipantFieldValue';

export interface Props {
  participant: Participant;
  isDisabled: boolean;
}

export const ParticipantProfileCard = (props: Props) => {
  const { participant, isDisabled } = props;

  const [tabIndex, setTabIndex] = React.useState(0);

  const onEditProfile = React.useCallback(() => {
    return showModal(ParticipantEditModal, {
      participantId: participant.id,
      isDisabled,
      openToTabIndex: tabIndex,
    });
  }, [isDisabled, participant.id, tabIndex]);

  const dob = DateTime.fromISO(participant.dob.toString()).toLocaleString(DateTime.DATE_SHORT);
  const age = DateTime.now().diff(DateTime.fromISO(participant.dob.toString()), [
    'years',
    'months',
  ]).years;

  return (
    <Card h='full'>
      <CardHeader>
        <HStack align='stretch'>
          <Stack spacing={0}>
            <Heading size='md'>Profile</Heading>
            <Text fontSize='sm' color='secondary'>
              View and edit personal details
            </Text>
          </Stack>
          <Spacer />
          <Button leftIcon={<Icon as={FaPencilAlt} />} size='sm' onClick={onEditProfile}>
            Edit profile
          </Button>
        </HStack>
      </CardHeader>
      <CardBody>
        <Stack spacing={4} h='full'>
          <Tabs
            index={tabIndex}
            onChange={setTabIndex}
            orientation='vertical'
            variant='soft-rounded'
            w='full'
            h='full'
          >
            <TabList>
              <Tab>General</Tab>
              <Tab>Key contacts</Tab>
              {participant.fieldGroups.map((fieldGroup) => (
                <Tab key={`${fieldGroup.id}-tab`}>{fieldGroup.template.name}</Tab>
              ))}
            </TabList>

            <TabPanels ml={6} pl={6} borderLeftWidth='1px' borderLeftColor='gray.200'>
              <TabPanel>
                <FormStack>
                  <FormSection heading='Basic details'>
                    <Grid w='full' templateColumns='repeat(2, 1fr)' gap={4} rowGap={6}>
                      <ProfileItem label='Name'>{renderParticipantName(participant)}</ProfileItem>
                      <ProfileItem label='Gender'>{renderGender(participant.gender)}</ProfileItem>
                      <ProfileItem label='Created'>
                        <FancyDate date={participant.createdAt} showHumanized={false} />
                      </ProfileItem>
                      <ProfileItem label='Date of birth'>
                        {dob} - {age} years old
                      </ProfileItem>
                      <ProfileItem label='Status'>
                        <ParticipantStatusTag status={participant.status} size='sm' />
                      </ProfileItem>
                      <ProfileItem label='Clinic location'>
                        <Tag w='fit-content' size='sm' colorScheme={participant.location.color}>
                          {participant.location.name}
                        </Tag>
                      </ProfileItem>
                      <ProfileItem label='NDIS number'>{participant.ndisNumber ?? '-'}</ProfileItem>
                      <ProfileItem label='Email'>{participant.email ?? '-'}</ProfileItem>
                      <ProfileItem label='Phone number'>{participant.phone ?? '-'}</ProfileItem>

                      <ProfileItem label='Labels'>
                        {P.isEmpty(participant.labels) ? (
                          '-'
                        ) : (
                          <Wrap pt={2}>
                            {participant.labels.map((label) => {
                              return (
                                <WrapItem key={label.id}>
                                  <Tag w='fit-content' size='sm' colorScheme={label.color}>
                                    {label.name}
                                  </Tag>
                                </WrapItem>
                              );
                            })}
                          </Wrap>
                        )}
                      </ProfileItem>
                      <ProfileItem label='Profile picture'>
                        <Avatar mt={1} src={participant.picture ?? AvatarMaleImage} size='lg' />
                      </ProfileItem>
                    </Grid>
                  </FormSection>

                  <Divider />

                  <FormSection heading='Address details'>
                    <Grid w='full' templateColumns='repeat(2, 1fr)' gap={4} rowGap={6}>
                      <ProfileItem label='Address'>{participant.address ?? '-'}</ProfileItem>
                      <ProfileItem label='Suburb'>{participant.suburb ?? '-'}</ProfileItem>
                      <ProfileItem label='State'>{participant.state ?? '-'}</ProfileItem>
                      <ProfileItem label='Postcode'>{participant.postcode ?? '-'}</ProfileItem>
                    </Grid>
                  </FormSection>
                </FormStack>
              </TabPanel>

              <TabPanel>
                <FormSection heading='Key contacts'>
                  {P.isEmpty(participant.keyContacts) && (
                    <Empty h='full' title='No key contacts assigned yet' p={0} />
                  )}
                  {participant.keyContacts.map((keyContact) => {
                    const secondary =
                      P.compact([
                        keyContact.description ?? 'Unknown relationship',
                        keyContact.email,
                        keyContact.phone,
                      ]).join(', ') ?? '-';

                    return (
                      <ListItem
                        key={keyContact.id}
                        icon={
                          <UserAvatar
                            name={keyContact.name}
                            secondary={secondary}
                            w={10}
                            h={10}
                            showTooltip={false}
                          />
                        }
                        title={keyContact.name}
                        secondary={secondary}
                        tertiary={<LabelsStack labels={keyContact.labels} />}
                        right={null}
                      />
                    );
                  })}
                </FormSection>
              </TabPanel>

              {participant.fieldGroups.map((fieldGroup) => {
                const content = P.run(() => {
                  if (P.isEmpty(fieldGroup.fields)) {
                    return <Empty title='No fields found' />;
                  }

                  return (
                    <FormSection heading={fieldGroup.template.name}>
                      <Grid w='full' templateColumns='repeat(2, 1fr)' gap={4} rowGap={6}>
                        {fieldGroup.fields.map((field) => (
                          <ProfileItem
                            key={field.id}
                            label={`${field.template.name}`}
                            colSpan={gridItemSize(field.template.type)}
                          >
                            <ParticipantFieldValue field={field} />
                          </ProfileItem>
                        ))}
                      </Grid>
                    </FormSection>
                  );
                });

                return <TabPanel key={`${fieldGroup.id}-panel`}>{content}</TabPanel>;
              })}
            </TabPanels>
          </Tabs>
          <Button leftIcon={<Icon as={FaPencilAlt} />} size='sm' onClick={onEditProfile}>
            Edit profile
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};
