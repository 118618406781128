import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  HStack,
  Input,
  Spacer,
} from '@chakra-ui/react';
import type * as Common from '@piccolohealth/pbs-common';
import { DndKitSortable, DragHandle, Sortable } from '@piccolohealth/ui';
import { dataAttr } from '@piccolohealth/ui';
import React from 'react';
import { useHoverDirty } from 'react-use';
import { EditableControls } from '../../../components/generic/EditableControls';
import { showModal } from '../../../components/generic/Modal';
import { ParticipantFieldGroupTemplateDeleteModal } from './ParticipantFieldGroupTemplateDeleteModal';

interface Props {
  fieldGroupTemplate: Common.ParticipantFieldGroupTemplate;
  isActive?: boolean;
  onClick: () => void;
  onEdit: (name: string) => void;
  onRemove: () => void;
}

export const ParticipantFieldGroupTemplate = (props: Props) => {
  const { fieldGroupTemplate, isActive, onClick, onEdit, onRemove } = props;

  const [value, setValue] = React.useState(fieldGroupTemplate.name);

  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);

  const { attributes, setNodeRef, listeners, transform, transition } = DndKitSortable.useSortable({
    id: fieldGroupTemplate.id,
  });

  React.useEffect(() => {
    setValue(fieldGroupTemplate.name);
  }, [fieldGroupTemplate.name]);

  return (
    <Sortable ref={setNodeRef} transform={transform} transition={transition}>
      <Editable isPreviewFocusable={false} value={value} onChange={setValue} onSubmit={onEdit}>
        <Box
          ref={ref}
          px={1.5}
          py={1.5}
          rounded='md'
          bg='white'
          _active={{
            color: 'gray.600',
            bg: 'gray.100',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          cursor='pointer'
          {...{ 'data-active': dataAttr(isActive) }}
        >
          <HStack spacing={1} h='full'>
            <DragHandle listeners={listeners} attributes={attributes} size='xs' />
            <HStack w='full' pr={2}>
              <Flex
                flexDir='column'
                align='start'
                fontSize='sm'
                fontWeight='semibold'
                color='gray.600'
                w='full'
                flexGrow={1}
              >
                <EditablePreview w='full' cursor='pointer' p={0} px={1.5} py={0.5} />
                <Input
                  as={EditableInput}
                  bg='white'
                  variant='unstyled'
                  size='sm'
                  px={1.5}
                  py={0.5}
                  h='auto'
                  _focusVisible={{ bg: 'white', boxShadow: 'none' }}
                />
              </Flex>
              <Spacer />
              <EditableControls
                onRemove={() => {
                  showModal(ParticipantFieldGroupTemplateDeleteModal, {
                    participantFieldGroupTemplateId: fieldGroupTemplate.id,
                    onDelete: onRemove,
                  });
                }}
                isHovering={isHovering}
              />
            </HStack>
          </HStack>
        </Box>
      </Editable>
    </Sortable>
  );
};
