import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateParticipant } from '../mutations/updateParticipant.mutation';
import type { UpdateParticipantMutation, UpdateParticipantMutationVariables } from '../types';

export const useUpdateParticipantMutation = (
  options?: MutationOptions<UpdateParticipantMutation, UpdateParticipantMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateParticipant), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
