import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateUser } from '../mutations/updateUser.mutation';
import type { UpdateUserMutation, UpdateUserMutationVariables } from '../types';

export const useUpdateUserMutation = (
  options?: MutationOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateUser), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('user'),
      });
    },
  });
};
