import type {
  ParticipantBehaviour,
  PhaseChangeLine,
  TimeSeries,
  TimeSeriesChartWidget,
} from '@piccolohealth/pbs-common';
import { DateTime } from '@piccolohealth/util';
import type { EChartsOption, Payload } from 'echarts';
import type EChartsReact from 'echarts-for-react';
import type { EChartsInstance } from 'echarts-for-react';
import React from 'react';
import { ChartTooltip } from '../components/timeseries/ChartTooltip';
import { getNormalizedRecordingDataset } from '../utils';
import { useTooltipRef } from './useTooltipRef';

export interface UseTimeseriesChartOptions {
  widget: TimeSeriesChartWidget;
  participantBehaviours: ParticipantBehaviour[];
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (date: DateTime) => void;
  onEndDateChange: (date: DateTime) => void;
  setWidget: React.Dispatch<React.SetStateAction<TimeSeriesChartWidget>>;
  withOptions?: (options: EChartsOption) => EChartsOption;
}

export const useTimeseriesChart = (opts: UseTimeseriesChartOptions) => {
  const {
    participantBehaviours,
    widget,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    setWidget,
    withOptions = (options) => options,
  } = opts;

  const ref = React.useRef<EChartsReact>(null);

  const tooltipRender = useTooltipRef();

  const options: EChartsOption = React.useMemo(() => {
    const normalizedDataset = getNormalizedRecordingDataset({
      participantBehaviours,
      series: widget.series,
      phaseChangeLines: widget.phaseChangeLines,
    });

    return withOptions({
      dataset: normalizedDataset.dataset,
      series: normalizedDataset.seriesOptions,
      grid: {
        top: 80,
        bottom: 180,
        left: 60,
        right: 60,
      },
      xAxis: {
        type: 'time',
        name: 'Date of recording',
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          fontFamily: 'Inter, sans-serif',
          fontSize: 14,
          fontWeight: 'bold',
        },

        axisLabel: {
          fontFamily: 'Inter, sans-serif',
          formatter: normalizedDataset.xAxisFormat,
          hideOverlap: true,
        },
      },
      yAxis: [
        {
          type: 'value',
          name: 'Frequency of behaviour',
          nameLocation: 'middle',
          nameGap: 40,
          nameRotate: 90,
          nameTextStyle: {
            fontFamily: 'Inter, sans-serif',
            fontSize: 14,
            fontWeight: 'bold',
          },
          minInterval: 1,
          splitNumber: 5,
          axisLabel: {
            fontFamily: 'Inter, sans-serif',
          },
        },
      ],
      dataZoom: [
        {
          show: true,
          type: 'slider',
          height: 60,
          startValue: startDate?.toJSDate(),
          endValue: endDate?.toJSDate(),
          bottom: 20,
          left: 0,
          right: 0,
          borderColor: '#E2E8F0',
          borderRadius: 6,
          textStyle: {
            fontWeight: 'bold',
          },
          labelFormatter: (value: number) =>
            DateTime.fromMillis(value).toLocaleString(DateTime.DATE_FULL),
        },
        {
          disabled: false,
          type: 'inside',
          startValue: startDate?.toJSDate(),
          endValue: endDate?.toJSDate(),
        },
      ],
      animationDuration: 400,
      tooltip: {
        confine: false,
        appendTo: document.body,
        position: (point) => {
          return [point[0], point[1]];
        },
        trigger: 'axis',
        axisPointer: {
          label: {
            formatter: (params) => {
              return DateTime.fromJSDate(new Date(params.value)).toLocaleString(DateTime.DATE_FULL);
            },
          },
        },
        formatter: (params) => {
          return (
            tooltipRender(<ChartTooltip params={params} users={normalizedDataset.users} />) ?? '-'
          );
        },
      },
      legend: {
        left: 2,
        top: 2,
        itemGap: 5,
        textStyle: {
          fontFamily: 'Inter, sans-serif',
          fontSize: 11,
        },
      },
    });
  }, [
    endDate,
    participantBehaviours,
    startDate,
    widget.phaseChangeLines,
    widget.series,
    tooltipRender,
    withOptions,
  ]);

  const dispatchAction = React.useCallback((payload: Payload) => {
    if (ref.current) {
      ref.current.getEchartsInstance().dispatchAction(payload);
    }
  }, []);

  const onChartReady = React.useCallback(
    (instance: EChartsInstance) => {
      instance.resize();
      instance.on('datazoom', () => {
        const { startValue, endValue } = instance.getOption().dataZoom[0];

        if (Number.isNaN(startValue) || Number.isNaN(endValue)) {
          return;
        }

        onStartDateChange(DateTime.fromMillis(startValue));
        onEndDateChange(DateTime.fromMillis(endValue));
      });
    },
    [onEndDateChange, onStartDateChange],
  );

  const onAddSeries = React.useCallback(
    (series: TimeSeries) => {
      setWidget((prev) => ({
        ...prev,
        series: [...prev.series, series],
      }));
    },
    [setWidget],
  );

  const onEditSeries = React.useCallback(
    (seriesId: string, series: TimeSeries) => {
      setWidget((prev) => ({
        ...prev,
        series: prev.series.map((s) => (s.id === seriesId ? series : s)),
      }));
    },
    [setWidget],
  );

  const onRemoveSeries = React.useCallback(
    (seriesId: string) => {
      setWidget((prev) => ({
        ...prev,
        series: prev.series.filter((s) => s.id !== seriesId),
      }));
    },
    [setWidget],
  );

  const onAddPhaseChangeLine = React.useCallback(
    (line: PhaseChangeLine) => {
      setWidget((prev) => ({
        ...prev,
        phaseChangeLines: [...prev.phaseChangeLines, line],
      }));
    },
    [setWidget],
  );

  const onEditPhaseChangeLine = React.useCallback(
    (id: string, line: PhaseChangeLine) => {
      setWidget((prev) => ({
        ...prev,
        phaseChangeLines: prev.phaseChangeLines.map((l) => (l.id === id ? line : l)),
      }));
    },
    [setWidget],
  );

  const onRemovePhaseChangeLine = React.useCallback(
    (id: string) => {
      setWidget((prev) => ({
        ...prev,
        phaseChangeLines: prev.phaseChangeLines.filter((l) => l.id !== id),
      }));
    },
    [setWidget],
  );

  const onSetTitle = React.useCallback(
    (title: string) => {
      setWidget((prev) => ({
        ...prev,
        title,
      }));
    },
    [setWidget],
  );

  return {
    ref,
    options,
    participantBehaviours,
    startDate,
    endDate,
    widget,
    dispatchAction,
    onChartReady,
    onAddSeries,
    onEditSeries,
    onRemoveSeries,
    onAddPhaseChangeLine,
    onEditPhaseChangeLine,
    onRemovePhaseChangeLine,
    onSetTitle,
  };
};

export type UseTimeseriesChartReturn = ReturnType<typeof useTimeseriesChart>;
