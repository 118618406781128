import { Icon, Stack, type StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { useDropArea } from '../hooks/useDropArea';
import type { FileUploadStatus } from '../hooks/useFileUpload';
import { FileInput } from './FileInput';

interface Props extends StackProps {
  multiple: boolean;
  files: File[];
  accept?: string;
  status: FileUploadStatus;
  onFilesChange: (files: File[]) => void;
}

export const FileUploadControl = (props: Props) => {
  const { multiple, files, accept, status, onFilesChange, ...rest } = props;

  const [dropAreaProps, state] = useDropArea({
    onFiles: (files) => onFilesChange(files),
  });

  const styles = state.over
    ? {
        bg: 'purple.50',
        borderColor: 'purple.300',
      }
    : {
        bg: 'gray.50',
        borderColor: 'gray.300',
      };

  return (
    <Stack
      {...dropAreaProps}
      {...styles}
      w='full'
      h='full'
      align='center'
      justify='center'
      padding={4}
      borderWidth='2px'
      borderRadius='8px'
      borderStyle='dashed'
      textAlign='center'
      {...rest}
    >
      <Stack align='center' justify='center'>
        <Icon as={FaCloudUploadAlt} fontSize={48} color='gray.400' display='unset' />
        <Text fontSize='xl' fontWeight='bold' color='gray.400'>
          Drag & Drop files here
        </Text>
        <Text fontSize='sm' fontWeight='bold' color='gray.400' mb='4' mt='2'>
          OR
        </Text>
        <FileInput
          multiple={multiple}
          size='sm'
          colorScheme='purple'
          variant='solid'
          files={files}
          onFilesChange={onFilesChange}
          data-pw='browseFilesModalButton'
          accept={accept}
          isLoading={status === 'uploading'}
          loadingText='Uploading...'
        >
          Browse files
        </FileInput>
      </Stack>
    </Stack>
  );
};
