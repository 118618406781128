import { useMutation } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { ExportIncidentsCsv } from '../mutations/exportIncidentsCsv.mutation';
import type { ExportIncidentsCsvMutation, ExportIncidentsCsvMutationVariables } from '../types';

export const useExportIncidentsCsvMutation = (
  options?: MutationOptions<ExportIncidentsCsvMutation, ExportIncidentsCsvMutationVariables>,
) => {
  return useMutation(gqlFetcher(ExportIncidentsCsv), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);
    },
  });
};
