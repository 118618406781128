import { Divider, HStack, Stack, Text } from '@chakra-ui/react';
import Knock, { type PreferenceSet } from '@knocklabs/client';
import {
  NOTIFICATION_CHANNEL_TYPES,
  NOTIFICATION_WORKFLOW_IDENTIFIERS,
  NOTIFICATION_WORKFLOW_METADATA,
} from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { HookedSwitch } from '../../../components/forms/HookedSwitch';
import { useAppContext } from '../../../hooks/useAppContext';

type FormValues = PreferenceSet;

export const NotificationPreferencesForm = () => {
  const { user, config, successToast, errorToast } = useAppContext();

  const knockClient = React.useMemo(() => {
    const knock = new Knock(config.knock.apiKey);
    knock.authenticate(user.id);
    return knock;
  }, [config.knock.apiKey, user.id]);

  const methods = useForm<PreferenceSet>({
    defaultValues: {},
  });
  const { reset } = methods;

  const preferences = methods.watch();

  const fetchPreferences = React.useCallback(async () => {
    const preferences = await knockClient.preferences.get().catch(() => {
      return {} as PreferenceSet;
    });

    // Add any missing workflow/channel type preferences
    const defaultedWorkflowPreferences = NOTIFICATION_WORKFLOW_IDENTIFIERS.reduce(
      (acc, workflowId) => {
        return {
          [workflowId]: {
            channel_types: NOTIFICATION_CHANNEL_TYPES.reduce((acc, channelType) => {
              return {
                ...acc,
                [channelType.id]:
                  P.get(preferences, `workflows.${workflowId}.channel_types.${channelType.id}`) ??
                  true,
              };
            }, {}),
          },
          ...acc,
        };
      },
      {},
    );

    reset({
      ...preferences,
      workflows: defaultedWorkflowPreferences,
    });
  }, [knockClient.preferences, reset]);

  // Read the preferences for the current user
  React.useEffect(() => {
    fetchPreferences();
  }, [fetchPreferences]);

  const onSubmit = async (values: FormValues) => {
    const updatedReferences = await knockClient.preferences.set({
      ...preferences,
      ...values,
    });

    await knockClient.preferences
      .set(updatedReferences)
      .catch((err) => {
        errorToast(`Error updating user profile: ${err.message}`);
      })
      .then(() => {
        successToast('User profile updated successfully');
        fetchPreferences();
      });
  };

  // Show a spinner here or something to indicate prefs are loading
  if (P.isEmptyObject(preferences)) {
    return <Spin />;
  }

  const workflows = NOTIFICATION_WORKFLOW_IDENTIFIERS.map((workflow) => ({
    id: workflow,
    ...NOTIFICATION_WORKFLOW_METADATA[workflow],
  }));

  const groupedWorkflows = P.groupBy(workflows, (workflow) => workflow.category);

  const content = P.listify(groupedWorkflows, (groupedWs, category) => {
    const workflows = groupedWs ?? [];
    return (
      <Stack spacing={4} mt={4}>
        <Text fontSize='md' fontWeight='semibold'>
          {category}
        </Text>

        <Stack spacing={4}>
          {workflows.map((workflow) => (
            <HStack key={workflow.name} align='start' spacing={32}>
              <Stack spacing={0} w='sm'>
                <Text fontSize='sm' fontWeight='semibold'>
                  {workflow.name}
                </Text>
                <Text fontSize='sm' color='secondary'>
                  {workflow.description}
                </Text>
              </Stack>
              <Stack spacing={2} flexShrink={0}>
                {NOTIFICATION_CHANNEL_TYPES.map(({ id, name }) => (
                  <HStack key={id} align='center'>
                    <HookedSwitch
                      colorScheme='purple'
                      name={`workflows.${workflow.id}.channel_types.${id}`}
                    />
                    <Text fontSize='xs' fontWeight='semibold'>
                      {name}
                    </Text>
                  </HStack>
                ))}
              </Stack>
            </HStack>
          ))}
        </Stack>
      </Stack>
    );
  });

  return (
    <FormProvider {...methods}>
      <Stack as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} spacing={6}>
        {P.intersperse(content, () => (
          <Divider />
        ))}
        <HStack>
          <HookedSubmitButton>Save</HookedSubmitButton>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
