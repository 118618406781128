import { SymbolType } from '@piccolohealth/pbs-common';
import React from 'react';

export const SYMBOL_SVGS: Record<SymbolType, string> = {
  [SymbolType.Rect]: 'm2 2h20v20h-20z',
  [SymbolType.Circle]: 'm12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10-10-4.48-10-10 4.48-10 10-10z',
  [SymbolType.Diamond]: 'm12 2l-10 10 10 10 10-10-10-10z',
  [SymbolType.Triangle]: 'm12 2l-10 20h20z',
};

interface Props {
  symbolType: SymbolType;
  color?: string;
  size: number;
}

export const SymbolTypeIcon = (props: Props) => {
  return (
    <svg
      fill={props.color}
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{props.symbolType}</title>
      <path d={SYMBOL_SVGS[props.symbolType]} />
    </svg>
  );
};
