import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type CreateParticipantQrBehaviourMethodsRequest,
  type CreateParticipantQrBehaviourRequest,
  type CreateParticipantQrRequest,
  type ParticipantBehaviour,
  ParticipantQrStatus,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useCreateParticipantQrMutation } from '../../graphql/hooks/useCreateParticipantQrMutation';
import { useParticipantBehavioursQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantQRForm } from './ParticipantQRForm';

type FormValues = CreateParticipantQrRequest;

const createParticipantQrBehaviourMethodsRequestSchema: z.ZodSchema<CreateParticipantQrBehaviourMethodsRequest> =
  z.object({
    abc: z.object({ enabled: z.boolean() }),
    frequency: z.object({ enabled: z.boolean() }),
    duration: z.object({ enabled: z.boolean() }),
    episodicSeverity: z.object({ enabled: z.boolean() }),
  });

export const behaviourSchema: z.ZodSchema<CreateParticipantQrBehaviourRequest> = z.object({
  behaviourId: z.string(),
  methods: createParticipantQrBehaviourMethodsRequestSchema,
});

export const participantQrRequestSchema: z.ZodSchema<FormValues> = z.object({
  name: z.string().nonempty('Name is required'),
  status: z.nativeEnum(ParticipantQrStatus),
  behaviours: z.array(behaviourSchema),
  labelIds: z.array(z.string()),
});

interface Props {
  participantId: string;
}

export const ParticipantQRCreateModal = createModal<Props>((props) => {
  const { participantId, modal } = props;
  const { visible, hide, remove } = modal;

  const { organization, successToast, errorToast } = useAppContext();

  const query = useParticipantBehavioursQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviours =
    (query.data?.organization?.participant?.behaviours as ParticipantBehaviour[]) ?? [];

  const initialValues: FormValues = {
    name: '',
    status: ParticipantQrStatus.Active,
    behaviours: participantBehaviours.map((behaviour) => ({
      behaviourId: behaviour.id,
      methods: {
        abc: { enabled: false },
        frequency: { enabled: false },
        duration: { enabled: false },
        episodicSeverity: { enabled: false },
      },
    })),
    labelIds: [],
  };

  const methods = useForm({
    values: initialValues,
    mode: 'all',
    resolver: zodResolver(participantQrRequestSchema),
  });

  const mutation = useCreateParticipantQrMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const behavioursToSubmit = values.behaviours.filter((behaviour) =>
        Object.values(behaviour.methods).some((method) => method.enabled),
      );

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantId,
          request: {
            name: values.name,
            status: values.status,
            behaviours: behavioursToSubmit,
            labelIds: values.labelIds,
          },
        })
        .then(() => {
          hide();
          successToast('QR code created');
        })
        .catch((err) => {
          errorToast(`Error creating QR code: ${err.message}`);
        });
    },
    [errorToast, hide, mutation, organization.id, participantId, successToast],
  );

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size='xl'
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <HStack>
                <Box>
                  <Heading size='md'>Add QR code</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    You can create a new QR code for this participant
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </HStack>
            </ModalHeader>
            <ModalBody>
              <ParticipantQRForm participantBehaviours={participantBehaviours} />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size='sm'>Create QR</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
