import { createGqlQuery } from '../fetcher';
import { Organization, OrganizationMinimal } from '../queries/organization.query';
import type * as Types from '../types';

export const useOrganizationQuery = createGqlQuery<
  Types.OrganizationQueryVariables,
  Types.OrganizationQuery
>((variables) => ['organization', variables], Organization);

export const useOrganizationMinimalQuery = createGqlQuery<
  Types.OrganizationMinimalQueryVariables,
  Types.OrganizationMinimalQuery
>((variables) => ['organization', variables, 'minimal'], OrganizationMinimal);
