import { HStack, IconButton } from '@chakra-ui/react';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { FaIndent, FaOutdent } from 'react-icons/fa';

interface Props {
  editor: Editor;
}

export const IndentSelect = (props: Props) => {
  const { editor } = props;

  const items = [
    {
      icon: FaOutdent,
      title: 'Decrease indent',
      action: () => editor.chain().focus().outdent().run(),
      isActive: () => false,
    },
    {
      icon: FaIndent,
      title: 'Increase indent',
      action: () => editor.chain().focus().indent().run(),
      isActive: () => false,
      isDisabled: () => false,
    },
  ];

  return (
    <HStack spacing={0} rounded='lg' overflow='hidden'>
      {items.map((item) => (
        <IconButton
          key={item.title}
          icon={<item.icon />}
          aria-label={item.title}
          variant='ghost'
          size='sm'
          rounded='none'
          onClick={item.action}
          isActive={item.isActive()}
        />
      ))}
    </HStack>
  );
};
