import { Stack, Tag, TagLabel, Text } from '@chakra-ui/react';
import {
  type Participant,
  ParticipantStatus,
  type PiccoloError,
  isParticipantDisabled,
  renderParticipantName,
} from '@piccolohealth/pbs-common';
import {
  DataTable,
  Empty,
  type PaginationOptions,
  type RowSelectionOptions,
  createColumnHelper,
} from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { TextLink } from '../../components/generic/TextLink';
import { useAppContext } from '../../hooks/useAppContext';
import { ManageParticipantLabelsControl } from '../labels/ManageParticipantLabelsControl';
import { ParticipantActionMenu } from './ParticipantActionMenu';
import { ParticipantStatusTag } from './ParticipantStatusTag';

interface Props {
  isLoading: boolean;
  error: PiccoloError | null;
  participants: Participant[];
  refetch: () => Promise<void>;
  pagination?: PaginationOptions;
  rowSelection?: RowSelectionOptions<Participant>;
}

export const ParticipantsTable = (props: Props) => {
  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Participant>();

    return [
      columnHelper.accessor((row) => `${row.firstName} ${row.lastName}`, {
        id: 'name',
        header: 'Name',
        minSize: 240,
        maxSize: 300,
        cell: (props) => (
          <Stack spacing={1}>
            <TextLink
              to={`/organizations/${organization.id}/participants/${props.row.original.id}`}
              fontWeight='bold'
              color='purple.600'
              fontSize='md'
            >
              {renderParticipantName(props.row.original)}
            </TextLink>
            <Text fontSize='xs'>{props.row.original.ndisNumber}</Text>
          </Stack>
        ),
      }),
      columnHelper.accessor('location.name', {
        header: 'Location',
        minSize: 200,
        maxSize: 300,
        cell: (props) => (
          <Tag size='sm' colorScheme={props.row.original.location.color}>
            <TagLabel>{props.row.original.location.name}</TagLabel>
          </Tag>
        ),
      }),
      columnHelper.accessor('status', {
        header: 'Status',
        minSize: 130,
        maxSize: 200,
        cell: (props) => <ParticipantStatusTag status={props.row.original.status} size='sm' />,
      }),
      columnHelper.accessor('label.name', {
        header: 'Labels',
        cell: (props) => (
          <ManageParticipantLabelsControl
            participantId={props.row.original.id}
            value={props.row.original.labels.map((l) => l.id)}
          />
        ),
      }),
      columnHelper.display({
        id: 'actions',
        maxSize: 140,
        header: 'Actions',
        cell: (props) => (
          <ParticipantActionMenu
            participantId={props.row.original.id}
            isDisabled={isParticipantDisabled(props.row.original.status)}
            size='sm'
          />
        ),
      }),
    ];
  }, [organization.id]);

  return (
    <DataTable
      columns={columns}
      data={props.participants}
      size='md'
      error={props.error}
      isLoading={props.isLoading}
      pagination={props.pagination}
      rowSelection={props.rowSelection}
      renderError={(error) => <Error error={error} />}
      renderEmpty={() => <Empty title='No participants found' />}
    />
  );
};
