import { Divider, Flex, HStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { UpdateUserRequest } from '@piccolohealth/pbs-common/src/graphql/types';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { HookedAvatarUploader } from '../../components/forms/HookedAvatarUploader';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedSelect } from '../../components/forms/HookedSelect';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { useUpdateUserMutation } from '../../graphql/hooks/useUpdateUserMutation';
import { useAppContext } from '../../hooks/useAppContext';

type FormValues = Omit<UpdateUserRequest, 'locations' | 'roles'>;

const schema: z.ZodSchema<FormValues> = z.object({
  picture: z.string().nullish(),
  name: z.string(),
  email: z.string(),
  title: z.string(),
  practitionerNumber: z.string().nullish(),
  settings: z.object({
    defaultOrganization: z.string().nullable(),
  }),
});

export const BasicSettings = () => {
  const { user, organization, successToast, errorToast } = useAppContext();

  const updateUserMutation = useUpdateUserMutation();

  const defaultValues = {
    picture: user.picture,
    name: user.name,
    email: user.email,
    title: user.title,
    practitionerNumber: user.practitionerNumber,
    settings: {
      defaultOrganization: user.settings.defaultOrganization,
    },
  };
  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const onSubmit = async (values: FormValues) => {
    const request = P.deepObjectDiff(defaultValues, values);

    await updateUserMutation
      .mutateAsync({
        organizationId: organization.id,
        userId: user.id,
        request,
      })
      .then(() => {
        successToast('User profile updated successfully');
      })
      .catch((err) => {
        errorToast(`Error updating user profile: ${err.message}`);
      });
  };

  React.useEffect(() => {
    methods.reset({
      picture: user.picture,
      name: user.name,
      email: user.email,
      title: user.title,
    });
  }, [methods, user.picture, user.name, user.email, user.title]);

  return (
    <FormProvider {...methods}>
      <FormStack as='form' onSubmit={methods.handleSubmit(onSubmit)} maxW='lg'>
        <FormSection heading='Basic' description='This is how others will see you on the site.'>
          <Divider />
          <FormStack>
            <HookedFormItem
              name='picture'
              label='Profile picture'
              helperText='Your picture. Recommended size is 96x96'
            >
              <Flex>
                <HookedAvatarUploader name='picture' w='96px' h='96px' />
              </Flex>
            </HookedFormItem>
            <HookedFormItem name='name' label='Name' helperText='Your public display name'>
              <HookedInput name='name' size='sm' placeholder='Enter your display name' />
            </HookedFormItem>
            <HookedFormItem name='email' label='Email' helperText='Your email address'>
              <HookedInput name='email' size='sm' placeholder='Enter your email' />
            </HookedFormItem>
            <HookedFormItem label='Title' name='title' helperText='Your professional title'>
              <HookedInput name='title' size='sm' placeholder='Enter your title' />
            </HookedFormItem>
            <HookedFormItem
              label='NDIS practitioner number'
              name='title'
              helperText='Your NDIS practitioner number'
            >
              <HookedInput
                name='practitionerNumber'
                size='sm'
                placeholder='Enter your NDIS practitioner number'
              />
            </HookedFormItem>
            <HookedFormItem
              label='Default organization'
              helperText='Which organization should load when you login?'
              name='settings.defaultOrganization'
            >
              <HookedSelect
                name='settings.defaultOrganization'
                placeholder='Select an organization'
                options={user.organizationMemberships.map((membership) => ({
                  label: membership.name,
                  value: membership.organizationId,
                  raw: membership.organizationId,
                }))}
              />
            </HookedFormItem>
          </FormStack>
        </FormSection>
        <HStack>
          <HookedSubmitButton>Save</HookedSubmitButton>
        </HStack>
      </FormStack>
    </FormProvider>
  );
};
