import { Dialog as ChakraDialog, Portal } from 'chakra-ui-v3';
import React from 'react';
import { forwardRef } from 'react';
import { CloseButton } from './CloseButton';

export interface DialogContentProps extends ChakraDialog.ContentProps {
  portalled?: boolean;
  portalRef?: React.RefObject<HTMLElement>;
  backdrop?: boolean;
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  function DialogContent(props, ref) {
    const { children, portalled = true, portalRef, backdrop = true, ...rest } = props;

    return (
      <Portal disabled={!portalled} container={portalRef}>
        {backdrop && <ChakraDialog.Backdrop />}
        <ChakraDialog.Positioner>
          <ChakraDialog.Content ref={ref} {...rest} asChild={false}>
            {children}
          </ChakraDialog.Content>
        </ChakraDialog.Positioner>
      </Portal>
    );
  },
);

export const DialogCloseTrigger = forwardRef<HTMLButtonElement, ChakraDialog.CloseTriggerProps>(
  function DialogCloseTrigger(props, ref) {
    return (
      <ChakraDialog.CloseTrigger position='absolute' top='2' insetEnd='2' {...props} asChild>
        <CloseButton size='sm' ref={ref}>
          {props.children}
        </CloseButton>
      </ChakraDialog.CloseTrigger>
    );
  },
);
