import { HStack, PinInput, PinInputField, type PinInputProps } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends Omit<PinInputProps, 'value' | 'onChange' | 'children'> {
  name: string;
  fieldCount: number;
}

export const HookedPinInput = (props: Props) => {
  const { name, fieldCount, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return (
    <HStack spacing={4}>
      <PinInput value={inputProps.value} onChange={inputProps.onChange} {...rest}>
        {P.range(0, fieldCount).map((i) => (
          <PinInputField key={i} fontWeight='bold' />
        ))}
      </PinInput>
    </HStack>
  );
};
