import { Box, Stack, Text } from '@chakra-ui/react';
import type { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';

interface Props {
  behaviour: ParticipantBehaviour;
}

export const SeriesListItem = (props: React.PropsWithChildren<Props>) => {
  const { behaviour, children } = props;

  const methodsWithData = P.groupBy(behaviour.recordings, (recording) => recording.method.id);
  const methodsWithDataCount = Object.keys(methodsWithData).length;

  return (
    <Stack
      key={behaviour.id}
      w='full'
      spacing={2}
      borderLeft='4px'
      borderColor='purple.400'
      pl={4}
      py={1}
    >
      <Stack spacing={0}>
        <Text fontSize='md' fontWeight='bold'>
          {behaviour.name}
        </Text>
        <Text fontSize='sm' color='secondary'>
          {methodsWithDataCount} {inflection.inflect('method', methodsWithDataCount)} with data
        </Text>
      </Stack>

      <Box>{children}</Box>
    </Stack>
  );
};
