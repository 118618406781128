import * as scroll from 'scroll-into-view-if-needed';
import type { StandardBehaviorOptions } from 'scroll-into-view-if-needed';

/**
 * Used over the native `scrollIntoView` to provide a smooth scroll behavior.
 * The native function does not work with Grammarly extension
 */

export const scrollIntoView = (element: Element, options?: StandardBehaviorOptions) => {
  return scroll.default(
    element,
    options ?? {
      scrollMode: 'if-needed',
      block: 'end',
      inline: 'nearest',
      behavior: 'smooth',
    },
  );
};
