import { AvatarGroup, HStack } from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import type { User } from '@piccolohealth/pbs-common';
import { Dot } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { UserAvatar } from '../user/UserAvatar';

interface Props {
  editor: Editor;
  status: WebSocketStatus;
}

export const UserStatusIndicator = (props: Props) => {
  const { editor, status } = props;

  const users = React.useMemo(() => {
    const uniqueUsers: User[] = editor.storage?.collaborationCursor?.users ?? [];
    return P.orderBy(uniqueUsers, (user) => user.name);
  }, [editor.storage?.collaborationCursor?.users]);

  const statusColor = P.run(() => {
    switch (status) {
      case WebSocketStatus.Connecting:
        return 'yellow.400';
      case WebSocketStatus.Connected:
        return 'green.400';
      case WebSocketStatus.Disconnected:
        return 'red.400';
    }
  });

  return (
    <HStack>
      <Dot bg={statusColor} size={2} />
      <AvatarGroup spacing={-3}>
        {users.map((user: User) => (
          <UserAvatar
            key={user.id}
            name={user.name}
            secondary={user.email}
            picture={user.picture}
            showTooltip
            size='sm'
          />
        ))}
      </AvatarGroup>
    </HStack>
  );
};
