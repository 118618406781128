import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { Label, UpdateLabelRequest } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateLabelMutation } from '../../../graphql/hooks/useUpdateLabelMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { LabelForm } from './LabelForm';

type FormValues = UpdateLabelRequest;

interface Props {
  label: Label;
}

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string(),
  color: z.string(),
  description: z.string().nullish(),
});

export const LabelUpdateModal = createModal<Props>((props) => {
  const { label, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const { hide, remove, visible } = modal;

  const methods = useForm({
    defaultValues: label,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useUpdateLabelMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          labelId: label.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Label updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating label: ${err.message}`);
        });
    },
    [errorToast, hide, mutation, organization.id, label.id, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Update a label</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <LabelForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size='sm' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size='sm'>Update label</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
