import { Tag, type TagProps } from '@chakra-ui/react';
import { ParticipantQrStatus } from '@piccolohealth/pbs-common';
import React from 'react';

interface Props extends TagProps {
  status: ParticipantQrStatus;
}

export const participantQRStatusOptions = {
  [ParticipantQrStatus.Active]: {
    label: 'Active',
    value: ParticipantQrStatus.Active,
    raw: ParticipantQrStatus.Active,
    color: 'green',
  },
  [ParticipantQrStatus.Archived]: {
    label: 'Archived',
    value: ParticipantQrStatus.Archived,
    raw: ParticipantQrStatus.Archived,
    color: 'gray',
  },
};

export const ParticipantQRStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const { label, color } = participantQRStatusOptions[status];

  return (
    <Tag colorScheme={color} w='fit-content' {...rest}>
      {label}
    </Tag>
  );
};
