import {
  Box,
  Collapse,
  HStack,
  IconButton,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { COMPANY_NAME, Role, type User } from '@piccolohealth/pbs-common';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import { JsonTree } from '../../components/generic/JsonTree';
import { UserAvatar } from '../../components/user/UserAvatar';
import { useRole } from '../../hooks/useRole';

interface Props {
  timestamp: DateTime;
  message: string;
  user?: User;
  payload: any;
}

export const ParticipantTimelineItem = (props: Props) => {
  const { timestamp, message, user, payload } = props;

  const disclosure = useDisclosure();
  const date = DateTime.fromISO(timestamp.toString());
  const hasSupportRole = useRole(Role.Support);

  // Show a relative string if younger than 24 hours, otherwise, show a  DateTime.DATETIME_SHORT
  const formattedDate =
    date.diffNow().as('hours') > -24
      ? date.toRelative()
      : date.toLocaleString(DateTime.DATETIME_SHORT);

  return (
    <Stack>
      <HStack py={2} px={6} spacing={4} align='center'>
        <UserAvatar
          name={user?.name ?? COMPANY_NAME}
          picture={user?.picture}
          secondary={user?.email ?? undefined}
          showTooltip={false}
          size='md'
        />

        <Stack spacing={0}>
          <HStack align='baseline'>
            <Text fontSize='sm' fontWeight='semibold'>
              {user?.name}
            </Text>
            <Text fontSize='xs' color='secondary'>
              {formattedDate}
            </Text>
          </HStack>
          <Box
            fontSize='sm'
            color='gray.700'
            __css={{
              a: {
                fontWeight: 'bold',
                color: 'purple.600',
                _hover: {
                  textDecoration: 'underline',
                },
              },
            }}
          >
            {message}
          </Box>
        </Stack>
        <Spacer />
        {hasSupportRole && (
          <IconButton
            color='gray.400'
            size='sm'
            variant='ghost'
            aria-label='History'
            icon={<FaRegClock />}
            onClick={disclosure.onToggle}
          />
        )}
      </HStack>
      {disclosure.isOpen && hasSupportRole && (
        <Collapse in={disclosure.isOpen}>
          <Box w='lg'>
            <JsonTree
              src={{
                timestamp,
                message,
                user,
                payload,
              }}
              enableClipboard={false}
            />
          </Box>
        </Collapse>
      )}
    </Stack>
  );
};
