import { Stack } from '@chakra-ui/react';
import React from 'react';
import type { UseTableReturn } from '../../hooks/useTable';
import { TableSeriesAddControl } from './TableSeriesAddControl';
import { TableSeriesEditControl } from './TableSeriesEditControl';

interface Props {
  participantBehaviourId: string;
  table: UseTableReturn;
}

export const TableSeriesControl = (props: Props) => {
  const { participantBehaviourId, table } = props;

  const series = table.widget.series.filter(
    (s) => s.source.participantBehaviourId === participantBehaviourId,
  );

  return (
    <Stack w='full'>
      {series?.map((series, index) => (
        <TableSeriesEditControl key={index} table={table} series={series} />
      ))}
      <TableSeriesAddControl participantBehaviourId={participantBehaviourId} table={table} />
    </Stack>
  );
};
