import { HStack, Spacer, Text } from '@chakra-ui/react';
import { FilterStatus, useKnockFeed } from '@knocklabs/react';
import { FloatingPopover, NumberedBadge } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaBell } from 'react-icons/fa';
import { SidebarButton } from '../../components/layouts/SidebarLayout';
import { NotificationCenterContents } from './NotificationCenterContents';

interface Props {
  isShrunk: boolean;
}

export const NotificationCenterButton = (props: Props) => {
  const { isShrunk } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const { useFeedStore, feedClient } = useKnockFeed();
  const unseenCount = useFeedStore((state) => state.metadata.unseen_count);
  const unseenItems = useFeedStore((state) => state.items.filter((item) => !item.seen_at));

  React.useEffect(() => {
    // When the feed client changes, or the status changes issue a re-fetch
    feedClient.fetch({ status: FilterStatus.All });
  }, [feedClient]);

  const markFetchedNotificationsAsSeen = React.useCallback(() => {
    if (!P.isEmpty(unseenItems)) {
      return feedClient.markAsSeen(unseenItems);
    }
  }, [feedClient, unseenItems]);

  const onOpenChange = (value: boolean) => {
    setIsOpen(value);
    if (!value) {
      markFetchedNotificationsAsSeen();
    }
  };

  return (
    <>
      <FloatingPopover
        isPortal={true}
        isRoot={true}
        placement='right-start'
        open={isOpen}
        setOpen={onOpenChange}
        render={() => <NotificationCenterContents onClose={() => onOpenChange(false)} />}
      >
        <SidebarButton isShrunk={isShrunk} leftIcon={<FaBell />}>
          <HStack w='full' lineHeight='normal' align='center'>
            <Text>Notifications</Text>
            <Spacer />
            <NumberedBadge count={unseenCount} />
          </HStack>
        </SidebarButton>
      </FloatingPopover>
    </>
  );
};
