import { useMutation } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { ExportParticipantsCsv } from '../mutations/exportParticipantsCsv.mutation';
import type {
  ExportParticipantsCsvMutation,
  ExportParticipantsCsvMutationVariables,
} from '../types';

export const useExportParticipantsCsvMutation = (
  options?: MutationOptions<ExportParticipantsCsvMutation, ExportParticipantsCsvMutationVariables>,
) => {
  return useMutation(gqlFetcher(ExportParticipantsCsv), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);
    },
  });
};
