import { useMutation } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreatePasswordChangeTicketUrl } from '../mutations/createPasswordChangeTicketUrl.mutation';
import type {
  CreatePasswordChangeTicketUrlMutation,
  CreatePasswordChangeTicketUrlMutationVariables,
} from '../types';

export const useCreatePasswordChangeTicketUrlMutation = (
  options?: MutationOptions<
    CreatePasswordChangeTicketUrlMutation,
    CreatePasswordChangeTicketUrlMutationVariables
  >,
) => useMutation(gqlFetcher(CreatePasswordChangeTicketUrl), options);
