import { Box, HStack, Icon, Spacer, Stack, type SystemStyleObject } from '@chakra-ui/react';
import type { ParticipantBehaviour, ParticipantWidgetLayoutItem } from '@piccolohealth/pbs-common';
import { Empty, ScrollArea, Spin, useDebouncedCallback } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { BiSolidBarChartAlt2 } from 'react-icons/bi';
import { Error } from '../../../components/generic/Error';
import {
  useParticipantBehavioursRecordingsQuery,
  useParticipantWidgetLayoutItemsQuery,
} from '../../../graphql/hooks/useParticipantQuery';
import { useUpdateParticipantWidgetLayoutItemsMutation } from '../../../graphql/hooks/useUpdateParticipantWidgetLayoutItemsMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { type UseGridReturn, useGrid } from '../hooks/useGrid';
import { ParticipantWidgetTimeRangeControl } from './ParticipantWidgetTimeRangeControl';
import { ParticipantDataGridItem } from './grid/ParticipantDataGridItem';
import { ParticipantWidgetLayoutItemAddControl } from './grid/ParticipantWidgetLayoutItem';

import 'gridstack/dist/gridstack-extra.min.css';
import 'gridstack/dist/gridstack.min.css';
import { ParticipantBehaviourRecordingCreateButton } from '../../behaviours/components/ParticipantBehaviourRecordingCreateButton';
import { ParticipantWidgetDataZoom } from './timeseries/ParticipantWidgetDataZoom';

interface ParticipantDataContentProps {
  participantId: string;
  participantBehaviours: ParticipantBehaviour[];
  items: ParticipantWidgetLayoutItem[];
  grid: UseGridReturn<ParticipantWidgetLayoutItem>;
  isDisabled?: boolean;
}

const ParticipantWidgetContent = (props: ParticipantDataContentProps) => {
  const { participantId, participantBehaviours, items, grid, isDisabled } = props;

  const { gridRef, refs } = grid;

  const content = P.run(() => {
    if (P.isEmpty(items)) {
      return (
        <Empty
          title='No data widgets'
          description='Add a data widget to display data for this participant'
          extra={
            <ParticipantWidgetLayoutItemAddControl
              participantId={participantId}
              onAdd={grid.onAdd}
              isDisabled={isDisabled}
            />
          }
          icon={<Icon as={BiSolidBarChartAlt2} fontSize='48' color='gray.300' />}
        />
      );
    }

    return (
      <>
        {items.map((item) => {
          return (
            <Box
              key={item.id}
              ref={refs.current[item.id]}
              className='grid-stack-item'
              h='full'
              w='full'
            >
              <Box
                className='grid-stack-item-content'
                bg='white'
                layerStyle='bordered'
                rounded='xl'
              >
                <ParticipantDataGridItem
                  participantId={participantId}
                  item={item}
                  grid={grid}
                  participantBehaviours={participantBehaviours}
                  isDisabled={isDisabled}
                />
              </Box>
            </Box>
          );
        })}
      </>
    );
  });

  return (
    <Box h='full' w='full'>
      <Box
        ref={gridRef}
        className='grid-stack'
        display='block'
        overflow='hidden'
        height='100%'
        minHeight='100% !important'
      >
        {content}
      </Box>
    </Box>
  );
};

interface Props {
  participantId: string;
  tabButtons: React.ReactNode;
  isDisabled?: boolean;
}

export const ParticipantDataWidgetsTab = (props: Props) => {
  const { participantId, tabButtons, isDisabled } = props;

  const { organization, errorToast } = useAppContext();

  const [startDate, setStartDate] = React.useState(DateTime.now().minus({ days: 30 }));
  const [endDate, setEndDate] = React.useState(DateTime.now());

  const onStartDateChange = useDebouncedCallback((date: DateTime) => {
    setStartDate(date.startOf('day'));
  }, 300);

  const onEndDateChange = useDebouncedCallback((date: DateTime) => {
    setEndDate(date.endOf('day'));
  }, 300);

  const updateParticipantWidgetLayoutItemsMutation =
    useUpdateParticipantWidgetLayoutItemsMutation();

  const participantWidgetLayoutItemsQuery = useParticipantWidgetLayoutItemsQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviourQuery = useParticipantBehavioursRecordingsQuery({
    organizationId: organization.id,
    participantId,
  });

  const isLoading =
    participantWidgetLayoutItemsQuery.isLoading || participantBehaviourQuery.isLoading;
  const error = participantWidgetLayoutItemsQuery.error ?? participantBehaviourQuery.error;

  // Use the mutation vars, if available, otherwise use the data from the query
  const items: ParticipantWidgetLayoutItem[] =
    (updateParticipantWidgetLayoutItemsMutation.variables
      ?.request as ParticipantWidgetLayoutItem[]) ??
    ((participantWidgetLayoutItemsQuery.data?.organization?.participant?.widgets ??
      []) as ParticipantWidgetLayoutItem[]);

  const participantBehaviours =
    (participantBehaviourQuery.data?.organization?.participant
      ?.behaviours as ParticipantBehaviour[]) ?? [];

  const onUpdate = React.useCallback(
    (items: ParticipantWidgetLayoutItem[]) => {
      updateParticipantWidgetLayoutItemsMutation
        .mutateAsync({
          organizationId: organization.id,
          participantId,
          request: items,
        })
        .catch((err) => {
          errorToast(`Error updating widgets: ${err.message}`);
          throw err;
        });
    },
    [errorToast, organization.id, participantId, updateParticipantWidgetLayoutItemsMutation],
  );

  const grid = useGrid<ParticipantWidgetLayoutItem>({
    items,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    onUpdate,
  });

  const css: SystemStyleObject = {
    '.grid-stack .grid-stack-placeholder > .placeholder-content': {
      rounded: 'xl',
    },
    '.ui-resizable-handle': {
      bottom: '16px !important',
      right: '16px !important',
    },
  };

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error h='full' error={error} />;
  }

  return (
    <Stack w='full' h='full' spacing={2} overflowY='hidden'>
      <HStack w='full' px={6} pt={4}>
        {tabButtons}
        <Box flexShrink={1}>
          <ParticipantWidgetTimeRangeControl
            startDate={grid.startDate}
            endDate={grid.endDate}
            onStartDateChange={grid.onStartDateChange}
            onEndDateChange={grid.onEndDateChange}
          />
        </Box>
        <Spacer />
        <Box w='full' pb={1}>
          <ParticipantWidgetDataZoom
            participantId={participantId}
            items={items}
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
        </Box>
        <HStack flexShrink={1}>
          <ParticipantWidgetLayoutItemAddControl
            participantId={participantId}
            onAdd={grid.onAdd}
            isDisabled={isDisabled}
          />
          <ParticipantBehaviourRecordingCreateButton
            participantId={participantId}
            participantBehaviours={participantBehaviours}
            colorScheme='purple'
            isDisabled={isDisabled}
          >
            Add recording
          </ParticipantBehaviourRecordingCreateButton>
        </HStack>
      </HStack>
      <ScrollArea w='full' h='full' bg='white' __css={css} overflowY='auto' px={4}>
        <ParticipantWidgetContent
          participantId={participantId}
          participantBehaviours={participantBehaviours}
          items={items}
          grid={grid}
          isDisabled={isDisabled}
        />
      </ScrollArea>
    </Stack>
  );
};
