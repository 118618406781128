import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateParticipantRestrictedPractice } from '../mutations/updateParticipantRestrictedPractice.mutation';
import type {
  UpdateParticipantRestrictedPracticeMutation,
  UpdateParticipantRestrictedPracticeMutationVariables,
} from '../types';

export const useUpdateParticipantRestrictedPracticeMutation = (
  options?: MutationOptions<
    UpdateParticipantRestrictedPracticeMutation,
    UpdateParticipantRestrictedPracticeMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateParticipantRestrictedPractice), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
