import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteParticipantUserLabel } from '../mutations/deleteParticipantUserLabel.mutation';
import type {
  DeleteParticipantUserLabelMutation,
  DeleteParticipantUserLabelMutationVariables,
} from '../types';

export const useDeleteParticipantUserLabelMutation = (
  options?: MutationOptions<
    DeleteParticipantUserLabelMutation,
    DeleteParticipantUserLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantUserLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
