import { Tag, type TagProps } from '@chakra-ui/react';
import {
  ParticipantDocumentStatus,
  renderParticipantDocumentStatus,
} from '@piccolohealth/pbs-common';
import React from 'react';

export const participantDocumentStatusColorName = (status: ParticipantDocumentStatus) => {
  const rendered = renderParticipantDocumentStatus(status);
  switch (status) {
    case ParticipantDocumentStatus.Draft:
      return ['blue', rendered];
    case ParticipantDocumentStatus.Finalized:
      return ['green', rendered];
  }
};

interface Props extends TagProps {
  status: ParticipantDocumentStatus;
}

export const ParticipantDocumentStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const [color, label] = participantDocumentStatusColorName(status);

  return (
    <Tag size='sm' colorScheme={color} {...rest}>
      {label}
    </Tag>
  );
};
