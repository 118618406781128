import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useDeleteLabelMutation } from '../../graphql/hooks/useDeleteLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  labelId: string;
  onDelete?: (labelId: string) => void;
}

export const LabelDeleteModal = createModal<Props>((props) => {
  const { organization, successToast, errorToast } = useAppContext();
  const { labelId, onDelete, modal } = props;

  const deleteLabelMutation = useDeleteLabelMutation();

  const onDeleteLabel = React.useCallback(() => {
    deleteLabelMutation
      .mutateAsync({
        organizationId: organization.id,
        labelId,
      })
      .then(() => {
        successToast('Label deleted successfully');
        onDelete?.(labelId);
      })
      .catch((err) => {
        errorToast(`Error deleting label: ${err.message}`);
      });
  }, [onDelete, labelId, deleteLabelMutation, organization.id, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading='Do you want to delete this label?'
      description={
        <Stack mb={6} spacing={4}>
          <Text>Deleting this label cannot be undone</Text>
        </Stack>
      }
      requireConfirm
      isLoading={deleteLabelMutation.isLoading}
      onDelete={async () => onDeleteLabel()}
    />
  );
});
