import { useMutation } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { GenerateParticipantDocumentPdf } from '../mutations/generateParticipantDocumentPdf.mutation';
import type {
  GenerateParticipantDocumentPdfMutation,
  GenerateParticipantDocumentPdfMutationVariables,
} from '../types';

export const useGenerateParticipantDocumentPdfMutation = (
  options?: MutationOptions<
    GenerateParticipantDocumentPdfMutation,
    GenerateParticipantDocumentPdfMutationVariables
  >,
) => {
  return useMutation(gqlFetcher(GenerateParticipantDocumentPdf), {
    ...options,
  });
};
