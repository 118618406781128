import { HocuspocusProvider, WebSocketStatus } from '@hocuspocus/provider';
import type { StatelessMessage, User } from '@piccolohealth/pbs-common';
import React from 'react';
import { useEffectOnce } from 'react-use';
import { useAppContext } from '../../../hooks/useAppContext';
import { useDelayedLoading } from '../../../hooks/useDelayedLoading';
import { getRandomColor } from '../utils';

export interface UseHocuspocusProviderReturn {
  provider: HocuspocusProvider;
  status: WebSocketStatus;
  isSynced: boolean;
  isSaving: boolean;
  user: User & {
    color: string;
  };
}

export const useHocuspocusProvider = (options: {
  documentId: string;
}): UseHocuspocusProviderReturn => {
  const { documentId } = options;
  const { config, organization, auth, user } = useAppContext();

  const [status, setStatus] = React.useState<WebSocketStatus>(WebSocketStatus.Disconnected);
  const [isSynced, setIsSynced] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const isSkeletonLoading = useDelayedLoading(250);

  const provider = React.useMemo(() => {
    return new HocuspocusProvider({
      url: `${config.api.url.replace('http', 'ws')}/organizations/${organization.id}/hocuspocus`,
      parameters: {
        organizationId: organization.id,
      },
      name: documentId,
      token: async () => {
        const { token } = await auth.getAccessToken();
        return token;
      },
      preserveConnection: false,

      onStatus: ({ status }) => {
        setStatus(status);
        console.log('Hocuspocus status', status);
      },
      onStateless: ({ payload }) => {
        const message = JSON.parse(payload) as StatelessMessage;

        switch (message.type) {
          case 'error':
            console.error('Hocuspocus stateless error', message.message);
            provider.disconnect();
            break;
          case 'saved':
            setIsSaving(true);
            setTimeout(() => setIsSaving(false), 1000);
            break;
        }
      },
      onAuthenticationFailed: ({ reason }) => {
        provider.disconnect();
        console.log('Hocuspocus authentication failed', reason);
      },
      onSynced: ({ state }) => {
        console.log('Hocuspocus synced', state);
        setIsSynced(state);
      },
    });
  }, [config.api.url, organization.id, documentId, auth.getAccessToken]);

  const userWithColor = React.useMemo(() => {
    return { ...user, color: getRandomColor() };
  }, [user]);

  const computedStatus = React.useMemo(() => {
    // If the fake minimum delay is not delayed, we are still connecting
    if (isSkeletonLoading) {
      return WebSocketStatus.Connecting;
    }

    // If we are not synced, we consider it same as connecting
    if (!isSynced) {
      return WebSocketStatus.Connecting;
    }

    // Otherwise we return the actual status
    return status;
  }, [isSynced, isSkeletonLoading, status]);

  useEffectOnce(() => {
    return () => {
      console.log(`Unmounting Hocuspocus provider for document ${documentId}`);
      provider.disconnect();
    };
  });

  return { status: computedStatus, isSynced, isSaving, provider, user: userWithColor };
};
