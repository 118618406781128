import { Box, type BoxProps } from '@chakra-ui/react';
import React from 'react';

export const ResizeHandleContainer = (props: BoxProps) => {
  const { children, ...rest } = props;

  return (
    <Box
      pos='absolute'
      top={0}
      bottom={0}
      display='flex'
      align-items='center'
      justifyContent='center'
      width={6}
      zIndex='25'
      cursor='col-resize'
      {...rest}
    >
      <Box
        opacity={0}
        transition='opacity 0.3s ease-in'
        width={1}
        height={10}
        maxH='50%'
        boxSizing='content-box'
        bg='rgba(0, 0, 0, 0.65)'
        border='1px solid rgba(255, 255, 255, 0.5)'
        border-radius='md'
      />
    </Box>
  );
};
