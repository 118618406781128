import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateLabel } from '../mutations/createLabel.mutation';
import type { CreateLabelMutation, CreateLabelMutationVariables } from '../types';

export const useCreateLabelMutation = (
  options?: MutationOptions<CreateLabelMutation, CreateLabelMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('organization'),
      });
    },
  });
};
