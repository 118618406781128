import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Icon,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { type IncidentFile, IncidentStatus } from '@piccolohealth/pbs-common';
import { Empty, FancyDate, FileIcon, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { useIncidentFilesQuery } from '../../graphql/hooks/useIncidentQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { IncidentFileActionMenu } from './IncidentFileActionMenu';
import { IncidentFileUploadModal } from './IncidentFileUploadModal';

interface Props {
  incidentId: string;
}

export const IncidentFilesCard = (props: Props) => {
  const { incidentId } = props;

  const { organization } = useAppContext();

  const onAddFile = () => {
    showModal(IncidentFileUploadModal, {
      incidentId,
    });
  };

  const { isLoading, error, data } = useIncidentFilesQuery({
    organizationId: organization.id,
    incidentId,
  });

  const files = (data?.organization?.incident?.files as IncidentFile[]) ?? [];
  const isReviewed = data?.organization?.incident?.status === IncidentStatus.Reviewed;

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error h='full' error={error} />;
    }

    if (P.isEmpty(files)) {
      return <Empty h='full' title='No files found' p={0} />;
    }

    return (
      <>
        {files.map((file) => (
          <React.Fragment key={file.id}>
            <HStack align='stretch' fontSize='sm'>
              <FileIcon name={file.name} w={10} h={10} />
              <Stack spacing={0} w='full'>
                <HStack>
                  <Text fontWeight='semibold' noOfLines={1}>
                    {file.name}
                  </Text>
                  <Spacer />
                  <FancyDate date={file.createdAt} showHumanized={false} color='secondary' />
                </HStack>
                <Spacer />
                <HStack>
                  <Button
                    size='xs'
                    variant='link'
                    w='fit-content'
                    onClick={() => window.open(file.viewUrl)}
                  >
                    View
                  </Button>
                  <Button
                    size='xs'
                    variant='link'
                    w='fit-content'
                    onClick={() => window.open(file.downloadUrl)}
                  >
                    Download
                  </Button>
                  <IncidentFileActionMenu file={file} size='xs' />
                </HStack>
              </Stack>
            </HStack>

            <Divider />
          </React.Fragment>
        ))}
      </>
    );
  });

  return (
    <Card h='full'>
      <CardHeader>
        <Stack spacing={0}>
          <Heading size='md'>Files</Heading>
          <Text fontSize='sm' color='secondary'>
            Files related to the incident
          </Text>
        </Stack>
      </CardHeader>
      <CardBody as={Stack} spacing={4}>
        {content}
        <Spacer />
        <HStack w='full'>
          <Button
            w='full'
            size='sm'
            leftIcon={<Icon as={FaPlus} />}
            onClick={onAddFile}
            isDisabled={isReviewed}
          >
            Add file
          </Button>
        </HStack>
      </CardBody>
    </Card>
  );
};
