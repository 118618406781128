import { TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { ParticipantDataTabButtons } from './components/ParticipantDataTabButtons';
import { ParticipantDataTablesTab } from './components/ParticipantDataTablesTab';
import { ParticipantDataWidgetsTab } from './components/ParticipantDataWidgetsTab';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantData = (props: Props) => {
  const { participantId, isDisabled } = props;

  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <Tabs index={tabIndex} variant='solid' size='sm' h='full' overflowY='hidden'>
      <TabPanels overflowY='auto' h='full' w='full' border='none' m={0} p={0}>
        <TabPanel h='full'>
          <ParticipantDataWidgetsTab
            participantId={participantId}
            tabButtons={<ParticipantDataTabButtons value={tabIndex} onChange={setTabIndex} />}
            isDisabled={isDisabled}
          />
        </TabPanel>
        <TabPanel h='full'>
          <ParticipantDataTablesTab
            participantId={participantId}
            tabButtons={<ParticipantDataTabButtons value={tabIndex} onChange={setTabIndex} />}
            isDisabled={isDisabled}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
