import { Box, Stack } from '@chakra-ui/react';
import { IncidentSeverity, LabelType, Role } from '@piccolohealth/pbs-common';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormStack } from '../../components/forms/FormStack';
import { HookedBooleanRadioGroup } from '../../components/forms/HookedBooleanRadioGroup';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedLabelChooser } from '../../components/forms/HookedLabelChooser';
import { HookedParticipantChooser } from '../../components/forms/HookedParticipantChooser';
import { HookedSelect } from '../../components/forms/HookedSelect';
import { HookedSingleDatepicker } from '../../components/forms/HookedSingleDatepicker';
import { HookedTextArea } from '../../components/forms/HookedTextArea';
import { HookedUserMultiChooser } from '../../components/forms/HookedUserMultiChooser';
import { HookedWitnessesControl } from '../../components/forms/HookedWitnessesControl';
import { IncidentSeverityTag, incidentSeverityDetails } from './IncidentSeverityTag';

export const IncidentForm = () => {
  const { setValue } = useFormContext();
  const participantId = useWatch({
    name: 'participant.id',
  });

  React.useEffect(() => {
    if (participantId) {
      setValue('reviewers', undefined);
    }
  }, [participantId, setValue]);

  return (
    <FormStack>
      <FormStack orientation='horizontal' spacing={8}>
        <HookedFormItem
          name='severity'
          label='Severity'
          helperText='How severe was this incident?'
          isRequired
        >
          <HookedSelect
            name='severity'
            size='sm'
            placeholder='Choose incident severity'
            optionVariant='tag'
            options={Object.values(IncidentSeverity).map((severity) => {
              const [color, label] = incidentSeverityDetails(severity);
              return {
                value: severity,
                raw: severity,
                label,
                color,
              };
            })}
            components={{
              Option: ({ option }) => {
                return <IncidentSeverityTag severity={option.raw} />;
              },
            }}
          />
        </HookedFormItem>
      </FormStack>
      <FormStack orientation='horizontal'>
        <HookedFormItem
          name='timestamp'
          label='Occurred on'
          helperText='When did the incident occur?'
          isRequired
        >
          <HookedSingleDatepicker name='timestamp' size='sm' placeholder='Choose a date' />
        </HookedFormItem>
        <HookedFormItem
          name='setting'
          label='Occurred where'
          helperText='Where did the incident occur?'
          isRequired
        >
          <HookedInput name='setting' size='sm' placeholder='Enter an incident setting' />
        </HookedFormItem>
      </FormStack>

      <FormStack orientation='horizontal'>
        <HookedFormItem
          name='participant'
          label='Participant'
          helperText='Was there a participant involved in the incident?'
        >
          <HookedParticipantChooser name='participant' placeholder='Choose a participant' />
        </HookedFormItem>
        <HookedFormItem
          name='ndisReportableIncident'
          label='NDIS reportable'
          helperText='Is the incident NDIS reportable?'
        >
          <HookedBooleanRadioGroup
            name='ndisReportableIncident'
            size='sm'
            options={[
              {
                label: 'Yes',
                value: 'true',
              },
              {
                label: 'No',
                value: 'false',
              },
            ]}
          />
        </HookedFormItem>
      </FormStack>
      <FormStack orientation='horizontal'>
        <HookedFormItem name='labelIds' label='Labels' helperText='Any labels you want to assign'>
          <HookedLabelChooser name='labelIds' type={LabelType.Incident} />
        </HookedFormItem>
      </FormStack>
      <FormStack>
        <HookedFormItem
          name='description'
          label='Description'
          helperText='Describe what happened in the incident'
          isRequired
        >
          <HookedTextArea name='description' size='sm' placeholder='What happened?' rows={6} />
        </HookedFormItem>
      </FormStack>

      <FormStack>
        <HookedFormItem name='witnesses' label='Witnesses'>
          <Stack w='full'>
            <HookedWitnessesControl name='witnesses' />
          </Stack>
        </HookedFormItem>
      </FormStack>

      <FormStack orientation='horizontal'>
        <HookedFormItem
          name='reviewers'
          label='Reviewers'
          helperText='Who should review this incident?'
          isRequired
        >
          <HookedUserMultiChooser
            name='reviewers'
            roles={[Role.TeamLeader, Role.Administrator]}
            participantId={participantId}
            size='sm'
            placeholder='Choose one or more incident reviewers'
          />
        </HookedFormItem>
      </FormStack>
    </FormStack>
  );
};
