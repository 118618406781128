import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteParticipantDocumentLabel } from '../mutations/deleteParticipantDocumentLabel.mutation';
import type {
  DeleteParticipantDocumentLabelMutation,
  DeleteParticipantDocumentLabelMutationVariables,
} from '../types';

export const useDeleteParticipantDocumentLabelMutation = (
  options?: MutationOptions<
    DeleteParticipantDocumentLabelMutation,
    DeleteParticipantDocumentLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantDocumentLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
