import { useMutation } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { GenerateIncidentPdf } from '../mutations/generateIncidentPdf.mutation';
import type { GenerateIncidentPdfMutation, GenerateIncidentPdfMutationVariables } from '../types';

export const useGenerateIncidentPdfMutation = (
  options?: MutationOptions<GenerateIncidentPdfMutation, GenerateIncidentPdfMutationVariables>,
) => {
  return useMutation(gqlFetcher(GenerateIncidentPdf), {
    ...options,
  });
};
