import { Button, ButtonGroup, Divider, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { FloatingPopover } from '@piccolohealth/ui';
import React from 'react';
import { useDeleteParticipantBehaviourRecordingMutation } from '../../../graphql/hooks/useDeleteParticipantBehaviourRecordingMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  participantId: string;
  participantBehaviourId: string;
  recordingId: string;
}

export const ParticipantBehaviourRecordingDeleteButton = (props: Props) => {
  const { participantId, participantBehaviourId, recordingId } = props;

  const { organization, successToast, errorToast } = useAppContext();
  const disclosure = useDisclosure();
  const mutation = useDeleteParticipantBehaviourRecordingMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        participantBehaviourId,
        recordingId,
      })
      .then(() => {
        successToast('Participant behaviour recording deleted successfully');
        disclosure.onClose();
      })
      .catch((err) => {
        errorToast(`Error deleting participant behaviour recording: ${err.message}`);
      });
  }, [
    mutation,
    organization.id,
    participantId,
    participantBehaviourId,
    recordingId,
    successToast,
    disclosure,
    errorToast,
  ]);

  return (
    <FloatingPopover
      placement='bottom-start'
      open={disclosure.isOpen}
      setOpen={(isOpen) => (isOpen ? disclosure.onOpen() : disclosure.onClose())}
      isPortal
      render={() => (
        <Stack w='100%' bg='white' px={6} py={4} spacing={4} shadow='popover' rounded='xl'>
          <Text>
            Deleting this recording <strong>cannot be undone.</strong>
          </Text>
          <Divider />
          <ButtonGroup size='sm' justifyContent='flex-end'>
            <Button key='close' onClick={disclosure.onClose}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='red'
              isLoading={mutation.isLoading}
              loadingText='Deleting...'
              type='submit'
              onClick={onDelete}
            >
              Delete
            </Button>
          </ButtonGroup>
        </Stack>
      )}
    >
      <Button size='sm' variant='link' colorScheme='red'>
        Delete
      </Button>
    </FloatingPopover>
  );
};
