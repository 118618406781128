import { useLagRadar } from '@piccolohealth/ui';
import { type QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ErrorBoundary } from './components/generic/ErrorBoundary';
import { PageError } from './components/generic/PageError';
import { ProtectedRoute } from './components/generic/ProtectedRoute';
import type { Config } from './config';
import { AuthContextProvider } from './context/AuthContext';
import { ConfigContextProvider } from './context/ConfigContext';
import { UserContextProvider } from './context/UserContext';
import { adminRoutes } from './features/admin/AdminRoutes';
import { Login } from './features/auth/Login';
import { Logout } from './features/auth/Logout';
import { MFAEnrol } from './features/auth/MFAEnrol';
import { PhoneEnrol } from './features/auth/PhoneEnrol';
import { RequestResetPassword } from './features/auth/RequestResetPassword';
import { ResetPassword } from './features/auth/ResetPassword';
import { TotpEnrol } from './features/auth/TotpEnrol';
import { VerifyInvite } from './features/auth/VerifyInvite';
import {
  OrganizationRedirect,
  organizationRoutes,
} from './features/organization/OrganizationRoutes';
import { ChakraProvider } from './theme/chakra';

const Routes = () => {
  // We add a 2nd error boundary here to catch any errors that occur before react-router
  // catches them. This is important because react-router does not re-throw the error
  // which means the outer error boundary will not see it.
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ChakraProvider>
          <ErrorBoundary>
            <AuthContextProvider>
              <Outlet />
            </AuthContextProvider>
          </ErrorBoundary>
        </ChakraProvider>
      ),
      children: [
        {
          path: '/logout',
          element: <Logout />,
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/mfa/enrol',
          element: <MFAEnrol />,
        },
        {
          path: '/mfa/phone-enrol',
          element: <PhoneEnrol />,
        },
        {
          path: '/mfa/totp-enrol',
          element: <TotpEnrol />,
        },
        {
          path: '/request-reset-password',
          element: <RequestResetPassword />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/verify-invite',
          element: <VerifyInvite />,
        },
        {
          path: '*',
          element: <PageError type='NotFound' />,
        },
        {
          path: '/',
          element: (
            <UserContextProvider>
              <Outlet />
            </UserContextProvider>
          ),
          children: [
            {
              path: '/',
              element: (
                <ProtectedRoute>
                  <Outlet />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: '/',
                  element: <OrganizationRedirect />,
                },
                {
                  path: 'organizations',
                  children: organizationRoutes,
                },
                {
                  path: 'admin',
                  children: adminRoutes,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: (
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      ),
      children: [
        {
          path: 'recording/*',
          lazy: () => import('./features/mobileRecording/RecordingApp'),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

interface Props {
  config: Config;
  queryClient: QueryClient;
}

export const Root = (props: Props) => {
  const { config, queryClient } = props;

  useLagRadar(config.lagRadarEnabled ?? false);

  return (
    <ConfigContextProvider config={config}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <IntercomProvider
            appId={config.intercom.appId}
            shouldInitialize={config.intercom.enabled}
          >
            <Routes />
          </IntercomProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </ConfigContextProvider>
  );
};
