import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateOrganization } from '../mutations/createOrganization.mutation';
import type { CreateOrganizationMutation, CreateOrganizationMutationVariables } from '../types';

export const useCreateOrganizationMutation = (
  options?: MutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateOrganization), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('organization'),
      });
    },
  });
};
