import { P } from '@piccolohealth/util';
import {
  type Editor,
  BubbleMenu as TiptapBubbleMenu,
  getMarkAttributes,
  isTextSelection,
} from '@tiptap/react';
import React from 'react';

import 'tippy.js/animations/shift-away.css';
import { type CommentThread, getCommentThread } from '../../extensions/comment/Comment';
import { isExtensionEnabled } from '../../utils';
import { CommentThreadContent } from './CommentThreadContent';

interface Props {
  editor: Editor;
}

export const CommentBubbleMenu = (props: Props) => {
  const { editor } = props;

  const enabled = isExtensionEnabled(editor, 'comment');

  const commentThread: CommentThread | null = React.useMemo(() => {
    if (!enabled) {
      return null;
    }

    const attributes = getMarkAttributes(editor.state, 'comment');
    const commentId = attributes?.comment;
    const document = editor.storage?.comment?.document;

    if (!commentId || !document) {
      return null;
    }

    return getCommentThread(document, commentId);
  }, [editor.state, editor.storage, enabled]);

  const content = P.run(() => {
    if (!commentThread) {
      return null;
    }

    return (
      <CommentThreadContent
        editor={editor}
        commentThread={commentThread}
        bg='white'
        shadow='popover'
        px={4}
        py={3}
        rounded='lg'
        w='xl'
        spacing={4}
      />
    );
  });

  return (
    <TiptapBubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 100,
        maxWidth: 'calc(100vw - 100px)',
        placement: 'bottom-start',
        arrow: false,
        animation: 'shift-away',
        inertia: true,
        zIndex: 100000,
      }}
      updateDelay={0}
      shouldShow={(ps) => {
        return ps.editor.isActive('comment') && isTextSelection(ps.state.tr.selection);
      }}
    >
      {content}
    </TiptapBubbleMenu>
  );
};
