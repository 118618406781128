import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  type Participant,
  type ParticipantUser,
  type User,
  renderParticipantName,
} from '@piccolohealth/pbs-common';
import {
  DataTable,
  ScrollArea,
  TransferList,
  type TransferListComponents,
  type TransferListContentItem,
  type TransferListOption,
  createColumnHelper,
  useDebouncedCallback,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { UserDescription } from '../../components/user/UserDescription';
import { useCreateParticipantUsersMutation } from '../../graphql/hooks/useCreateParticipantUsersMutation';
import { useDeleteParticipantUsersMutation } from '../../graphql/hooks/useDeleteParticipantUsersMutation';
import { useParticipantUsersQuery } from '../../graphql/hooks/useParticipantQuery';
import { useUsersQuery } from '../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ManageParticipantUserLabelsControl } from '../labels/ManageParticipantUserLabelsControl';

interface Props {
  participant: Participant;
}

export const ParticipantTeamMemberManageModal = createModal<Props>((props) => {
  const { participant, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const { hide, remove, visible } = modal;

  const [nameFilter, setNameFilter] = React.useState('');
  const onChangeName = useDebouncedCallback(setNameFilter, 1000);

  const createParticipantUsersMutation = useCreateParticipantUsersMutation();
  const deleteParticipantUsersMutation = useDeleteParticipantUsersMutation();

  const usersQuery = useUsersQuery({
    organizationId: organization.id,
    request: {
      name: nameFilter,
    },
  });

  const participantUsersQuery = useParticipantUsersQuery({
    organizationId: organization.id,
    participantId: participant.id,
  });

  const value: TransferListOption<ParticipantUser>[] = React.useMemo(() => {
    const assignedUsers =
      (participantUsersQuery.data?.organization?.participant?.assignedUsers as ParticipantUser[]) ??
      [];

    return assignedUsers.map((assignedUser) => ({
      label: assignedUser.user.name,
      value: assignedUser.user.id,
      raw: assignedUser,
    }));
  }, [participantUsersQuery.data?.organization?.participant?.assignedUsers]);

  const options: TransferListOption<ParticipantUser>[] = React.useMemo(() => {
    const users = (usersQuery.data?.organization?.users.results as User[]) ?? [];

    return users.map((user) => ({
      label: user.name,
      value: user.id,
      raw: {
        id: user.id,
        labels: [],
        user,
      },
    }));
  }, [usersQuery.data?.organization?.users.results]);

  const leftColumns = React.useMemo(() => {
    const columnHelper = createColumnHelper<TransferListContentItem<ParticipantUser>>();

    return [
      columnHelper.display({
        id: 'select',
        size: 32,
        minSize: 32,
        maxSize: 32,
        header: (ps) => {
          const rows = ps.table.getCoreRowModel().rows;
          const isAllChecked = !P.isEmpty(rows) && rows.every((row) => row.original.isSelected);
          const isIndeterminate = rows.some((row) => row.original.isSelected) && !isAllChecked;
          const onChange = () => {
            rows.forEach((row) => {
              if (!row.original.isSelected) {
                row.original.onClick();
              }

              if (isAllChecked) {
                row.original.onClick();
              }
            });
          };

          return (
            <Checkbox
              isChecked={isAllChecked}
              isIndeterminate={isIndeterminate}
              onChange={onChange}
            />
          );
        },
        cell: (ps) => {
          return (
            <Checkbox
              isDisabled={false}
              isChecked={ps.row.original.isSelected}
              onChange={ps.row.original.onClick}
            />
          );
        },
      }),
      columnHelper.display({
        header: 'Name',
        minSize: 380,
        maxSize: 380,
        cell: (props) => (
          <UserDescription
            fontSize='sm'
            name={props.row.original.raw.user.name}
            secondary={props.row.original.raw.user.title}
            picture={props.row.original.raw.user.picture}
          />
        ),
      }),
    ];
  }, []);

  const rightColumns = React.useMemo(() => {
    const columnHelper = createColumnHelper<TransferListContentItem<ParticipantUser>>();

    return [
      ...leftColumns,
      columnHelper.display({
        header: 'Labels',
        cell: (props) => (
          <ManageParticipantUserLabelsControl
            participantId={participant.id}
            participantUserId={props.row.original.raw.id}
            value={props.row.original.raw.labels.map((label) => label.id)}
          />
        ),
      }),
    ];
  }, [leftColumns, participant.id]);

  const components: TransferListComponents<ParticipantUser> = React.useMemo(() => {
    return {
      left: {
        Header: (props) => {
          return (
            <Stack>
              <HStack>
                <Heading size='md'>All users</Heading>
                <Spacer />
                <HStack>
                  <Text fontSize='sm' fontWeight='semibold'>
                    Items
                  </Text>
                  <Text fontSize='sm' color='secondary'>
                    {props.selectedItemCount} / {props.itemCount} selected
                  </Text>
                </HStack>
              </HStack>
              <Input
                defaultValue={nameFilter}
                onChange={(e) => onChangeName(e.target.value)}
                size='sm'
                w='3xs'
                placeholder='All users'
              />
            </Stack>
          );
        },
        Content: (props) => {
          return (
            <Stack h='full' w='lg' overflowY='auto'>
              <ScrollArea>
                <DataTable
                  columns={leftColumns}
                  data={props.options}
                  size='sm'
                  renderEmpty={() => <Text py={8}>No users found</Text>}
                  isLoading={usersQuery.isLoading}
                />
              </ScrollArea>
            </Stack>
          );
        },
      },
      right: {
        Header: (props) => {
          return (
            <Stack pb={10}>
              <HStack>
                <Heading size='md'>Assigned users</Heading>
                <Spacer />
                <HStack>
                  <Text fontSize='sm' fontWeight='semibold'>
                    Items
                  </Text>
                  <Text fontSize='sm' color='secondary'>
                    {props.selectedItemCount} / {props.itemCount} selected
                  </Text>
                </HStack>
              </HStack>
            </Stack>
          );
        },
        Content: (props) => {
          return (
            <Stack h='full' w='full' overflowY='auto'>
              <ScrollArea>
                <DataTable
                  columns={rightColumns}
                  data={props.options}
                  size='sm'
                  renderEmpty={() => <Text py={8}>No users assigned</Text>}
                  isLoading={participantUsersQuery.isLoading}
                />
              </ScrollArea>
            </Stack>
          );
        },
      },
    };
  }, [
    leftColumns,
    rightColumns,
    nameFilter,
    participantUsersQuery.isLoading,
    usersQuery.isLoading,
    onChangeName,
  ]);

  const onChange = React.useCallback(
    async (values: TransferListOption<ParticipantUser>[]) => {
      const existingUserIds = value.filter((value) => value.raw.user.id);
      const usersToAdd = values.filter((value) => !existingUserIds.includes(value));
      const usersToRemove = existingUserIds.filter((value) => !values.includes(value));

      if (!P.isEmpty(usersToAdd)) {
        await createParticipantUsersMutation
          .mutateAsync({
            organizationId: organization.id,
            requests: usersToAdd.map((user) => ({
              participantId: participant.id,
              userId: user.raw.user.id,
              labelIds: [],
            })),
          })
          .then(() => {
            successToast('Users added successfully');
          })
          .catch((err) => {
            errorToast(`Error adding users: ${err.message}`);
          });
      }

      if (!P.isEmpty(usersToRemove)) {
        await deleteParticipantUsersMutation
          .mutateAsync({
            organizationId: organization.id,
            participantId: participant.id,
            userIds: usersToRemove.map((user) => user.raw.user.id),
          })
          .then(() => {
            successToast('Users removed successfully');
          })
          .catch((err) => {
            errorToast(`Error removing users: ${err.message}`);
          });
      }

      return;
    },
    [
      value,
      createParticipantUsersMutation,
      deleteParticipantUsersMutation,
      organization.id,
      participant.id,
      successToast,
      errorToast,
    ],
  );

  const content = P.run(() => {
    return (
      <TransferList
        options={options}
        value={value}
        components={components}
        onChange={onChange}
        isLeftLoading={deleteParticipantUsersMutation.isLoading}
        isRightLoading={createParticipantUsersMutation.isLoading}
      />
    );
  });

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size='users'
      scrollBehavior='inside'
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <HStack>
            <Box>
              <Heading size='md'>
                Manage {`${renderParticipantName(participant)}'s`} team members
              </Heading>
              <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                You can add or remove team members from this participant.
              </Text>
            </Box>
            <Spacer />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </HStack>
        </ModalHeader>
        <ModalBody>
          <Stack w='full' h='full'>
            {content}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
