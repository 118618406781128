import { IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';
import { FaGripVertical, FaRemoveFormat, FaTrash } from 'react-icons/fa';

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onClickRemoveFormatting: () => void;
  onClickDelete: () => void;
}

export const DragHandleMenu = (props: Props) => {
  const { isOpen, setOpen, onClickRemoveFormatting, onClickDelete } = props;

  return (
    <Menu isOpen={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} isLazy>
      <MenuButton
        as={IconButton}
        tabIndex={-1}
        icon={<FaGripVertical />}
        variant='ghost'
        aria-label='Drag handle'
        fontSize='10px'
        size='xs'
        color='gray.500'
        cursor='grab'
      />
      <MenuList>
        <MenuItem icon={<FaRemoveFormat />} onClick={onClickRemoveFormatting}>
          Clear formatting
        </MenuItem>
        <MenuDivider />
        <MenuItem color='danger' icon={<FaTrash />} onClick={onClickDelete}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
