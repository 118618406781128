import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Empty, FancyDate, ScrollArea } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { BiSolidBarChartAlt2 } from 'react-icons/bi';
import { ParticipantBehaviourRecordingTypeTag } from '../../../behaviours/components/ParticipantBehaviourRecordingTypeTag';
import { ParticipantBehaviourRecordingValue } from '../../../behaviours/components/ParticipantBehaviourRecordingValue';
import type { UseTableReturn } from '../../hooks/useTable';

interface Props {
  table: UseTableReturn;
}

export const TableWidgetContent = (props: Props) => {
  const { table } = props;

  const content = P.run(() => {
    if (P.isEmpty(table.recordings)) {
      return (
        <Stack h='full' w='full' align='center' justify='center' textAlign='center'>
          <Empty
            title='No series added'
            description='Add series to view them in the table. Series are used to display data on the table.'
            icon={<Icon as={BiSolidBarChartAlt2} fontSize='48' color='gray.300' />}
          />
        </Stack>
      );
    }

    const entriesGroupedByDate = P.groupBy(table.recordings, (recording) =>
      DateTime.fromISO(recording.timestamp.toString()).startOf('day').toISO(),
    );

    return (
      <ScrollArea>
        <Accordion allowMultiple allowToggle defaultIndex={P.range(0, 1000)}>
          {P.listify(entriesGroupedByDate, (recordings, timestamp) => (
            <AccordionItem key={timestamp}>
              <AccordionButton px={4} bg='gray.50'>
                <HStack w='full'>
                  <FancyDate
                    date={DateTime.fromISO(timestamp)}
                    direction='row'
                    showHumanized={false}
                    showTooltip={true}
                    format={DateTime.DATE_SHORT}
                    fontSize='xs'
                    fontWeight='semibold'
                  />
                  <Spacer />
                  <AccordionIcon />
                </HStack>
              </AccordionButton>
              <AccordionPanel px={4} py={3}>
                <Stack>
                  {(recordings ?? []).map((recording) => {
                    const behaviour = table.participantBehaviours.find(
                      (behaviour) => behaviour.id === recording.participantBehaviourId,
                    );

                    return (
                      <Stack key={recording.id} w='full' spacing={0}>
                        <HStack w='full'>
                          <Text fontSize='sm' fontWeight='semibold'>
                            {behaviour?.name}
                          </Text>
                          <Spacer />
                          <ParticipantBehaviourRecordingTypeTag
                            type={recording.__typename}
                            size='sm'
                          />
                        </HStack>
                        <Box fontSize='xs'>
                          <ParticipantBehaviourRecordingValue recording={recording} />
                        </Box>
                      </Stack>
                    );
                  })}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </ScrollArea>
    );
  });

  return (
    <Box h='full' w='full' overflow='hidden' userSelect='none'>
      {content}
    </Box>
  );
};
