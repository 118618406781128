import { Divider, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import type * as Common from '@piccolohealth/pbs-common';
import type { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { FormSection } from '../../../components/forms/FormSection';
import { FormStack } from '../../../components/forms/FormStack';
import { HookedEpisodicSeverityScales } from '../../../components/forms/HookedEpisodicSeverityScales';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../components/forms/HookedInput';
import { HookedMethodFormItem } from '../../../components/forms/HookedMethodFormItem';
import { HookedSelect } from '../../../components/forms/HookedSelect';
import { HookedTextArea } from '../../../components/forms/HookedTextArea';
import { participantBehaviourStatusOptions } from './ParticipantBehaviourStatusTag';

export const ParticipantBehaviourForm = () => {
  const statusOptions: SelectOption<Common.ParticipantBehaviourStatus>[] = Object.values(
    participantBehaviourStatusOptions,
  );
  return (
    <Tabs
      orientation='vertical'
      variant='soft-rounded'
      w='full'
      h='full'
      isLazy={false}
      lazyBehavior='keepMounted'
    >
      <TabList>
        <Tab>General</Tab>
        <Tab>Data collection</Tab>
      </TabList>

      <TabPanels ml={6} pl={6} borderLeftWidth='1px' borderLeftColor='gray.200'>
        <TabPanel>
          <FormSection heading='General' description='Basic information about a behaviour'>
            <Divider />
            <FormStack>
              <HookedFormItem name='name' label='Name' helperText='What is the behaviour called?'>
                <HookedInput name='name' placeholder='Enter a name..' />
              </HookedFormItem>
              <HookedFormItem name='status' label='Status' isRequired>
                <HookedSelect name='status' size='sm' options={statusOptions} optionVariant='tag' />
              </HookedFormItem>
              <HookedFormItem
                name='description'
                label='Description'
                helperText='How would you describe the behaviour?'
              >
                <HookedTextArea
                  name='description'
                  placeholder='Enter a description..'
                  minRows={4}
                />
              </HookedFormItem>
              <HookedFormItem
                name='example'
                label='Example'
                helperText='What is an example of the behaviour?'
              >
                <HookedTextArea name='example' placeholder='Enter an example..' minRows={4} />
              </HookedFormItem>
              <HookedFormItem
                name='nonExample'
                label='Non-Example'
                helperText='What is a non-example of the behaviour?'
              >
                <HookedTextArea name='nonExample' placeholder='Enter a non-example..' minRows={4} />
              </HookedFormItem>
            </FormStack>
          </FormSection>
        </TabPanel>
        <TabPanel>
          <FormSection
            heading='Data collection'
            description='How do you want to collect data for this behaviour?'
          >
            <Divider />
            <FormStack>
              <HookedMethodFormItem
                name='methods.abc'
                label='ABC'
                helperText='Antecedent, behaviour, consequence data'
              />
              <HookedMethodFormItem
                name='methods.frequency'
                label='Frequency'
                helperText='Number of times a behaviour occurs'
              />
              <HookedMethodFormItem
                name='methods.duration'
                label='Duration'
                helperText='How long a behaviour occurs for'
              />
              <HookedMethodFormItem
                name='methods.episodicSeverity'
                label='Episodic Severity'
                helperText='How severe a behaviour is'
              >
                <HookedFormItem name='methods.episodicSeverity.scales'>
                  <HookedEpisodicSeverityScales name='methods.episodicSeverity.scales' />
                </HookedFormItem>
              </HookedMethodFormItem>
            </FormStack>
          </FormSection>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
