import { Stack } from '@chakra-ui/react';
import React from 'react';
import type { UseTimeseriesChartReturn } from '../../../participantData/hooks/useTimeseriesChart';
import { SeriesListItem } from '../SeriesListItem';
import { TimeSeriesControl } from './TimeSeriesControl';

interface Props {
  chart: UseTimeseriesChartReturn;
}

export const TimeSeriesList = (props: Props) => {
  const { chart } = props;

  return (
    <Stack w='full'>
      {chart.participantBehaviours.map((behaviour) => (
        <SeriesListItem key={behaviour.id} behaviour={behaviour}>
          <TimeSeriesControl behaviour={behaviour} chart={chart} />
        </SeriesListItem>
      ))}
    </Stack>
  );
};
