import {
  Alert,
  AlertIcon,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import { type Template, TemplateType, type TemplateVersion } from '@piccolohealth/pbs-common';
import { FancyDate, ScrollArea } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import { generateHTML } from '@tiptap/html';
import React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { createModal } from '../../components/generic/Modal';
import { Editor } from '../../components/tiptap/Editor';
import { EditorOutlineSection } from '../../components/tiptap/EditorOutlineSection';
import { useEditor } from '../../components/tiptap/hooks/useEditor';
import { EditorLayout } from '../../components/tiptap/layout/EditorLayout';
import {
  BASE_EXTENSIONS,
  convertYDocToJSONContent,
  deserializeYDoc,
  getParticipantDocumentTemplateExtensions,
  getParticipantNoteTemplateExtensions,
  postProcessContent,
} from '../../components/tiptap/utils';
import { UserAvatar } from '../../components/user/UserAvatar';
import { useRestoreTemplateVersionMutation } from '../../graphql/hooks/useRestoreTemplateVersionMutation';
import {
  useTemplateVersionQuery,
  useTemplateVersionsInfiniteQuery,
} from '../../graphql/hooks/useTemplateQuery';
import { useAppContext } from '../../hooks/useAppContext';

interface ParticipantDocumentHistorySidebarProps {
  templateId: string;
  activeVersionId: string | null;
  setActiveVersionId: (versionId: string) => void;
}

const TemplateHistorySidebar = (props: ParticipantDocumentHistorySidebarProps) => {
  const { templateId, activeVersionId, setActiveVersionId } = props;
  const { organization } = useAppContext();

  const endRef = React.useRef<HTMLDivElement>(null);

  const templateVersionsQuery = useTemplateVersionsInfiniteQuery({
    organizationId: organization.id,
    templateId,
    request: {
      pagination: {
        limit: 40,
      },
    },
  });

  const templateVersionsPagination = P.first(templateVersionsQuery.data?.pages ?? [])?.organization
    ?.template?.versions?.pagination;

  const templateVersions =
    templateVersionsQuery.data?.pages.flatMap(
      (page) => page.organization?.template?.versions.results as TemplateVersion[],
    ) ?? [];

  const latestVersion = P.first(templateVersions);
  const isLoading = templateVersionsQuery.isLoading;
  const hasNextPage = templateVersionsQuery.hasNextPage;
  const total = templateVersionsPagination?.total ?? 0;

  const fetchNextPage = () => {
    templateVersionsQuery.fetchNextPage();
  };

  // https://github.com/biomejs/biome/issues/630
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to run this effect when the number of pages changes
  React.useEffect(() => {
    if (endRef.current) {
      scrollIntoView(endRef.current, {
        scrollMode: 'if-needed',
        block: 'end',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [templateVersionsQuery.data?.pages.length]);

  React.useEffect(() => {
    // TODO: Remove the versionId nil check when we make it required.
    if (!P.isNil(latestVersion) && !P.isNil(latestVersion.versionId)) {
      setActiveVersionId(latestVersion.versionId);
    }
  }, [latestVersion, setActiveVersionId]);

  if (!templateVersionsPagination) {
    return null;
  }

  const content = P.run(() => {
    if (P.isEmpty(templateVersions)) {
      return (
        <Text fontSize='xs' fontWeight='bold' color='secondary'>
          No versions found
        </Text>
      );
    }

    return (
      <>
        <Text fontSize='xs' fontWeight='bold' color='secondary'>
          Versions
        </Text>
        <ScrollArea flexDir='column' overflowY='auto' h='full'>
          <Stack pr={4}>
            {templateVersions.map((document, index) => (
              <Button
                as={HStack}
                key={document.versionId}
                isActive={activeVersionId === document.versionId}
                onClick={() => {
                  // TODO: Remove the versionId nil check when we make it required.
                  if (!P.isNil(document.versionId)) {
                    setActiveVersionId(document.versionId);
                  }
                }}
                variant='ghost'
                color='gray.600'
                size='sm'
                justifyContent='start'
                whiteSpace='initial'
                h='auto'
                px={3}
                py={2}
                rounded='md'
                fontWeight='semibold'
              >
                <HStack w='full' align='start'>
                  <Stack spacing={1}>
                    <Text fontWeight='semibold'>Version {total - index}</Text>
                    <FancyDate
                      fontSize='xs'
                      color='secondary'
                      date={document.createdAt}
                      showTooltip={false}
                      direction='row'
                    />
                  </Stack>
                  <Spacer />
                  <AvatarGroup spacing={-3}>
                    {document.users.map((member) => (
                      <UserAvatar
                        key={member.id}
                        name={member.name}
                        secondary={member.email}
                        picture={member.picture}
                        showTooltip
                        size='sm'
                      />
                    ))}
                  </AvatarGroup>
                </HStack>
              </Button>
            ))}
            <Box ref={endRef} />
          </Stack>
        </ScrollArea>
        <Spacer />
        <Divider />
        <HStack pr={4}>
          <Text fontSize='xs' whiteSpace='nowrap'>
            {templateVersionsPagination.total}{' '}
            {inflection.inflect('version', templateVersionsPagination.total)}
          </Text>
          <Spacer minW={4} />
          <Button
            variant='link'
            onClick={fetchNextPage}
            fontSize='xs'
            isDisabled={!hasNextPage}
            isLoading={isLoading}
            justifyContent='end'
          >
            Load more
          </Button>
        </HStack>
      </>
    );
  });

  return (
    <Stack
      h='full'
      w='280px'
      spacing={4}
      flexShrink={0}
      borderRightWidth='1px'
      borderRightColor='gray.200'
    >
      {content}
    </Stack>
  );
};

interface Props {
  template: Template;
}

export const TemplateVersionHistoryModal = createModal<Props>((props) => {
  const { modal, template } = props;
  const { hide, visible } = modal;
  const { organization, successToast, errorToast, location } = useAppContext();

  const [activeVersionId, setActiveVersionId] = React.useState<string | null>(null);

  const restoreTemplateVersionMutation = useRestoreTemplateVersionMutation();

  const activeTemplateQuery = useTemplateVersionQuery(
    {
      organizationId: organization.id,
      templateId: template.id,
      versionId: activeVersionId ?? '',
    },
    {
      enabled: activeVersionId !== null,
    },
  );

  const activeTemplate = activeTemplateQuery.data?.organization?.template;

  const status = P.run(() => {
    if (activeTemplateQuery.isFetching) {
      return WebSocketStatus.Connecting;
    }

    if (activeTemplateQuery.isError) {
      return WebSocketStatus.Disconnected;
    }

    return WebSocketStatus.Connected;
  });

  const extensions = React.useMemo(() => {
    if (!activeTemplate) {
      return BASE_EXTENSIONS;
    }

    switch (activeTemplate.type) {
      case TemplateType.ParticipantDocument: {
        return getParticipantDocumentTemplateExtensions({
          variables: [],
        });
      }
      case TemplateType.ParticipantNote:
        return getParticipantNoteTemplateExtensions({});
    }
  }, [activeTemplate]);

  const content = React.useMemo(() => {
    const value = activeTemplate?.value ?? undefined;

    if (!value) {
      return undefined;
    }

    const document = deserializeYDoc(value);
    const jsonContent = convertYDocToJSONContent(document);
    const processed = postProcessContent(jsonContent, []);
    const htmlContent = generateHTML(processed, extensions);

    return htmlContent;
  }, [activeTemplate?.value, extensions]);

  const { editor } = useEditor(
    {
      extensions,
      isEditable: false,
      content,
    },
    [extensions, content],
  );

  const onRestoreVersion = React.useCallback(async () => {
    await restoreTemplateVersionMutation
      .mutateAsync({
        organizationId: organization.id,
        templateId: template.id,
        versionId: activeVersionId ?? '',
      })
      .then(() => {
        hide();
        successToast('Version restored successfully');
        location.reload();
      })
      .catch((err) => {
        errorToast(`Error restoring version: ${err.message}`);
      });
  }, [
    activeVersionId,
    location,
    organization.id,
    restoreTemplateVersionMutation,
    template.id,
    hide,
    successToast,
    errorToast,
  ]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='outline'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='start'>
            <Box>
              <Heading size='md'>{template.name} - History</Heading>
              <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                You can view the history of this template here.
              </Text>
            </Box>
            <Spacer />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </Flex>
        </ModalHeader>
        <ModalBody pb={4} overflowY='auto'>
          <HStack w='full' h='full' spacing={4}>
            <TemplateHistorySidebar
              templateId={template.id}
              activeVersionId={activeVersionId}
              setActiveVersionId={setActiveVersionId}
            />
            <Stack w='full' h='full' align='center'>
              <Box h='full' w='full' overflowY='auto' layerStyle='fade'>
                {activeTemplate && (
                  <EditorLayout
                    documentId={activeTemplate.id}
                    isSidebarOpen={true}
                    status={status}
                    headerContent={
                      <Heading size='lg' noOfLines={1}>
                        {activeTemplate.name}
                      </Heading>
                    }
                    toolbarContent={null}
                    mainContent={<Editor editor={editor} />}
                    sidebarContent={<EditorOutlineSection editor={editor} />}
                  />
                )}
              </Box>

              <Alert status='warning' py={4}>
                <AlertIcon />
                <Text fontWeight='semibold'>
                  Restoring this version will overwrite the current document. Are you sure you want
                  to restore this version?
                </Text>
                <Spacer />
                <HStack>
                  <Button size='sm' onClick={hide}>
                    Close
                  </Button>
                  <Button
                    colorScheme='purple'
                    size='sm'
                    onClick={onRestoreVersion}
                    isLoading={restoreTemplateVersionMutation.isLoading}
                  >
                    Restore version
                  </Button>
                </HStack>
              </Alert>
            </Stack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
