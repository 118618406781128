import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  type CreateParticipantQrBehaviourRequest,
  type ParticipantBehaviour,
  isBehaviourDisabled,
} from '@piccolohealth/pbs-common';
import { Empty, NumberedBadge } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormStack } from '../../components/forms/FormStack';
import { HookedMethodFormItem } from '../../components/forms/HookedMethodFormItem';

interface Props {
  participantBehaviours: ParticipantBehaviour[];
}

export const ParticipantQRBehavioursControl = (props: Props) => {
  const { participantBehaviours } = props;

  const methodCounts = (
    useWatch({ name: 'behaviours' }) as CreateParticipantQrBehaviourRequest[]
  ).reduce<Record<string, number>>((acc, behaviour) => {
    acc[behaviour.behaviourId] = Object.values(behaviour.methods).filter(
      (method) => method.enabled,
    ).length;
    return acc;
  }, {});

  if (P.isEmpty(participantBehaviours)) {
    return <Empty title='No behaviours' description='This participant has no behaviours' py={0} />;
  }

  return (
    <Accordion allowMultiple allowToggle>
      {participantBehaviours.map((behaviour, index) => {
        const behaviourDisabled = isBehaviourDisabled(behaviour.status);

        return (
          <AccordionItem key={behaviour.id} isDisabled={behaviourDisabled}>
            <AccordionButton px={4} bg='gray.50'>
              <HStack w='full'>
                <Text fontSize='sm'>{behaviour.name}</Text>
                <Spacer />
                <NumberedBadge count={methodCounts[behaviour.id]} />
                <AccordionIcon />
              </HStack>
            </AccordionButton>
            <AccordionPanel px={4} py={3}>
              <FormStack>
                <HookedMethodFormItem
                  name={`behaviours.[${index}].methods.abc`}
                  label='ABC'
                  helperText='Antecedent, behaviour, consequence data'
                  border={0}
                  px={2}
                  py={0}
                />
                <HookedMethodFormItem
                  name={`behaviours.[${index}].methods.frequency`}
                  label='Frequency'
                  helperText='Number of times a behaviour occurs'
                  border={0}
                  px={2}
                  py={0}
                />
                <HookedMethodFormItem
                  name={`behaviours.[${index}].methods.duration`}
                  label='Duration'
                  helperText='How long a behaviour occurs for'
                  border={0}
                  px={2}
                  py={0}
                />
                <HookedMethodFormItem
                  name={`behaviours.[${index}].methods.episodicSeverity`}
                  label='Episodic Severity'
                  helperText='How severe a behaviour is'
                  border={0}
                  px={2}
                  py={0}
                />
              </FormStack>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
