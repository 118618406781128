import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import { ScrollArea, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Error } from '../../../components/generic/Error';
import { createModal } from '../../../components/generic/Modal';
import { useParticipantBehaviourQuery } from '../../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { ParticipantBehaviourRecordingsTable } from './ParticipantBehaviourRecordingsTable';

interface Props {
  participantId: string;
  participantBehaviour: ParticipantBehaviour;
}

export const ParticipantBehaviourRecordingsModal = createModal<Props>((props) => {
  const { participantId, participantBehaviour, modal } = props;

  const { visible, hide, remove } = modal;

  const { organization } = useAppContext();

  const { isLoading, error, data } = useParticipantBehaviourQuery({
    organizationId: organization.id,
    participantId,
    participantBehaviourId: participantBehaviour.id,
  });

  const participantBehaviours = P.compact([
    data?.organization?.participant?.behaviour as ParticipantBehaviour | null,
  ]);

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error error={error} />;
    }

    return (
      <Stack h='full'>
        <ScrollArea w='full' h='full' bg='white' overflowY='auto' px={6}>
          <ParticipantBehaviourRecordingsTable
            participantId={participantId}
            participantBehaviours={participantBehaviours}
          />
        </ScrollArea>
      </Stack>
    );
  });

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size='outline'
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='start'>
            <Box>
              <Heading size='md'>{participantBehaviour.name} recordings</Heading>
              <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                View all data entries for this behaviour
              </Text>
            </Box>
            <Spacer />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </Flex>
        </ModalHeader>
        <ModalBody overflowY='auto' pb={4} px={0}>
          {content}
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button size='sm' onClick={hide}>
              Close
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
