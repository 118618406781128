import { Box, Flex } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdminNavbar } from './AdminNavbar';

export const AdminLayout = () => {
  return (
    <NiceModal.Provider>
      <Flex h='100%' flexDir='column'>
        <AdminNavbar />
        <Box h='100%' flexGrow={1} overflowY='auto' bg='white'>
          <Outlet />
        </Box>
      </Flex>
    </NiceModal.Provider>
  );
};
