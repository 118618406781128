import { Tag, type TagProps } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { renderParticipantStatus } from '@piccolohealth/pbs-common';
import React from 'react';

interface Props extends TagProps {
  status: Common.ParticipantStatus;
}

export const participantStatusOptions = {
  [Common.ParticipantStatus.New]: {
    label: renderParticipantStatus(Common.ParticipantStatus.New),
    value: Common.ParticipantStatus.New,
    raw: Common.ParticipantStatus.New,
    color: 'red',
  },
  [Common.ParticipantStatus.Waitlist]: {
    label: renderParticipantStatus(Common.ParticipantStatus.Waitlist),
    value: Common.ParticipantStatus.Waitlist,
    raw: Common.ParticipantStatus.Waitlist,
    color: 'orange',
  },
  [Common.ParticipantStatus.Intake]: {
    label: renderParticipantStatus(Common.ParticipantStatus.Intake),
    value: Common.ParticipantStatus.Intake,
    raw: Common.ParticipantStatus.Intake,
    color: 'blue',
  },
  [Common.ParticipantStatus.OnHold]: {
    label: renderParticipantStatus(Common.ParticipantStatus.OnHold),
    value: Common.ParticipantStatus.OnHold,
    raw: Common.ParticipantStatus.OnHold,
    color: 'yellow',
  },
  [Common.ParticipantStatus.Active]: {
    label: renderParticipantStatus(Common.ParticipantStatus.Active),
    value: Common.ParticipantStatus.Active,
    raw: Common.ParticipantStatus.Active,
    color: 'green',
  },
  [Common.ParticipantStatus.Archived]: {
    label: renderParticipantStatus(Common.ParticipantStatus.Archived),
    value: Common.ParticipantStatus.Archived,
    raw: Common.ParticipantStatus.Archived,
    color: 'gray',
  },
};

export const ParticipantStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const { label, color } = participantStatusOptions[status];

  return (
    <Tag colorScheme={color} w='fit-content' {...rest}>
      {label}
    </Tag>
  );
};
