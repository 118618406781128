export const NOTIFICATION_WORKFLOW_IDENTIFIERS = [
  'incident-created',
  'incident-reviewed',
  'participant-created-from-form',
  'participant-updated-from-form',
  'participant-document-review-requested',
  'participant-document-review-responded',
] as const;

export type NotificationWorkflowIdentifier = (typeof NOTIFICATION_WORKFLOW_IDENTIFIERS)[number];

export const NOTIFICATION_WORKFLOW_CATEGORIES = ['Incidents', 'Participants'] as const;

export type NotificationWorkflowCategory = (typeof NOTIFICATION_WORKFLOW_CATEGORIES)[number];

export const NOTIFICATION_CHANNEL_TYPES = [
  {
    id: 'email',
    name: 'Email',
  },
  {
    id: 'in_app_feed',
    name: 'In app feed',
  },
] as const;

export type NotificationChannelType = (typeof NOTIFICATION_CHANNEL_TYPES)[number]['id'];

export const NOTIFICATION_WORKFLOW_METADATA: Record<
  NotificationWorkflowIdentifier,
  {
    category: NotificationWorkflowCategory;
    name: string;
    description: string;
  }
> = {
  'incident-created': {
    category: 'Incidents',
    name: 'Incident created',
    description: 'When a new incident is created, and you are assigned as a reviewer',
  },
  'incident-reviewed': {
    category: 'Incidents',
    name: 'Incident reviewed',
    description: 'When an incident you created, is reviewed',
  },
  'participant-created-from-form': {
    category: 'Participants',
    name: 'Participant created from form',
    description: 'When a participant is created from a form',
  },
  'participant-updated-from-form': {
    category: 'Participants',
    name: 'Participant updated from form',
    description: 'When a participant is updated from a form',
  },
  'participant-document-review-requested': {
    category: 'Participants',
    name: 'Participant document review requested',
    description: 'When a request to notify reviewers of a participant is made',
  },
  'participant-document-review-responded': {
    category: 'Participants',
    name: 'Participant document review responded',
    description: 'When a reviewer for a participant document responds to a review request',
  },
};

export interface NotificationPayload {
  companyName: string;
  companyUrl: string;
  organizationId: string;
  organizationName: string;
  content: string;
  subject: string;
  actionUrl?: string;
}
