import { Divider, Text } from '@chakra-ui/react';
import type { Method, ParticipantBehaviour } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormSection } from '../../../components/forms/FormSection';
import { FormStack } from '../../../components/forms/FormStack';
import { HookedDurationInput } from '../../../components/forms/HookedDurationInput';
import { HookedEpisodicSeveritySlider } from '../../../components/forms/HookedEpisodicSeveritySlider';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedNumberInput } from '../../../components/forms/HookedNumberInput';
import { HookedSelect } from '../../../components/forms/HookedSelect';
import { HookedSingleDatepicker } from '../../../components/forms/HookedSingleDatepicker';
import { HookedTextArea } from '../../../components/forms/HookedTextArea';
import { methodOptions } from './MethodTag';

interface DataMethodFormProps {
  method: Method;
}

const RecordingMethodForm = (props: DataMethodFormProps) => {
  switch (props.method.__typename) {
    case 'AbcMethod':
      return (
        <FormSection heading='ABC data'>
          <FormStack>
            <HookedFormItem
              name='payload.abc.antecedent'
              label='Antecedent'
              helperText='The events, action, or circumstances that occurred before the behaviour'
            >
              <HookedTextArea
                name='payload.abc.antecedent'
                size='sm'
                placeholder='Describe the antecedent'
              />
            </HookedFormItem>
            <HookedFormItem
              name='payload.abc.behaviour'
              label='Behaviour'
              helperText='What happened during the behaviour?'
            >
              <HookedTextArea
                name='payload.abc.behaviour'
                size='sm'
                placeholder='Describe the behaviour'
              />
            </HookedFormItem>
            <HookedFormItem
              name='payload.abc.consequence'
              label='Consequence'
              helperText='The action or response that follows the behaviour.'
            >
              <HookedTextArea
                name='payload.abc.consequence'
                size='sm'
                placeholder='Describe the consequence'
              />
            </HookedFormItem>
          </FormStack>
        </FormSection>
      );
    case 'FrequencyMethod':
      return (
        <FormSection heading='Frequency data'>
          <FormStack>
            <HookedFormItem
              name='payload.frequency.value'
              label='Frequency'
              helperText='How many times did the behaviour occur?'
            >
              <HookedNumberInput name='payload.frequency.value' defaultValue={0} min={0} />
            </HookedFormItem>
          </FormStack>
        </FormSection>
      );
    case 'DurationMethod':
      return (
        <FormSection heading='Duration data'>
          <FormStack>
            <HookedFormItem
              name='payload.duration.value'
              label='Duration'
              helperText='For how long did the behaviour occur?'
            >
              <HookedDurationInput name='payload.duration.value' />
            </HookedFormItem>
          </FormStack>
        </FormSection>
      );
    case 'EpisodicSeverityMethod': {
      const options = props.method.scales.map((scale) => ({
        label: scale.description,
        value: scale.id,
      }));

      return (
        <FormSection heading='Episodic severity data'>
          <FormStack>
            <HookedFormItem
              name='payload.episodicSeverity.level'
              label='Episodic severity'
              helperText='What level of severity was the behaviour?'
            >
              <HookedEpisodicSeveritySlider
                name='payload.episodicSeverity.level'
                min={1}
                max={options.length}
                defaultValue={1}
                options={options}
              />
            </HookedFormItem>
          </FormStack>
        </FormSection>
      );
    }

    default:
      return <Text fontSize='sm'>Not implemented</Text>;
  }
};

interface Props {
  participantBehaviours: ParticipantBehaviour[];
  isChangeBehaviourDisabled?: boolean;
  isChangeMethodDisabled?: boolean;
}

export const ParticipantBehaviourRecordingForm = (props: Props) => {
  const { participantBehaviours, isChangeBehaviourDisabled, isChangeMethodDisabled } = props;
  const methodType = useWatch({ name: 'method' });
  const participantBehaviourId = useWatch({ name: 'participantBehaviourId' });

  const { selectedMethod, methodOptionsPrime, participantBehaviourOptions } = React.useMemo(() => {
    const participantBehaviourOptions = participantBehaviours.map((pb) => ({
      label: pb.name,
      value: pb.id,
      raw: pb,
    }));

    const selectedParticipantBehaviour = participantBehaviours.find(
      (pb) => pb.id === participantBehaviourId,
    );
    const enabledMethods: Method[] = Object.values(
      selectedParticipantBehaviour?.methods ?? {},
    ).filter((m) => !P.isString(m) && m.enabled) as Method[];

    const selectedMethod: Method | undefined = enabledMethods.find(
      (m) => m.__typename === methodType,
    );

    const methodOptionsPrime = P.compact(
      enabledMethods.map((m) => (m.__typename ? methodOptions[m.__typename] : undefined)),
    );

    return {
      selectedMethod,
      enabledMethods,
      methodOptionsPrime,
      participantBehaviourOptions,
    };
  }, [methodType, participantBehaviourId, participantBehaviours]);

  return (
    <FormStack>
      <FormSection heading='Basic'>
        <FormStack>
          <HookedFormItem name='participantBehaviourId' label='Behaviour'>
            <HookedSelect
              name='participantBehaviourId'
              options={participantBehaviourOptions}
              isDisabled={isChangeBehaviourDisabled}
            />
          </HookedFormItem>
          <FormStack orientation='horizontal'>
            <HookedFormItem
              name='method'
              label='Data collection method'
              helperText='What sort of behaviour data do you want to add?'
            >
              <HookedSelect
                name='method'
                optionVariant='tag'
                options={methodOptionsPrime}
                isDisabled={isChangeMethodDisabled}
              />
            </HookedFormItem>
            <HookedFormItem
              name='timestamp'
              label='Timestamp'
              helperText='When did the behaviour occur?'
            >
              <HookedSingleDatepicker name='timestamp' />
            </HookedFormItem>
          </FormStack>
        </FormStack>
      </FormSection>
      <Divider />

      {selectedMethod && <RecordingMethodForm method={selectedMethod} />}
    </FormStack>
  );
};
