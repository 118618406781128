import { Icon } from '@chakra-ui/react';
import { TrendlineType } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';

interface SvgProps {
  size: number;
}

const Linear = (props: SvgProps) => {
  return (
    <svg viewBox='0 0 100 100' width={`${props.size}px`} height={`${props.size}px`}>
      <title>Linear</title>
      <line y2='10' x2='90' y1='90' x1='10' stroke='black' strokeWidth='5' fill='none' />
    </svg>
  );
};

const Exponential = (props: SvgProps) => {
  return (
    <svg viewBox='0 0 100 100' width={`${props.size}px`} height={`${props.size}px`}>
      <title>Exponential</title>
      <path d='M 10 90 Q 50 70 90 10' stroke='black' strokeWidth='5' fill='none' />
    </svg>
  );
};

const Polynomial = (props: SvgProps) => {
  return (
    <svg viewBox='0 0 100 100' width={`${props.size}px`} height={`${props.size}px`}>
      <title>Polynomial</title>
      <path d='M 10 90 Q 40 20 70 60 Q 90 80 90 10' stroke='black' strokeWidth='5' fill='none' />
    </svg>
  );
};

const Logarithmic = (props: SvgProps) => {
  return (
    <svg viewBox='0 0 100 100' width={`${props.size}px`} height={`${props.size}px`}>
      <title>Logarithmic</title>
      <path d='M 10 90 Q 30 70 50 60 Q 70 50 90 10' stroke='black' strokeWidth='5' fill='none' />
    </svg>
  );
};

interface Props {
  trendlineType: TrendlineType;
  color?: string;
  size?: number;
}

export const TrendlineTypeIcon = (props: Props) => {
  const icon = P.run(() => {
    switch (props.trendlineType) {
      case TrendlineType.Linear:
        return Linear;
      case TrendlineType.Polynomial:
        return Polynomial;
    }
  });

  return <Icon as={icon} color={props.color} size={props.size ?? 16} />;
};
