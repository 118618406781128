import { Image, type ImageProps } from '@chakra-ui/react';
import React from 'react';
interface Props extends ImageProps {}

export const FadeImage = (props: Props) => {
  const [isLoaded, setLoaded] = React.useState(false);

  return (
    <Image
      onLoad={() => setLoaded(true)}
      opacity={isLoaded ? 1 : 0}
      transition='opacity 0.5s ease-in-out'
      {...props}
    />
  );
};
