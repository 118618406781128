import { Box } from '@chakra-ui/react';
import { EditorContent, type Editor as TiptapEditor } from '@tiptap/react';
import React from 'react';
import { CommentBubbleMenu } from './menus/comment/CommentBubbleMenu';
import { DragAndPlusButton } from './menus/DragAndPlusButton';
import { SearchAndReplaceMenu } from './menus/SearchAndReplaceMenu';
import { TableBubbleMenu } from './menus/TableBubbleMenu';

interface Props {
  editor: TiptapEditor | null;
}

export const Editor = (props: Props) => {
  const { editor } = props;

  return (
    <>
      <Box flex={1} overflowY='auto' px={4}>
        <Box
          className='csr'
          as={EditorContent}
          editor={editor}
          flexDirection='column'
          spellCheck='true'
          lang='en-GB'
        />
      </Box>
      {editor?.isEditable && (
        <Box>
          <TableBubbleMenu editor={editor} />
          <CommentBubbleMenu editor={editor} />
          <DragAndPlusButton editor={editor} />
          <SearchAndReplaceMenu editor={editor} />
        </Box>
      )}
    </>
  );
};
