import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteParticipantQR } from '../mutations/deleteParticipantQR.mutation';
import type { DeleteParticipantQrMutation, DeleteParticipantQrMutationVariables } from '../types';

export const useDeleteParticipantQRMutation = (
  options?: MutationOptions<DeleteParticipantQrMutation, DeleteParticipantQrMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantQR), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
