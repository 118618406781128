import {
  Alert,
  AlertIcon,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import type { ParticipantDocument, ParticipantDocumentVersion } from '@piccolohealth/pbs-common';
import { FancyDate, ScrollArea } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { Editor } from '../../components/tiptap/Editor';
import { EditorOutlineSection } from '../../components/tiptap/EditorOutlineSection';
import { useEditor } from '../../components/tiptap/hooks/useEditor';
import { EditorLayout } from '../../components/tiptap/layout/EditorLayout';
import {
  convertYDocToJSONContent,
  deserializeYDoc,
  generateHTML,
  getParticipantDocumentExtensions,
  postProcessContent,
} from '../../components/tiptap/utils';
import { UserAvatar } from '../../components/user/UserAvatar';
import {
  useParticipantDocumentVersionQuery,
  useParticipantDocumentVersionsInfiniteQuery,
} from '../../graphql/hooks/useParticipantQuery';
import { useRestoreParticipantDocumentVersionMutation } from '../../graphql/hooks/useRestoreParticipantDocumentVersionMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { scrollIntoView } from '../../utils/scrollIntoView';

interface ParticipantDocumentHistorySidebarProps {
  participantId: string;
  participantDocumentId: string;
  activeVersionId: string | null;
  setActiveVersionId: (versionId: string) => void;
}

const ParticipantDocumentHistorySidebar = (props: ParticipantDocumentHistorySidebarProps) => {
  const { participantId, participantDocumentId, activeVersionId, setActiveVersionId } = props;
  const { organization } = useAppContext();

  const endRef = React.useRef<HTMLDivElement>(null);

  const participantDocumentVersionsQuery = useParticipantDocumentVersionsInfiniteQuery({
    organizationId: organization.id,
    participantId,
    participantDocumentId,
    request: {
      pagination: {
        limit: 40,
      },
    },
  });

  const participantDocumentVersionsPagination = P.first(
    participantDocumentVersionsQuery.data?.pages ?? [],
  )?.organization?.participant?.document?.versions?.pagination;

  const participantDocumentVersions =
    participantDocumentVersionsQuery.data?.pages.flatMap(
      (page) =>
        page.organization?.participant?.document?.versions.results as ParticipantDocumentVersion[],
    ) ?? [];

  const latestVersion = P.first(participantDocumentVersions);
  const isLoading = participantDocumentVersionsQuery.isLoading;
  const hasNextPage = participantDocumentVersionsQuery.hasNextPage;
  const total = participantDocumentVersionsPagination?.total ?? 0;

  const fetchNextPage = () => {
    participantDocumentVersionsQuery.fetchNextPage();
  };

  // https://github.com/biomejs/biome/issues/630
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to run this effect when the number of pages changes
  React.useEffect(() => {
    if (endRef.current) {
      scrollIntoView(endRef.current);
    }
  }, [participantDocumentVersionsQuery.data?.pages.length]);

  React.useEffect(() => {
    // TODO: Remove the active version null check when we make the active version id required
    if (!P.isNil(latestVersion) && !P.isNil(latestVersion.versionId)) {
      setActiveVersionId(latestVersion.versionId);
    }
  }, [latestVersion, setActiveVersionId]);

  if (!participantDocumentVersionsPagination) {
    return null;
  }

  const content = P.run(() => {
    if (P.isEmpty(participantDocumentVersions)) {
      return (
        <Text fontSize='xs' fontWeight='bold' color='secondary'>
          No versions found
        </Text>
      );
    }

    return (
      <>
        <Text fontSize='xs' fontWeight='bold' color='secondary'>
          Versions
        </Text>
        <ScrollArea flexDir='column' overflowY='auto' h='full'>
          <Stack pr={4}>
            {participantDocumentVersions.map((document, index) => (
              <Button
                as={HStack}
                key={document.versionId}
                isActive={activeVersionId === document.versionId}
                onClick={() => {
                  // TODO: Remove the active version null check when we make the active version id required
                  if (!P.isNil(document.versionId)) {
                    setActiveVersionId(document.versionId);
                  }
                }}
                variant='ghost'
                color='gray.600'
                size='sm'
                justifyContent='start'
                whiteSpace='initial'
                h='auto'
                px={3}
                py={2}
                rounded='md'
                fontWeight='semibold'
              >
                <HStack w='full' align='start'>
                  <Stack spacing={1}>
                    <Text fontWeight='semibold'>Version {total - index}</Text>
                    <FancyDate
                      fontSize='xs'
                      color='secondary'
                      date={document.createdAt}
                      showTooltip={false}
                      showHumanized={false}
                      direction='row'
                    />
                  </Stack>
                  <Spacer />
                  <AvatarGroup spacing={-3}>
                    {document.users.map((member) => (
                      <UserAvatar
                        key={member.id}
                        name={member.name}
                        secondary={member.email}
                        picture={member.picture}
                        showTooltip
                        size='sm'
                      />
                    ))}
                  </AvatarGroup>
                </HStack>
              </Button>
            ))}
            <Box ref={endRef} />
          </Stack>
        </ScrollArea>
        <Spacer />
        <Divider />
        <HStack pr={4}>
          <Text fontSize='xs' whiteSpace='nowrap'>
            {participantDocumentVersionsPagination.total}{' '}
            {inflection.inflect('version', participantDocumentVersionsPagination.total)}
          </Text>
          <Spacer minW={4} />
          <Button
            variant='link'
            onClick={fetchNextPage}
            fontSize='xs'
            isDisabled={!hasNextPage}
            isLoading={isLoading}
            justifyContent='end'
          >
            Load more
          </Button>
        </HStack>
      </>
    );
  });

  return (
    <Stack
      h='full'
      w='280px'
      spacing={4}
      flexShrink={0}
      borderRightWidth='1px'
      borderRightColor='gray.200'
    >
      {content}
    </Stack>
  );
};

interface Props {
  document: ParticipantDocument;
}

export const ParticipantDocumentHistoryModal = createModal<Props>((props) => {
  const { document, modal } = props;

  const { hide, visible } = modal;
  const { organization, successToast, errorToast, location } = useAppContext();

  const [activeVersionId, setActiveVersionId] = React.useState<string | null>(null);

  const restoreParticipantDocumentVersionMutation = useRestoreParticipantDocumentVersionMutation();

  const activeParticipantDocumentQuery = useParticipantDocumentVersionQuery(
    {
      organizationId: organization.id,
      participantId: document.participantId,
      participantDocumentId: document.id,
      versionId: activeVersionId ?? '',
    },
    {
      enabled: activeVersionId !== null,
    },
  );

  const activeParticipantDocument =
    activeParticipantDocumentQuery.data?.organization?.participant?.document;

  const variables = activeParticipantDocument?.variables ?? [];
  const extensions = getParticipantDocumentExtensions({ variables });

  const content = React.useMemo(() => {
    const value = activeParticipantDocument?.value ?? undefined;

    if (!value) {
      return undefined;
    }

    const document = deserializeYDoc(value);
    const jsonContent = convertYDocToJSONContent(document);
    const processed = postProcessContent(jsonContent, []);
    const htmlContent = generateHTML(processed, extensions);

    return htmlContent;
  }, [activeParticipantDocument?.value, extensions]);

  const { editor } = useEditor(
    {
      extensions,
      isEditable: false,
      content,
    },
    [activeParticipantDocument],
  );

  React.useEffect(() => {
    if (editor && !editor.isDestroyed && content && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  const onRestoreVersion = React.useCallback(async () => {
    await restoreParticipantDocumentVersionMutation
      .mutateAsync({
        organizationId: organization.id,
        participantDocumentId: document.id,
        participantId: document.participantId,
        versionId: activeVersionId ?? '',
      })
      .then(() => {
        hide();
        successToast('Version restored successfully');
        location.reload();
      })
      .catch((err) => {
        errorToast(`Error restoring version: ${err.message}`);
      });
  }, [
    activeVersionId,
    document.id,
    document.participantId,
    organization.id,
    restoreParticipantDocumentVersionMutation,
    location,
    errorToast,
    hide,
    successToast,
  ]);

  const status = P.run(() => {
    if (activeParticipantDocumentQuery.isFetching) {
      return WebSocketStatus.Connecting;
    }

    if (activeParticipantDocumentQuery.isError) {
      return WebSocketStatus.Disconnected;
    }

    return WebSocketStatus.Connected;
  });

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='outline'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='start'>
            <Box>
              <Heading size='md'>{document.name} - History</Heading>
              <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                You can view the history of this document here.
              </Text>
            </Box>
            <Spacer />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </Flex>
        </ModalHeader>
        <ModalBody pb={4} overflowY='auto'>
          <HStack w='full' h='full' spacing={4}>
            <ParticipantDocumentHistorySidebar
              participantId={document.participantId}
              participantDocumentId={document.id}
              activeVersionId={activeVersionId}
              setActiveVersionId={setActiveVersionId}
            />
            <Stack w='full' h='full' align='center'>
              <Box h='full' w='full' overflowY='auto' layerStyle='fade'>
                {activeParticipantDocument && (
                  <EditorLayout
                    documentId={document.id}
                    isSidebarOpen={true}
                    status={status}
                    headerContent={
                      <Heading size='lg' noOfLines={1}>
                        {activeParticipantDocument.name}
                      </Heading>
                    }
                    toolbarContent={null}
                    mainContent={<Editor editor={editor} />}
                    sidebarContent={<EditorOutlineSection editor={editor} />}
                  />
                )}
              </Box>

              <Alert status='warning' py={4}>
                <AlertIcon />
                <Text fontWeight='semibold'>
                  Restoring this version will overwrite the current document. Are you sure you want
                  to restore this version?
                </Text>
                <Spacer />
                <HStack>
                  <Button size='sm' onClick={hide}>
                    Close
                  </Button>
                  <Button
                    colorScheme='purple'
                    size='sm'
                    onClick={onRestoreVersion}
                    isLoading={restoreParticipantDocumentVersionMutation.isLoading}
                  >
                    Restore version
                  </Button>
                </HStack>
              </Alert>
            </Stack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
