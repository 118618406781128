import * as Sentry from '@sentry/react';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { getParticipantBaseKey } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { FileUploadModal } from './FileUploadModal';

const getInvalidateQueriesTracker = () => ({
  calls: [] as number[],
  timeThreshold: 60000, // 1 minute
  limit: 10, // Maximum allowed calls within the time threshold

  track: function (queryKey: string) {
    const timestamp = Date.now();
    this.calls.push(timestamp);
    this.checkAndReport(queryKey);
  },

  checkAndReport: function (queryKey: string) {
    const now = Date.now();
    const startTime = now - this.timeThreshold;
    const callsWithinThreshold = this.calls.filter((timestamp) => timestamp >= startTime);

    if (callsWithinThreshold.length > this.limit) {
      const message = `Excessive invalidateQueries calls for ${queryKey} detected: ${callsWithinThreshold.length} calls within ${this.timeThreshold}ms`;
      console.warn(message);
      Sentry.captureEvent({
        message,
        extra: {
          queryKey,
          calls: callsWithinThreshold,
        },
      });
    }

    this.calls = callsWithinThreshold;
  },
});

interface Props {
  participantId: string;
  files?: File[];
}

export const ParticipantFileUploadModal = createModal<Props>((props) => {
  const { participantId, files, ...rest } = props;
  const { hide } = rest.modal;

  const { queryClient, organization, config } = useAppContext();
  const url = `${config.api.url}/organizations/${organization.id}/participants/${participantId}/files`;

  const tracker = React.useMemo(getInvalidateQueriesTracker, []);

  const onUploaded = React.useCallback(
    (status: 'success' | 'error') => {
      const queryKey = getParticipantBaseKey({
        organizationId: organization.id,
        participantId,
      });

      tracker.track(JSON.stringify(queryKey));

      queryClient.invalidateQueries(queryKey);

      if (status === 'success') {
        hide();
      }
    },
    [organization.id, participantId, tracker, queryClient, hide],
  );

  return <FileUploadModal url={url} onUploaded={onUploaded} files={files} {...rest} />;
});
