import { Grid, IconButton, Stack } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { HexColorPicker as RcHexColorPicker } from 'react-colorful';
import { FloatingPopover } from './FloatingPopover';

const presetColors = {
  black: '#000000',
  gray: '#718096',
  blue: '#2979ff',
  cyan: '#00e5ff',
  green: '#00e676',
  orange: '#ff9100',
  purple: '#d500f9',
  red: '#ff1744',
  teal: '#1de9b6',
  yellow: '#ffea00',
} as const;

export interface ColorPickerHexProps {
  value: string;
  onChange: (color: string) => void;
}

export const ColorPickerHex = (props: ColorPickerHexProps) => {
  const { value, onChange } = props;

  return (
    <FloatingPopover
      placement='bottom-start'
      render={() => {
        return (
          <Stack bg='white' shadow='popover' rounded='lg' p={4}>
            <Stack>
              <RcHexColorPicker color={value} onChange={onChange} />
              <Grid templateColumns='repeat(5, 1fr)' gridGap={2}>
                {P.listify(presetColors, (presetColor, name) => (
                  <IconButton
                    key={name}
                    bg={presetColor}
                    onClick={() => onChange(presetColor)}
                    w={8}
                    minW={8}
                    h={8}
                    minH={8}
                    _hover={{ filter: 'saturate(200%)' }}
                    _active={{ filter: 'saturate(300%)' }}
                    aria-label={presetColor}
                  />
                ))}
              </Grid>
            </Stack>
          </Stack>
        );
      }}
    >
      <IconButton
        bg={value}
        w={6}
        minW={6}
        h={6}
        minH={6}
        _hover={{ filter: 'saturate(200%)' }}
        _active={{ filter: 'saturate(300%)' }}
        aria-label='Chosen color'
      />
    </FloatingPopover>
  );
};
