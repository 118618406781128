import {
  Box,
  type BoxProps,
  Button,
  type ButtonProps,
  HStack,
  Icon,
  Input,
  type InputProps,
  type ListItemProps,
  Spacer,
  type StackProps,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  chakra,
  forwardRef,
} from '@chakra-ui/react';
import type { Placement } from '@floating-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretDown, FaCheck } from 'react-icons/fa';
import { type SelectGroup, type SelectOption, useSelect } from '../hooks/useSelect';
import { FloatingPopover } from './FloatingPopover';
import { ScrollAreaAutosize } from './ScrollArea';
import { Spin } from './Spin';

export type { SelectOption };

export type OptionVariant = 'tag' | 'text';

export interface SelectComponents<A, B> {
  Loading?: () => React.ReactElement | null;
  Value?: (props: { option: SelectOption<A> }) => React.ReactElement | null;
  Placeholder?: (props: { placeholder?: string }) => React.ReactElement | null;
  NoOptions?: () => React.ReactElement | null;
  OptionGroup?: (props: { group: SelectGroup<A, B> }) => React.ReactElement | null;
  Option?: (props: { option: SelectOption<A> }) => React.ReactElement | null;
  Header?: (props: StackProps) => React.ReactElement | null;
  Footer?: (props: StackProps) => React.ReactElement | null;
}

export const Loading = () => (
  <Text px={2} py={1} fontSize='sm' fontWeight='semibold' color='gray.500' userSelect='none'>
    Loading...
  </Text>
);

export const SelectContainer = (props: BoxProps) => {
  const { children, ...rest } = props;

  return (
    <chakra.div minW='3xs' w='full' h='auto' rounded='md' bg='white' shadow='popover' {...rest}>
      {props.children}
    </chakra.div>
  );
};

export const SelectItem = forwardRef((props: ListItemProps, ref) => {
  const { children, ...rest } = props;

  return (
    <chakra.li
      ref={ref}
      {...rest}
      listStyleType='none'
      px={2}
      py={1}
      fontSize='sm'
      rounded='md'
      userSelect='none'
      scrollMarginY={2}
      _active={{
        bg: 'gray.100',
      }}
      _checked={{
        fontWeight: 'semibold',
      }}
      _disabled={{
        color: 'gray.400',
      }}
    >
      {children}
    </chakra.li>
  );
});

export const SelectButton = forwardRef(
  (
    props: ButtonProps & {
      showArrow?: boolean;
      variant?: ButtonProps['variant'];
      size?: ButtonProps['size'];
      isDisabled?: boolean;
      isLoading?: boolean;
    },
    ref,
  ) => {
    const { showArrow, isLoading, children, ...rest } = props;

    const height = P.run(() => {
      switch (props.size) {
        case 'xs':
          return 6;
        case 'sm':
          return 8;
        case 'md':
          return 10;
        case 'lg':
          return 12;
        default:
          return 6;
      }
    });

    return (
      <Button
        ref={ref}
        as='div'
        disabled={props.isDisabled}
        size={props.size}
        variant={props.variant}
        w='full'
        minW='auto'
        fontWeight='normal'
        data-pw='button'
        lineHeight='inherit'
        role='group'
        height='auto'
        minH={height}
        {...rest}
      >
        {children}
        {showArrow && (
          <>
            <Spacer />
            <SelectStatus isLoading={isLoading} />
          </>
        )}
      </Button>
    );
  },
);

export const SelectInput = forwardRef((props: InputProps, ref) => {
  return (
    <Input
      ref={ref}
      size='sm'
      variant='unstyled'
      bg='white'
      placeholder='Search...'
      _focus={{
        bg: 'white',
      }}
      {...props}
    />
  );
});

export const SelectStatus = (props: { isLoading?: boolean } & BoxProps) => {
  const { isLoading, ...rest } = props;

  return (
    <Box
      {...rest}
      visibility='hidden'
      _groupHover={{ visibility: 'visible' }}
      _groupFocusWithin={{ visibility: 'visible' }}
      display='flex'
      pointerEvents='none'
      alignItems='center'
    >
      {isLoading ? <Spin size='xs' /> : <Icon as={FaCaretDown} fontSize='xs' />}
    </Box>
  );
};

export const SelectCheck = () => {
  return (
    <Icon
      as={FaCheck}
      visibility='hidden'
      fontSize='xs'
      _groupChecked={{
        visibility: 'visible',
      }}
    />
  );
};

export const NoOptions = () => (
  <Text px={2} py={1} fontSize='sm' fontWeight='semibold' color='gray.500' userSelect='none'>
    No results found
  </Text>
);

export const OptionGroup = <A, B>(props: { group: SelectGroup<A, B> }) => {
  return (
    <Text fontWeight='semibold' color='gray.500' py={2} px={1} fontSize='xs'>
      {props.group.label}
    </Text>
  );
};

export const TagOption = <A,>(props: { option: SelectOption<A> }) => {
  const text =
    props.option.raw === 'CREATABLE' ? (
      <Box display='inline-flex'>
        <Text>Create</Text>
        <Text fontWeight='bold'>&nbsp;{`"${props.option.label}"`}</Text>
      </Box>
    ) : (
      <Text>{props.option.label}</Text>
    );

  return (
    <HStack>
      <Tag size='sm' variant='subtle' colorScheme={props.option.color}>
        <TagLabel whiteSpace='normal'>{text}</TagLabel>
      </Tag>
      <Spacer />
      <SelectCheck />
    </HStack>
  );
};

export const TextOption = <A,>(props: { option: SelectOption<A> }) => {
  const text =
    props.option.raw === 'CREATABLE' ? (
      <Box display='inline-flex'>
        <Text>Create</Text>
        <Text fontWeight='bold'>&nbsp;{`"${props.option.label}"`}</Text>
      </Box>
    ) : (
      <Text>{props.option.label}</Text>
    );

  return (
    <HStack>
      {text}
      <Spacer />
      <SelectCheck />
    </HStack>
  );
};

export const TagValue = <A,>(props: {
  option: SelectOption<A>;
  onRemove?: () => void;
}) => {
  return (
    <Tag size='sm' variant='subtle' colorScheme={props.option?.color}>
      <TagLabel whiteSpace='normal'>{props.option.label}</TagLabel>
      {props.onRemove && (
        <TagCloseButton
          tabIndex={-1}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onRemove?.();
          }}
        />
      )}
    </Tag>
  );
};

export const TextValue = <A,>(props: { option: SelectOption<A> }) => {
  return <Text>{props.option.label}</Text>;
};

export const Placeholder = (props: { placeholder?: string }) => {
  return <Text color='gray.500'>{props.placeholder ?? 'No value selected'}</Text>;
};

export const Header = (props: StackProps) => {
  return (
    <HStack
      py={1.5}
      px={3}
      color='gray.500'
      borderBottomWidth='1px'
      borderBottomColor='gray.200'
      bg='white'
      w='full'
      justify='start'
      roundedTop='md'
      userSelect='none'
      tabIndex={-1}
      {...props}
    >
      {props.children}
    </HStack>
  );
};

export const Footer = (props: StackProps) => {
  return (
    <HStack
      py={1.5}
      px={3}
      bg='gray.50'
      color='gray.500'
      fontWeight='semibold'
      fontSize='xs'
      borderTopWidth='1px'
      borderTopColor='gray.200'
      justify='start'
      w='full'
      roundedBottom='md'
      userSelect='none'
      tabIndex={-1}
    >
      {props.children}
    </HStack>
  );
};

export const SelectComponents = {
  Loading,
  NoOptions,
  OptionGroup,
  Placeholder,
  Option: TagOption,
  Value: TagValue,
  Footer,
  Header,
};

const getComponents = <A, B>(
  components: Partial<SelectComponents<A, B>>,
  optionVariant: OptionVariant,
) => {
  return {
    ...SelectComponents,
    Option: optionVariant === 'tag' ? TagOption : TextOption,
    Value: optionVariant === 'tag' ? TagValue : TextValue,
    Footer: () => <></>,
    Header: undefined,
    ...components,
  };
};

export interface SelectProps<A, B> extends Omit<BoxProps, 'onChange'> {
  options: SelectOption<A>[] | SelectGroup<A, B>[];
  value: SelectOption<A> | null;
  inputValue?: string;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  optionVariant?: OptionVariant;
  placeholder?: string;
  placement?: Placement;
  isLoading?: boolean;
  isDisabled?: boolean;
  showArrow?: boolean;
  components?: SelectComponents<A, B>;
  onChange: (value: SelectOption<A>) => void;
  onInputChange?: (value: string) => void;
  onOpen?: () => void;
}

export const Select = <A, B>(props: SelectProps<A, B>) => {
  const {
    options,
    value,
    inputValue,
    placeholder,
    isLoading = false,
    isDisabled,
    components,
    showArrow = true,
    size = 'sm',
    variant = 'selectOutline',
    optionVariant = 'text',
    placement = 'bottom-start',
    onChange,
    onInputChange,
    onOpen,
    ...rest
  } = props;

  const select = useSelect<A, B>({
    options,
    value,
    inputValue,
    isDisabled,
    onChange,
    onInputChange,
    onOpen,
  });

  const Components = getComponents(components ?? {}, optionVariant);

  const renderOption = (option: SelectOption<A>) => {
    return (
      <SelectItem key={option.value} {...select.optionProps(option)}>
        {Components.Option({ option })}
      </SelectItem>
    );
  };

  const renderGroup = (group: SelectGroup<A, B>) => {
    return (
      <Box key={group.id}>
        {Components.OptionGroup({ group })}
        <Box pl={1}>{group.options.map(renderOption)}</Box>
      </Box>
    );
  };

  return (
    <chakra.div {...select.menuProps()} w='full' {...rest}>
      <FloatingPopover
        open={select.isOpen}
        enabled={!isDisabled}
        placement={placement}
        isRoot={false}
        trigger='focus'
        render={() => (
          <SelectContainer>
            {Components.Header ? (
              Components.Header({})
            ) : (
              <Box borderBottomWidth='1px'>
                <SelectInput {...select.inputProps()} />
              </Box>
            )}
            <ScrollAreaAutosize maxH='xs' overflow='auto'>
              <chakra.ul p={1.5} data-pw='menu'>
                {isLoading && select.options.length === 0 && Components.Loading()}
                {!isLoading && select.options.length === 0 && Components.NoOptions()}
                {select.options.map((option) =>
                  'options' in option ? renderGroup(option) : renderOption(option),
                )}
              </chakra.ul>
            </ScrollAreaAutosize>
            {Components.Footer({})}
          </SelectContainer>
        )}
      >
        <Box tabIndex={-1}>
          <SelectButton
            {...select.buttonProps()}
            variant={variant}
            size={size}
            showArrow={showArrow}
            isDisabled={isDisabled}
            isLoading={isLoading}
          >
            {P.isNil(select.value)
              ? Components.Placeholder({ placeholder })
              : Components.Value({ option: select.value })}
          </SelectButton>
        </Box>
      </FloatingPopover>
    </chakra.div>
  );
};
