import data from '@emoji-mart/data';
import * as Sentry from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { init as emojiInit } from 'emoji-mart';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Root } from './App';
import { getConfig } from './config';
import { sendExceptionToSentry } from './utils/errors';

getConfig().then((config) => {
  if (typeof (window as any).global === 'undefined') {
    (window as any).global = window;
  }

  Sentry.init(config.sentry);
  emojiInit({ data });

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error) => sendExceptionToSentry(error),
    }),
    mutationCache: new MutationCache({
      onError: (error) => sendExceptionToSentry(error),
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
    logger: {
      log: (message) => console.log(message),
      warn: (message) => console.warn(message),
      error: (error) => {
        sendExceptionToSentry(error);
        console.error(error);
      },
    },
  });

  const element = document.getElementById('app');

  if (element) {
    const root = createRoot(element);
    root.render(<Root config={config} queryClient={queryClient} />);
  }
});
