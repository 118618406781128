import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { UpdateUserRequest, User } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useUpdateUserMutation } from '../../graphql/hooks/useUpdateUserMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { UserForm } from './UserForm';
import { userSchema } from './UserInviteModal';

type FormValues = UpdateUserRequest & {
  organizationId: string;
};

interface Props {
  user: User;
}

export const UserEditModal = createModal<Props>((props) => {
  const { modal, user } = props;
  const { visible, hide, remove } = modal;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateUserMutation();

  const organizationMembership = user.organizationMemberships.find(
    (membership) => membership.organizationId === organization.id,
  );

  const defaultValues = React.useMemo(() => {
    return {
      organizationId: organization.id,
      name: user.name,
      email: user.email,
      title: user.title,
      practitionerNumber: user.practitionerNumber,
      picture: user.picture,
      roles: organizationMembership?.roles ?? [],
      locations: (organizationMembership?.locations ?? []).map((l) => l.id),
      settings: user.settings,
    };
  }, [
    organization.id,
    organizationMembership?.locations,
    organizationMembership?.roles,
    user.email,
    user.name,
    user.picture,
    user.practitionerNumber,
    user.title,
    user.settings,
  ]);

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(userSchema),
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const request = P.pickBy(values, (value, key) => {
        return !P.isEqual(value, defaultValues[key]);
      });

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          userId: user.id,
          request: P.deepTrim(request),
        })
        .then(() => {
          hide();
          successToast('User updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating user: ${err.message}`);
        });
    },
    [mutation, organization.id, user.id, defaultValues, hide, successToast, errorToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Update a user</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <UserForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size='sm' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size='sm'>Save</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
