import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { RestoreTemplateVersion } from '../mutations/restoreTemplateVersion.mutation';
import type {
  RestoreTemplateVersionMutation,
  RestoreTemplateVersionMutationVariables,
} from '../types';

export const useRestoreTemplateVersionMutation = (
  options?: MutationOptions<
    RestoreTemplateVersionMutation,
    RestoreTemplateVersionMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(RestoreTemplateVersion), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('template'),
      });
    },
  });
};
