import { Box, Flex } from '@chakra-ui/react';
import type { LooseObject } from '@piccolohealth/util';
import React from 'react';
import { CommandInput } from './CommandInput';
import type { CommandMenuContext, FreePage } from './CommandMenu';

interface Props<A> {
  page: FreePage<A>;
  context: CommandMenuContext<A>;
  args?: LooseObject;
}

export const FreePageContent = <A,>(props: Props<A>) => {
  const { page, context, args } = props;

  return (
    <Flex flexDir='column' w='full'>
      <Box borderBottom='1px solid' borderColor='gray.300' p={1}>
        <CommandInput
          currentPagesLength={context.currentPagesLength}
          popPage={context.popPage}
          value={page.title}
          readOnly
        />
      </Box>
      <Box flexGrow={1} overflowY='auto' w='full'>
        {page.body(context, args)}
      </Box>
    </Flex>
  );
};
