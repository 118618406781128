import { Box, Button, Divider, HStack, Input, Spacer, Stack, Text } from '@chakra-ui/react';
import type * as Common from '@piccolohealth/pbs-common';
import { LabelType, type User } from '@piccolohealth/pbs-common';
import {
  FloatingPopover,
  MultiSelect,
  type MultiSelectOption,
  NumberedBadge,
  type OnChangeRequest,
  PaginationSelectFooter,
  RangeDatepicker,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretDown, FaFilter } from 'react-icons/fa';
import { FormItem } from '../../components/forms/FormItem';
import { FormStack } from '../../components/forms/FormStack';
import { UserChooserSelectComponents } from '../../components/forms/UserChooser';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useOrganizationQuery } from '../../graphql/hooks/useOrganizationQuery';
import { useUsersQuery } from '../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../hooks/useAppContext';
import type { ParticipantsFilter } from '../../hooks/useParticipantsFilter';
import { participantStatusOptions } from './ParticipantStatusTag';

interface Props {
  filter: ParticipantsFilter;
}

const ParticipantNameFilterControl = (props: Props) => {
  const [value, setValue] = React.useState(props.filter.nameFilter);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      props.filter.onNameFilter(value);
    },
    [props.filter],
  );

  React.useEffect(() => {
    setValue(props.filter.nameFilter);
  }, [props.filter.nameFilter]);

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder='All participants'
      size='sm'
    />
  );
};

const ParticipantNdisNumberFilterControl = (props: Props) => {
  const [value, setValue] = React.useState<string>(props.filter.ndisNumberFilter);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      props.filter.onNdisNumberFilter(value);
    },
    [props.filter],
  );

  React.useEffect(() => {
    setValue(props.filter.ndisNumberFilter);
  }, [props.filter.ndisNumberFilter]);

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder='All NDIS numbers'
      size='sm'
    />
  );
};

const ParticipantLocationFilterControl = (props: Props) => {
  const { organization } = useAppContext();

  const { isLoading, data } = useOrganizationQuery({
    organizationId: organization.id,
  });

  const options: MultiSelectOption<Common.Location>[] = React.useMemo(() => {
    const locations = (data?.organization?.locations as Common.Location[]) ?? [];

    return locations.map((location) => {
      return {
        label: location.name,
        value: location.id,
        raw: location,
        color: location.color,
      };
    });
  }, [data?.organization?.locations]);

  const selectedValues: MultiSelectOption<Common.Location>[] = React.useMemo(() => {
    return (props.filter.locationFilter ?? []).flatMap((location) => {
      return options.find((o) => P.isEqual(o.raw.id, location)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.Location>) => {
      props.filter.onLocationFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All locations'
      optionVariant='tag'
    />
  );
};

const ParticipantStatusFilterControl = (props: Props) => {
  const options: MultiSelectOption<Common.ParticipantStatus>[] =
    Object.values(participantStatusOptions);

  const selectedValues: MultiSelectOption<Common.ParticipantStatus>[] = React.useMemo(() => {
    return (props.filter.statusFilter ?? []).flatMap((status) => {
      return options.find((o) => P.isEqual(o.raw, status)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.ParticipantStatus>) => {
      props.filter.onStatusFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All statuses'
      optionVariant='tag'
    />
  );
};

const ParticipantAssignedUsersFilterControl = (props: Props) => {
  const { organization } = useAppContext();
  const { assignedToFilter, onAssignedToFilter } = props.filter;

  const req = {
    organizationId: organization.id,
    request: {
      pagination: {
        limit: 10000,
      },
    },
  };
  const { data, isFetching, isFetched, refetch } = useUsersQuery(req, {
    enabled: !P.isEmpty(assignedToFilter ?? []),
    keepPreviousData: true,
    cacheTime: 0,
  });

  const pagination = organization?.users?.pagination;

  const options: MultiSelectOption<User>[] = React.useMemo(() => {
    return ((data?.organization?.users.results as User[]) ?? []).map((user) => {
      return {
        value: user.id,
        label: user.name,
        raw: user,
      };
    });
  }, [data?.organization?.users.results]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<User>) => {
      onAssignedToFilter(req.values.map((item) => item.value));
    },
    [onAssignedToFilter],
  );

  const selectedValues = React.useMemo(() => {
    return (assignedToFilter ?? []).flatMap((assignedTo) => {
      return options.find((o) => P.isEqual(o.raw.id, assignedTo)) ?? [];
    });
  }, [options, assignedToFilter]);

  const onOpen = React.useCallback(() => {
    if (!isFetched) {
      refetch();
    }
  }, [isFetched, refetch]);

  return (
    <MultiSelect
      options={options}
      selectedValues={selectedValues}
      isLoading={isFetching}
      onChange={onChange}
      onOpen={onOpen}
      placeholder='All users'
      components={{
        Option: UserChooserSelectComponents.Option,
        Value: UserChooserSelectComponents.Value,
        Footer: () =>
          pagination ? (
            <PaginationSelectFooter
              items='user'
              hasMore={false}
              total={pagination.total}
              isLoading={isFetching}
              fetchNextPage={P.noop}
            />
          ) : null,
      }}
    />
  );
};

const ParticipantLabelFilterControl = (props: Props) => {
  const { organization } = useAppContext();
  const { data, isLoading } = useLabelsQuery({
    organizationId: organization.id,
    type: LabelType.Participant,
  });

  const options: MultiSelectOption<Common.Label>[] = React.useMemo(() => {
    const labels = (data?.organization?.labels as Common.Label[]) ?? [];
    return labels.map((label) => {
      return {
        label: label.name,
        value: label.id,
        raw: label,
        color: label.color,
      };
    });
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Common.Label>[] = React.useMemo(() => {
    return (props.filter.labelFilter ?? []).flatMap((label) => {
      return options.find((o) => P.isEqual(o.raw.id, label)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.Label>) => {
      props.filter.onLabelFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All labels'
      optionVariant='tag'
    />
  );
};

const ParticipantDateFilterControl = (props: Props) => {
  return (
    <RangeDatepicker
      startDate={props.filter.startDateFilter ?? null}
      endDate={props.filter.endDateFilter ?? null}
      onStartDateChange={(date) => props.filter.onStartDateFilter(date ?? undefined)}
      onEndDateChange={(date) => props.filter.onEndDateFilter(date ?? undefined)}
    />
  );
};

export const ParticipantsFilterControls = (props: Props) => {
  return (
    <FormStack orientation='horizontal'>
      <Box maxW='2xs'>
        <ParticipantNameFilterControl filter={props.filter} />
      </Box>
      <FloatingPopover
        isPortal
        render={() => (
          <Stack bg='white' spacing={0} w='xs' shadow='popover' rounded='md' overflow='hidden'>
            <HStack py={2} px={4} borderBottomWidth='1px'>
              <Text fontWeight='semibold'>Filters</Text>
              <Spacer />
              <NumberedBadge count={props.filter.activeFilterCount} />
            </HStack>
            <FormStack py={4} px={4} orientation='vertical'>
              <FormItem label='NDIS number'>
                <ParticipantNdisNumberFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label='Status'>
                <ParticipantStatusFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label='Location'>
                <ParticipantLocationFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label='Assigned users'>
                <ParticipantAssignedUsersFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label='Labels'>
                <ParticipantLabelFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label='Date'>
                <ParticipantDateFilterControl filter={props.filter} />
              </FormItem>

              <Divider />

              <Button
                w='fit-content'
                size='sm'
                variant='outline'
                isDisabled={props.filter.activeFilterCount === 0}
                onClick={props.filter.reset}
              >
                Reset
              </Button>
            </FormStack>
          </Stack>
        )}
      >
        <Button
          size='sm'
          variant='outline'
          lineHeight='unset'
          leftIcon={<FaFilter />}
          rightIcon={<FaCaretDown />}
        >
          <HStack>
            <Text>Filter</Text>
            <Box w='28px'>
              <NumberedBadge count={props.filter.activeFilterCount} />
            </Box>
          </HStack>
        </Button>
      </FloatingPopover>
    </FormStack>
  );
};
