import {
  HStack,
  Spacer,
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import type { Label, LabelType } from '@piccolohealth/pbs-common';
import {
  MultiSelect,
  MultiSelectComponents,
  type MultiSelectOption,
  type MultiSelectProps,
  type OnChangeRequest,
  SelectCheck,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ManageLabelMenu } from './ManageLabelMenu';

const labelToOption = (label: Label): MultiSelectOption<Label> => {
  return {
    label: label.name,
    value: label.id,
    raw: label,
  };
};

export interface LabelsControlProps
  extends Omit<MultiSelectProps<Label>, 'options' | 'selectedValues'> {
  onChange: (request: OnChangeRequest<Label>) => void;
  onDelete?: (labelId: string) => void;
  value: string[];
  type: LabelType;
  canManage: boolean;
}

export const LabelsControl = (props: LabelsControlProps) => {
  const { onChange, onDelete, isLoading, isDisabled, value, variant, type, canManage } = props;
  const { organization } = useAppContext();

  const labelsQuery = useLabelsQuery({
    organizationId: organization.id,
    type,
  });

  const options: MultiSelectOption<Label>[] = React.useMemo(() => {
    const labels = (labelsQuery?.data?.organization?.labels as Label[]) || [];
    return labels.map(labelToOption);
  }, [labelsQuery]);

  const selectedValues = React.useMemo(() => {
    const values: MultiSelectOption<Label>[] = value.flatMap((v) =>
      options.filter((o) => o.value === v),
    );
    return values;
  }, [options, value]);

  return (
    <MultiSelect
      options={options}
      selectedValues={selectedValues}
      isLoading={isLoading}
      isCreatable={canManage}
      isDisabled={isDisabled}
      onChange={onChange}
      variant={variant}
      placeholder='Add a label'
      size='sm'
      bg='white'
      components={{
        Value: (props) => (
          <Tag size='sm' variant='subtle' colorScheme={props.option.raw.color}>
            <TagLabel>{props.option.label}</TagLabel>
            <TagCloseButton
              tabIndex={-1}
              isDisabled={isDisabled}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange({
                  value: props.option,
                  values: selectedValues.filter((v) => v.value !== props.option.value),
                  action: 'REMOVE',
                });
              }}
            />
          </Tag>
        ),

        Option: (props) => {
          const isNewLabel = P.isString(props.option.raw) && props.option.raw === 'CREATABLE';

          const text = isNewLabel ? (
            <>
              <Text>Create label</Text>
              <Text fontWeight='bold'>&nbsp;{`"${props.option.label}"`}</Text>
            </>
          ) : (
            <Text>{props.option.label}</Text>
          );

          return (
            <HStack w='full'>
              <Tooltip label={props.option.raw.description} p={2}>
                <Tag size='sm' colorScheme={props.option.raw.color ?? 'blue'} maxW='100%'>
                  {isNewLabel && <TagLeftIcon as={FaPlus} />}
                  <TagLabel display='inline-flex' lineHeight='normal'>
                    {text}
                  </TagLabel>
                </Tag>
              </Tooltip>
              <Spacer />
              <SelectCheck />
              {canManage && !isNewLabel && (
                <ManageLabelMenu label={props.option.raw} onDelete={onDelete} />
              )}
            </HStack>
          );
        },
        Footer: () => (
          <MultiSelectComponents.Footer>
            <Text color='gray.500' fontSize='xs' fontWeight='semibold'>
              {canManage ? 'Select a label or create one' : 'Select a label'}
            </Text>
          </MultiSelectComponents.Footer>
        ),
      }}
    />
  );
};
