import { Text } from '@chakra-ui/react';
import { FieldTemplateType, type ParticipantField } from '@piccolohealth/pbs-common';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';

interface Props {
  field: ParticipantField;
}

export const ParticipantFieldValue = (props: Props) => {
  const { field } = props;

  if (P.isNil(field.value)) {
    return (
      <Text fontSize='sm' color='gray.600'>
        N/A
      </Text>
    );
  }

  switch (field.template.type) {
    case FieldTemplateType.Input:
      return (
        <Text fontSize='sm' color='gray.600' whiteSpace='pre-wrap'>
          {field.template.attributes.prefix}
          {field.value}
          {field.template.attributes.suffix}
        </Text>
      );
    case FieldTemplateType.Textarea:
    case FieldTemplateType.Select:
      return (
        <Text fontSize='sm' color='gray.600' whiteSpace='pre-wrap'>
          {field.value}
        </Text>
      );
    case FieldTemplateType.Datepicker:
      return (
        <Text fontSize='sm' color='gray.600' whiteSpace='pre-wrap'>
          {DateTime.fromISO(field.value.toString()).toLocaleString(DateTime.DATE_SHORT)}
        </Text>
      );
    case FieldTemplateType.MultiSelect:
      return (
        <Text fontSize='sm' color='gray.600'>
          {!P.isEmpty(field.value) ? [field.value].flat().join(', ') : '-'}
        </Text>
      );
  }
};
