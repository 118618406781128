import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteUser } from '../mutations/deleteUser.mutation';
import type { DeleteUserMutation, DeleteUserMutationVariables } from '../types';

export const useDeleteUserMutation = (
  options?: MutationOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteUser), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('user'),
      });
    },
  });
};
