import { MenuItem, Text } from '@chakra-ui/react';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { usePermission } from '../../hooks/usePermission';
import { UserDeleteModal } from './UserDeleteModal';

interface Props {
  userId: string;
}

export const UserDeleteMenuItem = (props: Props) => {
  const { userId } = props;
  const hasPermission = usePermission('delete', 'user');

  return (
    <MenuItem
      isDisabled={!hasPermission}
      onClick={() => showModal(UserDeleteModal, { userId })}
      icon={<FaRegTrashAlt />}
    >
      <Text color='danger'>Delete</Text>
    </MenuItem>
  );
};
