import {
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { useHoverDirty } from 'react-use';
import { useAppContext } from '../../../../hooks/useAppContext';
import { usePermission } from '../../../../hooks/usePermission';
import { TextareaAutosize } from '../../../forms/TextareaAutosize';
import { EditableControls } from '../../../generic/EditableControls';
import { EmojiPickerButton } from '../../../generic/EmojiPickerButton';
import { UserAvatar } from '../../../user/UserAvatar';
import type { CommentData } from '../../extensions/comment/Comment';
import { CommentReactionsList } from './CommentReactionsList';

export interface CommentDataView {
  comment: CommentData;
  onEditComment: (comment: CommentData) => void;
  onRemoveComment: (comment: CommentData) => void;
  isDisabled?: boolean;
}

export const CommentDataView = (props: CommentDataView) => {
  const { comment, onRemoveComment, onEditComment } = props;

  const { user } = useAppContext();
  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);
  const managePermission = usePermission('manage', 'participantDocumentComment');
  // If a user has manage permission (e.g. they're Admin/Support/TeamLeader), they can edit/remove
  // all comments, not just those they are the author of.
  const canEditComment = comment.userId === user.id || managePermission;
  const isDisabled = props.isDisabled || !canEditComment;
  const [content, setContent] = React.useState(comment.content);

  const onAddReaction = React.useCallback(
    (id: string) => {
      const reaction = comment.reactions?.find((reaction) => reaction.id === id);

      if (reaction && reaction.userId === user.id) {
        return;
      }

      onEditComment({
        ...comment,
        reactions: [...(comment.reactions ?? []), { id, username: user.name, userId: user.id }],
      });
    },
    [comment, onEditComment, user.id, user.name],
  );

  const onRemoveReaction = React.useCallback(
    (id: string) => {
      onEditComment({
        ...comment,
        reactions: P.compact(
          (comment.reactions ?? []).map((reaction) => {
            if (reaction.id === id && reaction.userId === user.id) {
              return null;
            }
            return reaction;
          }),
        ),
      });
    },
    [comment, onEditComment, user.id],
  );

  return (
    <Editable
      key={comment.time}
      ref={ref}
      isDisabled={isDisabled}
      isPreviewFocusable={false}
      selectAllOnFocus={false}
      value={content}
      onChange={setContent}
      onSubmit={() => onEditComment({ ...comment, content })}
    >
      <HStack align='center' spacing={2}>
        <UserAvatar
          name={comment.username}
          picture={comment.picture}
          size='xs'
          showTooltip={false}
        />
        <Text fontSize='sm' fontWeight='bold' flexShrink={0}>
          {comment.username}
        </Text>
        <Text fontSize='xs' color='secondary' flexShrink={0}>
          {DateTime.fromMillis(comment.time).toRelative({
            style: 'narrow',
          })}
        </Text>
        <Spacer />
        {!isDisabled && (
          <EditableControls onRemove={() => onRemoveComment(comment)} isHovering={isHovering} />
        )}
      </HStack>
      <Flex minH='8' align='center' mt={1}>
        <EditablePreview
          px={3}
          py={1}
          borderColor='transparent'
          borderWidth='1px'
          wordBreak='break-word'
          lineHeight='20px'
          fontSize='sm'
        />
        <EditableTextarea
          as={TextareaAutosize}
          minRows={1}
          py={1}
          bg='white'
          fontSize='sm'
          lineHeight='20px'
          h='auto'
          _focusVisible={{
            boxShadow: 'none',
          }}
        />
      </Flex>
      <HStack spacing={0}>
        <CommentReactionsList
          reactions={comment.reactions ?? []}
          onAddReaction={onAddReaction}
          onRemoveReaction={onRemoveReaction}
          isDisabled={props.isDisabled}
        />
        <EmojiPickerButton onSelect={({ id }) => onAddReaction(id)} isDisabled={props.isDisabled} />
      </HStack>
    </Editable>
  );
};
