import { HStack, IconButton } from '@chakra-ui/react';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { FaAlignCenter, FaAlignLeft, FaAlignRight } from 'react-icons/fa';

interface Props {
  editor: Editor;
}

export const AlignmentSelect = (props: Props) => {
  const { editor } = props;

  const items = [
    {
      icon: FaAlignLeft,
      title: 'Align left',
      action: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.isActive({ textAlign: 'left' }),
    },
    {
      icon: FaAlignCenter,
      title: 'Align center',
      action: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.isActive({ textAlign: 'center' }),
    },
    {
      icon: FaAlignRight,
      title: 'Align right',
      action: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.isActive({ textAlign: 'right' }),
    },
  ];

  return (
    <HStack spacing={0} rounded='lg' overflow='hidden'>
      {items.map((item) => (
        <IconButton
          key={item.title}
          icon={<item.icon />}
          aria-label={item.title}
          variant='ghost'
          size='sm'
          rounded='none'
          onClick={item.action}
          isActive={item.isActive()}
        />
      ))}
    </HStack>
  );
};
