import type { ParticipantBehaviour, TimeSeriesChartWidget } from '@piccolohealth/pbs-common';
import { DateTime, P } from '@piccolohealth/util';
import type { BarSeriesOption, EChartsOption, LineSeriesOption } from 'echarts';
import type { DatasetOption } from 'echarts/types/dist/shared.js';
import React from 'react';
import { useTimeseriesChart } from '../../hooks/useTimeseriesChart';
import type { DatasetSource } from '../../utils';
import { TimeSeriesChartWidgetContent } from '../timeseries/TimeSeriesChartWidgetContent';

interface Props {
  widget: TimeSeriesChartWidget;
  participantBehaviours: ParticipantBehaviour[];
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (date: DateTime) => void;
  onEndDateChange: (date: DateTime) => void;
}

export const TimeSeriesGridItem = (props: Props) => {
  const { widget, participantBehaviours, startDate, endDate, onStartDateChange, onEndDateChange } =
    props;

  const withOptions = React.useCallback(
    (options: EChartsOption): EChartsOption => {
      const dataZoom = P.flatten(P.compact([options.dataZoom])).map((i) => {
        return i.type === 'slider' ? { ...i, show: false } : i;
      });

      // Check if there is data in the selected date range
      // If there is no data, show a message in the center of the chart
      const hasData = P.run(() => {
        const dataset = options.dataset as DatasetOption;
        const series = P.compact([options.series].flat()) as (LineSeriesOption | BarSeriesOption)[];
        const seriesDimensions = P.compact(series.map((s) => s.encode?.y)) as string[];
        const source = (dataset.source as DatasetSource[]) ?? [];

        return source.some((s) => {
          const timestamp = DateTime.fromISO(s.timestamp);
          const isInRange = timestamp >= startDate && timestamp <= endDate;
          const hasValue = seriesDimensions.some((d) => s[d] !== null);
          return isInRange && hasValue;
        });
      });

      return {
        ...options,
        grid: {
          top: 40,
          bottom: 30,
          left: 20,
          right: 30,
        },
        dataZoom,
        yAxis: {
          show: false,
        },
        xAxis: {
          ...options.xAxis,
          name: undefined,
        },
        title: {
          show: !hasData,
          textStyle: {
            color: '#718096',
            fontSize: 12,
            fontWeight: 'normal',
          },
          text: 'No data for the selected date range',
          left: 'center',
          top: 'center',
        },
      };
    },
    [endDate, startDate],
  );

  const chart = useTimeseriesChart({
    widget,
    participantBehaviours,
    startDate,
    endDate,
    setWidget: P.noop,
    onStartDateChange,
    onEndDateChange,
    withOptions,
  });

  return <TimeSeriesChartWidgetContent chart={chart} />;
};
