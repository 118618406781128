import { HStack, Stack, type StackProps, Text } from '@chakra-ui/react';
import React from 'react';
import { UserAvatar } from './UserAvatar';

interface Props extends StackProps {
  name: string;
  secondary?: string;
  picture?: string | null;
}

export const UserDescription = (props: Props) => {
  const { name, secondary, picture, ...rest } = props;

  return (
    <HStack spacing={4} fontSize='sm' {...rest}>
      <UserAvatar
        secondary={secondary}
        picture={picture}
        name={name}
        w={10}
        h={10}
        showTooltip={false}
      />
      <Stack spacing={1} lineHeight={1} align='start'>
        <Text fontWeight='semibold'>{name}</Text>
        {secondary && <Text color='secondary'>{secondary}</Text>}
      </Stack>
    </HStack>
  );
};
