import { Box } from '@chakra-ui/react';
import type { Template } from '@piccolohealth/pbs-common';
import React from 'react';
import { useImageUpload } from '../../components/tiptap/hooks/useImageUpload';
import { getParticipantNoteTemplateExtensions } from '../../components/tiptap/utils';
import { useAppContext } from '../../hooks/useAppContext';
import { DocumentTemplateEditor } from './DocumentTemplateEditor';
import { TemplateTitleBar } from './TemplateTitleBar';

interface Props {
  template: Template;
}

export const ParticipantNoteTemplateEditor = (props: Props) => {
  const { template } = props;

  const { organization, config } = useAppContext();

  const onImageUpload = useImageUpload({
    imageUploadUrl: `${config.api.url}/organizations/${organization.id}/templates/${template.id}/images`,
  });

  const extensions = getParticipantNoteTemplateExtensions({ onImageUpload });

  return (
    <Box borderTopWidth='1px' borderColor='gray.200' h='full'>
      <DocumentTemplateEditor
        documentId={`template/${template.id}`}
        extensions={extensions}
        isEditable
        title={(options) => <TemplateTitleBar template={template} {...options} />}
      />
    </Box>
  );
};
