import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateTemplate } from '../mutations/updateTemplate.mutation';
import type { UpdateTemplateMutation, UpdateTemplateMutationVariables } from '../types';

export const useUpdateTemplateMutation = (
  options?: MutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateTemplate), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('template'),
      });
    },
  });
};
