import { type Label, LabelType } from '@piccolohealth/pbs-common';
import type { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useCreateParticipantDocumentLabelMutation } from '../../graphql/hooks/useCreateParticipantDocumentLabelMutation';
import { useDeleteParticipantDocumentLabelMutation } from '../../graphql/hooks/useDeleteParticipantDocumentLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { LabelsControl } from './LabelsControl';

export interface ManageParticipantDocumentLabelsControlProps {
  participantId: string;
  participantDocumentId: string;
  value: string[];
  isDisabled?: boolean;
}

export const ManageParticipantDocumentLabelsControl = (
  props: ManageParticipantDocumentLabelsControlProps,
) => {
  const { participantId, participantDocumentId, value, isDisabled } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const createParticipantDocumentLabelMutation = useCreateParticipantDocumentLabelMutation({
    retry: false,
  });
  const deleteParticipantDocumentLabelMutation = useDeleteParticipantDocumentLabelMutation({
    retry: false,
  });

  const labelManagePermission = usePermission('manage', 'label');
  const participantDocumentLabelManagePermission = usePermission(
    'manage',
    'participantDocumentLabel',
  );

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type: LabelType.ParticipantDocument,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label ${err.message}`));
        }
        case 'ADD': {
          return createParticipantDocumentLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              participantDocumentId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error adding label to participant document: ${err.message}`),
            );
        }

        case 'REMOVE': {
          return deleteParticipantDocumentLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              participantDocumentId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error removing label from participant document: ${err.message}`),
            );
        }
      }
    },
    [
      createLabelMutation,
      organization.id,
      errorToast,
      createParticipantDocumentLabelMutation,
      participantId,
      participantDocumentId,
      deleteParticipantDocumentLabelMutation,
    ],
  );

  return (
    <LabelsControl
      onChange={onChange}
      value={value}
      isDisabled={isDisabled || !participantDocumentLabelManagePermission}
      canManage={labelManagePermission}
      variant='selectGhost'
      type={LabelType.ParticipantDocument}
    />
  );
};
