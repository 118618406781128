import { Stack, type StackProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends StackProps {
  orientation?: 'horizontal' | 'vertical';
}

export const FormStack = (props: React.PropsWithChildren<Props>) => {
  const { orientation, children, ...rest } = props;

  const direction = (orientation ?? 'vertical') === 'horizontal' ? 'row' : 'column';

  return (
    <Stack w='full' h='full' spacing={4} direction={direction} {...rest}>
      {children}
    </Stack>
  );
};
