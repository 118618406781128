import { Switch, type SwitchProps } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends SwitchProps {
  name: string;
}

export const HookedSwitch = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return (
    <Switch
      {...rest}
      isChecked={inputProps.value}
      onChange={(e) => inputProps.onChange(e.target.checked)}
    />
  );
};
