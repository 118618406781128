import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { RestoreParticipantDocumentVersion } from '../mutations/restoreParticipantDocumentVersion.mutation';
import type {
  RestoreParticipantDocumentVersionMutation,
  RestoreParticipantDocumentVersionMutationVariables,
} from '../types';

export const useRestoreParticipantDocumentVersionMutation = (
  options?: MutationOptions<
    RestoreParticipantDocumentVersionMutation,
    RestoreParticipantDocumentVersionMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(RestoreParticipantDocumentVersion), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
