import type { ParticipantBehaviour, TableWidget } from '@piccolohealth/pbs-common';
import { type DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { useTable } from '../../hooks/useTable';
import { TableWidgetContent } from '../table/TableWidgetContent';

interface Props {
  widget: TableWidget;
  participantBehaviours: ParticipantBehaviour[];
  startDate: DateTime;
  endDate: DateTime;
}

export const TableGridItem = (props: Props) => {
  const { widget, participantBehaviours, startDate, endDate } = props;

  const table = useTable({
    widget,
    participantBehaviours,
    startDate,
    endDate,
    setWidget: P.noop,
  });

  return <TableWidgetContent table={table} />;
};
