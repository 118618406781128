import { IconButton, type IconButtonProps } from '@chakra-ui/react';
import { QRCode } from '@piccolohealth/ui';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
  qrId: string;
}

export const ParticipantQRCode = (props: Props) => {
  const { qrId, ...rest } = props;
  const value = `${window.origin}/recording/qr/${qrId}`;

  return (
    <IconButton
      as={NavLink}
      to={value}
      variant='outline'
      aria-label='QR code'
      p={2}
      h={24}
      w={24}
      icon={
        <QRCode
          value={value}
          size={256}
          bgColor='transparent'
          style={{
            height: 'auto',
            maxWidth: '100%',
            width: '100%',
          }}
        />
      }
      {...rest}
    />
  );
};
