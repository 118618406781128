import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteParticipantQrLabel } from '../mutations/deleteParticipantQRLabel.mutation';
import type {
  DeleteParticipantQrLabelMutation,
  DeleteParticipantQrLabelMutationVariables,
} from '../types';

export const useDeleteParticipantQRLabelMutation = (
  options?: MutationOptions<
    DeleteParticipantQrLabelMutation,
    DeleteParticipantQrLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantQrLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
