import { HStack, IconButton } from '@chakra-ui/react';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { FaBold, FaItalic, FaStrikethrough, FaUnderline } from 'react-icons/fa';

interface Props {
  editor: Editor;
}

export const MarkSelect = (props: Props) => {
  const { editor } = props;

  const items = [
    {
      icon: FaBold,
      title: 'Bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: FaItalic,
      title: 'Italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: FaStrikethrough,
      title: 'Strikethrough',
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike'),
    },
    {
      icon: FaUnderline,
      title: 'Underline',
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline'),
    },
  ];

  return (
    <HStack spacing={0} rounded='lg' overflow='hidden'>
      {items.map((item) => (
        <IconButton
          key={item.title}
          icon={<item.icon />}
          aria-label={item.title}
          variant='ghost'
          size='sm'
          rounded='none'
          onClick={item.action}
          isActive={item.isActive()}
        />
      ))}
    </HStack>
  );
};
