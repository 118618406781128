import { Button, type ButtonProps, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

export const ParticipantRestrictedPracticeCreateButton = (props: ButtonProps) => {
  return (
    <Button
      w='full'
      h='full'
      rounded='2xl'
      borderWidth='2px'
      borderStyle='dashed'
      color='gray.400'
      bg='white'
      _hover={{
        borderColor: 'gray.400',
        color: 'gray.500',
      }}
      fontSize='2xl'
      leftIcon={<Icon as={FaPlus} />}
      {...props}
    >
      Add restricted practice
    </Button>
  );
};
