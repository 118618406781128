import { Button, HStack, Icon, Tag } from '@chakra-ui/react';
import type * as Common from '@piccolohealth/pbs-common';
import { DataTable, Empty, createColumnHelper } from '@piccolohealth/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormStack } from '../../../components/forms/FormStack';
import { showModal } from '../../../components/generic/Modal';
import { LabelCreateModal } from './LabelCreateModal';
import { LabelDeleteModal } from './LabelDeleteModal';
import { LabelUpdateModal } from './LabelUpdateModal';

interface Props {
  type: Common.LabelType;
  labels: Common.Label[];
}

export const LabelsForType = (props: Props) => {
  const { type, labels } = props;

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Common.Label>();

    return [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (props) => (
          <Tag colorScheme={props.row.original.color} size='sm'>
            {props.row.original.name}
          </Tag>
        ),
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (props) => (
          <HStack>
            <Button
              size='xs'
              variant='link'
              onClick={() =>
                showModal(LabelUpdateModal, {
                  label: props.row.original,
                })
              }
            >
              Edit
            </Button>
            <Button
              size='xs'
              variant='link'
              colorScheme='red'
              onClick={() =>
                showModal(LabelDeleteModal, {
                  labelId: props.row.original.id,
                })
              }
            >
              Delete
            </Button>
          </HStack>
        ),
      }),
    ];
  }, []);

  return (
    <FormStack>
      <DataTable
        columns={columns}
        data={labels}
        renderEmpty={() => <Empty title='No labels found' />}
        size='sm'
      />
      <Button
        flexShrink={0}
        size='sm'
        leftIcon={<Icon as={FaPlus} />}
        onClick={() =>
          showModal(LabelCreateModal, {
            type,
          })
        }
      >
        Add new label
      </Button>
    </FormStack>
  );
};
