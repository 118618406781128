import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateLabel } from '../mutations/updateLabel.mutation';
import type { UpdateLabelMutation, UpdateLabelMutationVariables } from '../types';

export const useUpdateLabelMutation = (
  options?: MutationOptions<UpdateLabelMutation, UpdateLabelMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('labels') || query.queryKey.includes('participant'),
      });
    },
  });
};
