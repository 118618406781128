import { Box, Card, CardBody, CardHeader, Divider, HStack, Spacer } from '@chakra-ui/react';
import { ADMIN_EMAIL, COMPANY_NAME, type ParticipantNote } from '@piccolohealth/pbs-common';
import { FancyDate, ScrollArea } from '@piccolohealth/ui';
import React from 'react';
import { showModal } from '../../components/generic/Modal';
import { UserDescription } from '../../components/user/UserDescription';
import { ManageParticipantNoteLabelsControl } from './ManageParticipantNoteLabelsControl';
import { ParticipantNoteActionMenu } from './ParticipantNoteActionMenu';
import { ParticipantNoteEditModal } from './ParticipantNoteEditModal';
import { ParticipantNoteEditorViewOnly } from './ParticipantNoteEditorViewOnly';

interface Props {
  participantNote: ParticipantNote;
  isDisabled?: boolean;
}

export const ParticipantNoteCard = (props: Props) => {
  const { participantNote, isDisabled } = props;

  return (
    <Card w='full'>
      <CardHeader py={4}>
        <HStack fontSize='sm' spacing={4} w='full'>
          <UserDescription
            name={participantNote.user?.name ?? COMPANY_NAME}
            secondary={participantNote.user?.email ?? ADMIN_EMAIL}
            picture={participantNote?.user?.picture}
          />

          <ManageParticipantNoteLabelsControl
            participantNote={participantNote}
            isDisabled={isDisabled}
          />
          <Spacer />
          <HStack flexShrink={0} spacing={4}>
            <FancyDate date={participantNote.createdAt} showHumanized={false} />
            <ParticipantNoteActionMenu
              participantNote={participantNote}
              size='sm'
              isDisabled={isDisabled}
            />
          </HStack>
        </HStack>
      </CardHeader>

      <Divider />

      <CardBody
        as={ScrollArea}
        overflowY='hidden'
        py={0}
        layerStyle='fade'
        onClick={() =>
          showModal(ParticipantNoteEditModal, {
            participantId: participantNote.participantId,
            participantNoteId: participantNote.id,
            isDisabled,
          })
        }
        cursor='pointer'
      >
        <Box h='xs'>
          <ParticipantNoteEditorViewOnly value={participantNote.value} />
        </Box>
      </CardBody>
      <Divider />
    </Card>
  );
};
