import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import type { Editor } from '@tiptap/react';
import React from 'react';
import {
  FaCaretDown,
  FaCheck,
  FaFont,
  FaHeading,
  FaListOl,
  FaListUl,
  FaQuoteLeft,
} from 'react-icons/fa';

interface Props {
  editor: Editor;
}

export const NodeTypeSelect = (props: Props) => {
  const { editor } = props;

  const items = [
    {
      icon: FaHeading,
      title: 'Heading 1',
      action: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive('heading', { level: 1 }),
    },
    {
      icon: FaHeading,
      title: 'Heading 2',
      action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive('heading', { level: 2 }),
    },
    {
      icon: FaHeading,
      title: 'Heading 3',
      action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActive: () => editor.isActive('heading', { level: 3 }),
    },
    {
      icon: FaHeading,
      title: 'Heading 4',
      action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
      isActive: () => editor.isActive('heading', { level: 4 }),
    },
    {
      icon: FaFont,
      title: 'Text',
      action: () => editor.chain().focus().setParagraph().run(),
      isActive: () => editor.isActive('paragraph'),
    },
    {
      icon: FaListUl,
      title: 'Bullet List',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
    },
    {
      icon: FaListOl,
      title: 'Ordered List',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList'),
    },
    {
      icon: FaQuoteLeft,
      title: 'Blockquote',
      action: () => editor.chain().focus().toggleBlockquote().run(),
      isActive: () => editor.isActive('blockquote'),
    },
  ];

  const activeItem = P.run(() => {
    const activeItems = items.filter((item) => item.isActive());

    if (activeItems.length > 1) {
      const withoutParagraph = activeItems.filter((item) => item.title !== 'Paragraph');
      return P.first(withoutParagraph);
    }

    return P.first(activeItems);
  });

  return (
    <Menu placement='bottom' isLazy>
      <MenuButton
        as={Button}
        variant='ghost'
        size='sm'
        rightIcon={<FaCaretDown />}
        rounded='lg'
        bg='white'
        w='100px'
        textAlign='left'
      >
        {activeItem?.title ?? 'Paragraph'}
      </MenuButton>

      <MenuList minW='160px'>
        {items.map((item) => (
          <MenuItem
            icon={<item.icon />}
            key={item.title}
            onClick={item.action}
            fontSize='sm'
            py={1}
            px={2}
          >
            <HStack>
              <Text>{item.title}</Text>
              <Spacer />
              {item === activeItem && <FaCheck fontSize='10px' />}
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
