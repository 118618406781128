import { HStack, IconButton, Spacer, Stack, Text } from '@chakra-ui/react';
import { Dot, FancyDate } from '@piccolohealth/ui';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import type { UseTimeseriesChartReturn } from '../../../participantData/hooks/useTimeseriesChart';
import { PhaseChangeLineEditControl } from './PhaseChangeLineEditControl';

interface Props {
  chart: UseTimeseriesChartReturn;
}

export const PhaseChangeList = (props: Props) => {
  const { chart } = props;

  return (
    <Stack w='full'>
      {chart.widget.phaseChangeLines.map((phaseChangeLine) => (
        <HStack
          key={phaseChangeLine.id}
          w='full'
          spacing={4}
          bg='gray.50'
          rounded='lg'
          py={2}
          px={3}
        >
          <Dot bg={phaseChangeLine.color} size='12px' />
          <Stack spacing={0}>
            <Text fontWeight='semibold' fontSize='sm'>
              {phaseChangeLine.name}
            </Text>
            <HStack>
              <Text fontSize='xs'>{phaseChangeLine.type}</Text>
              <FancyDate date={phaseChangeLine.timestamp} showHumanized={false} fontSize='xs' />
            </HStack>
          </Stack>
          <Spacer />
          <HStack spacing={0}>
            <PhaseChangeLineEditControl chart={chart} phaseChangeLine={phaseChangeLine} />
            <IconButton
              icon={<FaTrash />}
              aria-label='Remove'
              size='xs'
              variant='ghost'
              onClick={() => chart.onRemovePhaseChangeLine(phaseChangeLine.id)}
            />
          </HStack>
        </HStack>
      ))}
    </Stack>
  );
};
