import { Alert, AlertIcon, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantMutation } from '../../graphql/hooks/useDeleteParticipantMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
}

export const ParticipantDeleteModal = createModal<Props>((props) => {
  const { participantId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
      })
      .then(() => {
        successToast('Participant deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting participant: ${err.message}`);
      });
  }, [organization.id, mutation, participantId, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading='Do you want to delete this participant?'
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this participant <strong>will remove associated data.</strong>
          </Text>
          <Alert status='info' fontSize='md' border='none'>
            <AlertIcon />
            If you want to keep the data, consider archiving the participant instead.
          </Alert>
        </Stack>
      }
      requireConfirm
      onDelete={onDelete}
    />
  );
});
