import { Box, Button, Divider, HStack, Input, Spacer, Stack, Text } from '@chakra-ui/react';
import type { Method, ParticipantBehaviourStatus } from '@piccolohealth/pbs-common';
import {
  FloatingPopover,
  MultiSelect,
  type MultiSelectOption,
  NumberedBadge,
  type OnChangeRequest,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretDown, FaFilter } from 'react-icons/fa';
import { FormItem } from '../../../components/forms/FormItem';
import { FormStack } from '../../../components/forms/FormStack';
import type { ParticipantBehavioursFilter } from '../hooks/useParticipantBehavioursFilter';
import { methodOptions } from './MethodTag';
import { participantBehaviourStatusOptions } from './ParticipantBehaviourStatusTag';

interface Props {
  filter: ParticipantBehavioursFilter;
}

const ParticipantBehaviourNameFilterControl = (props: Props) => {
  const [value, setValue] = React.useState(props.filter.nameFilter);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      props.filter.onNameFilter(value);
    },
    [props.filter],
  );

  React.useEffect(() => {
    setValue(props.filter.nameFilter);
  }, [props.filter.nameFilter]);

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder='All behaviours'
      size='sm'
    />
  );
};

const ParticipantBehaviourStatusFilterControl = (props: Props) => {
  const options: MultiSelectOption<ParticipantBehaviourStatus>[] = Object.values(
    participantBehaviourStatusOptions,
  );

  const selectedValues: MultiSelectOption<ParticipantBehaviourStatus>[] = React.useMemo(() => {
    return (props.filter.statusFilter ?? []).flatMap((status) => {
      return options.find((o) => P.isEqual(o.raw, status)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<ParticipantBehaviourStatus>) => {
      props.filter.onStatusFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All statuses'
      optionVariant='tag'
    />
  );
};

const ParticipantBehaviourMethodFilterControl = (props: Props) => {
  const options: MultiSelectOption<Method['__typename']>[] = Object.values(methodOptions);

  const selectedValues: MultiSelectOption<Method['__typename']>[] = React.useMemo(() => {
    return (props.filter.methodFilter ?? []).flatMap((status) => {
      return options.find((o) => P.isEqual(o.raw, status)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Method['__typename']>) => {
      props.filter.onMethodFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All methods'
      optionVariant='tag'
    />
  );
};

export const ParticipantBehaviourFilterControls = (props: Props) => {
  return (
    <FormStack orientation='horizontal'>
      <Box maxW='2xs'>
        <ParticipantBehaviourNameFilterControl filter={props.filter} />
      </Box>
      <FloatingPopover
        isPortal
        render={() => (
          <Stack bg='white' spacing={0} w='xs' shadow='popover' rounded='md' overflow='hidden'>
            <HStack py={2} px={4} borderBottomWidth='1px'>
              <Text fontWeight='semibold'>Filters</Text>
              <Spacer />
              <NumberedBadge count={props.filter.activeFilterCount} />
            </HStack>
            <FormStack py={4} px={4} orientation='vertical'>
              <FormItem label='Status'>
                <ParticipantBehaviourStatusFilterControl filter={props.filter} />
              </FormItem>
              <FormItem label='Methods'>
                <ParticipantBehaviourMethodFilterControl filter={props.filter} />
              </FormItem>
              <Divider />

              <Button
                w='fit-content'
                size='sm'
                variant='outline'
                isDisabled={props.filter.activeFilterCount === 0}
                onClick={props.filter.reset}
              >
                Reset
              </Button>
            </FormStack>
          </Stack>
        )}
      >
        <Button
          size='sm'
          variant='outline'
          lineHeight='unset'
          leftIcon={<FaFilter />}
          rightIcon={<FaCaretDown />}
        >
          <HStack>
            <Text>Filter</Text>
            <Box w='28px'>
              <NumberedBadge count={props.filter.activeFilterCount} />
            </Box>
          </HStack>
        </Button>
      </FloatingPopover>
    </FormStack>
  );
};
