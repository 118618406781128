import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateParticipantFile } from '../mutations/updateParticipantFile.mutation';
import type {
  UpdateParticipantFileMutation,
  UpdateParticipantFileMutationVariables,
} from '../types';

export const useUpdateParticipantFileMutation = (
  options?: MutationOptions<UpdateParticipantFileMutation, UpdateParticipantFileMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateParticipantFile), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
