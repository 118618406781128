import { Text } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import { type NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import type { Variable } from '../utils';

export const HighlightedVariableValue = (props: NodeViewProps) => {
  const { id } = props.node.attrs;
  const { variables } = props.extension.options;

  const variable = ((variables as Variable[]) ?? []).find((v) => v.id === id);

  if (!variable) {
    return null;
  }

  const rendered = P.run(() => {
    if (P.isNil(variable.value)) {
      return <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
    }

    return variable.value;
  });

  return (
    <NodeViewWrapper as='span'>
      <Text as='span' variant='highlighted' zIndex={10} userSelect='text'>
        {rendered}
      </Text>
    </NodeViewWrapper>
  );
};
