import { Button, type ButtonProps } from '@chakra-ui/react';
import type { Participant } from '@piccolohealth/pbs-common';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';

interface Props extends ButtonProps {
  qrId: string;
  participant: Participant;
}

export const ParticipantQRCopyLinkButton = (props: Props) => {
  const { qrId, participant, children, ...rest } = props;

  const { successToast } = useAppContext();

  const handleCopy = async () => {
    const value = `${window.origin}/recording/qr/${qrId}`;
    await navigator.clipboard.writeText(value);
    successToast('Link copied to clipboard');
  };

  return (
    <Button size='xs' w='fit-content' onClick={handleCopy} {...rest}>
      {children}
    </Button>
  );
};
