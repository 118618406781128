import { useRequiredParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Navigate, type RouteObject } from 'react-router-dom';
import { OrganizationNotFound } from '../../components/generic/OrganizationNotFound';
import { PageError } from '../../components/generic/PageError';
import { PermissionRoute } from '../../components/generic/PermissionRoute';
import { ProtectedRoute } from '../../components/generic/ProtectedRoute';
import { useAppContext } from '../../hooks/useAppContext';
import { Incident } from '../incidents/Incident';
import { IncidentCrumb } from '../incidents/IncidentCrumb';
import { Incidents } from '../incidents/Incidents';
import { Participant } from '../participant/Participant';
import { ParticipantCrumb } from '../participant/ParticipantCrumb';
import { Participants } from '../participant/Participants';
import { Profile } from '../profile/Profile';
import { Settings } from '../settings/Settings';
import { Template } from '../templates/Template';
import { TemplateCrumb } from '../templates/TemplateCrumb';
import { Templates } from '../templates/Templates';
import { User } from '../user/User';
import { UserCrumb } from '../user/UserCrumb';
import { Users } from '../user/Users';
import { OrganizationLayout } from './OrganizationLayout';

export const ParticipantsRedirect = () => {
  const { organizationId } = useRequiredParams<{ organizationId: string }>();
  return <Navigate to={`/organizations/${organizationId}/participants`} />;
};

export const OrganizationRedirect = () => {
  const { user } = useAppContext();

  const organizationMemberships = user?.organizationMemberships ?? [];
  const fallbackOrganizationId = P.first(organizationMemberships)?.organizationId;
  const organizationId = fallbackOrganizationId;

  if (organizationId) {
    return <Navigate to={`/organizations/${organizationId}/participants`} />;
  } else {
    return <OrganizationNotFound />;
  }
};

export const organizationRoutes: RouteObject[] = [
  {
    path: ':organizationId',
    element: (
      <ProtectedRoute>
        <OrganizationLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <ParticipantsRedirect />,
      },
      {
        path: 'participants',
        handle: { crumb: () => 'Participants' },
        children: [
          {
            index: true,
            element: <Participants />,
          },
          {
            path: ':participantId/*',
            handle: { crumb: () => ParticipantCrumb },
            element: (
              <PermissionRoute actions='read' subject='participant'>
                <Participant />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'incidents',
        handle: { crumb: () => 'Incidents' },
        children: [
          {
            index: true,
            element: (
              <PermissionRoute actions='create' subject='incident'>
                <Incidents />
              </PermissionRoute>
            ),
          },
          {
            path: ':incidentId',
            handle: { crumb: () => IncidentCrumb },
            element: (
              <PermissionRoute actions='create' subject='incident'>
                <Incident />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'templates',
        handle: { crumb: () => 'Templates' },
        children: [
          {
            index: true,
            element: (
              <PermissionRoute actions='create' subject='template'>
                <Templates />
              </PermissionRoute>
            ),
          },
          {
            path: ':templateId',
            handle: { crumb: () => TemplateCrumb },
            element: (
              <PermissionRoute actions='create' subject='template'>
                <Template />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'users',
        handle: { crumb: () => 'Users' },
        children: [
          {
            index: true,
            element: (
              <PermissionRoute actions='create' subject='user'>
                <Users />
              </PermissionRoute>
            ),
          },
          {
            path: ':userId/*',
            handle: { crumb: () => UserCrumb },
            element: (
              <PermissionRoute actions='create' subject='user'>
                <User />
              </PermissionRoute>
            ),
          },
        ],
      },
      {
        path: 'settings/*',
        handle: { crumb: () => 'Settings' },
        element: (
          <PermissionRoute actions='update' subject='organization'>
            <Settings />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/profile/*',
        handle: { crumb: () => 'User' },
        index: true,
        element: (
          <PermissionRoute actions='update' subject='user'>
            <Profile />
          </PermissionRoute>
        ),
      },
      {
        path: '*',
        element: <PageError type='NotFound' />,
      },
    ],
  },
];
