import { HStack, IconButton } from '@chakra-ui/react';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { FaRedo, FaUndo } from 'react-icons/fa';

interface Props {
  editor: Editor;
}

export const UndoRedo = (props: Props) => {
  const { editor } = props;

  const items = [
    {
      icon: FaUndo,
      title: 'Undo',
      action: () => editor.chain().focus().undo().run(),
      isActive: () => false,
      isDisabled: () => !editor.can().undo(),
    },
    {
      icon: FaRedo,
      title: 'Redo',
      action: () => editor.chain().focus().redo().run(),
      isActive: () => false,
      isDisabled: () => !editor.can().redo(),
    },
  ];

  return (
    <HStack spacing={1}>
      {items.map((item) => (
        <IconButton
          key={item.title}
          icon={<item.icon />}
          aria-label={item.title}
          variant='ghost'
          size='sm'
          rounded='md'
          onClick={item.action}
          isActive={item.isActive()}
          isDisabled={item.isDisabled()}
        />
      ))}
    </HStack>
  );
};
