import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../../components/generic/DeleteConfirmModal';
import { createModal } from '../../../components/generic/Modal';
import { useDeleteLocationMutation } from '../../../graphql/hooks/useDeleteLocationMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  locationId: string;
}

export const LocationDeleteModal = createModal<Props>((props) => {
  const { locationId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteLocationMutation({});

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        locationId,
      })
      .then(async () => {
        successToast('Location deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting location: ${err.message}`);
      });
  }, [mutation, organization, locationId, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading='Do you want to delete this location?'
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this location <strong>cannot be undone.</strong>
          </Text>
        </Stack>
      }
      requireConfirm
      onDelete={onDelete}
      isLoading={mutation.isLoading}
    />
  );
});
