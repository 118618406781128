import { Textarea, type TextareaProps, forwardRef } from '@chakra-ui/react';
import React from 'react';
import ResizeTextarea from 'react-textarea-autosize';

export interface Props extends TextareaProps {
  minRows?: number;
}

export const TextareaAutosize = forwardRef<Props, 'textarea'>((props, ref) => (
  <Textarea
    minH='unset'
    overflow='hidden'
    w='100%'
    resize='none'
    ref={ref}
    minRows={3}
    as={ResizeTextarea}
    {...props}
  />
));
