import { ScrollArea } from '@piccolohealth/ui';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { TableOfContentView } from './TableOfContentView';

interface Props {
  editor: Editor | null;
}

export const EditorOutlineSection = (props: Props) => {
  const { editor } = props;
  return (
    <ScrollArea w='full' h='full' pt={2}>
      {editor && <TableOfContentView editor={editor} />}
    </ScrollArea>
  );
};
