import { MenuItem, Text } from '@chakra-ui/react';
import type { User } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { usePermission } from '../../hooks/usePermission';
import { UserEditModal } from './UserEditModal';

interface Props {
  user: User;
}

export const UserEditMenuItem = (props: Props) => {
  const { user } = props;
  const hasPermission = usePermission('update', 'user');

  return (
    <MenuItem
      isDisabled={!hasPermission}
      onClick={() => showModal(UserEditModal, { user })}
      icon={<FaPencilAlt />}
    >
      <Text>Edit</Text>
    </MenuItem>
  );
};
