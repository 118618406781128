import { Alert, AlertIcon, Text } from '@chakra-ui/react';
import { inflection } from '@piccolohealth/util';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const FormErrorsAlert = () => {
  const { formState } = useFormContext();

  const errors = Object.values(formState.errors);

  if (errors.length === 0) {
    return null;
  }

  const verb = errors.length === 1 ? 'is' : 'are';

  return (
    <Alert status='error' mt={4}>
      <AlertIcon />
      <Text fontSize='sm'>
        There {verb} {errors.length} {inflection.inflect('error', errors.length)} in the form.
        Please correct all errors before submitting.
      </Text>
    </Alert>
  );
};
