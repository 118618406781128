import { NumberedBadge } from '@piccolohealth/ui';
import React from 'react';
import { useParticipantWidgetLayoutItemsQuery } from '../../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  participantId: string;
}

export const ParticipantDataBadge = (props: Props) => {
  const { participantId } = props;
  const { organization } = useAppContext();

  const { data } = useParticipantWidgetLayoutItemsQuery({
    organizationId: organization.id,
    participantId,
  });

  const widgets = data?.organization?.participant?.widgets || [];

  return <NumberedBadge count={widgets.length} />;
};
