import { Button, Divider, Stack, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { type DataSource, MethodType, type TableSeries } from '@piccolohealth/pbs-common';
import { FloatingPopover } from '@piccolohealth/ui';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { z } from 'zod';
import { FormSection } from '../../../../components/forms/FormSection';
import { HookedSubmitButton } from '../../../../components/forms/HookedSubmitButton';
import type { UseTableReturn } from '../../hooks/useTable';
import { METHOD_TYPE_OPTIONS } from '../../utils';
import { TableSeriesForm } from './TableSeriesForm';

type FormValues = TableSeries;

const sourceSchema: z.ZodSchema<DataSource> = z.object({
  __typename: z.literal('ParticipantBehaviourDataSource'),
  method: z.nativeEnum(MethodType),
  participantBehaviourId: z.string(),
});

export const tableSeriesSchema: z.ZodSchema<TableSeries> = z.object({
  __typename: z.literal('TableSeries'),
  id: z.string(),
  source: sourceSchema,
});

interface TimeSeriesAddFormProps {
  participantBehaviourId: string;
  table: UseTableReturn;
  onClose: () => void;
}

export const TableSeriesAddForm = (props: TimeSeriesAddFormProps) => {
  const { participantBehaviourId, table, onClose } = props;

  const initialValues: TableSeries = {
    __typename: 'TableSeries',
    id: uuid(),
    source: {
      __typename: 'ParticipantBehaviourDataSource',
      method: METHOD_TYPE_OPTIONS[0].raw,
      participantBehaviourId,
    },
  };

  const methods = useForm<TableSeries>({
    defaultValues: initialValues,
    resolver: zodResolver(tableSeriesSchema),
  });

  const { reset } = methods;

  const onSubmit = (values: FormValues) => {
    table.onAddSeries(values);
    reset({
      ...initialValues,
      id: uuid(),
    });
    onClose();
  };

  return (
    <Stack
      bg='white'
      spacing={0}
      w='lg'
      layerStyle='bordered'
      shadow='popover'
      rounded='md'
      overflow='hidden'
      p={4}
    >
      <FormProvider {...methods}>
        <FormSection as='form' heading='Add series' onSubmit={methods.handleSubmit(onSubmit)}>
          <Divider />
          <TableSeriesForm table={table} />
          <Divider />
          <HookedSubmitButton w='full' size='sm' colorScheme='purple' isDisabled={false}>
            Add
          </HookedSubmitButton>
        </FormSection>
      </FormProvider>
    </Stack>
  );
};

interface Props {
  participantBehaviourId: string;
  table: UseTableReturn;
}

export const TableSeriesAddControl = (props: Props) => {
  const { participantBehaviourId, table } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FloatingPopover
      open={isOpen}
      setOpen={(v) => (v ? onOpen() : onClose())}
      render={() => (
        <TableSeriesAddForm
          participantBehaviourId={participantBehaviourId}
          table={table}
          onClose={onClose}
        />
      )}
    >
      <Button
        w='full'
        rightIcon={<FaPlus />}
        variant='outline'
        borderStyle='dashed'
        size='sm'
        onClick={onOpen}
      >
        Add series
      </Button>
    </FloatingPopover>
  );
};
