import { Box, HStack, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react';
import type { Participant, ParticipantBehaviour, ParticipantQr } from '@piccolohealth/pbs-common';
import React from 'react';
import { usePermission } from '../../hooks/usePermission';
import { ManageParticipantQRLabelsControl } from '../labels/ManageParticipantQRLabelsControl';
import { ParticipantQRActionsMenu } from './ParticipantQRActionsMenu';
import { ParticipantQRCode } from './ParticipantQRCode';
import { ParticipantQRCopyImageButton } from './ParticipantQRCopyImageButton';
import { ParticipantQRCopyLinkButton } from './ParticipantQRCopyLinkButton';
import { ParticipantQRRecordingsPopover } from './ParticipantQRRecordingsPopover';
import { ParticipantQRStatusTag } from './ParticipantQRStatusTag';

interface Props {
  qr: ParticipantQr;
  participantBehaviours: ParticipantBehaviour[];
  participant: Participant;
  isDisabled?: boolean;
}

export const ParticipantQRListItem = (props: Props) => {
  const { qr, participantBehaviours, participant } = props;

  const updateParticipantQRPermission = usePermission('update', 'participantQr');
  const isDisabled = props.isDisabled || !updateParticipantQRPermission;

  return (
    <HStack w='full' align='stretch' spacing={4}>
      <ParticipantQRCode qrId={qr.id} h={24} w={24} flexShrink={0} />
      <Stack minW='180px' py={2} spacing={0}>
        <Text fontWeight='semibold' fontSize='lg'>
          {qr.name}
        </Text>
        <Box fontSize='sm' color='secondary'>
          <ParticipantQRRecordingsPopover qr={qr} />
        </Box>
        <Spacer />
        <HStack align='stretch'>
          <ParticipantQRCopyLinkButton qrId={qr.id} participant={participant} variant='link'>
            Copy link
          </ParticipantQRCopyLinkButton>
          <ParticipantQRCopyImageButton qrId={qr.id} participant={participant} variant='link'>
            Copy QR code
          </ParticipantQRCopyImageButton>
        </HStack>
      </Stack>
      <Stack w='full' py={2}>
        <SimpleGrid
          w='full'
          columns={2}
          templateColumns='max-content auto'
          alignItems='baseline'
          rowGap={2}
          columnGap={2}
        >
          <Box fontSize='sm' color='secondary'>
            Status:
          </Box>

          <Text fontSize='sm' noOfLines={3}>
            <ParticipantQRStatusTag status={qr.status} size='sm' />
          </Text>
          <Box fontSize='sm' color='secondary'>
            Behaviours:
          </Box>

          <Text fontSize='sm' noOfLines={3}>
            {qr.behaviours.map((behaviour) => behaviour.name).join(', ')}
          </Text>
          <Box fontSize='sm' color='secondary'>
            Labels:
          </Box>
          <ManageParticipantQRLabelsControl
            participantId={participant.id}
            qrId={qr.id}
            value={qr.labels.map((l) => l.id)}
            isDisabled={isDisabled}
          />
        </SimpleGrid>
      </Stack>
      <Box>
        <ParticipantQRActionsMenu
          participantId={participant.id}
          qr={qr}
          participantBehaviours={participantBehaviours}
          isDisabled={isDisabled}
        />
      </Box>
    </HStack>
  );
};
