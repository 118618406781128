import {
  Alert,
  AlertIcon,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import type { ParticipantNote, ParticipantNoteVersion } from '@piccolohealth/pbs-common';
import { FancyDate, ScrollArea } from '@piccolohealth/ui';
import { P, inflection } from '@piccolohealth/util';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { Editor } from '../../components/tiptap/Editor';
import { useEditor } from '../../components/tiptap/hooks/useEditor';
import { EditorLayout } from '../../components/tiptap/layout/EditorLayout';
import {
  convertYDocToJSONContent,
  deserializeYDoc,
  generateHTML,
  getParticipantNoteExtensions,
  postProcessContent,
} from '../../components/tiptap/utils';
import { UserAvatar } from '../../components/user/UserAvatar';
import {
  useParticipantNoteVersionQuery,
  useParticipantNoteVersionsInfiniteQuery,
} from '../../graphql/hooks/useParticipantQuery';
import { useRestoreParticipantNoteVersionMutation } from '../../graphql/hooks/useRestoreParticipantNoteVersionMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { scrollIntoView } from '../../utils/scrollIntoView';

interface ParticipantNoteHistorySidebarProps {
  participantId: string;
  participantNoteId: string;
  activeVersionId: string | null;
  setActiveVersionId: (versionId: string) => void;
}

const ParticipantNoteHistorySidebar = (props: ParticipantNoteHistorySidebarProps) => {
  const { participantId, participantNoteId, activeVersionId, setActiveVersionId } = props;
  const { organization } = useAppContext();

  const endRef = React.useRef<HTMLDivElement>(null);

  const participantNoteVersionsQuery = useParticipantNoteVersionsInfiniteQuery({
    organizationId: organization.id,
    participantId,
    participantNoteId,
    request: {
      pagination: {
        limit: 40,
      },
    },
  });

  const participantNoteVersionsPagination = P.first(participantNoteVersionsQuery.data?.pages ?? [])
    ?.organization?.participant?.note?.versions?.pagination;

  const participantNoteVersions =
    participantNoteVersionsQuery.data?.pages.flatMap(
      (page) => page.organization?.participant?.note?.versions.results as ParticipantNoteVersion[],
    ) ?? [];

  const latestVersion = P.first(participantNoteVersions);
  const isLoading = participantNoteVersionsQuery.isLoading;
  const hasNextPage = participantNoteVersionsQuery.hasNextPage;
  const total = participantNoteVersionsPagination?.total ?? 0;

  const fetchNextPage = () => {
    participantNoteVersionsQuery.fetchNextPage();
  };

  // https://github.com/biomejs/biome/issues/630
  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to run this effect when the number of pages changes
  React.useEffect(() => {
    if (endRef.current) {
      scrollIntoView(endRef.current);
    }
  }, [participantNoteVersionsQuery.data?.pages.length]);

  React.useEffect(() => {
    // TODO: Remove the active version null check when we make the active version id required
    if (!P.isNil(latestVersion) && !P.isNil(latestVersion.versionId)) {
      setActiveVersionId(latestVersion.versionId);
    }
  }, [latestVersion, setActiveVersionId]);

  if (!participantNoteVersionsPagination) {
    return null;
  }

  const content = P.run(() => {
    if (P.isEmpty(participantNoteVersions)) {
      return (
        <Text fontSize='xs' fontWeight='bold' color='secondary'>
          No versions found
        </Text>
      );
    }

    return (
      <>
        <Text fontSize='xs' fontWeight='bold' color='secondary'>
          Versions
        </Text>
        <ScrollArea flexDir='column' overflowY='auto' h='full'>
          <Stack pr={4}>
            {participantNoteVersions.map((note, index) => (
              <Button
                as={HStack}
                key={note.versionId}
                isActive={activeVersionId === note.versionId}
                onClick={() => {
                  // TODO: Remove the active version null check when we make the active version id required
                  if (!P.isNil(note.versionId)) {
                    setActiveVersionId(note.versionId);
                  }
                }}
                variant='ghost'
                color='gray.600'
                size='sm'
                justifyContent='start'
                whiteSpace='initial'
                h='auto'
                px={3}
                py={2}
                rounded='md'
                fontWeight='semibold'
              >
                <HStack w='full' align='start'>
                  <Stack spacing={1}>
                    <Text fontWeight='semibold'>Version {total - index}</Text>
                    <FancyDate
                      fontSize='xs'
                      color='secondary'
                      date={note.createdAt}
                      showTooltip={false}
                      showHumanized={false}
                      direction='row'
                    />
                  </Stack>
                  <Spacer />
                  <AvatarGroup spacing={-3}>
                    {note.users.map((member) => (
                      <UserAvatar
                        key={member.id}
                        name={member.name}
                        secondary={member.email}
                        picture={member.picture}
                        showTooltip
                        size='sm'
                      />
                    ))}
                  </AvatarGroup>
                </HStack>
              </Button>
            ))}
            <Box ref={endRef} />
          </Stack>
        </ScrollArea>
        <Spacer />
        <Divider />
        <HStack pr={4}>
          <Text fontSize='xs' whiteSpace='nowrap'>
            {participantNoteVersionsPagination.total}{' '}
            {inflection.inflect('version', participantNoteVersionsPagination.total)}
          </Text>
          <Spacer minW={4} />
          <Button
            variant='link'
            onClick={fetchNextPage}
            fontSize='xs'
            isDisabled={!hasNextPage}
            isLoading={isLoading}
            justifyContent='end'
          >
            Load more
          </Button>
        </HStack>
      </>
    );
  });

  return (
    <Stack
      h='full'
      w='280px'
      spacing={4}
      flexShrink={0}
      borderRightWidth='1px'
      borderRightColor='gray.200'
    >
      {content}
    </Stack>
  );
};

interface Props {
  note: ParticipantNote;
}

export const ParticipantNoteHistoryModal = createModal<Props>((props) => {
  const { note, modal } = props;

  const { hide, visible } = modal;
  const { organization, successToast, errorToast, location } = useAppContext();

  const [activeVersionId, setActiveVersionId] = React.useState<string | null>(null);

  const restoreParticipantDocumentVersionMutation = useRestoreParticipantNoteVersionMutation();

  const activeParticipantNoteQuery = useParticipantNoteVersionQuery(
    {
      organizationId: organization.id,
      participantId: note.participantId,
      participantNoteId: note.id,
      versionId: activeVersionId ?? '',
    },
    {
      enabled: activeVersionId !== null,
    },
  );

  const activeParticipantNote = activeParticipantNoteQuery.data?.organization?.participant?.note;

  const extensions = getParticipantNoteExtensions({});

  const content = React.useMemo(() => {
    const valueBuffer = activeParticipantNote?.value ?? undefined;

    if (!valueBuffer) {
      return undefined;
    }

    const document = deserializeYDoc(valueBuffer.data);
    const jsonContent = convertYDocToJSONContent(document);
    const processed = postProcessContent(jsonContent, []);
    const htmlContent = generateHTML(processed, extensions);

    return htmlContent;
  }, [activeParticipantNote?.value, extensions]);

  const { editor } = useEditor(
    {
      extensions,
      isEditable: false,
      content,
    },
    [activeParticipantNote],
  );

  React.useEffect(() => {
    if (editor && !editor.isDestroyed && content && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  const onRestoreVersion = React.useCallback(async () => {
    await restoreParticipantDocumentVersionMutation
      .mutateAsync({
        organizationId: organization.id,
        participantNoteId: note.id,
        participantId: note.participantId,
        versionId: activeVersionId ?? '',
      })
      .then(() => {
        hide();
        successToast('Version restored successfully');
        location.reload();
      })
      .catch((err) => {
        errorToast(`Error restoring version: ${err.message}`);
      });
  }, [
    activeVersionId,
    note.id,
    note.participantId,
    organization.id,
    restoreParticipantDocumentVersionMutation,
    location,
    errorToast,
    hide,
    successToast,
  ]);

  const status = P.run(() => {
    if (activeParticipantNoteQuery.isFetching) {
      return WebSocketStatus.Connecting;
    }

    if (activeParticipantNoteQuery.isError) {
      return WebSocketStatus.Disconnected;
    }

    return WebSocketStatus.Connected;
  });

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='outline'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align='start'>
            <Box>
              <Heading size='md'>Note - History</Heading>
              <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                You can view the history of this note here.
              </Text>
            </Box>
            <Spacer />
            <ModalCloseButton position='unset' top='unset' right='unset' />
          </Flex>
        </ModalHeader>
        <ModalBody pb={4} overflowY='auto'>
          <HStack w='full' h='full' spacing={4}>
            <ParticipantNoteHistorySidebar
              participantId={note.participantId}
              participantNoteId={note.id}
              activeVersionId={activeVersionId}
              setActiveVersionId={setActiveVersionId}
            />
            <Stack w='full' h='full' align='center'>
              <Box h='full' w='full' overflowY='auto' layerStyle='fade'>
                {activeParticipantNote && (
                  <EditorLayout
                    documentId={note.id}
                    isSidebarOpen={false}
                    status={status}
                    headerContent={null}
                    toolbarContent={null}
                    mainContent={<Editor editor={editor} />}
                    sidebarContent={null}
                  />
                )}
              </Box>

              <Alert status='warning' py={4}>
                <AlertIcon />
                <Text fontWeight='semibold'>
                  Restoring this version will overwrite the current note. Are you sure you want to
                  restore this version?
                </Text>
                <Spacer />
                <HStack>
                  <Button size='sm' onClick={hide}>
                    Close
                  </Button>
                  <Button
                    colorScheme='purple'
                    size='sm'
                    onClick={onRestoreVersion}
                    isLoading={restoreParticipantDocumentVersionMutation.isLoading}
                  >
                    Restore version
                  </Button>
                </HStack>
              </Alert>
            </Stack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
