import { HStack, Progress, Spacer, Stack, Text } from '@chakra-ui/react';
import { Duration, P, humanizeDuration, prettyBytes } from '@piccolohealth/util';
import React from 'react';
import type { FileUploadProgress } from '../hooks/useFileUpload';
import { FileIcon } from './FileIcon';

const getProgressProps = (item: FileUploadProgress) => {
  const uploadingText =
    !P.isNil(item.total) && !P.isNil(item.percentage)
      ? `${P.round(item.percentage)}% - ${prettyBytes(item.loaded)} of ${prettyBytes(item.total)}`
      : '';

  const elapsed = !P.isNil(item.elapsed) ? humanizeDuration(Duration.fromMillis(item.elapsed)) : '';

  switch (item.status) {
    case 'idle': {
      return { colorScheme: 'gray', isAnimated: false, progressText: '', elapsed };
    }
    case 'uploading': {
      return { colorScheme: 'purple', isAnimated: true, progressText: uploadingText, elapsed };
    }
    case 'errored': {
      return { colorScheme: 'red', isAnimated: false, progressText: item.error ?? '', elapsed };
    }
    case 'finished': {
      return {
        colorScheme: 'green',
        isAnimated: false,
        item,
        progressText: uploadingText,
        elapsed,
      };
    }
  }
};

interface Props {
  files: FileUploadProgress[];
}

export const FileUploadItemsProgress = (props: Props) => {
  const { files } = props;

  if (P.isEmpty(files)) {
    return null;
  }

  const rows = files.map((item: FileUploadProgress) => {
    const { colorScheme, isAnimated, progressText, elapsed } = getProgressProps(item);

    return (
      <HStack key={item.id} w='full'>
        <FileIcon name={item.file.name} w={12} h={12} flexShrink={0} />
        <Stack spacing={1} w='full' isTruncated>
          <Text isTruncated fontSize='sm'>
            {item.file.name}
          </Text>
          <Progress
            value={P.round(item.percentage)}
            rounded='xl'
            hasStripe
            colorScheme={colorScheme}
            isAnimated={isAnimated}
            size='xs'
          />
          <HStack w='full'>
            <Text fontSize='xs' color='secondary'>
              {progressText}
            </Text>
            <Spacer />
            <Text fontSize='xs' color='secondary' flexShrink={0}>
              {elapsed}
            </Text>
          </HStack>
        </Stack>
      </HStack>
    );
  });

  const finished = files.filter((f) => f.status === 'finished' || f.status === 'errored').length;
  const total = files.length;
  const percent = P.round((finished / total) * 100);

  return (
    <Stack spacing={4} h='full' w='full'>
      <Text fontSize='lg' fontWeight='semibold'>
        Uploaded {finished}/{total} ({percent}%)
      </Text>
      <Stack w='full' spacing={4}>
        {rows}
      </Stack>
    </Stack>
  );
};
