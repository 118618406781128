import { Button, Divider, HStack } from '@chakra-ui/react';
import React from 'react';
import { FormItem } from '../../components/forms/FormItem';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { useCreatePasswordChangeTicketUrlMutation } from '../../graphql/hooks/useCreatePasswordChangeTicketUrlMutation';
import { useAppContext } from '../../hooks/useAppContext';

export const SecuritySettings = () => {
  const { organization } = useAppContext();

  const resetPasswordMutation = useCreatePasswordChangeTicketUrlMutation();

  const resetPassword = async () => {
    const resp = await resetPasswordMutation.mutateAsync({ organizationId: organization.id });
    window.location.href = resp.createPasswordChangeTicketUrl;
  };

  return (
    <FormStack maxW='lg'>
      <FormSection heading='Security' description='Manage your password here'>
        <Divider />
        <FormItem helperText='You can request a new password. You will be emailed a link to reset it.'>
          <HStack>
            <Button
              isLoading={resetPasswordMutation.isLoading}
              colorScheme='purple'
              size='sm'
              onClick={resetPassword}
            >
              Reset password
            </Button>
          </HStack>
        </FormItem>
      </FormSection>
    </FormStack>
  );
};
