import { Box, Divider, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import {
  type TransferListOption,
  type UseTransferListReturn,
  useTransferList,
} from '../hooks/useTransferList';
import { ScrollAreaAutosize } from './ScrollArea';

export interface TransferListHeaderProps {
  itemCount: number;
  selectedItemCount: number;
}

const TransferListHeader = React.memo((props: TransferListHeaderProps) => {
  return (
    <Stack>
      <HStack>
        <Text fontSize='sm' fontWeight='semibold'>
          Items
        </Text>
        <Text fontSize='sm' color='secondary'>
          {props.selectedItemCount} / {props.itemCount} selected
        </Text>
      </HStack>
    </Stack>
  );
});

export type TransferListContentItem<A> = TransferListOption<A> & {
  isSelected: boolean;
  onClick: () => void;
};

export interface TransferListContentProps<A> {
  options: TransferListContentItem<A>[];
}

const TransferListContent = React.memo(<A,>(props: TransferListContentProps<A>) => {
  return (
    <Stack>
      {props.options.map((option) => (
        <HStack key={option.value}>
          <input type='checkbox' checked={option.isSelected} onChange={option.onClick} />
          <Text fontSize='sm'>{option.label}</Text>
        </HStack>
      ))}
    </Stack>
  );
});

interface TransferButtonsProps {
  onClickTransferToRight: () => void;
  onClickTransferToLeft: () => void;
  isLeftDisabled: boolean;
  isRightDisabled: boolean;
  isLeftLoading?: boolean;
  isRightLoading?: boolean;
}

const TransferButtons = React.memo((props: TransferButtonsProps) => {
  return (
    <Stack h='full' align='center'>
      <Box h='full' w='1px' bg='gray.200' />
      <Stack>
        <IconButton
          aria-label='measurement-transfer-list-item-right'
          icon={<FaArrowRight />}
          onClick={props.onClickTransferToRight}
          isDisabled={props.isRightDisabled}
          isLoading={props.isRightLoading}
        />
        <IconButton
          aria-label='measurement-transfer-list-item-left'
          icon={<FaArrowLeft />}
          onClick={props.onClickTransferToLeft}
          isDisabled={props.isLeftDisabled}
          isLoading={props.isLeftLoading}
        />
      </Stack>
      <Box h='full' w='1px' bg='gray.200' />
    </Stack>
  );
});

export interface TransferListComponents<A> {
  left?: {
    Header?: (props: TransferListHeaderProps) => React.ReactElement | null;
    Content?: (props: TransferListContentProps<A>) => React.ReactElement | null;
  };
  right?: {
    Header?: (props: TransferListHeaderProps) => React.ReactElement | null;
    Content?: (props: TransferListContentProps<A>) => React.ReactElement | null;
  };
}

export const TransferListComponents = {
  left: {
    Header: TransferListHeader,
    Content: TransferListContent,
  },
  right: {
    Header: TransferListHeader,
    Content: TransferListContent,
  },
};

const getComponents = <A,>(components: Partial<TransferListComponents<A>>) => {
  return {
    left: {
      ...TransferListComponents.left,
      ...components.left,
    },
    right: {
      ...TransferListComponents.right,
      ...components.right,
    },
  };
};

export interface TransferListProps<A> {
  options: TransferListOption<A>[];
  value: TransferListOption<A>[];
  onChange: (values: TransferListOption<A>[]) => void;
  components?: TransferListComponents<A>;
  isLeftLoading?: boolean;
  isRightLoading?: boolean;
}

export const TransferList = <A,>(props: TransferListProps<A>) => {
  const { components } = props;

  const transferList: UseTransferListReturn<A> = useTransferList(props);

  const Components = React.useMemo(() => getComponents(components ?? {}), [components]);

  return (
    <HStack w='full' h='full'>
      <Stack h='full'>
        {Components.left.Header({
          itemCount: transferList.leftOptions.length,
          selectedItemCount: transferList.leftOptions.filter((o) => o.isSelected).length,
        })}
        <Divider />
        <Components.left.Content options={transferList.leftOptions} />
      </Stack>

      <TransferButtons
        onClickTransferToRight={transferList.onClickTransferToRight}
        onClickTransferToLeft={transferList.onClickTransferToLeft}
        isLeftDisabled={!transferList.rightOptions.some((o) => o.isSelected)}
        isRightDisabled={!transferList.leftOptions.some((o) => o.isSelected)}
        isLeftLoading={transferList.isLeftLoading}
        isRightLoading={transferList.isRightLoading}
      />

      <Stack h='full' flexGrow={1}>
        {Components.right.Header({
          itemCount: transferList.rightOptions.length,
          selectedItemCount: transferList.rightOptions.filter((o) => o.isSelected).length,
        })}
        <Divider />
        <ScrollAreaAutosize w='full' h='full' overflowY='auto'>
          <Components.right.Content options={transferList.rightOptions} />
        </ScrollAreaAutosize>
      </Stack>
    </HStack>
  );
};
