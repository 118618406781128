import { Box, Center, Flex, Icon, type ListItemProps, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { typedMemo } from '../../utils/memo';
import { FloatingPopover } from '../FloatingPopover';
import type { Action, CommandMenuContext } from './CommandMenu';
import { CommandMenuItemTooltip } from './CommandMenuItemTooltip';

export interface Props<A> extends Omit<ListItemProps, 'value'> {
  action: Action<A>;
  context: CommandMenuContext<A>;
}

function CommandActionMenuItem<A>(props: Props<A>) {
  const { action, context } = props;

  return (
    <FloatingPopover
      trigger='hover'
      shouldInitialFocus={false}
      render={() =>
        action.tooltip && <CommandMenuItemTooltip ml={4} tooltip={action.tooltip(context)} />
      }
    >
      <Flex alignItems='center' w='full' py={1} userSelect='none'>
        <Center
          flexShrink={0}
          h={10}
          w={10}
          p={2}
          borderWidth='1px'
          borderColor='gray.200'
          rounded='md'
          bg='white'
        >
          {action.leftIcon(context)}
        </Center>
        <Box ml={2}>
          {action.title}
          {action.description && (
            <Text fontSize='xs' color='secondary' noOfLines={1} maxW='xs'>
              {action.description}
            </Text>
          )}
        </Box>
        <Spacer />
        {action.kind === 'goto' && <Icon as={FaAngleRight} />}
      </Flex>
    </FloatingPopover>
  );
}

export const CommandActionMenuItemMemo = typedMemo(CommandActionMenuItem);
