import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type ParticipantBehaviour,
  ParticipantBehaviourStatus,
  type UpdateParticipantBehaviourRequest,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateParticipantBehaviourMutation } from '../../../graphql/hooks/useUpdateParticipantBehaviourMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { ParticipantBehaviourForm } from './ParticipantBehaviourForm';

type FormValues = UpdateParticipantBehaviourRequest;

export const methodsSchema = z.object({
  abc: z.object({
    id: z.string(),
    enabled: z.boolean(),
  }),
  duration: z.object({
    id: z.string(),
    enabled: z.boolean(),
  }),
  frequency: z.object({
    id: z.string(),
    enabled: z.boolean(),
  }),
  episodicSeverity: z
    .object({
      id: z.string(),
      enabled: z.boolean(),
      scales: z.array(
        z.object({
          id: z.string(),
          description: z.string(),
        }),
      ),
    })
    .superRefine(({ enabled, scales }, ctx) => {
      if (!enabled) {
        return true;
      }

      if (scales.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please add at least 2 episodic severity scales',
          path: ['scales'],
        });
        return false;
      }

      if (scales.length > 10) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You have reached the limit of 10 episodic severity scales',
          path: ['scales'],
        });
        return false;
      }
    }),
});

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string().nonempty('Please enter a name'),
  status: z.nativeEnum(ParticipantBehaviourStatus),
  description: z.string().nonempty('Please enter a description'),
  example: z.string().nonempty('Please enter an example'),
  nonExample: z.string().nonempty('Please enter a non-example'),
  methods: methodsSchema,
});

interface Props {
  participantBehaviour: ParticipantBehaviour;
}

export const ParticipantBehaviourEditModal = createModal<Props>((props) => {
  const { modal, participantBehaviour } = props;
  const { visible, hide, remove } = modal;
  const { organization, successToast, errorToast } = useAppContext();

  const defaultValues = participantBehaviour;

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const mutation = useUpdateParticipantBehaviourMutation();

  const onSubmit = (values: FormValues) => {
    mutation
      .mutateAsync({
        organizationId: organization.id,
        participantBehaviourId: participantBehaviour.id,
        request: values,
      })
      .then(() => {
        hide();
        successToast('Participant behaviour updated successfully');
      })
      .catch((err) => {
        errorToast(`Error updating participant behaviour: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='5xl'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Update a participant behaviour</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody pb={4} overflowY='auto'>
              <ParticipantBehaviourForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size='sm' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size='sm'>Save</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
