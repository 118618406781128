import { P } from '@piccolohealth/util';

export type PiccoloRequestHeadersOptions = {
  accessToken?: {
    token: string;
    isExpired: boolean;
    expirationTime: number;
    currentTime: number;
  };
  organizationId?: string;
  commit?: string;
};

export type PiccoloRequestHeaders = Partial<{
  Authorization?: string;
  'X-Piccolo-Version'?: string;
  'X-Piccolo-OrganizationId'?: string;
  'X-Piccolo-AccessTokenIsExpired'?: string;
  'X-Piccolo-AccessTokenExpirationTime'?: string;
  'X-Piccolo-AccessTokenCurrentTime'?: string;
}>;

export const getRequestHeaders = (options: PiccoloRequestHeadersOptions): PiccoloRequestHeaders => {
  return P.pickBy(
    {
      Authorization: options.accessToken ? `Bearer ${options.accessToken.token}` : undefined,
      'X-Piccolo-Version': options.commit,
      'X-Piccolo-OrganizationId': options?.organizationId,
      'X-Piccolo-AccessTokenIsExpired': options.accessToken?.isExpired?.toString(),
      'X-Piccolo-AccessTokenExpirationTime': options.accessToken?.expirationTime?.toString(),
      'X-Piccolo-AccessTokenCurrentTime': options.accessToken?.currentTime?.toString(),
    },
    (i) => !P.isNil(i),
  );
};
