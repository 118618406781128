import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateParticipantDocumentLabel } from '../mutations/createParticipantDocumentLabel.mutation';
import type {
  CreateParticipantDocumentLabelMutation,
  CreateParticipantDocumentLabelMutationVariables,
} from '../types';

export const useCreateParticipantDocumentLabelMutation = (
  options?: MutationOptions<
    CreateParticipantDocumentLabelMutation,
    CreateParticipantDocumentLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantDocumentLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
