import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, ModalFooter, Stack } from '@chakra-ui/react';
import type { ParticipantBehaviour, TableWidget } from '@piccolohealth/pbs-common';
import { Empty, Spin, useDebouncedCallback } from '@piccolohealth/ui';
import { type DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { FormItem } from '../../../../components/forms/FormItem';
import { createModal } from '../../../../components/generic/Modal';
import { useParticipantBehavioursRecordingsQuery } from '../../../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../../../hooks/useAppContext';
import { useTable } from '../../hooks/useTable';
import { ParticipantWidgetTimeRangeControl } from '../ParticipantWidgetTimeRangeControl';
import { TableWidgetContent } from './TableWidgetContent';

interface Props {
  participantId: string;
  widget: TableWidget;
  startDate: DateTime;
  endDate: DateTime;
}

export const TableWidgetViewModal = createModal<Props>((props) => {
  const { modal, participantId, widget } = props;
  const { visible, hide, remove } = modal;
  const { organization } = useAppContext();
  const [startDate, setStartDate] = React.useState(props.startDate);
  const [endDate, setEndState] = React.useState(props.endDate);

  const onStartDateChange = useDebouncedCallback((date: DateTime) => {
    setStartDate(date);
  }, 100);

  const onEndDateChange = useDebouncedCallback((date: DateTime) => {
    setEndState(date);
  }, 100);

  const participantBehaviourQuery = useParticipantBehavioursRecordingsQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviours =
    (participantBehaviourQuery.data?.organization?.participant
      ?.behaviours as ParticipantBehaviour[]) ?? [];

  const table = useTable({
    participantBehaviours,
    widget,
    startDate,
    endDate,
    setWidget: P.noop,
  });

  const content = P.run(() => {
    if (participantBehaviourQuery.isLoading) {
      return <Spin />;
    }

    if (!participantBehaviours) {
      return <Empty />;
    }

    return (
      <Stack w='full' h='full'>
        <FormItem label='Date'>
          <ParticipantWidgetTimeRangeControl
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        </FormItem>
        <TableWidgetContent table={table} />
      </Stack>
    );
  });

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size='outline'
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{widget.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody py={0}>{content}</ModalBody>
        <ModalFooter>
          <Button colorScheme='purple' onClick={hide}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
