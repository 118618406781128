import { Button, type ButtonProps, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
  CreateParticipantBehaviourRecordingRequest,
  Method,
  ParticipantBehaviour,
} from '@piccolohealth/pbs-common';
import { FloatingPopover, ScrollAreaAutosize } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { useCreateParticipantBehaviourRecordingMutation } from '../../../graphql/hooks/useCreateParticipantBehaviourRecordingMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { dateTimeSchema } from '../../../utils/zod';
import { ParticipantBehaviourRecordingForm } from './ParticipantBehaviourRecordingForm';

type FormValues = CreateParticipantBehaviourRecordingRequest & {
  participantBehaviourId: string;
  method: Method['__typename'];
};

const payloadSchema = z.object({
  frequency: z
    .object({
      value: z.number(),
    })
    .optional(),
  duration: z
    .object({
      value: z.number(),
    })
    .optional(),
  abc: z
    .object({
      antecedent: z.string(),
      behaviour: z.string(),
      consequence: z.string(),
    })
    .optional(),
  episodicSeverity: z
    .object({
      level: z.number(),
    })
    .optional(),
});

const schema = z.object({
  participantBehaviourId: z.string({}),
  timestamp: dateTimeSchema,
  method: z.string({
    required_error: 'A recording method is required',
  }),
  payload: payloadSchema,
});

interface ParticipantBehaviourRecordingCreateContentProps {
  participantId: string;
  participantBehaviours: ParticipantBehaviour[];
  onClose: () => void;
}

const ParticipantBehaviourRecordingCreateContent = (
  props: ParticipantBehaviourRecordingCreateContentProps,
) => {
  const { participantId, participantBehaviours, onClose } = props;

  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useCreateParticipantBehaviourRecordingMutation();

  const defaultValues = React.useMemo(() => {
    const defaultBehaviour = P.first(participantBehaviours);

    if (P.isNil(defaultBehaviour)) {
      return {
        timestamp: DateTime.now(),
      };
    }

    const enabledMethods = Object.values(defaultBehaviour.methods).filter(
      (m) => !P.isString(m) && m.enabled,
    ) as Method[];

    if (P.isEmpty(enabledMethods)) {
      return {
        participantBehaviourId: defaultBehaviour.id,
        timestamp: DateTime.now(),
      };
    }

    const defaultMethod = defaultBehaviour.methods.frequency.enabled
      ? defaultBehaviour.methods.frequency.__typename
      : enabledMethods[0].__typename;

    return {
      participantBehaviourId: defaultBehaviour.id,
      method: defaultMethod,
      timestamp: DateTime.now(),
    };
  }, [participantBehaviours]);

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const { reset } = methods;

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const payload = P.run(() => {
        switch (values.method) {
          case 'AbcMethod':
            return { abc: values.payload.abc };
          case 'FrequencyMethod':
            return { frequency: values.payload.frequency };
          case 'DurationMethod':
            return { duration: values.payload.duration };
          case 'EpisodicSeverityMethod':
            return { episodicSeverity: values.payload.episodicSeverity };
          default:
            return {};
        }
      });

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantId: participantId,
          participantBehaviourId: values.participantBehaviourId,
          request: {
            payload,
            timestamp: values.timestamp,
          },
        })
        .then(async () => {
          successToast('Participant behaviour recording created successfully');
          onClose();
          reset();
        })
        .catch((err) => {
          errorToast(`Error creating participant behaviour recording: ${err.message}`);
        });
    },
    [mutation, organization.id, participantId, successToast, onClose, reset, errorToast],
  );

  return (
    <Stack minH='xs' w='xl' bg='white' spacing={0} shadow='popover' rounded='xl'>
      <HStack px={6} py={3} borderBottomWidth='1px'>
        <Text fontSize='md' fontWeight='semibold'>
          Add recording
        </Text>
      </HStack>

      <FormProvider {...methods}>
        <ScrollAreaAutosize maxH='3xl' h='full'>
          <Stack as='form' px={6} py={4} spacing={8} onSubmit={methods.handleSubmit(onSubmit)}>
            <ParticipantBehaviourRecordingForm participantBehaviours={participantBehaviours} />
            <HookedSubmitButton isDisabled={false} leftIcon={<FaPlus />}>
              Add data
            </HookedSubmitButton>
          </Stack>
        </ScrollAreaAutosize>
      </FormProvider>
    </Stack>
  );
};

interface Props extends ButtonProps {
  participantId: string;
  participantBehaviours: ParticipantBehaviour[];
}

export const ParticipantBehaviourRecordingCreateButton = (props: Props) => {
  const { participantId, participantBehaviours, children, ...rest } = props;

  const disclosure = useDisclosure();

  return (
    <FloatingPopover
      placement='left'
      open={disclosure.isOpen}
      setOpen={(isOpen) => (isOpen ? disclosure.onOpen() : disclosure.onClose())}
      isPortal
      render={() => (
        <ParticipantBehaviourRecordingCreateContent
          participantId={participantId}
          participantBehaviours={participantBehaviours}
          onClose={disclosure.onClose}
        />
      )}
    >
      <Button size='sm' leftIcon={<FaPlus />} {...rest}>
        {children}
      </Button>
    </FloatingPopover>
  );
};
