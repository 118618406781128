import { Icon } from '@chakra-ui/react';
import type { Command } from '@piccolohealth/ui';
import React from 'react';
import {
  FaCodepen,
  FaFont,
  FaGripLines,
  FaHeading,
  FaImage,
  FaListOl,
  FaListUl,
  FaMinus,
  FaQuoteLeft,
  FaTable,
} from 'react-icons/fa';
import { type TiptapCommandMenuState, type Variable, isExtensionEnabled } from '../../utils';
import { ImageUploadPage } from '../image/ImageUploadPage';

export const getEditingPages =
  (variables: Variable[]) =>
  (state: TiptapCommandMenuState): Command.Page<TiptapCommandMenuState>[] => {
    const pages: Command.Page<TiptapCommandMenuState>[] = [];

    const basePage: Command.ActionsPage<TiptapCommandMenuState> = {
      kind: 'actions',
      id: 'ROOT',
      title: 'What would you like to do?',
      searchable: false,
      actions: [
        {
          kind: 'void',
          id: 'blockquote',
          title: 'Blockquote',
          raw: 'Blockquote',
          description: 'Make writing standout',
          leftIcon: () => <Icon as={FaQuoteLeft} />,
          action: (props) => props.editor.chain().focus().toggleBlockquote().run(),
        },
        {
          kind: 'void',
          id: 'text',
          title: 'Text',
          raw: 'Text',
          description: 'Body sized text',
          leftIcon: () => <Icon as={FaFont} />,
          action: (props) => props.editor.chain().focus().setParagraph().run(),
        },
        {
          kind: 'void',
          id: 'heading1',
          title: 'Heading 1',
          raw: 'Heading 1',
          description: 'Big section heading',
          leftIcon: () => <Icon as={FaHeading} />,
          action: (props) => props.editor.chain().focus().toggleHeading({ level: 1 }).run(),
        },
        {
          kind: 'void',
          id: 'heading2',
          title: 'Heading 2',
          description: 'Medium section heading',
          raw: 'Heading 2',
          leftIcon: () => <Icon as={FaHeading} />,
          action: (props) => props.editor.chain().focus().toggleHeading({ level: 2 }).run(),
        },
        {
          kind: 'void',
          id: 'heading3',
          title: 'Heading 3',
          raw: 'Heading 3',
          description: 'Small section heading',
          leftIcon: () => <Icon as={FaHeading} />,
          action: (props) => props.editor.chain().focus().toggleHeading({ level: 3 }).run(),
        },
        {
          kind: 'void',
          id: 'bulletedList',
          title: 'Bulleted list',
          raw: 'Bulleted list',
          description: 'Create a list with bullets',
          leftIcon: () => <Icon as={FaListUl} />,
          action: (props) => props.editor.chain().focus().toggleBulletList().run(),
        },
        {
          kind: 'void',
          id: 'orderedList',
          title: 'Numbered list',
          raw: 'Numbered list',
          description: 'Create a list with numbering',
          leftIcon: () => <Icon as={FaListOl} />,
          action: (props) => props.editor.chain().focus().toggleOrderedList().run(),
        },
        {
          kind: 'void',
          id: 'table',
          title: 'Table',
          raw: 'Table',
          description: 'Add simple tabular content',
          leftIcon: () => <Icon as={FaTable} />,
          action: (props) => props.editor.chain().focus().insertTable().run(),
        },
        {
          kind: 'void',
          id: 'horizontalRule',
          title: 'Divider',
          raw: 'Divider',
          description: 'Visually divide content',
          leftIcon: () => <Icon as={FaMinus} />,
          action: (props) => props.editor.chain().focus().setHorizontalRule().run(),
        },
        {
          kind: 'void',
          id: 'pageBreak',
          title: 'Page break',
          raw: 'Page break',
          description: 'Start a new page when printing',
          leftIcon: () => <Icon as={FaGripLines} />,
          action: (props) => props.editor.chain().focus().setPageBreak().run(),
        },
      ],
    };

    pages.push(basePage);

    if (isExtensionEnabled(state.editor, 'variable')) {
      basePage.actions.push({
        kind: 'goto',
        id: 'fields',
        title: 'Fields',
        raw: 'Fields',
        description: 'Insert a data field into the document',
        leftIcon: () => <Icon as={FaCodepen} />,
      });

      pages.push({
        kind: 'actions',
        id: 'fields',
        title: 'Fields',
        searchable: true,
        actions: variables.map((variable) => {
          return {
            kind: 'void',
            id: variable.id,
            showTooltip: false,
            title: variable.label,
            raw: variable.label,
            category: variable.category,
            leftIcon: () => <Icon as={FaCodepen} />,
            description: variable.value.toString(),
            action: (props: TiptapCommandMenuState) => {
              props.editor
                .chain()
                .focus()
                .insertVariableAt(
                  props.range,
                  variable.id,
                  variable.arrayPath,
                  variable.arrayLength,
                )
                .run();
            },
          } as Command.VoidAction<TiptapCommandMenuState>;
        }),
      });
    }

    if (isExtensionEnabled(state.editor, 'image')) {
      basePage.actions.push({
        kind: 'goto',
        id: 'image',
        title: 'Image',
        raw: 'Image',
        description: 'Add an image',
        leftIcon: () => <Icon as={FaImage} />,
      });

      pages.push({
        kind: 'free',
        id: 'image',
        title: 'Add an image',
        body: (context) => {
          const onImageUpload = context.ctx.editor.storage.image.onImageUpload;

          return (
            <ImageUploadPage
              editor={context.ctx.editor}
              onExit={context.onExit}
              onImageUpload={onImageUpload}
            />
          );
        },
      });
    }

    return pages;
  };
