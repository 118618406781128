import { Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { z } from 'zod';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../components/forms/HookedInput';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { useAuth } from '../../../context/AuthContext';
import { AuthCard } from './AuthCard';
import { AuthLayout } from './AuthLayout';
import { MFAChooseMethodButton } from './MFAChooseMethodButton';

type FormValues = {
  phoneNumber: string;
};

const schema: z.ZodSchema<FormValues> = z.object({
  phoneNumber: z.string().min(1, 'Phone number is required'),
});

interface Props {
  onSendCode: (phoneNumber: string) => Promise<void>;
}

export const PhoneEnrolCard = (props: Props) => {
  const { onSendCode } = props;
  const auth = useAuth();

  const methods = useForm<FormValues>({
    defaultValues: {
      phoneNumber: '',
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    return onSendCode(data.phoneNumber);
  };

  if (auth.isLoading) {
    return (
      <AuthLayout>
        <Spin />
      </AuthLayout>
    );
  }

  if (!auth.isLoggedIn) {
    return <Navigate to='/login' />;
  }

  return (
    <AuthLayout>
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Secure Your Account
          </Text>
          <Text fontSize='sm' color='secondary'>
            Enter your phone number below. An SMS will be sent to that number with a code to enter
            on the next screen.
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={8}>
              <HookedFormItem name='phoneNumber'>
                <HookedInput name='phoneNumber' type='tel' placeholder='+61 111222333' />
              </HookedFormItem>
              <HookedSubmitButton size='md' isDisabled={false} loadingText='Sending code...'>
                Send code
              </HookedSubmitButton>
            </Stack>
          </form>
        </FormProvider>
        <MFAChooseMethodButton />
      </AuthCard>
    </AuthLayout>
  );
};
