import { P } from '@piccolohealth/util';
import { Alert, type AlertRootProps, Box, type BoxProps, Square } from 'chakra-ui-v3';
import React from 'react';
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaSearch,
  FaTimesCircle,
} from 'react-icons/fa';

export type Status = 'success' | 'warning' | 'error' | 'info' | 'empty';

export interface ResultProps extends Omit<AlertRootProps, 'status'> {
  status: Status;
  title?: string;
  description?: string | React.ReactNode;
  extra?: React.ReactNode;
  icon?: React.ReactNode;
}

const StatusIcon = (props: { status: Status } & BoxProps) => {
  const { status, ...rest } = props;

  const { icon, iconColor, bgColor } = P.run(() => {
    switch (status) {
      case 'success':
        return { icon: <FaCheckCircle size='100%' />, iconColor: 'green.400', bgColor: 'green.50' };
      case 'warning':
        return {
          icon: <FaExclamationTriangle size='100%' />,
          iconColor: 'orange.400',
          bgColor: 'orange.50',
        };
      case 'error':
        return { icon: <FaTimesCircle size='100%' />, iconColor: 'red.400', bgColor: 'red.50' };
      case 'info':
        return { icon: <FaInfoCircle size='100%' />, iconColor: 'blue.400', bgColor: 'blue.50' };
      case 'empty':
        return { icon: <FaSearch size='100%' />, iconColor: 'gray.400', bgColor: 'gray.50' };
    }
  });

  return (
    <Square size={20} rounded='3xl' bg={bgColor}>
      <Box color={iconColor} boxSize={10} {...rest}>
        {icon}
      </Box>
    </Square>
  );
};

export const Result = (props: ResultProps) => {
  const { status, title, description, extra, icon, ...rest } = props;

  return (
    <Alert.Root {...rest} variant='subtle' bg='transparent'>
      <Alert.Content
        p={4}
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        spaceY={2}
        h='full'
      >
        {icon ? <Box>{icon}</Box> : <StatusIcon status={status} boxSize='8' />}
        <Alert.Title color='fg' fontSize='lg' fontWeight='bold'>
          {title}
        </Alert.Title>
        <Alert.Description color='fg.muted'>{description}</Alert.Description>
        {extra && <Box mt={4}>{extra}</Box>}
      </Alert.Content>
    </Alert.Root>
  );
};
