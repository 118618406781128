import { Stack, Text } from '@chakra-ui/react';
import { TemplateStatus } from '@piccolohealth/pbs-common';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useUpdateTemplateMutation } from '../../graphql/hooks/useUpdateTemplateMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  templateId: string;
}

export const TemplateDeleteModal = createModal<Props>((props) => {
  const { templateId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateTemplateMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        templateId,
        request: {
          status: TemplateStatus.Archived,
        },
      })
      .then(async () => {
        successToast('Template deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting template: ${err.message}`);
      });
  }, [mutation, organization.id, templateId, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading='Do you want to delete this template?'
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this template <strong>cannot be undone.</strong>
          </Text>
        </Stack>
      }
      requireConfirm
      onDelete={onDelete}
      isLoading={mutation.isLoading}
    />
  );
});
