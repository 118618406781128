import { HStack, Stack } from '@chakra-ui/react';
import { COMPANY_NAME, type PiccoloError } from '@piccolohealth/pbs-common';
import {
  DataTable,
  Empty,
  FancyDate,
  FileIcon,
  type PaginationOptions,
  type RowSelectionOptions,
  createColumnHelper,
} from '@piccolohealth/ui';
import React from 'react';
import { TextLink } from '../../components/generic/TextLink';
import { UserDescription } from '../../components/user/UserDescription';
import { useAppContext } from '../../hooks/useAppContext';
import { type ParticipantFileOrDocument, isParticipantFile } from '../../utils/fileOrDocument';
import { ManageParticipantDocumentLabelsControl } from '../labels/ManageParticipantDocumentLabelsControl';
import { ManageParticipantFileLabelsControl } from '../labels/ManageParticipantFileLabelsControl';
import { ParticipantDocumentActionMenu } from '../participantDocument/ParticipantDocumentActionMenu';
import { ParticipantFileActionMenu } from './ParticipantFileActionMenu';

interface Props {
  isLoading: boolean;
  isDisabled?: boolean;
  error: PiccoloError | null;
  filesAndDocuments: ParticipantFileOrDocument[];
  refetch: () => Promise<void>;
  pagination?: PaginationOptions;
  rowSelection?: RowSelectionOptions<ParticipantFileOrDocument>;
}

export const ParticipantFilesAndDocumentsTable = (props: Props) => {
  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<ParticipantFileOrDocument>();

    return [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (ps) => {
          const fileOrDocument = ps.row.original;

          const { to, isExternal } = isParticipantFile(fileOrDocument)
            ? { to: fileOrDocument.viewUrl, isExternal: true }
            : {
                to: `/organizations/${organization.id}/participants/${fileOrDocument.participantId}/documents/${fileOrDocument.id}`,
                isExternal: false,
              };

          return (
            <HStack spacing={2} align='center' w='full'>
              <FileIcon name={fileOrDocument.name} w={12} h={12} flexShrink={0} />
              <Stack w='full' spacing={1}>
                <TextLink
                  to={to}
                  isExternal={isExternal}
                  noOfLines={1}
                  fontWeight='bold'
                  color='purple.600'
                  fontSize='md'
                >
                  {fileOrDocument.name}
                </TextLink>
                <FancyDate
                  date={fileOrDocument.createdAt}
                  fontSize='xs'
                  spacing={1}
                  direction='row'
                />
              </Stack>
            </HStack>
          );
        },
      }),
      columnHelper.accessor('uploader', {
        header: 'Uploaded by',
        cell: (ps) => {
          const fileOrDocument = ps.row.original;

          const uploaderOrCreator = isParticipantFile(fileOrDocument)
            ? fileOrDocument.uploader
            : fileOrDocument.creator;

          return (
            <UserDescription
              name={uploaderOrCreator?.name ?? COMPANY_NAME}
              secondary={uploaderOrCreator?.email}
              picture={uploaderOrCreator?.picture}
            />
          );
        },
      }),
      columnHelper.accessor('labels.name', {
        header: 'Labels',
        cell: (ps) => {
          const fileOrDocument = ps.row.original;

          return isParticipantFile(fileOrDocument) ? (
            <ManageParticipantFileLabelsControl
              participantId={fileOrDocument.participantId}
              participantFileId={fileOrDocument.id}
              value={fileOrDocument.labels.map((l) => l.id)}
              isDisabled={props.isDisabled}
            />
          ) : (
            <ManageParticipantDocumentLabelsControl
              participantId={fileOrDocument.participantId}
              participantDocumentId={fileOrDocument.id}
              value={fileOrDocument.labels.map((l) => l.id)}
              isDisabled={props.isDisabled}
            />
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: (ps) => {
          const fileOrDocument = ps.row.original;

          return isParticipantFile(fileOrDocument) ? (
            <ParticipantFileActionMenu file={fileOrDocument} isDisabled={props.isDisabled} />
          ) : (
            <ParticipantDocumentActionMenu
              participantDocument={fileOrDocument}
              isDisabled={props.isDisabled}
            />
          );
        },
      }),
    ];
  }, [organization.id, props.isDisabled]);

  return (
    <DataTable
      columns={columns}
      data={props.filesAndDocuments}
      isLoading={props.isLoading}
      error={props.error}
      renderEmpty={() => <Empty title='No files or documents found' />}
      pagination={props.pagination}
      rowSelection={props.rowSelection}
      size='md'
    />
  );
};
