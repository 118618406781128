import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  type ParticipantDocument,
  type ParticipantDocumentStatus,
  renderParticipantDocumentStatus,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { useUpdateParticipantDocumentMutation } from '../../graphql/hooks/useUpdateParticipantDocumentMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantDocument: ParticipantDocument;
  status: ParticipantDocumentStatus;
}

export const ParticipantDocumentChangeStatusModal = createModal<Props>((props) => {
  const { visible, hide, remove } = props.modal;

  const { participantDocument, status } = props;
  const { organization, errorToast, successToast } = useAppContext();
  const mutation = useUpdateParticipantDocumentMutation();

  const onClick = async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId: participantDocument.participantId,
        participantDocumentId: participantDocument.id,
        request: {
          status,
        },
      })
      .then(() => {
        successToast('Participant document status updated');
        hide();
      })
      .catch((err) => {
        errorToast(`Error updating participant document status: ${err.message}`);
      });
  };

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='2xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Do you want to change the status from{' '}
          {renderParticipantDocumentStatus(participantDocument.status)} to{' '}
          {renderParticipantDocumentStatus(status)}?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Alert status='success'>
              <AlertIcon />
              <AlertTitle>There were no issues found</AlertTitle>
            </Alert>
            <Text>Are you sure you want to continue?</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              isLoading={mutation.isLoading}
              loadingText='Updating status...'
              colorScheme='purple'
              onClick={onClick}
            >
              Update
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
