import axios from 'axios';
import React from 'react';
import { useAuth } from '../../../context/AuthContext';
import { asPiccoloError } from '../../../utils/errors';
import { useConfig } from '../../../context/ConfigContext';
import { getRequestHeaders } from '@piccolohealth/util';

export const useImageUpload = (options: { imageUploadUrl: string }) => {
  const { imageUploadUrl } = options;
  const { config } = useConfig();
  const { getAccessToken } = useAuth();

  const onImageUpload = React.useCallback(
    async (file: File) => {
      const accessToken = await getAccessToken();

      const headers = getRequestHeaders({
        accessToken,
        commit: config.buildInfo.commit,
      });

      const formData = new FormData();
      formData.append('files', file);

      const response = await axios(imageUploadUrl, {
        method: 'POST',
        headers,
        data: formData,
      }).catch((error) => {
        throw asPiccoloError(error);
      });

      return response.data.url;
    },
    [getAccessToken, imageUploadUrl, config.buildInfo.commit],
  );

  return onImageUpload;
};
