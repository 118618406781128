import React from 'react';
import { FormStack } from '../../../components/forms/FormStack';
import { HookedColorPicker } from '../../../components/forms/HookedColorPicker';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../components/forms/HookedInput';

export const LocationForm = () => {
  return (
    <FormStack>
      <HookedFormItem name='name' label='Name'>
        <HookedInput name='name' size='sm' placeholder='Enter a name' />
      </HookedFormItem>

      <HookedFormItem name='color' label='Color'>
        <HookedColorPicker name='color' />
      </HookedFormItem>
    </FormStack>
  );
};
