import { Box, Button, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedPinInput } from '../../../components/forms/HookedPinInput';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { AuthCard } from './AuthCard';
import { AuthLayout } from './AuthLayout';
import { MFAChooseMethodButton } from './MFAChooseMethodButton';

type FormValues = {
  verificationCode: string;
};

const schema: z.ZodSchema<FormValues> = z.object({
  verificationCode: z.string().length(6, 'Verification code must be 6 characters'),
});

interface Props {
  onVerifyCode: (verificationCode: string) => Promise<void>;
  onResendCode: () => Promise<void>;
}

export const PhoneVerifyCard = (props: Props) => {
  const [isResending, setIsResending] = React.useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      verificationCode: '',
    },
    resolver: zodResolver(schema),
  });

  const onVerifyCode = async (values: FormValues) => {
    return props.onVerifyCode(values.verificationCode).catch(() => {
      methods.setError('verificationCode', {
        message: 'Invalid verification code',
      });
      methods.resetField('verificationCode', {
        keepError: true,
      });
    });
  };

  const onResendCode = async () => {
    setIsResending(true);
    await props.onResendCode();
    setIsResending(false);
  };

  return (
    <AuthLayout>
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Two-factor Authentication
          </Text>
          <Text fontSize='sm' color='secondary'>
            Enter the verification code we sent to your phone
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <Stack as='form' spacing={4} align='center' onSubmit={methods.handleSubmit(onVerifyCode)}>
            <Box>
              <HookedFormItem name='verificationCode'>
                <HookedPinInput name='verificationCode' fieldCount={6} size='lg' />
              </HookedFormItem>
            </Box>

            <HookedSubmitButton
              w='full'
              size='md'
              mt={4}
              isDisabled={false}
              loadingText='Verifying...'
            >
              Verify
            </HookedSubmitButton>
          </Stack>
        </FormProvider>
        <HStack w='full'>
          <MFAChooseMethodButton />
          <Spacer />
          <Button
            variant='link'
            w='fit-content'
            cursor='pointer'
            onClick={onResendCode}
            isLoading={isResending}
            loadingText='Resending code...'
          >
            Resend code
          </Button>
        </HStack>
      </AuthCard>
    </AuthLayout>
  );
};
