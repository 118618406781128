import { ImageUploader } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props {
  name: string;
  maxWidth: number;
  maxHeight: number;
}

export const HookedImageUploader = (props: Props) => {
  const { name, maxWidth, maxHeight } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <ImageUploader {...inputProps} maxWidth={maxWidth} maxHeight={maxHeight} />;
};
