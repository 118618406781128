import { Box } from '@chakra-ui/react';
import { TiptapTransformer } from '@hocuspocus/transformer';
import {
  type ParticipantFieldGroupTemplate,
  renderParticipantName,
} from '@piccolohealth/pbs-common';
import { Command, FloatingPopover } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import SearchAndReplace from '@sereneinserenade/tiptap-search-and-replace';
import TableOfContent from '@tiptap-pro/extension-table-of-content';
import type { Editor, Extensions, JSONContent, Range } from '@tiptap/core';
import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import ListKeyMap from '@tiptap/extension-list-keymap';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import * as TiptapHTML from '@tiptap/html';
import type { Mark } from '@tiptap/pm/model';
import type { Transaction } from '@tiptap/pm/state';
import * as TiptapReact from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React from 'react';
import traverse from 'traverse';
import * as Y from 'yjs';
import { ChakraProvider } from '../../theme/chakra';
import { Comment } from './extensions/comment/Comment';
import { Document } from './extensions/document/Document';
import { HorizontalRule } from './extensions/horizontalrule/HorizontalRule';
import { Image } from './extensions/image/Image';
import { Indent } from './extensions/indent/Indent';
import { PageBreak } from './extensions/pagebreak/PageBreak';
import type { Renderer, SlashMenuProps } from './extensions/slashmenu/SlashMenu';
import { TableCell } from './extensions/table/TableCell';
import { TableHeader } from './extensions/table/TableHeader';
import { TrailingNode } from './extensions/trailingnode/TrailingNode';
import { VariableNode } from './extensions/variable/VariableNode';

export const PAGE_WIDTH = '900px';

export const COLORS = [
  { hex: '#000000', title: 'Black' },
  { hex: '#CBD5E0', title: 'Gray' },
  { hex: '#FC8181', title: 'Red' },
  { hex: '#F6AD55', title: 'Orange' },
  { hex: '#F6E05E', title: 'Yellow' },
  { hex: '#68D391', title: 'Green' },
  { hex: '#4FD1C5', title: 'Teal' },
  { hex: '#63B3ED', title: 'Blue' },
  { hex: '#B794F4', title: 'Purple' },
];

export const BASE_EXTENSIONS: Extensions = P.compact([
  StarterKit.configure({
    document: false,
    gapcursor: false,
    horizontalRule: false,
    dropcursor: {
      color: '#68cef8',
      width: 4,
    },
  }),
  Document,
  ListKeyMap,
  Underline,
  Highlight.configure({
    multicolor: true,
  }),
  HorizontalRule,
  TextStyle,
  Color,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Table.configure({
    resizable: true,
    lastColumnResizable: false,
  }),
  TableRow,
  TableHeader,
  TableCell,
  Placeholder,
  Indent,
  PageBreak,
  TableOfContent,
  TrailingNode,
  SearchAndReplace.configure(),
]);

export const getParticipantDocumentTemplateExtensions = (options: {
  variables: Variable[];
  onImageUpload?: (file: File) => Promise<string>;
}) => {
  return [
    ...BASE_EXTENSIONS,
    VariableNode.configure({ variables: options.variables }),
    Image.configure({ onImageUpload: options.onImageUpload }),
  ];
};

export const getParticipantNoteTemplateExtensions = (options: {
  onImageUpload?: (file: File) => Promise<string>;
}) => {
  return [...BASE_EXTENSIONS, Image.configure({ onImageUpload: options.onImageUpload })];
};

export const getParticipantNoteExtensions = (options: {
  onImageUpload?: (file: File) => Promise<string>;
}) => {
  return [...BASE_EXTENSIONS, Image.configure({ onImageUpload: options.onImageUpload })];
};

export const getParticipantDocumentExtensions = (options: {
  variables: Variable[];
  onImageUpload?: (file: File) => Promise<string>;
  document?: Y.Doc;
}) => {
  return [
    ...BASE_EXTENSIONS,
    VariableNode.configure({ variables: options.variables }),
    Image.configure({ onImageUpload: options.onImageUpload }),
    Comment.configure({ document: options.document }),
  ];
};

export interface MarkWithRange {
  mark: Mark;
  range: Range;
}

export interface Variable {
  id: string;
  category: string;
  label: string;
  value: string | string[];
  arrayPath?: string;
  arrayLength?: number;
}

export const isExtensionEnabled = (editor: Editor | null, name: string): boolean => {
  return (editor?.extensionManager?.extensions ?? []).some((extension) => extension.name === name);
};

export const isJSONContent = (value: any): value is JSONContent => {
  return P.isString(value.type) && P.isArray(value.content);
};

export const deserializeYDoc = (content: any): Y.Doc => {
  const document = new Y.Doc();
  Y.applyUpdate(document, new Uint8Array(content));
  return document;
};

export const serializeYDoc = (document: Y.Doc): Uint8Array => {
  return Y.encodeStateAsUpdate(document);
};

export const convertYDocToJSONContent = (document: Y.Doc): JSONContent => {
  return TiptapTransformer.fromYdoc(document, 'default');
};

export const convertJSONContentToYDoc = (content: JSONContent, extensions: Extensions): Y.Doc => {
  return TiptapTransformer.toYdoc(content, 'default', extensions as any);
};

export const generateHTML = (content: JSONContent, extensions: Extensions): string => {
  return TiptapHTML.generateHTML(content, extensions);
};

export const generateJSON = (content: string, extensions: Extensions): Record<string, any> => {
  return TiptapHTML.generateJSON(content, extensions);
};

export const ydocToHtml = (content: any, extensions: Extensions): string => {
  const yDoc = deserializeYDoc(content);
  const jsonContent = convertYDocToJSONContent(yDoc ?? {});
  const processedJsonContent = postProcessContent(jsonContent, []);
  return generateHTML(processedJsonContent, extensions);
};

export const renderVariable = (variables: Variable[], id: string): string => {
  const variable = variables.find((variable) => variable.id === id);

  if (!variable) {
    return '';
  }

  return variable.value.toString();
};

export type TiptapCommandMenuState = {
  editor: Editor;
  range: Range;
};

export const slashMenuRenderer = (
  getPages: (state: TiptapCommandMenuState) => Command.Page<TiptapCommandMenuState>[],
  rootPageId?: Command.PageIdWithArgs,
): Renderer => {
  let reactRenderer: TiptapReact.ReactRenderer<unknown, SlashMenuProps>;

  return {
    onStart: (props) => {
      reactRenderer = new TiptapReact.ReactRenderer(
        () => {
          const state = {
            editor: props.editor,
            range: {
              from: props.editor.state.selection.anchor,
              to: props.editor.state.selection.anchor + 1,
            },
          };

          return (
            <FloatingPopover
              open={props.open}
              setOpen={props.setOpen}
              clientRect={props.clientRect}
              shouldInitialFocus
              isPortal
              render={({ context }) => (
                <Command.CommandMenu
                  ctx={state}
                  onOpenChange={context.onOpenChange}
                  getPages={getPages}
                  rootPageId={rootPageId}
                />
              )}
            />
          );
        },
        {
          props,
          editor: props.editor,
        },
      );

      if (!props.clientRect) {
        return;
      }
    },

    onUpdate: (props) => {
      reactRenderer.render();
      reactRenderer?.updateProps(props);
    },

    onExit: () => {
      reactRenderer?.destroy();
    },
  };
};

export const getRandomColor = () => {
  const colors = ['#ffcc00', '#ff00ff', '#00ffff', '#00ff00', '#ff0000'];
  return colors[Math.floor(Math.random() * colors.length)];
};

export const getMarksByName = (
  tr: Transaction,
  name: string,
  attributes?: Record<string, any>,
): MarkWithRange[] => {
  const marks: MarkWithRange[] = [];

  // Find all comment marks with the same comment id
  tr.doc.descendants((node, pos) => {
    const mark = node.marks.find((mark) => {
      const isNameEqual = mark.type.name === name;
      const isAttributesEqual =
        !attributes ||
        Object.keys(attributes).every((key) => {
          return mark.attrs[key] === attributes[key];
        });

      return isNameEqual && isAttributesEqual;
    });

    if (mark) {
      marks.push({
        mark,
        range: {
          from: pos,
          to: pos + node.nodeSize,
        },
      });
    }
  });

  return marks;
};

export interface DocumentVariablesOptions {
  organization: {
    timezone: string;
    locale: string;
  };
  user: {
    name: string;
    email: string;
    title: string;
    practitionerNumber: string | null;
  };
  participant: {
    firstName: string;
    lastName: string;
    dob: DateTime;
    gender: string;
    location: {
      name: string;
    } | null;
    ndisNumber: string | null;
    email: string | null;
    phone: string | null;
    address: string | null;
    postcode: string | null;
    state: string | null;
    suburb: string | null;
    status: string;
    keyContacts: {
      name: string | null;
      email: string | null;
      phone: string | null;
      description: string | null;
    }[];
    fieldGroups: {
      template: {
        name: string;
      };
      fields: {
        id: string;
        template: {
          name: string;
        };
      }[];
    }[];
    createdAt: DateTime;
  };
}

export const getDocumentVariables = (options: DocumentVariablesOptions): Variable[] => {
  const { user, organization, participant } = options;

  const basicVariables: Variable[] = [
    { category: 'Practitioner', id: 'user.name', label: 'Practitioner name', value: user.name },
    {
      category: 'Practitioner',
      id: 'user.practitionerNumber',
      label: 'Practitioner number',
      value: user.practitionerNumber,
    },
    { id: 'address', label: 'Address', category: 'Basic', value: participant.address },
    { id: 'suburb', label: 'Suburb', category: 'Basic', value: participant.suburb },
    {
      id: 'dob',
      label: 'Date of birth',
      category: 'Basic',
      value: DateTime.fromISO(participant.dob.toString(), {
        zone: organization.timezone,
      }).toLocaleString(DateTime.DATE_SHORT, { locale: organization.locale }),
    },
    { id: 'name', label: 'Name', category: 'Basic', value: renderParticipantName(participant) },
    { id: 'firstName', label: 'First name', category: 'Basic', value: participant.firstName },
    { id: 'lastName', label: 'Last name', category: 'Basic', value: participant.lastName },
    { id: 'gender', label: 'Gender', category: 'Basic', value: participant.gender },
    { id: 'location', label: 'Location', category: 'Basic', value: participant.location?.name },
    { id: 'ndisNumber', label: 'NDIS number', category: 'Basic', value: participant.ndisNumber },
    { id: 'email', label: 'Email', category: 'Basic', value: participant.email },
    { id: 'phone', label: 'Phone number', category: 'Basic', value: participant.phone },
    { id: 'postcode', label: 'Postcode', category: 'Basic', value: participant.postcode },
    { id: 'state', label: 'State', category: 'Basic', value: participant.state },
    { id: 'status', label: 'Status', category: 'Basic', value: participant.status },
    {
      id: 'documentCreatedDate',
      label: 'Document created date',
      category: 'Basic',
      // Not strictly correct, but we don't have a document created date yet
      value: DateTime.now()
        .setZone(organization.timezone)
        .toLocaleString(DateTime.DATE_SHORT, { locale: organization.locale }),
    },
    {
      id: 'participantCreatedDate',
      label: 'Participant created date',
      category: 'Basic',
      value: DateTime.fromISO(participant.createdAt.toString(), {
        zone: organization.timezone,
      }).toLocaleString(DateTime.DATE_SHORT, { locale: organization.locale }),
    },
    ...participant.keyContacts.flatMap((kc, index) => [
      {
        id: `keyContacts.[${index}].name`,
        label: `Key contact ${index + 1} name`,
        category: 'Key contacts',
        value: kc.name,
        arrayPath: 'keyContacts',
        arrayLength: participant.keyContacts.length,
      },
      {
        id: `keyContacts.[${index}].email`,
        label: `Key contact ${index + 1} email`,
        category: 'Key contacts',
        value: kc.email,
        arrayPath: 'keyContacts',
        arrayLength: participant.keyContacts.length,
      },
      {
        id: `keyContacts.[${index}].phone`,
        label: `Key contact ${index + 1} phone`,
        category: 'Key contacts',
        value: kc.phone,
        arrayPath: 'keyContacts',
        arrayLength: participant.keyContacts.length,
      },
      {
        id: `keyContacts.[${index}].description`,
        label: `Key contact ${index + 1} description`,
        category: 'Key contacts',
        value: kc.description,
        arrayPath: 'keyContacts',
        arrayLength: participant.keyContacts.length,
      },
    ]),
  ].map((v) => ({
    ...v,
    value: v.value ?? '-',
  }));

  const fieldGroupVariables: Variable[] = participant.fieldGroups.flatMap((fieldGroup) => {
    return fieldGroup.fields.map((field) => {
      return {
        id: field.id,
        category: fieldGroup.template.name,
        label: field.template.name,
        value: field.template.name,
      };
    });
  });

  return [...basicVariables, ...fieldGroupVariables];
};

export const getTemplateVariables = (
  fieldGroupTemplates: ParticipantFieldGroupTemplate[],
): Variable[] => {
  const basicVariables: Variable[] = [
    { category: 'Practitioner', id: 'user.name', label: 'Practitioner name' },
    { category: 'Practitioner', id: 'user.practitionerNumber', label: 'Practitioner number' },
    { category: 'Basic', id: 'address', label: 'Address' },
    { category: 'Basic', id: 'suburb', label: 'Suburb' },
    { category: 'Basic', id: 'dob', label: 'Date of birth' },
    { category: 'Basic', id: 'name', label: 'Name' },
    { category: 'Basic', id: 'firstName', label: 'First name' },
    { category: 'Basic', id: 'lastName', label: 'Last name' },
    { category: 'Basic', id: 'gender', label: 'Gender' },
    { category: 'Basic', id: 'location', label: 'Location' },
    { category: 'Basic', id: 'ndisNumber', label: 'NDIS number' },
    { category: 'Basic', id: 'postcode', label: 'Postcode' },
    { category: 'Basic', id: 'state', label: 'State' },
    { category: 'Basic', id: 'status', label: 'Status' },
    {
      id: 'documentCreatedDate',
      label: 'Document created date',
      category: 'Basic',
    },
    {
      id: 'participantCreatedDate',
      label: 'Participant created date',
      category: 'Basic',
    },
    {
      category: 'Key contacts',
      id: 'keyContacts.[n].name',
      label: 'Key contact name',
      arrayPath: 'keyContacts',
      arrayLength: 1,
    },
    {
      category: 'Key contacts',
      id: 'keyContacts.[n].email',
      label: 'Key contact email',
      arrayPath: 'keyContacts',
      arrayLength: 1,
    },
    {
      category: 'Key contacts',
      id: 'keyContacts.[n].phone',
      label: 'Key contact phone',
      arrayPath: 'keyContacts',
      arrayLength: 1,
    },
    {
      category: 'Key contacts',
      id: 'keyContacts.[n].description',
      label: 'Key contact description',
      arrayPath: 'keyContacts',
      arrayLength: 1,
    },
  ].map(({ category, id, label, arrayPath, arrayLength }) => {
    return {
      id,
      category,
      label,
      value: label,
      arrayPath,
      arrayLength,
    };
  });

  const fieldGroupTemplateVariables: Variable[] = fieldGroupTemplates.flatMap(
    (fieldGroupTemplate) => {
      return fieldGroupTemplate.fields.map((fieldTemplate) => {
        return {
          id: fieldTemplate.id,
          category: fieldGroupTemplate.name,
          label: fieldTemplate.name,
          value: fieldTemplate.name,
        };
      });
    },
  );

  return [...basicVariables, ...fieldGroupTemplateVariables];
};

/**
 * Removes comments
 */
export const postProcessContent = (content: JSONContent, variables: Variable[]) => {
  traverse(content).forEach(function (node) {
    if (node?.type === 'comment') {
      this.remove();
    }
  });

  return content;
};

export const TiptapDocumentPDFContent = (props: {
  document: JSONContent;
  variables: Variable[];
}) => {
  const extensions = getParticipantDocumentExtensions({ variables: props.variables });
  const __html = generateHTML(props.document, extensions);

  return (
    <ChakraProvider>
      <Box className='ssr ProseMirror' dangerouslySetInnerHTML={{ __html }} />
    </ChakraProvider>
  );
};
