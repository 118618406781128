import { HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import type { ParticipantDocument } from '@piccolohealth/pbs-common';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { FaClock, FaListOl } from 'react-icons/fa';
import { EditorOutlineSection } from '../../components/tiptap/EditorOutlineSection';
import { ParticipantDocumentReviewSection } from './ParticipantDocumentReviewSection';

interface Props {
  participantDocument: ParticipantDocument;
  editor: Editor | null;
}

export const ParticipantDocumentSidebar = (props: Props) => {
  const { participantDocument, editor } = props;

  return (
    <>
      <Tabs
        size='sm'
        variant='soft-rounded'
        isFitted
        w='full'
        h='full'
        display='flex'
        flexDir='column'
      >
        <TabList w='full' py={2} px={3}>
          <Tab fontSize='xs' py='3.5px'>
            <HStack lineHeight='normal'>
              <FaClock />
              <Text>Review</Text>
            </HStack>
          </Tab>
          <Tab fontSize='xs' py='3.5px'>
            <HStack lineHeight='normal'>
              <FaListOl />
              <Text>Outline</Text>
            </HStack>
          </Tab>
        </TabList>

        <TabPanels overflowY='auto' h='full'>
          <TabPanel h='full'>
            <ParticipantDocumentReviewSection
              participantDocument={participantDocument}
              editor={editor}
            />
          </TabPanel>
          <TabPanel h='full' w='full'>
            <EditorOutlineSection editor={editor} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
