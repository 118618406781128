import { PhaseChangeType } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormStack } from '../../../../components/forms/FormStack';
import { HookedColorPickerHex } from '../../../../components/forms/HookedColorPickerHex';
import { HookedFormItem } from '../../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../../components/forms/HookedInput';
import { HookedRadioGroup } from '../../../../components/forms/HookedRadioGroup';
import { HookedSingleDatepicker } from '../../../../components/forms/HookedSingleDatepicker';

export const PhaseChangeLineForm = () => {
  return (
    <FormStack w='full'>
      <FormStack>
        <HookedFormItem
          name='name'
          label='Name'
          helperText='What do you want to call the phase change line?'
        >
          <HookedInput
            name='name'
            placeholder='Eg: Physical aggresson vs Self Injurious'
            size='sm'
          />
        </HookedFormItem>
      </FormStack>
      <FormStack>
        <HookedFormItem
          name='timestamp'
          label='Timestamp'
          helperText='When did the phase change occur?'
        >
          <HookedSingleDatepicker name='timestamp' />
        </HookedFormItem>
      </FormStack>
      <FormStack orientation='horizontal'>
        <HookedFormItem name='type' label='Type' helperText='What type of phase change is this?'>
          <HookedRadioGroup
            name='type'
            size='sm'
            options={[
              {
                label: 'Minor',
                value: PhaseChangeType.Minor,
              },
              {
                label: 'Major',
                value: PhaseChangeType.Major,
              },
            ]}
          />
        </HookedFormItem>
        <HookedFormItem name='color' label='Color' w='fit-content'>
          <HookedColorPickerHex name='color' />
        </HookedFormItem>
      </FormStack>
    </FormStack>
  );
};
