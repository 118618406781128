import { Select, type SelectOption, type SelectProps } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props<A, B> extends Omit<SelectProps<A, B>, 'onChange' | 'value'> {
  name: string;
}

export const HookedSelect = <A, B>(props: React.PropsWithChildren<Props<A, B>>) => {
  const { name, options, ...rest } = props;
  const { field } = useController({ name });

  const { onChange, value } = field;

  const onChangePrime = React.useCallback(
    (value: SelectOption<A> | null) => onChange(value?.value),
    [onChange],
  );

  const selectedValue: SelectOption<A> | null = React.useMemo(() => {
    const defaultValue = value
      ? {
          value,
          label: value ? P.upperFirst(value) : value,
          raw: value,
        }
      : null;

    return (
      // If value doesn't exist as an option, still display the value
      options.flatMap((o) => ('options' in o ? o.options : o)).find((o) => o.value === value) ??
      defaultValue
    );
  }, [value, options]);

  return <Select {...rest} options={options} onChange={onChangePrime} value={selectedValue} />;
};
