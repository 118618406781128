import { Box, HStack, Spacer, Text, chakra } from '@chakra-ui/react';

import { P, matchSorter, stripHtmlTags } from '@piccolohealth/util';
import React from 'react';
import { type SelectGroup, useSelect } from '../../hooks/useSelect';
import { ScrollAreaAutosize } from '../ScrollArea';
import { SelectContainer, SelectItem, type SelectOption } from '../Select';
import { CommandInput } from './CommandInput';
import type { Action, ActionsPage, CommandMenuContext } from './CommandMenu';
import { CommandActionMenuItemMemo } from './CommandMenuItem';

export const Option = <A,>(props: { option: SelectOption<A> }) => {
  return (
    <HStack>
      <Text>{props.option.label}</Text>
      <Spacer />
    </HStack>
  );
};

interface Props<A> {
  page: ActionsPage<A>;
  context: CommandMenuContext<A>;
}

export const ActionsPageContent = <A,>(props: Props<A>) => {
  const { page, context } = props;

  const [inputValue, setInputValue] = React.useState('');

  const { actions, options } = React.useMemo(() => {
    const sanitizedLastWord = inputValue.trim();

    const filteredActions =
      sanitizedLastWord.length === 0
        ? page.actions
        : matchSorter(
            page.actions.map((action) => ({
              ...action,

              stripped: stripHtmlTags(action.raw),
            })),
            sanitizedLastWord,
            {
              keys: ['stripped'],
              threshold: matchSorter.rankings.WORD_STARTS_WITH,
            },
          );

    const groupedActions: Record<string, Action<A>[] | undefined> = P.groupBy(
      P.orderBy(filteredActions, (action) => action.category ?? 'AAA'),
      (action) => action.category ?? 'ROOT',
    );

    const actions = Object.values(groupedActions).flat();

    const options: SelectGroup<Action<A>, string>[] = Object.entries(groupedActions).map(
      ([category, actions]) => {
        return {
          id: category,
          label: category,
          raw: category,
          options: (actions ?? []).map((action) => ({
            value: action.id,
            label: '',
            raw: action,
          })),
        };
      },
    );

    return {
      actions,
      options,
    };
  }, [page.actions, inputValue]);

  const onChange = React.useCallback(
    (option: SelectOption<Action<A>>) => {
      const action = option.raw;

      switch (action.kind) {
        case 'goto': {
          setInputValue('');
          context.pushPage(action.id, action.args);
          break;
        }
        case 'void': {
          setInputValue('');
          action.action(context.ctx);
          context.onExit();
        }
      }
    },
    [context],
  );

  const select = useSelect({
    options,
    value: null,
    onChange,
  });

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        if (actions.length === 0) {
          event.preventDefault();
          return true;
        }

        select.selectFocusedOption();
        return true;
      }

      if (event.key === 'Backspace') {
        if (inputValue !== '') {
          return false;
        }

        if (context.currentPagesLength > 1) {
          context.popPage();
          return true;
        }

        context.onExit();
        return true;
      }

      if (event.key === 'ArrowDown') {
        select.focusNextOption();
        return true;
      }

      if (event.key === 'ArrowUp') {
        select.focusPrevOption();
        return true;
      }

      return false;
    },
    [context, inputValue, actions, select],
  );

  const renderOption = (option: SelectOption<Action<A>>) => {
    return (
      <SelectItem key={option.value} {...select.optionProps(option)}>
        <CommandActionMenuItemMemo action={option.raw} context={context} />
      </SelectItem>
    );
  };

  const renderGroup = (group: SelectGroup<Action<A>, string>) => {
    return (
      <Box key={group.id}>
        {group.label !== 'ROOT' && (
          <Text
            my={2}
            mx={2}
            fontSize='xs'
            color='secondary'
            fontWeight='semibold'
            textTransform='uppercase'
          >
            {group.label}
          </Text>
        )}
        <Box>{group.options.map(renderOption)}</Box>
      </Box>
    );
  };

  return (
    <Box>
      <Box p={1} borderBottomWidth='1px' borderBottomColor='gray.300'>
        <CommandInput
          ref={context.inputRef}
          currentPagesLength={context.currentPagesLength}
          popPage={context.popPage}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          placeholder='Search'
          onKeyDown={onKeyDown}
        />
      </Box>

      <SelectContainer w='full'>
        <ScrollAreaAutosize maxH='xs' overflow='auto' type='auto'>
          <chakra.ul p={1.5} data-pw='menu' w='sm'>
            {select.options.length === 0 && (
              <Box fontSize='sm' w='full' pb={2} px={3} py={2}>
                No actions found.
              </Box>
            )}
            {select.options.map((option) =>
              'options' in option ? renderGroup(option) : renderOption(option),
            )}
          </chakra.ul>
        </ScrollAreaAutosize>
      </SelectContainer>
    </Box>
  );
};
