import { Tag, type TagProps } from '@chakra-ui/react';
import { Role } from '@piccolohealth/pbs-common';
import React from 'react';

interface Props extends TagProps {
  role: Role;
}

export const RoleTag = (props: Props) => {
  const { role, ...rest } = props;

  switch (role) {
    case Role.Support:
      return (
        <Tag colorScheme='cyan' {...rest}>
          Support
        </Tag>
      );
    case Role.Administrator:
      return (
        <Tag colorScheme='purple' {...rest}>
          Administrator
        </Tag>
      );
    case Role.TeamLeader:
      return (
        <Tag colorScheme='blue' {...rest}>
          Team Leader
        </Tag>
      );
    case Role.TeamMember:
      return (
        <Tag colorScheme='green' {...rest}>
          Team Member
        </Tag>
      );
  }
};
