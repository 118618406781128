import { Node, type NodeViewProps, type Range, mergeAttributes } from '@tiptap/core';
import { type CommandProps, ReactNodeViewRenderer } from '@tiptap/react';
import { type ComponentType, memo } from 'react';
import { HighlightedVariableValue } from '../../nodes/HighlightedVariableValue';
import { type Variable, renderVariable } from '../../utils';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    VariableNode: {
      insertVariableAt: (
        range: Range,
        id: string,
        arrayPath?: string,
        arrayLength?: number,
      ) => ReturnType;
    };
  }
}

export type VariableNodeProps = {
  name: string;
} & NodeViewProps;

export interface VariableNodeOptions {
  variables: Variable[];
  component: ComponentType<VariableNodeProps>;
}

export const VariableNode = Node.create<VariableNodeOptions>({
  name: 'variable',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addOptions() {
    return {
      variables: [],
      component: () => null,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
      },
      arrayPath: {
        default: undefined,
      },
      arrayLength: {
        default: undefined,
      },
    };
  },

  addStorage() {
    return {
      variables: this.options.variables,
    };
  },

  parseHTML() {
    return [
      {
        tag: 'variable',
      },
    ];
  },

  renderHTML(props) {
    return [
      'variable',
      mergeAttributes(props.HTMLAttributes, { class: 'node-variable' }),
      renderVariable(this.options.variables, props.node.attrs.id),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(memo<NodeViewProps>(HighlightedVariableValue));
  },

  addCommands() {
    return {
      insertVariableAt:
        (range: Range, id: string, arrayPath?: string, arrayLength?: number) =>
        ({ editor, chain }: CommandProps) => {
          const nodeBefore = editor.view.state.selection.$from.nodeBefore;

          // If the previous character is /, then extend the range back 1
          if (nodeBefore?.text?.endsWith('/')) {
            range.from -= 1;
          }

          range.to -= 1;

          return chain()
            .insertContentAt(range, { type: this.name, attrs: { id, arrayPath, arrayLength } })
            .run();
        },
    };
  },
});
