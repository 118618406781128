import {
  Button,
  type ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import type { ParticipantNote } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaAngleDown, FaHistory, FaTrash } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ParticipantNoteDeleteModal } from './ParticipantNoteDeleteModal';
import { ParticipantNoteHistoryModal } from './ParticipantNoteHistoryModal';

interface Props extends ButtonProps {
  participantNote: ParticipantNote;
  onDelete?: () => void;
}

export const ParticipantNoteActionMenu = (props: Props) => {
  const { participantNote, onDelete, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior='keepMounted' size='sm'>
      <MenuButton as={Button} variant='link' rightIcon={<Icon as={FaAngleDown} />} {...rest}>
        Actions
      </MenuButton>
      <MenuList>
        <MenuGroup title='Notes'>
          <MenuItem
            icon={<FaHistory />}
            onClick={() =>
              showModal(ParticipantNoteHistoryModal, {
                note: participantNote,
              })
            }
          >
            View version history
          </MenuItem>
          <MenuItem
            icon={<FaTrash />}
            color='danger'
            onClick={() =>
              showModal(ParticipantNoteDeleteModal, {
                participantId: participantNote.participantId,
                participantNoteId: participantNote.id,
                onDelete,
              })
            }
          >
            Delete
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
