import { Tag, TagLabel, type TagProps } from '@chakra-ui/react';
import { RestrictedPracticeStatus } from '@piccolohealth/pbs-common';
import React from 'react';

export const restrictedPracticeStatusOptions = {
  [RestrictedPracticeStatus.Ongoing]: {
    label: 'Ongoing',
    value: RestrictedPracticeStatus.Ongoing,
    raw: RestrictedPracticeStatus.Ongoing,
    color: 'blue',
  },
  [RestrictedPracticeStatus.Ceased]: {
    label: 'Ceased',
    value: RestrictedPracticeStatus.Ceased,
    raw: RestrictedPracticeStatus.Ceased,
    color: 'green',
  },
};

interface Props extends TagProps {
  status: RestrictedPracticeStatus;
}

export const RestrictedPracticeStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const option = restrictedPracticeStatusOptions[status];

  return (
    <Tag size='sm' colorScheme={option.color} {...rest}>
      <TagLabel>{option.label}</TagLabel>
    </Tag>
  );
};
