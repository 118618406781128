import * as Common from '@piccolohealth/pbs-common';
import { LabelType, renderTemplateType } from '@piccolohealth/pbs-common';
import type { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { FormStack } from '../../components/forms/FormStack';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedLabelChooser } from '../../components/forms/HookedLabelChooser';
import { HookedSelect } from '../../components/forms/HookedSelect';
import { templateStatusOptions } from './TemplateStatusTag';

const documentTypeOptions: SelectOption<Common.TemplateType>[] = Object.values(
  Common.TemplateType,
).map((type) => {
  return {
    label: renderTemplateType(type),
    value: type,
    raw: type,
  };
});

const statusOptions: SelectOption<Common.TemplateStatus>[] = [
  Common.TemplateStatus.Draft,
  Common.TemplateStatus.Published,
].map((status) => {
  const { color, label } = templateStatusOptions[status];

  return {
    label,
    color,
    value: status,
    raw: status,
  };
});

interface Props {
  isEditing?: boolean;
}

export const TemplateForm = (props: Props) => {
  const { isEditing } = props;

  return (
    <FormStack>
      <HookedFormItem name='name' label='Name' helperText='The name of the template' isRequired>
        <HookedInput name='name' size='sm' placeholder='Enter a name' />
      </HookedFormItem>
      {!isEditing && (
        <HookedFormItem name='type' label='Type' isRequired>
          <HookedSelect
            name='type'
            size='sm'
            placeholder='Choose a type of template'
            options={documentTypeOptions}
          />
        </HookedFormItem>
      )}
      <HookedFormItem name='status' label='Status' isRequired>
        <HookedSelect name='status' size='sm' options={statusOptions} optionVariant='tag' />
      </HookedFormItem>
      <HookedFormItem name='labelIds' label='Labels'>
        <HookedLabelChooser name='labelIds' type={LabelType.Template} />
      </HookedFormItem>
    </FormStack>
  );
};
