import { Button, HStack, IconButton } from '@chakra-ui/react';
import { FieldTemplateType, type ParticipantFieldGroupTemplate } from '@piccolohealth/pbs-common';
import type { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FaTrash } from 'react-icons/fa';
import { FormStack } from '../../../components/forms/FormStack';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../components/forms/HookedInput';
import { HookedSelect } from '../../../components/forms/HookedSelect';
import { fieldTemplateTypeTagColorName } from '../../../components/generic/FieldTemplateTypeTag';

const HookedInputAttributesForm = () => {
  return (
    <FormStack>
      <HookedFormItem name='attributes.prefix' label='Prefix'>
        <HookedInput name='attributes.prefix' placeholder='Enter a prefix' />
      </HookedFormItem>

      <HookedFormItem name='attributes.suffix' label='Suffix'>
        <HookedInput name='attributes.suffix' placeholder='Enter a suffix' />
      </HookedFormItem>
    </FormStack>
  );
};

const HookedSelectAttributesForm = () => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'attributes.options',
    shouldUnregister: true,
  });

  const options = fields as unknown as SelectOption<string>[];

  const onDeleteOption = React.useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const onAddOption = React.useCallback(() => {
    append({
      label: 'Example',
      value: 'Example',
      raw: 'Example',
    });
  }, [append]);

  return (
    <HookedFormItem name='attributes.options' label='Options'>
      <FormStack>
        {options.map((option, index) => (
          <HStack key={`${option.value}-${index}`} spacing={4}>
            <HookedInput name={`attributes.options.${index}.value`} size='sm' />
            <IconButton
              aria-label='Delete'
              size='xs'
              variant='ghost'
              icon={<FaTrash />}
              onClick={() => onDeleteOption(index)}
            />
          </HStack>
        ))}

        <Button size='sm' onClick={() => onAddOption()}>
          Add new option
        </Button>
      </FormStack>
    </HookedFormItem>
  );
};

const HookedAttributesForm = () => {
  const type = useWatch({ name: 'type' }) as FieldTemplateType;

  switch (type) {
    case FieldTemplateType.Input:
      return <HookedInputAttributesForm />;
    case FieldTemplateType.Textarea:
    case FieldTemplateType.Datepicker:
      return null;
    case FieldTemplateType.Select:
    case FieldTemplateType.MultiSelect:
      return <HookedSelectAttributesForm />;
  }
};

interface Props {
  fieldGroupTemplates: ParticipantFieldGroupTemplate[];
}

export const ParticipantFieldTemplateForm = (props: Props) => {
  const { fieldGroupTemplates } = props;

  return (
    <FormStack>
      <HookedFormItem name='name' label='Name'>
        <HookedInput name='name' size='sm' placeholder='Enter a name' />
      </HookedFormItem>

      <HookedFormItem name='type' label='Type'>
        <HookedSelect
          name='type'
          size='sm'
          optionVariant='tag'
          placeholder='Choose a type'
          options={Object.values(FieldTemplateType).map((type) => {
            const [color, label] = fieldTemplateTypeTagColorName(type);
            return {
              value: type,
              raw: type,
              label,
              color,
            };
          })}
        />
      </HookedFormItem>

      <HookedFormItem name='participantFieldGroupTemplateId' label='Group'>
        <HookedSelect
          name='participantFieldGroupTemplateId'
          size='sm'
          placeholder='Choose a group'
          options={fieldGroupTemplates.map((group) => {
            return {
              value: group.id,
              raw: group,
              label: group.name,
            };
          })}
        />
      </HookedFormItem>

      <HookedAttributesForm />
    </FormStack>
  );
};
