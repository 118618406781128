import { NumberedBadge } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface FormErrorsBadgeProps {
  names: string[];
}
export const FormErrorsBadge = (props: FormErrorsBadgeProps) => {
  const { formState } = useFormContext();

  const errors = P.compact(props.names.map((name) => P.get(formState.errors, name)));

  if (errors.length === 0) {
    return null;
  }

  return <NumberedBadge count={errors.length} colorScheme='red' />;
};
