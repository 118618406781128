import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../components/generic/Modal';
import { useDeleteParticipantFieldGroupTemplateMutation } from '../../../graphql/hooks/useDeleteParticipantFieldGroupTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  participantFieldGroupTemplateId: string;
  onDelete: () => void;
}

export const ParticipantFieldGroupTemplateDeleteModal = createModal<Props>((props) => {
  const { participantFieldGroupTemplateId, onDelete, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantFieldGroupTemplateMutation({});
  const { hide, remove, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantFieldGroupTemplateId,
      })
      .then(async () => {
        hide();
        successToast('Participant field group template deleted successfully');
        onDelete();
      })
      .catch((err) => {
        errorToast(`Error deleting participant field group template: ${err.message}`);
      });
  }, [
    mutation,
    organization.id,
    participantFieldGroupTemplateId,
    hide,
    successToast,
    onDelete,
    errorToast,
  ]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this participant field group template?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='red'
              isLoading={mutation.isLoading}
              loadingText='Deleting...'
              type='submit'
              onClick={onClick}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
