import { Text } from '@chakra-ui/react';
import type { ParticipantBehaviourRecording } from '@piccolohealth/pbs-common';
import { DescriptionList } from '@piccolohealth/ui';
import { Duration } from '@piccolohealth/util';
import React from 'react';

interface Props {
  recording: ParticipantBehaviourRecording;
}

export const ParticipantBehaviourRecordingValue = (props: Props) => {
  switch (props.recording.__typename) {
    case 'ParticipantBehaviourRecordingFrequency':
      return <>{props.recording.value} times</>;
    case 'ParticipantBehaviourRecordingDuration': {
      if (props.recording.value === 0) {
        return <>0 seconds</>;
      }

      const value = Duration.fromObject({
        seconds: props.recording.value,
      }).rescale();

      return <>{value.toHuman()}</>;
    }
    case 'ParticipantBehaviourRecordingEpisodicSeverity':
      return <>Level {props.recording.value}</>;
    case 'ParticipantBehaviourRecordingAbc':
      return (
        <DescriptionList
          rowGap={0}
          items={[
            {
              title: 'A',
              content: <Text>{props.recording.antecedent}</Text>,
            },
            {
              title: 'B',
              content: <Text>{props.recording.behaviour}</Text>,
            },
            {
              title: 'C',
              content: <Text>{props.recording.consequence}</Text>,
            },
          ]}
        />
      );
    case undefined:
      return <Text>Unknown</Text>;
  }
};
