import type { Maybe, Pagination, PiccoloError } from '@piccolohealth/pbs-common';
import type { PaginationOptions } from '@piccolohealth/ui';
import { getRequestHeaders, P } from '@piccolohealth/util';
import {
  type QueryKey,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
  type UseMutationOptions,
  type UseMutationResult,
  type UseQueryOptions,
  type UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import type { DocumentNode } from 'graphql/language/ast';
import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useConfig } from '../context/ConfigContext';
import { asPiccoloError } from '../utils/errors';
import { fetchWithTimeout } from '../utils/fetch';

export type MutationOptions<A, B> = UseMutationOptions<A, PiccoloError, B, unknown>;
export type MutationResult<A, B> = UseMutationResult<A, PiccoloError, B, unknown>;
export type MutationFn<A, B> = (options: MutationOptions<A, B>) => MutationResult<A, B>;

export type InfiniteQueryOptions<A> = UseInfiniteQueryOptions<unknown, PiccoloError, A>;
export type InfiniteQueryResult<A> = UseInfiniteQueryResult<A, PiccoloError>;
export type QueryOptions<A> = UseQueryOptions<unknown, PiccoloError, A>;
export type QueryResult<A> = UseQueryResult<A, PiccoloError>;
export type QueryFn<A, B> = (options: QueryOptions<A>) => QueryResult<B>;
export type KeyFn<A> = (variables: A) => QueryKey;

export interface PaginationFilter {
  currentPageNumber: number;
  pageSize: number;
  pageSizeOptions: number[];
  showTotal: (total: number, range: [number, number]) => string;
  setPageSize: (n: number) => void;
  setCurrentPageNumber: (n: number) => void;
}

const getOperationName = (query: string | DocumentNode): string | undefined => {
  if (typeof query === 'string') {
    return undefined;
  }
  return (query.definitions[0] as any)?.name.value;
};

export const useGqlFetcher = () => {
  const { config } = useConfig();
  const queryClient = useQueryClient();
  const { isAuthenticated, getAccessToken } = useAuth();

  return async <TData, TVariables>(query: string | DocumentNode, variables?: TVariables) => {
    const opName = getOperationName(query);
    const opNameParam = opName ? `?op=${opName}` : '';
    const headers = getRequestHeaders({
      accessToken: isAuthenticated ? await getAccessToken() : undefined,
      organizationId: P.get<string, string>(variables ?? {}, 'organizationId') ?? undefined,
      commit: config.buildInfo.commit,
    });

    const client = new GraphQLClient(`${config.api.url}/api${opNameParam}`, {
      fetch: fetchWithTimeout,
      headers,
      responseMiddleware: (resp: any) => {
        const version = resp?.headers?.get('X-Piccolo-Version');
        if (version) {
          queryClient.setQueryData(['X-Piccolo-Version'], version);
        }
      },
    });
    return client.request<TData>(query, variables ?? {}).catch((err) => {
      throw asPiccoloError(err);
    });
  };
};

export const gqlFetcher = <TData, TVariables>(
  query: string | DocumentNode,
): ((variables?: TVariables) => Promise<TData>) => {
  const gqlFetcher = useGqlFetcher();
  return async (variables?: TVariables) => {
    return gqlFetcher(query, variables);
  };
};

export const createGqlQuery = <Variables, Query>(
  getKey: KeyFn<Variables>,
  document: DocumentNode,
) => {
  const getFetcher = () => gqlFetcher<Query, Variables>(document);

  const query = (variables: Variables, options?: QueryOptions<Query>) =>
    useQuery(getKey(variables), getFetcher().bind(null, variables), options);

  query.getKey = getKey;
  query.document = document;
  query.getFetcher = getFetcher;

  return query;
};

export const createGqlMutation = <Variables, Mutation>(document: DocumentNode) => {
  const getFetcher = () => gqlFetcher<Mutation, Variables>(document);

  const mutation = (options?: MutationOptions<Mutation, Variables>) => {
    return useMutation(getFetcher().bind(null), options);
  };

  mutation.getFetcher = getFetcher;
  mutation.document = document;

  return mutation;
};

export const createPaginatedGqlQuery = <Variables, Query>(
  getKey: KeyFn<Variables>,
  document: DocumentNode,
  options: {
    filter: PaginationFilter;
    getPaginationResponse: (response?: Query) => Maybe<Pagination | undefined>;
  },
) => {
  const { filter, getPaginationResponse } = options;
  const getFetcher = () => gqlFetcher<Query, Variables>(document);

  const query = (variables: Variables, options?: QueryOptions<Query>) => {
    const resp = useQuery(getKey(variables), getFetcher().bind(null, variables), options);

    const paginationResp = getPaginationResponse(resp.data);

    const refetch = React.useCallback(async () => {
      await resp.refetch();
    }, [resp.refetch]);

    const pagination: PaginationOptions | undefined = !P.isNil(paginationResp)
      ? {
          total: paginationResp.total,
          currentPage: filter.currentPageNumber,
          pageSize: filter.pageSize,
          pageSizeOptions: filter.pageSizeOptions,
          hasNextPage: paginationResp.hasNextPage,
          hasPreviousPage: paginationResp.hasPreviousPage,
          showTotal: filter.showTotal,
          nextPage: () => filter.setCurrentPageNumber(filter.currentPageNumber + 1),
          previousPage: () => filter.setCurrentPageNumber(filter.currentPageNumber - 1),
          onPageSizeChange: filter.setPageSize,
        }
      : undefined;

    return {
      ...resp,
      pagination,
      refetch,
    };
  };

  query.getKey = getKey;
  query.document = document;
  query.getFetcher = getFetcher;

  return query;
};
