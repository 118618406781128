import { DatePicker, type DatePickerValueChangeDetails } from '@ark-ui/react/date-picker';
import { Icon, Input, InputGroup, type InputProps, InputRightElement } from '@chakra-ui/react';
import { parseZonedDateTime } from '@internationalized/date';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { FaRegCalendar } from 'react-icons/fa';
import { FloatingPopover } from './FloatingPopover';
import { DayView, LOCALE, MonthView, YearView } from './RangeDatepicker';

export interface SingleDatepickerProps extends Omit<InputProps, 'onChange'> {
  onChange: (date: DateTime | null) => void;
  date: DateTime | null;
  isDisabled?: boolean;
  placeholder?: string;
  showIcon?: boolean;
}

export const SingleDatepicker = (props: SingleDatepickerProps) => {
  const { date, isDisabled, showIcon, onChange, ...rest } = props;
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const value = React.useMemo(() => {
    if (props.date) {
      return [parseZonedDateTime(props.date?.toISO({ extendedZone: true }))];
    }

    return [];
  }, [props.date]);

  const onInputChange = React.useCallback(
    (value: string) => {
      if (value === '') {
        props.onChange(null);
        return;
      }

      const inputDate = DateTime.fromFormat(inputRef.current?.value ?? '', 'dd/MM/yyyy');
      if (inputDate.isValid) {
        props.onChange(inputDate);
        return;
      }
    },
    [props],
  );

  const onChangePrime = React.useCallback(
    (details: DatePickerValueChangeDetails) => {
      if (details.value.length !== 1) {
        return;
      }
      const [start] = details.value;
      props.onChange(
        DateTime.fromISO(start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toString()),
      );
    },
    [props],
  );

  const onInputBlur = React.useCallback(() => {
    if (inputRef.current?.value === '') {
      return;
    }

    const inputDate = DateTime.fromFormat(inputRef.current?.value ?? '', 'dd/MM/yyyy');

    if (!inputDate.isValid && inputRef.current) {
      inputRef.current.value = props.date?.toFormat('dd/MM/yyyy') ?? '';
    }
  }, [props]);

  const onClickClear = React.useCallback(() => {
    props.onChange(null);
    setPopoverOpen(false);
  }, [props]);

  return (
    <DatePicker.Root
      open={true}
      selectionMode='single'
      closeOnSelect={false}
      onValueChange={onChangePrime}
      value={value}
      timeZone={date?.zoneName}
      locale={LOCALE}
      disabled={isDisabled}
      aria-selected
    >
      <FloatingPopover
        isPortal
        open={popoverOpen && !isDisabled}
        setOpen={setPopoverOpen}
        placement='bottom-start'
        render={() => (
          <>
            <DayView months={1} onClickClear={onClickClear} />
            <MonthView onClickClear={onClickClear} />
            <YearView onClickClear={onClickClear} />
          </>
        )}
      >
        <DatePicker.Control>
          <InputGroup size='sm' {...rest}>
            <Input
              ref={inputRef}
              as={DatePicker.Input}
              index={0}
              size='sm'
              bg='white'
              onChange={(e) => onInputChange(e.target.value)}
              onBlur={onInputBlur}
              {...rest}
            />
            {(showIcon ?? true) && (
              <InputRightElement>
                <Icon color='gray.300' as={FaRegCalendar} />
              </InputRightElement>
            )}
          </InputGroup>
        </DatePicker.Control>
      </FloatingPopover>
    </DatePicker.Root>
  );
};
