import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateParticipantUsers } from '../mutations/createParticipantUsers.mutation';
import type {
  CreateParticipantUsersMutation,
  CreateParticipantUsersMutationVariables,
} from '../types';

export const useCreateParticipantUsersMutation = (
  options?: MutationOptions<
    CreateParticipantUsersMutation,
    CreateParticipantUsersMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantUsers), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
