import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteLabel } from '../mutations/deleteLabel.mutation';
import type { DeleteLabelMutation, DeleteLabelMutationVariables } from '../types';

export const useDeleteLabelMutation = (
  options?: MutationOptions<DeleteLabelMutation, DeleteLabelMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes('organization') || query.queryKey.includes('participant'),
      });
    },
  });
};
