import { Alert, AlertIcon, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../components/generic/DeleteConfirmModal';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantQRMutation } from '../../graphql/hooks/useDeleteParticipantQRMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
  qrId: string;
}

export const ParticipantQRDeleteModal = createModal<Props>((props) => {
  const { participantId, qrId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useDeleteParticipantQRMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        qrId,
      })
      .then(async () => {
        successToast('QR code deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting QR code: ${err.message}`);
      });
  }, [mutation, organization.id, participantId, qrId, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading='Do you want to delete this QR code?'
      description={
        <Stack mb={6} spacing={4}>
          <Text>
            Deleting this QR code <strong>will remove associated recordings.</strong>
          </Text>
          <Alert status='info' fontSize='md' border='none'>
            <AlertIcon />
            If you want to keep the recordings, consider archiving the QR code instead.
          </Alert>
        </Stack>
      }
      requireConfirm
      onDelete={onDelete}
      isLoading={mutation.isLoading}
    />
  );
});
