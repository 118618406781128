import { HStack, Input } from '@chakra-ui/react';
import React from 'react';
import type { UsersFilter } from '../../hooks/useUsersFilter';

interface Props {
  filter: UsersFilter;
}

export const UsersFilterControls = (props: Props) => {
  const { filter } = props;

  return (
    <HStack>
      <Input
        onChange={(e) => filter.onNameFilter(e.target.value)}
        size='sm'
        w='3xs'
        placeholder='All users'
      />
    </HStack>
  );
};
