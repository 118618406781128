import { createGqlQuery } from '../fetcher';
import { ParticipantFieldGroupTemplates } from '../queries/participantFieldGroupTemplates.query';
import type * as Types from '../types';

export const useParticipantFieldGroupTemplatesQuery = createGqlQuery<
  Types.ParticipantFieldGroupTemplatesQueryVariables,
  Types.ParticipantFieldGroupTemplatesQuery
>(
  (variables) => ['organization', variables.organizationId, 'participantFieldGroupTemplates'],
  ParticipantFieldGroupTemplates,
);
