import { Spin } from '@piccolohealth/ui';
import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { PageError } from '../../components/generic/PageError';
import { AdminLayout } from './components/AdminLayout';
import { AdminProtectedRoute } from './components/AdminProtectedRoute';
import { Onboard } from './components/Onboard';

const GraphQLIDE = React.lazy(() => import('./components/GraphQLIDE'));

export const adminRoutes: RouteObject[] = [
  {
    path: '*',
    handle: { crumb: () => 'Admin' },
    element: (
      <AdminProtectedRoute>
        <AdminLayout />
      </AdminProtectedRoute>
    ),
    children: [
      {
        path: 'onboard',
        handle: { crumb: () => 'Onboard' },
        element: <Onboard />,
      },
      {
        path: 'graphiql',
        element: (
          <React.Suspense fallback={<Spin />}>
            <GraphQLIDE />
          </React.Suspense>
        ),
      },
      {
        path: '*',
        element: <PageError type='NotFound' />,
      },
    ],
  },
];
