import { Divider, HStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { P, localeList, timezoneList } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedImageUploader } from '../../components/forms/HookedImageUploader';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedSelect } from '../../components/forms/HookedSelect';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { useUpdateOrganizationMutation } from '../../graphql/hooks/useUpdateOrganizationMutation';
import { useAppContext } from '../../hooks/useAppContext';

type FormValues = {
  name: string;
  timezone: string;
  locale: string;
  logo?: string | null;
};

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string(),
  timezone: z.string(),
  locale: z.string(),
  logo: z.string().nullish(),
});

export const BasicSettings = () => {
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateOrganizationMutation();

  const defaultValues = {
    name: organization.name,
    timezone: organization.timezone,
    locale: organization.locale,
    logo: organization.logo,
  };
  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const onSubmit = async (values: FormValues) => {
    const request = P.pickBy(values, (value, key) => {
      return !P.isEqual(value, defaultValues[key]);
    });

    await mutation
      .mutateAsync({
        organizationId: organization.id,
        updateOrganizationRequest: request,
      })
      .then(() => {
        successToast('Basic settings updated successfully');
      })
      .catch((err) => {
        errorToast(`Error updating basic settings: ${err.message}`);
      });
  };

  React.useEffect(() => {
    methods.reset({
      name: organization.name,
      timezone: organization.timezone,
      locale: organization.locale,
      logo: organization.logo,
    });
  }, [methods, organization.name, organization.timezone, organization.logo, organization.locale]);

  const timezoneOptions = timezoneList.map((timezone) => ({
    key: timezone,
    value: timezone,
    label: timezone,
    raw: timezone,
  }));

  const localeOptions = localeList.map((locale) => ({
    key: locale,
    value: locale,
    label: locale,
    raw: locale,
  }));

  return (
    <FormProvider {...methods}>
      <FormStack as='form' onSubmit={methods.handleSubmit(onSubmit)} maxW='lg'>
        <FormSection heading='Basic' description='Basic settings related to your organization'>
          <Divider />
          <FormStack>
            <HookedFormItem
              name='name'
              label='Organization name'
              helperText='The name of your organization'
            >
              <HookedInput name='name' size='sm' />
            </HookedFormItem>

            <HookedFormItem
              name='timezone'
              label='Timezone'
              helperText='The main timezone of your organization'
            >
              <HookedSelect name='timezone' options={timezoneOptions} />
            </HookedFormItem>

            <HookedFormItem
              name='locale'
              label='Locale'
              helperText='The date format for your organization'
            >
              <HookedSelect name='locale' options={localeOptions} />
            </HookedFormItem>
            <HookedFormItem label='Logo' name='logo'>
              <HookedImageUploader name='logo' maxWidth={150} maxHeight={120} />
            </HookedFormItem>
          </FormStack>
        </FormSection>
        <Divider />
        <HStack>
          <HookedSubmitButton>Save</HookedSubmitButton>
        </HStack>
      </FormStack>
    </FormProvider>
  );
};
