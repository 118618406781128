import { type Template, TemplateStatus } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import type { TemplatesFilter } from '../../features/templates/hooks/useTemplatesFilter';
import { useAppContext } from '../../hooks/useAppContext';
import { createGqlQuery } from '../fetcher';
import { Templates } from '../queries/templates.query';
import type * as Types from '../types';

export const useTemplatesQuery = createGqlQuery<
  Types.TemplatesQueryVariables,
  Types.TemplatesQuery
>((variables) => ['organization', 'template', variables], Templates);

export const useTemplatesFilterQuery = (filter: TemplatesFilter) => {
  const { organization } = useAppContext();

  const resp = useTemplatesQuery({
    organizationId: organization.id,
    request: {
      name: filter.nameFilter,
      label: filter.labelFilter,
      type: filter.typeFilter,
      status:
        !P.isUndefined(filter.statusFilter) && P.isEmpty(filter.statusFilter)
          ? [TemplateStatus.Published, TemplateStatus.Draft]
          : filter.statusFilter,
    },
  });

  const templates = (resp.data?.organization?.templates?.results as Template[]) ?? [];

  return {
    ...resp,
    templates,
  };
};
