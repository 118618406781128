import { HStack, Spacer, Text } from '@chakra-ui/react';
import type { MethodType } from '@piccolohealth/pbs-common';
import type { SelectOption } from '@piccolohealth/ui';
import { inflection } from '@piccolohealth/util';
import React from 'react';
import { BadgeItem } from '../../../../components/generic/BadgeItem';

interface Props {
  option: SelectOption<MethodType>;
  count: number;
}

export const MethodOption = (props: Props) => {
  const { option, count } = props;

  return (
    <HStack w='full'>
      <BadgeItem label={option.label} color={option.color} />
      <Spacer />
      <Text color='gray.500' fontSize='xs'>
        {count} {inflection.inflect('recording', count)}
      </Text>
    </HStack>
  );
};
