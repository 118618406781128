import { Spin } from '@piccolohealth/ui';
import type { MultiFactorError } from 'firebase/auth';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { type MultiFactorVerifyChallenge, useAuth } from '../../context/AuthContext';
import { AuthLayout } from './components/AuthLayout';
import { LoginCard } from './components/LoginCard';
import { PhoneVerifyCard } from './components/PhoneVerifyCard';
import { TotpVerifyCard } from './components/TotpVerifyCard';

export const Login = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');

  const [multiFactorVerifyChallenge, setMultiFactorVerifyChallenge] =
    React.useState<MultiFactorVerifyChallenge | null>(null);

  const onMultiFactorError = async (error: MultiFactorError) => {
    const challenge = await auth.getMultiFactorVerifyChallenge(error);
    setMultiFactorVerifyChallenge(challenge);
  };

  const onPhoneVerifyCode = async (verificationCode: string) => {
    if (!multiFactorVerifyChallenge || multiFactorVerifyChallenge.type !== 'phone') {
      return;
    }

    return auth.verifyPhoneVerificationCode(multiFactorVerifyChallenge, verificationCode);
  };

  const onPhoneResendCode = async () => {
    if (!multiFactorVerifyChallenge || multiFactorVerifyChallenge.type !== 'phone') {
      return;
    }

    await auth.sendPhoneVerificationCode(multiFactorVerifyChallenge.resolver);
  };

  const onTotpVerifyCode = async (verificationCode: string) => {
    if (!multiFactorVerifyChallenge || multiFactorVerifyChallenge.type !== 'totp') {
      return;
    }

    return auth.verifyTotpVerificationCode(multiFactorVerifyChallenge, verificationCode);
  };

  if (auth.isLoading) {
    return (
      <AuthLayout>
        <Spin />
      </AuthLayout>
    );
  }

  if (auth.isAuthenticated) {
    return <Navigate to={returnUrl ? decodeURIComponent(returnUrl) : '/'} />;
  }

  if (auth.isLoggedIn && !auth.isMFAEnrolled) {
    return <Navigate to='/mfa/enrol' />;
  }

  if (multiFactorVerifyChallenge && multiFactorVerifyChallenge.type === 'phone') {
    return <PhoneVerifyCard onVerifyCode={onPhoneVerifyCode} onResendCode={onPhoneResendCode} />;
  }

  if (multiFactorVerifyChallenge && multiFactorVerifyChallenge.type === 'totp') {
    return <TotpVerifyCard onVerifyCode={onTotpVerifyCode} />;
  }

  return <LoginCard onMultiFactorError={onMultiFactorError} />;
};
