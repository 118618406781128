import { Stack } from '@chakra-ui/react';
import type { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import React from 'react';
import type { UseTimeseriesChartReturn } from '../../../participantData/hooks/useTimeseriesChart';
import { TimeSeriesAddControl } from './TimeSeriesAddControl';
import { TimeSeriesEditControl } from './TimeSeriesEditControl';

interface Props {
  behaviour: ParticipantBehaviour;
  chart: UseTimeseriesChartReturn;
}

export const TimeSeriesControl = (props: Props) => {
  const { behaviour, chart } = props;

  const series = chart.widget.series.filter(
    (s) => s.source.participantBehaviourId === behaviour.id,
  );

  return (
    <Stack>
      {series.map((series, index) => (
        <TimeSeriesEditControl key={index} chart={chart} series={series} />
      ))}
      <TimeSeriesAddControl participantBehaviourId={behaviour.id} chart={chart} />
    </Stack>
  );
};
