import { Button, type ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export const HookedSubmitButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  const { formState } = useFormContext();

  return (
    <Button
      type='submit'
      isLoading={formState.isSubmitting}
      colorScheme='purple'
      size='sm'
      isDisabled={!formState.isDirty}
      {...rest}
    >
      {children}
    </Button>
  );
};
