import { Stack, Wrap, WrapItem } from '@chakra-ui/react';
import type { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import { P, matchSorter } from '@piccolohealth/util';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { useParticipantBehavioursQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantBehaviourCard } from './components/ParticipantBehaviourCard';
import { ParticipantBehaviourCreateButton } from './components/ParticipantBehaviourCreateButton';
import { ParticipantBehaviourCreateModal } from './components/ParticipantBehaviourCreateModal';
import { ParticipantBehaviourFilterControls } from './components/ParticipantBehaviourFilterControls';
import { useParticipantBehavioursFilter } from './hooks/useParticipantBehavioursFilter';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantBehaviours = (props: Props) => {
  const { participantId, isDisabled } = props;
  const { organization } = useAppContext();

  const filter = useParticipantBehavioursFilter();

  const { data, isLoading, error } = useParticipantBehavioursQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviours = React.useMemo(() => {
    const results = (data?.organization?.participant?.behaviours as ParticipantBehaviour[]) ?? [];

    const nameFiltered = matchSorter(results, filter.nameFilter, {
      keys: ['name'],
    });

    const statusFiltered = !P.isEmpty(filter.statusFilter)
      ? nameFiltered.filter((v) => filter.statusFilter.includes(v.status))
      : nameFiltered;

    const methodFiltered = !P.isEmpty(filter.methodFilter)
      ? statusFiltered.filter((v) => {
          const enabledMethods = P.compact([
            v.methods.abc.enabled ? v.methods.abc : null,
            v.methods.frequency.enabled ? v.methods.frequency : null,
            v.methods.duration.enabled ? v.methods.duration : null,
            v.methods.episodicSeverity.enabled ? v.methods.episodicSeverity : null,
          ]);

          return enabledMethods.some((m) => filter.methodFilter.includes(m.__typename));
        })
      : statusFiltered;

    return methodFiltered;
  }, [
    data?.organization?.participant?.behaviours,
    filter.methodFilter,
    filter.nameFilter,
    filter.statusFilter,
  ]);

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Stack px={6} py={4} spacing={4}>
      <ParticipantBehaviourFilterControls filter={filter} />
      <Wrap spacing={4}>
        {participantBehaviours.map((participantBehaviour) => (
          <WrapItem key={participantBehaviour.id} w='full' maxW='lg' minH='xs'>
            <ParticipantBehaviourCard
              isDisabled={isDisabled}
              participantId={participantId}
              participantBehaviour={participantBehaviour}
            />
          </WrapItem>
        ))}
        <WrapItem w='full' maxW='lg' minH='xs'>
          <ParticipantBehaviourCreateButton
            isDisabled={isDisabled}
            onClick={() =>
              showModal(ParticipantBehaviourCreateModal, {
                participantId,
              })
            }
          />
        </WrapItem>
      </Wrap>
    </Stack>
  );
};
