import { Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedPinInput } from '../../../components/forms/HookedPinInput';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { AuthCard } from './AuthCard';
import { AuthLayout } from './AuthLayout';
import { MFAChooseMethodButton } from './MFAChooseMethodButton';

type FormValues = {
  verificationCode: string;
};

const schema: z.ZodSchema<FormValues> = z.object({
  verificationCode: z.string().length(6).length(6, 'Verification code must be 6 characters'),
});

interface Props {
  onVerifyCode: (verificationCode: string) => Promise<void>;
}

export const TotpVerifyCard = (props: Props) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      verificationCode: '',
    },
    resolver: zodResolver(schema),
  });

  const onVerifyCode = async (values: FormValues) => {
    return props.onVerifyCode(values.verificationCode).catch(() => {
      methods.setError('verificationCode', {
        message: 'Invalid verification code',
      });
      methods.resetField('verificationCode', {
        keepError: true,
      });
    });
  };

  return (
    <AuthLayout>
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Two-factor Authentication
          </Text>
          <Text fontSize='sm' color='secondary'>
            Enter the verification code in your authenticator app
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onVerifyCode)}>
            <Stack spacing={4}>
              <HookedFormItem name='verificationCode'>
                <HookedPinInput name='verificationCode' fieldCount={6} size='md' />
              </HookedFormItem>

              <HookedSubmitButton size='md' mt={4} isDisabled={false} loadingText='Verifying...'>
                Verify
              </HookedSubmitButton>
            </Stack>
          </form>
        </FormProvider>
        <MFAChooseMethodButton />
      </AuthCard>
    </AuthLayout>
  );
};
