import { Tag, TagLabel, type TagProps } from '@chakra-ui/react';
import { FormType } from '@piccolohealth/pbs-common';
import React from 'react';

export const formTypeColorName = (type: FormType) => {
  switch (type) {
    case FormType.Participant:
      return ['green', 'Participant'];
  }
};

interface Props extends TagProps {
  type: FormType;
}

export const FormTypeTag = (props: Props) => {
  const { type, ...rest } = props;

  const [color, label] = formTypeColorName(type);

  return (
    <Tag size='sm' colorScheme={color} {...rest}>
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
};
