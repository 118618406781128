import { HStack, Heading, Stack, Text } from '@chakra-ui/react';
import type * as Common from '@piccolohealth/pbs-common';
import { PiccoloError, TemplateType, renderTemplateType } from '@piccolohealth/pbs-common';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useTemplateQuery } from '../../graphql/hooks/useTemplateQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantDocumentTemplateEditor } from './ParticipantDocumentTemplateEditor';
import { ParticipantNoteTemplateEditor } from './ParticipantNoteTemplateEditor';

export const Template = () => {
  const { templateId } = useRequiredParams<{ templateId: string }>();
  const { organization } = useAppContext();

  const { data, isLoading, error } = useTemplateQuery({
    templateId,
    organizationId: organization.id,
  });

  const template = data?.organization?.template as Common.Template | null;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!template) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'TemplateNotFound',
            message: 'Template not found',
          })
        }
      />
    );
  }

  const content = P.run(() => {
    switch (template.type) {
      case TemplateType.ParticipantDocument:
        return <ParticipantDocumentTemplateEditor template={template} />;
      case TemplateType.ParticipantNote:
        return <ParticipantNoteTemplateEditor template={template} />;
      default:
        return null;
    }
  });

  return (
    <ContentLayout
      headerContent={
        <Stack align='start' spacing={0}>
          <Heading size='lg'>{template?.name}</Heading>
          <HStack align='center'>
            <Text fontSize='sm' color='secondary'>
              {renderTemplateType(template.type)}
            </Text>
          </HStack>
        </Stack>
      }
    >
      {content}
    </ContentLayout>
  );
};
