import { Button, Divider, Input, Spacer, Stack } from '@chakra-ui/react';
import type { TimeSeriesChartWidget } from '@piccolohealth/pbs-common';
import { ScrollArea } from '@piccolohealth/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormItem } from '../../../../components/forms/FormItem';
import { FormSection } from '../../../../components/forms/FormSection';
import { FormStack } from '../../../../components/forms/FormStack';
import type { UseTimeseriesChartReturn } from '../../hooks/useTimeseriesChart';
import { PhaseChangeLineAddControl } from '../phaseChange/PhaseChangeLineAddControl';
import { PhaseChangeList } from '../phaseChange/PhaseChangeList';
import { TimeSeriesList } from './TimeSeriesList';

interface Props {
  chart: UseTimeseriesChartReturn;
  onSubmit: (value: TimeSeriesChartWidget) => void;
}

export const TimeSeriesWidgetModalSidebar = (props: Props) => {
  const { chart, onSubmit } = props;

  return (
    <Stack w='390px' h='full' spacing={4} flexShrink={0}>
      <ScrollArea h='full'>
        <FormStack p={4}>
          <FormSection heading='Basic details' description='Set the basic details of the chart'>
            <FormItem label='Chart title'>
              <Input
                value={chart.widget.title}
                onChange={(e) => chart.onSetTitle(e.target.value)}
                size='sm'
                placeholder='Enter chart title'
              />
            </FormItem>
          </FormSection>

          <Divider />

          <FormSection heading='Behaviours' description='Select the behaviours you want to view'>
            <TimeSeriesList chart={chart} />
          </FormSection>

          <Divider />

          <FormSection heading='Phase change lines' description='Add a phase change line'>
            <PhaseChangeList chart={chart} />
            <PhaseChangeLineAddControl chart={chart} />
          </FormSection>

          <Spacer />
        </FormStack>
      </ScrollArea>
      <Stack px={4} pb={4}>
        <Button
          leftIcon={<FaPlus />}
          size='sm'
          colorScheme='purple'
          onClick={() => onSubmit(chart.widget)}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
