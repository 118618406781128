import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { renderPiccoloError } from '@piccolohealth/pbs-common';
import { stringParam, useQueryParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaRegCheckCircle } from 'react-icons/fa';
import { Navigate, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { useAuth } from '../../context/AuthContext';
import { asPiccoloError } from '../../utils/errors';
import { AuthCard } from './components/AuthCard';
import { AuthLayout } from './components/AuthLayout';
import { BackToLoginButton } from './components/BackToLoginButton';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const schema: z.ZodSchema<FormValues> = z
  .object({
    password: z.string().min(8, 'Password must be at least 8 characters'),
    confirmPassword: z.string().min(1, 'Please confirm your password'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });

export const ResetPassword = () => {
  const [params] = useQueryParams({
    oobCode: stringParam,
    email: stringParam,
  });
  const { oobCode, email } = params;

  const { resetPassword, logout } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    logout();
  }, [logout]);

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    if (!oobCode || !email) {
      return;
    }

    try {
      await resetPassword(oobCode, email, data.password);
    } catch (error) {
      const piccoloError = asPiccoloError(error);
      const renderedError = renderPiccoloError(piccoloError);
      methods.reset({ password: '', confirmPassword: '' });
      methods.setError('password', { message: renderedError.message });
    }
  };

  const onClickLogin = () => {
    navigate('/login');
  };

  if (!oobCode) {
    return <Navigate to='/login' />;
  }

  const content = P.run(() => {
    if (methods.formState.isSubmitSuccessful) {
      return (
        <AuthCard>
          <Stack spacing={4} align='center'>
            <Icon as={FaRegCheckCircle} color='success' boxSize={16} />
            <Text fontSize='sm' textAlign='center'>
              Your password has been reset successfully. Please login with your new password.
            </Text>
            <Button w='full' size='md' colorScheme='purple' onClick={onClickLogin}>
              Login
            </Button>
          </Stack>
        </AuthCard>
      );
    }

    return (
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Reset Password
          </Text>
          <Text fontSize='sm' color='secondary'>
            Please enter your new password below
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <HookedFormItem name='password' label='New Password'>
                <HookedInput
                  name='password'
                  type='password'
                  size='md'
                  placeholder='Enter new password'
                />
              </HookedFormItem>
              <HookedFormItem name='confirmPassword' label='Confirm Password'>
                <HookedInput
                  name='confirmPassword'
                  type='password'
                  size='md'
                  placeholder='Confirm new password'
                />
              </HookedFormItem>

              <HookedSubmitButton size='md' mt={4} loadingText='Resetting password...'>
                Reset Password
              </HookedSubmitButton>
            </Stack>
          </form>
        </FormProvider>
        <BackToLoginButton />
      </AuthCard>
    );
  });

  return <AuthLayout>{content}</AuthLayout>;
};
