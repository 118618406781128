import { Box, type BoxProps, Flex, Image, Text } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import type { CommandTooltip } from './CommandMenu';

interface Props extends BoxProps {
  tooltip?: CommandTooltip;
}

export const CommandMenuItemTooltip = (props: Props) => {
  const { tooltip, ...rest } = props;

  if (!tooltip) {
    return null;
  }

  const content = P.run(() => {
    switch (tooltip.kind) {
      case 'image': {
        return (
          <Flex flexDir='column' overflowY='hidden' w='140px'>
            <Box bg='gray.100' rounded='md'>
              <Image src={tooltip.src} fallback={<Box w='144px' h='96px' />} rounded='md' />
            </Box>
            <Box maxH='3xs'>
              <Text color='white' fontWeight='semibold' mt='1'>
                {tooltip.description}
              </Text>
            </Box>
          </Flex>
        );
      }
      case 'content':
        return tooltip.content;

      default:
        return null;
    }
  });

  return (
    <Box
      w='fit-content'
      p={2}
      rounded='md'
      shadow='2xl'
      fontSize='sm'
      bg='gray.700'
      borderColor='red.700'
      color='white'
      zIndex={100000}
      {...rest}
    >
      {content}
    </Box>
  );
};
