import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../../components/generic/DeleteConfirmModal';
import { createModal } from '../../../components/generic/Modal';
import { useDeleteParticipantFieldTemplateMutation } from '../../../graphql/hooks/useDeleteParticipantFieldTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  participantFieldTemplateId: string;
}

export const ParticipantFieldTemplateDeleteModal = createModal<Props>((props) => {
  const { participantFieldTemplateId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantFieldTemplateMutation({});

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantFieldTemplateId,
      })
      .then(async () => {
        successToast('Participant field template deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting participant field template: ${err.message}`);
      });
  }, [mutation, organization, participantFieldTemplateId, successToast, errorToast]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading='Do you want to delete this participant field template?'
      description={
        <Stack mb={6} spacing={4}>
          <Text>Deleting this field template cannot be undone</Text>
        </Stack>
      }
      requireConfirm
      onDelete={onDelete}
    />
  );
});
