import { Input } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { MultiSelect, type MultiSelectOption, type OnChangeRequest } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormItem } from '../../components/forms/FormItem';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import type { ParticipantNotesFilter } from '../../hooks/useParticipantNotesFilter';

interface Props {
  filter: ParticipantNotesFilter;
}

const ParticipantNoteContentFilterControl = (props: Props) => {
  return (
    <Input
      onChange={(e) => props.filter.onContentFilter(e.target.value)}
      value={props.filter.contentFilter}
      placeholder='All content'
      size='sm'
    />
  );
};
const ParticipantNoteLabelFilterControl = (props: Props) => {
  const { organization } = useAppContext();
  const { data, isLoading } = useLabelsQuery({
    organizationId: organization.id,
    type: Common.LabelType.ParticipantNote,
  });

  const options: MultiSelectOption<Common.Label>[] = React.useMemo(() => {
    const labels = (data?.organization?.labels as Common.Label[]) ?? [];
    return labels.map((label) => {
      return {
        label: label.name,
        value: label.id,
        raw: label,
        color: label.color,
      };
    });
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Common.Label>[] = React.useMemo(() => {
    return (props.filter.labelFilter ?? []).flatMap((label) => {
      return options.find((o) => P.isEqual(o.raw.id, label)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.Label>) => {
      props.filter.onLabelFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All labels'
      optionVariant='tag'
    />
  );
};

export const ParticipantNotesFilterControls = (props: Props) => {
  return (
    <>
      <FormItem label='Content' w='3xs'>
        <ParticipantNoteContentFilterControl filter={props.filter} />
      </FormItem>

      <FormItem label='Labels' w='sm'>
        <ParticipantNoteLabelFilterControl filter={props.filter} />
      </FormItem>
    </>
  );
};
