import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  type InputProps,
  forwardRef,
} from '@chakra-ui/react';
import React, { type InputHTMLAttributes } from 'react';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';

type Props = {
  currentPagesLength: number;
  popPage: () => void;
} & (InputProps & InputHTMLAttributes<HTMLInputElement>);

export const CommandInput = forwardRef((props: Props, ref) => {
  const { popPage, currentPagesLength, ...rest } = props;

  const icon =
    currentPagesLength > 1 ? (
      <InputLeftElement onClick={() => popPage()} cursor='pointer' {...rest}>
        <Icon as={FaArrowLeft} color='gray.300' _hover={{ color: 'gray.500' }} />
      </InputLeftElement>
    ) : (
      <InputLeftElement pointerEvents='none' {...rest}>
        <Icon as={FaSearch} color='gray.300' />
      </InputLeftElement>
    );

  return (
    <InputGroup size='sm' variant='unstyled' display='flex'>
      {icon}
      <Input
        ref={ref}
        fontWeight='semibold'
        variant='unstyled'
        _hover={{ bg: 'transparent' }}
        {...rest}
      />
    </InputGroup>
  );
});
