import { Tag, TagLabel, type TagProps } from '@chakra-ui/react';
import { TemplateStatus } from '@piccolohealth/pbs-common';
import React from 'react';

export const templateStatusOptions = {
  [TemplateStatus.Draft]: {
    label: 'Draft',
    value: TemplateStatus.Draft,
    raw: TemplateStatus.Draft,
    color: 'blue',
  },
  [TemplateStatus.Archived]: {
    label: 'Archived',
    value: TemplateStatus.Archived,
    raw: TemplateStatus.Archived,
    color: 'gray',
  },
  [TemplateStatus.Published]: {
    label: 'Published',
    value: TemplateStatus.Published,
    raw: TemplateStatus.Published,
    color: 'green',
  },
};

interface Props extends TagProps {
  status: TemplateStatus;
}

export const TemplateStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const { label, color } = templateStatusOptions[status];

  return (
    <Tag size='sm' colorScheme={color} {...rest}>
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
};
