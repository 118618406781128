import { HStack, Stack, Text } from '@chakra-ui/react';
import type {
  ParticipantBehaviour,
  ParticipantBehaviourRecording,
} from '@piccolohealth/pbs-common';
import { DataTable, Empty, FancyDate, createColumnHelper } from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import React from 'react';
import { UserDescription } from '../../../components/user/UserDescription';
import { FLOOR_PERIOD } from '../../participantData/utils';
import { ParticipantBehaviourRecordingDeleteButton } from './ParticipantBehaviourRecordingDeleteButton';
import { ParticipantBehaviourRecordingEditButton } from './ParticipantBehaviourRecordingEditButton';
import { ParticipantBehaviourRecordingTypeTag } from './ParticipantBehaviourRecordingTypeTag';
import { ParticipantBehaviourRecordingValue } from './ParticipantBehaviourRecordingValue';

type ParticipantBehaviourRecordingWithBehaviour = ParticipantBehaviourRecording & {
  participantBehaviour: ParticipantBehaviour;
};

interface Props {
  participantId: string;
  participantBehaviours: ParticipantBehaviour[];
}

export const ParticipantBehaviourRecordingsTable = (props: Props) => {
  const { participantId, participantBehaviours } = props;

  const orderedGroupedRecordings = React.useMemo(() => {
    const recordings: ParticipantBehaviourRecordingWithBehaviour[] = participantBehaviours.flatMap(
      (b) => b.recordings.map((r) => ({ ...r, participantBehaviour: b })),
    );

    const groupedByDay = P.groupBy(recordings, (r) =>
      DateTime.fromISO(r.timestamp.toString()).startOf(FLOOR_PERIOD).toISO(),
    );

    const outerOrdered = P.orderBy(
      Object.entries(groupedByDay),
      ([timestamp]) => DateTime.fromISO(timestamp),
      'desc',
    );

    return outerOrdered.map(([timestamp, recordings]) => {
      return [
        timestamp,
        P.orderBy(recordings ?? [], (recording) => recording.timestamp, 'desc'),
      ] as const;
    });
  }, [participantBehaviours]);

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<ParticipantBehaviourRecordingWithBehaviour>();

    return [
      columnHelper.display({
        header: 'Behaviour',
        minSize: 200,
        maxSize: 400,
        cell: (ps) => {
          return <Text noOfLines={2}>{ps.row.original.participantBehaviour.name}</Text>;
        },
      }),
      columnHelper.display({
        header: 'Type',
        minSize: 150,
        maxSize: 200,
        cell: (ps) => {
          return <ParticipantBehaviourRecordingTypeTag type={ps.row.original.__typename} />;
        },
      }),
      columnHelper.display({
        header: 'User',
        minSize: 240,
        maxSize: 300,
        cell: (ps) => {
          return (
            <UserDescription
              name={ps.row.original.user.name}
              secondary={ps.row.original.user.email}
              picture={
                ps.row.original.user.__typename === 'User'
                  ? ps.row.original.user.picture
                  : undefined
              }
            />
          );
        },
      }),
      columnHelper.display({
        header: 'Value',
        minSize: 60,
        cell: (ps) => {
          return <ParticipantBehaviourRecordingValue recording={ps.row.original} />;
        },
      }),
      columnHelper.display({
        header: 'Actions',
        minSize: 60,
        maxSize: 200,
        cell: (ps) => {
          return (
            <HStack w='full'>
              <ParticipantBehaviourRecordingEditButton
                participantId={participantId}
                participantBehaviours={[ps.row.original.participantBehaviour]}
                recording={ps.row.original}
              />
              <ParticipantBehaviourRecordingDeleteButton
                participantId={participantId}
                participantBehaviourId={ps.row.original.participantBehaviourId}
                recordingId={ps.row.original.id}
              />
            </HStack>
          );
        },
      }),
    ];
  }, [participantId]);

  if (P.isEmpty(Object.values(orderedGroupedRecordings))) {
    return <Empty title='No recordings found' />;
  }

  return (
    <Stack spacing={8} h='full'>
      {orderedGroupedRecordings.map(([timestamp, recordings]) => (
        <Stack key={timestamp} py={0} spacing={0} borderBottomWidth='1px'>
          <FancyDate
            date={DateTime.fromISO(timestamp)}
            format={DateTime.DATE_SHORT}
            showHumanized={false}
            direction='row'
            fontSize='lg'
            fontWeight='semibold'
          />
          <DataTable
            columns={columns}
            data={recordings}
            size='sm'
            showHeader={false}
            showFooter={false}
          />
        </Stack>
      ))}
    </Stack>
  );
};
