import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import { type ParticipantBehaviour, isBehaviourDisabled } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaEye } from 'react-icons/fa';
import { showModal } from '../../../components/generic/Modal';
import { ParticipantBehaviourActionMenu } from './ParticipantBehaviourActionMenu';
import { ParticipantBehaviourDataSummary } from './ParticipantBehaviourDataSummary';
import { ParticipantBehaviourRecordingCreateButton } from './ParticipantBehaviourRecordingCreateButton';
import { ParticipantBehaviourRecordingsModal } from './ParticipantBehaviourRecordingsModal';
import { ParticipantBehaviourStatusTag } from './ParticipantBehaviourStatusTag';

interface Props {
  participantId: string;
  participantBehaviour: ParticipantBehaviour;
  isDisabled?: boolean;
}

export const ParticipantBehaviourCard = (props: Props) => {
  const { participantId, participantBehaviour, isDisabled } = props;

  return (
    <Card w='full' h='full'>
      <CardHeader>
        <Stack>
          <HStack align='stretch'>
            <Stack spacing={0}>
              <Heading size='md' noOfLines={1}>
                {participantBehaviour.name}
              </Heading>
            </Stack>
            <Spacer />
            <ParticipantBehaviourActionMenu
              participantBehaviour={participantBehaviour}
              isDisabled={isDisabled}
            />
          </HStack>
          <ParticipantBehaviourStatusTag status={participantBehaviour.status} w='fit-content' />
        </Stack>
      </CardHeader>

      <CardBody>
        <Stack spacing={4} h='full'>
          <ParticipantBehaviourDataSummary participantBehaviour={participantBehaviour} />
          <Spacer />
          <HStack w='full'>
            <Button
              leftIcon={<Icon as={FaEye} />}
              size='sm'
              w='full'
              onClick={() =>
                showModal(ParticipantBehaviourRecordingsModal, {
                  participantId,
                  participantBehaviour,
                })
              }
            >
              View recordings
            </Button>
            <ParticipantBehaviourRecordingCreateButton
              participantBehaviours={[participantBehaviour]}
              participantId={participantId}
              isDisabled={isDisabled || isBehaviourDisabled(participantBehaviour.status)}
              w='full'
            >
              Add recording
            </ParticipantBehaviourRecordingCreateButton>
          </HStack>
        </Stack>
      </CardBody>
    </Card>
  );
};
