import type { Incident } from '@piccolohealth/pbs-common';
import type { RowSelectionOptions } from '@piccolohealth/ui';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import type { IncidentsFilter } from '../../hooks/useIncidentsFilter';
import { createGqlQuery, createPaginatedGqlQuery } from '../fetcher';
import { Incidents } from '../queries/incidents.query';
import type * as Types from '../types';

export const getIncidentsBaseKey = (variables: Types.IncidentsQueryVariables) => [
  'incident',
  variables,
];

export const useIncidentsQuery = createGqlQuery<
  Types.IncidentsQueryVariables,
  Types.IncidentsQuery
>(getIncidentsBaseKey, Incidents);

export const useIncidentsFilterQuery = (filter: IncidentsFilter) => {
  const { organization } = useAppContext();
  const [selectedRows, onRowsSelect] = React.useState<Incident[]>([]);

  const query = createPaginatedGqlQuery<Types.IncidentsQueryVariables, Types.IncidentsQuery>(
    getIncidentsBaseKey,
    Incidents,
    {
      filter,
      getPaginationResponse: (response) => {
        return response?.organization?.incidents?.pagination;
      },
    },
  );

  const rowSelection: RowSelectionOptions<Incident> = {
    selectedRows,
    onRowsSelect,
  };

  const resp = query({
    organizationId: organization.id,
    request: {
      pagination: {
        offset: filter.currentPageNumber * filter.pageSize,
        limit: filter.pageSize,
      },
      timestamp: filter.timestampFilter,
      location: filter.locationFilter,
      participant: filter.participantFilter,
      severity: filter.severityFilter,
      status: filter.statusFilter,
      label: filter.labelFilter,
      start: filter.startDateFilter ? filter.startDateFilter : null,
      end: filter.endDateFilter ? filter.endDateFilter : null,
    },
  });

  const incidents = (resp.data?.organization?.incidents?.results as Incident[]) ?? [];

  return {
    ...resp,
    incidents,
    rowSelection,
  };
};
