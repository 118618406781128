import { Box, Stack, type StackProps } from '@chakra-ui/react';
import React from 'react';

export interface FormSectionProps extends StackProps {
  heading?: string | React.ReactElement;
  description?: string | React.ReactElement;
  layout?: 'vertical' | 'horizontal';
}

export const FormSection = (props: React.PropsWithChildren<FormSectionProps>) => {
  const { heading, description, layout, children, ...rest } = props;

  const stackProps: Partial<StackProps> = {
    direction: layout === 'horizontal' ? 'row' : 'column',
    align: layout === 'horizontal' ? 'center' : 'start',
    spacing: 6,
  };

  return (
    <Box w='full'>
      <Stack spacing={0} mb={4}>
        {heading && (
          <Box fontSize='lg' fontWeight='bold'>
            {heading}
          </Box>
        )}
        {description && (
          <Box fontSize='sm' color='secondary'>
            {description}
          </Box>
        )}
      </Stack>
      <Stack {...stackProps} {...rest}>
        {children}
      </Stack>
    </Box>
  );
};
