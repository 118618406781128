import { Box, type BoxProps, Flex, type FlexProps, forwardRef } from '@chakra-ui/react';
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import React from 'react';

interface Props extends BoxProps {
  scrollRef?: React.RefObject<HTMLDivElement>;
}

export const ScrollArea = forwardRef<Props, 'div'>(({ children, scrollRef, ...props }, ref) => (
  <Box as={ScrollAreaPrimitive.Root} ref={ref} pos='relative' h='full' overflow='hidden' {...props}>
    <Box
      ref={scrollRef}
      as={ScrollAreaPrimitive.Viewport}
      h='full'
      w='full'
      __css={{
        '> div': {
          height: '100%',
          display: 'block !important',
        },
      }}
    >
      {children}
    </Box>
    <ScrollBar />
    <ScrollAreaPrimitive.Corner />
  </Box>
));

export const ScrollBar = forwardRef<FlexProps, 'div'>(({ ...props }, ref) => (
  <Flex
    as={ScrollAreaPrimitive.ScrollAreaScrollbar}
    ref={ref}
    orientation='vertical'
    userSelect='none'
    h='full'
    w='9px'
    borderLeftWidth='1px'
    borderLeftColor='transparent'
    p='1px'
    bg='transparent'
    onMouseDown={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}
    {...props}
  >
    <Box
      as={ScrollAreaPrimitive.ScrollAreaThumb}
      pos='relative'
      flex='1'
      rounded='full'
      bg='gray.400'
    />
  </Flex>
));

export const ScrollAreaAutosize = forwardRef((props: BoxProps, ref) => {
  const { children, maxH, maxHeight, dir, ...rest } = props;

  return (
    <Flex {...rest} maxHeight={maxH ?? maxHeight}>
      <Flex flexDir='column' w='full' flex={1}>
        <ScrollArea dir={dir} ref={ref}>
          {children}
        </ScrollArea>
      </Flex>
    </Flex>
  );
});
