import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import type { ParticipantRestrictedPractice } from '@piccolohealth/pbs-common';
import { DescriptionList, FancyDate } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { ManageParticipantRestrictedPracticeLabelsControl } from './ManageParticipantRestrictedPracticeLabelsControl';
import { ParticipantRestrictedPracticeActionMenu } from './ParticipantRestrictedPracticeActionMenu';
import { RestrictedPracticeStatusTag } from './RestrictedPracticeStatusTag';
import { restrictedPracticeTypeOptions } from './RestrictedPracticeTypeTag';

interface Props {
  participantId: string;
  participantRestrictedPractice: ParticipantRestrictedPractice;
  isDisabled?: boolean;
}

export const ParticipantRestrictedPracticeCard = (props: Props) => {
  const { participantId, participantRestrictedPractice, isDisabled } = props;

  const text = restrictedPracticeTypeOptions[participantRestrictedPractice.type].label;

  const identifiedAt = DateTime.fromISO(participantRestrictedPractice.identifiedAt.toString());
  const startedAt = participantRestrictedPractice.startedAt
    ? DateTime.fromISO(participantRestrictedPractice.startedAt.toString())
    : null;
  const ceasedAt = participantRestrictedPractice.ceasedAt
    ? DateTime.fromISO(participantRestrictedPractice.ceasedAt.toString())
    : null;

  const items = [
    {
      title: 'Description',
      content: <Text>{participantRestrictedPractice.description}</Text>,
    },
    {
      title: 'Date Identified',
      content: (
        <FancyDate
          date={identifiedAt}
          format={DateTime.DATE_SHORT}
          showHumanized={false}
          direction='row'
        />
      ),
    },
    {
      title: 'Date Started',
      content: startedAt ? (
        <FancyDate
          date={startedAt}
          format={DateTime.DATE_SHORT}
          showHumanized={false}
          direction='row'
        />
      ) : (
        <Text>N/A</Text>
      ),
    },
    {
      title: 'Date Ceased',
      content: ceasedAt ? (
        <FancyDate
          date={ceasedAt}
          format={DateTime.DATE_SHORT}
          showHumanized={false}
          direction='row'
        />
      ) : (
        <Text>Still ongoing</Text>
      ),
    },

    {
      title: 'Labels',
      content: (
        <Box ml={-4}>
          <ManageParticipantRestrictedPracticeLabelsControl
            participantId={participantId}
            restrictedPracticeId={participantRestrictedPractice.id}
            value={participantRestrictedPractice.labels.map((l) => l.id)}
            isDisabled={isDisabled}
          />
        </Box>
      ),
    },
  ];

  return (
    <Card w='full' h='full'>
      <CardHeader>
        <Stack>
          <HStack align='stretch'>
            <Stack spacing={0}>
              <Heading size='md' noOfLines={1}>
                {text}
              </Heading>
            </Stack>
            <Spacer />
            <ParticipantRestrictedPracticeActionMenu
              participantId={participantId}
              participantRestrictedPractice={participantRestrictedPractice}
              isDisabled={isDisabled}
            />
          </HStack>
          <RestrictedPracticeStatusTag
            status={participantRestrictedPractice.status}
            w='fit-content'
          />
        </Stack>
      </CardHeader>

      <CardBody>
        <Stack spacing={4} h='full'>
          <DescriptionList items={items} fontSize='sm' />
        </Stack>
      </CardBody>
    </Card>
  );
};
