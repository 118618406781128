import { Divider } from '@chakra-ui/react';
import React from 'react';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { NotificationPreferencesForm } from './notifications/NotificationPreferencesForm';

export const NotificationsSettings = () => {
  return (
    <FormStack maxW='xl'>
      <FormSection heading='Notifications' description='Manage your notification preferences here'>
        <Divider />
        <NotificationPreferencesForm />
      </FormSection>
    </FormStack>
  );
};
