import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { type Participant, renderParticipantName } from '@piccolohealth/pbs-common';
import {
  PaginationSelectFooter,
  Select,
  SelectCheck,
  type SelectOption,
  type SelectProps,
  useDebouncedCallback,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { ParticipantStatusTag } from '../../features/participant/ParticipantStatusTag';
import { useParticipantsInfiniteQuery } from '../../graphql/hooks/useParticipantsQuery';
import type { ParticipantsQueryVariables } from '../../graphql/types';
import { useAppContext } from '../../hooks/useAppContext';
import { UserAvatar } from '../user/UserAvatar';

const DEBOUNCE_MS = 750;

export interface ParticipantChooserProps
  extends Omit<
    SelectProps<Participant, unknown>,
    'value' | 'onChange' | 'options' | 'selectedValues'
  > {
  value: Participant | null;
  onChange: (value: Participant | undefined) => void;
}

export const ParticipantChooser = (props: ParticipantChooserProps) => {
  const { value, onChange, isDisabled, ...rest } = props;
  const { organization } = useAppContext();

  const [inputValue, setInputValue] = React.useState('');

  const req: ParticipantsQueryVariables = {
    organizationId: organization.id,
    request: {
      name: inputValue,
      pagination: {
        limit: 10000,
      },
    },
  };
  const query = useParticipantsInfiniteQuery(req, {
    enabled: false,
    keepPreviousData: true,
    cacheTime: 0,
  });

  const isLoading = query.isFetching;
  const hasMore = query.hasNextPage ?? false;

  const pagination = P.first(query.data?.pages ?? [])?.organization?.participants?.pagination;

  const options: SelectOption<Participant>[] = React.useMemo(() => {
    return (query.data?.pages ?? [])
      .flatMap((page) => (page.organization?.participants.results as Participant[]) ?? [])
      .map((participant) => {
        return {
          value: participant.id,
          label: renderParticipantName(participant),
          raw: participant,
        };
      });
  }, [query.data?.pages]);

  const debouncedRefetch = useDebouncedCallback(query.refetch, DEBOUNCE_MS);

  const onChangePrime = React.useCallback(
    (value: SelectOption<Participant> | null) => {
      onChange(value?.raw ?? undefined);
      debouncedRefetch();
    },
    [debouncedRefetch, onChange],
  );

  const onInputChangePrime = React.useCallback(
    (value: string) => {
      setInputValue(value);
      debouncedRefetch();
    },
    [debouncedRefetch],
  );

  const selectedValue = React.useMemo(() => {
    if (!value) {
      return null;
    }

    return {
      value: value.id,
      label: renderParticipantName(value),
      raw: value,
    };
  }, [value]);

  const onOpen = React.useCallback(() => {
    if (!query.isFetched) {
      query.refetch();
    }
  }, [query]);

  return (
    <Select
      value={selectedValue}
      inputValue={inputValue}
      isLoading={isLoading}
      options={options}
      onChange={onChangePrime}
      onInputChange={onInputChangePrime}
      onOpen={onOpen}
      {...rest}
      components={{
        Option: (props) => {
          const { location, picture, status } = props.option.raw;
          const name = renderParticipantName(props.option.raw);

          return (
            <HStack spacing={2} py={1} fontSize='sm' w='full'>
              <UserAvatar
                secondary={location?.name}
                picture={picture}
                name={name}
                w='30px'
                h='30px'
                showTooltip={false}
              />
              <Stack spacing={1} lineHeight={1} align='start'>
                <Text fontWeight='semibold'>{name}</Text>
                {location && (
                  <Text fontSize='xs' color='secondary'>
                    {location?.name}
                  </Text>
                )}
              </Stack>
              <Spacer />
              <ParticipantStatusTag status={status} size='sm' />
              <SelectCheck />
            </HStack>
          );
        },
        Value: (props) => {
          const { location, picture } = props.option.raw;
          const name = renderParticipantName(props.option.raw);
          return (
            <HStack spacing={2} fontSize='sm' w='full'>
              <UserAvatar
                secondary={location?.name}
                picture={picture}
                name={name}
                w='20px'
                h='20px'
                showTooltip={false}
              />
              <Text>{name}</Text>
            </HStack>
          );
        },
        Footer: () =>
          pagination ? (
            <PaginationSelectFooter
              items='participant'
              total={pagination.total}
              isLoading={isLoading}
              fetchNextPage={query.fetchNextPage}
              hasMore={hasMore}
            />
          ) : null,
      }}
    />
  );
};
