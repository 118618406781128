import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import type { NodeRangeSelection } from '@tiptap-pro/extension-node-range';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { AddNodeMenu } from './AddNodeMenu';
import { useDragHandle } from './DragHandle';
import { DragHandleMenu } from './DragHandleMenu';

type Props = {
  editor: Editor;
};

export const DragAndPlusButton = (props: Props) => {
  const { editor } = props;

  const dragHandleDisclosure = useDisclosure();
  const addNodeDisclosure = useDisclosure();
  const [selection, setSelection] = React.useState<NodeRangeSelection | null>(null);
  const [isLocked, setIsLocked] = React.useState(false);

  React.useEffect(() => {
    dragHandleDisclosure.isOpen || addNodeDisclosure.isOpen
      ? setIsLocked(true)
      : setIsLocked(false);
  }, [dragHandleDisclosure.isOpen, addNodeDisclosure.isOpen]);

  const onOpenAddNodeMenu = React.useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        if (selection) {
          editor
            .chain()
            .setTextSelection(selection.from + 1)
            .run();
        }
        addNodeDisclosure.onOpen();
      } else {
        addNodeDisclosure.onClose();
      }
    },
    [addNodeDisclosure, editor, selection],
  );

  const onOpenDragHandleMenu = React.useCallback(
    (isOpen: boolean) => {
      isOpen ? dragHandleDisclosure.onOpen() : dragHandleDisclosure.onClose();
    },
    [dragHandleDisclosure],
  );

  const onClickRemoveFormatting = React.useCallback(() => {
    if (!selection) {
      return;
    }

    editor.chain().focus().setNodeSelection(selection.from).unsetAllMarks().setParagraph().run();
  }, [editor, selection]);

  const onClickDelete = React.useCallback(() => {
    if (!selection) {
      return;
    }

    editor.chain().focus().deleteRange({ from: selection.from, to: selection.to }).run();
  }, [editor, selection]);

  const childrenRef = React.useRef<HTMLDivElement>(null);
  const dragHandleRef = React.useRef<HTMLDivElement>(null);

  const dragHandle = useDragHandle({
    editor,
    onSelection: setSelection,
    isLocked,
    childrenRef,
    dragHandleRef,
  });

  return (
    <HStack spacing={1} ref={childrenRef} lineHeight='normal'>
      <AddNodeMenu editor={editor} isOpen={addNodeDisclosure.isOpen} setOpen={onOpenAddNodeMenu} />
      <Box
        draggable
        ref={dragHandleRef}
        onDragStart={dragHandle.onDragStart}
        onClick={dragHandle.onDragClick}
      >
        <DragHandleMenu
          isOpen={dragHandleDisclosure.isOpen}
          setOpen={onOpenDragHandleMenu}
          onClickRemoveFormatting={onClickRemoveFormatting}
          onClickDelete={onClickDelete}
        />
      </Box>
    </HStack>
  );
};
