import { Divider, Flex, SkeletonText, Stack } from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import { MotionSlideOut } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { PAGE_WIDTH } from '../utils';
import { ConnectedContent } from './ConnectedContent';
import { ConnectingContent } from './ConnectingContent';
import { DisconnectedContent } from './DisconnectedContent';

interface Props {
  documentId: string;
  status: WebSocketStatus;
  isSidebarOpen: boolean;
  headerContent: React.ReactNode;
  toolbarContent: React.ReactNode;
  mainContent: React.ReactNode;
  sidebarContent: React.ReactNode;
}

export const EditorLayout = (props: Props) => {
  const headerContent = P.run(() => {
    switch (props.status) {
      case WebSocketStatus.Connected:
        return props.headerContent;
      case WebSocketStatus.Connecting:
        return <SkeletonText mt={2} w='full' mb={8} noOfLines={1} skeletonHeight='36px' />;
      case WebSocketStatus.Disconnected:
        return props.headerContent;
    }
  });

  const toolbarContent = P.run(() => {
    switch (props.status) {
      case WebSocketStatus.Connected:
        return props.toolbarContent;
      case WebSocketStatus.Connecting:
        return null;
      case WebSocketStatus.Disconnected:
        return null;
    }
  });

  const mainContent = P.run(() => {
    switch (props.status) {
      case WebSocketStatus.Connected:
        return (
          <ConnectedContent documentId={props.documentId}>{props.mainContent}</ConnectedContent>
        );
      case WebSocketStatus.Connecting:
        return <ConnectingContent />;
      case WebSocketStatus.Disconnected:
        return <DisconnectedContent />;
    }
  });

  const sidebarContent = P.run(() => {
    switch (props.status) {
      case WebSocketStatus.Connected:
        return props.sidebarContent;
      case WebSocketStatus.Connecting:
        return <SkeletonText px={4} py={4} />;
      case WebSocketStatus.Disconnected:
        return null;
    }
  });

  return (
    <Flex flexDir='row' w='full' h='full'>
      <Stack w='full' spacing={2}>
        <Stack
          w='full'
          h='auto'
          flexShrink={0}
          maxW={PAGE_WIDTH}
          alignSelf='center'
          spacing={4}
          mt={2}
          px={6}
        >
          {headerContent}
          {toolbarContent ? toolbarContent : <Divider />}
        </Stack>
        {mainContent}
      </Stack>

      <MotionSlideOut isOpen={props.isSidebarOpen}>
        <Stack w='300px' flexShrink={0} borderLeftWidth='1px' borderColor='gray.200' h='full'>
          {sidebarContent}
        </Stack>
      </MotionSlideOut>
    </Flex>
  );
};
