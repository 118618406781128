import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateParticipantFieldGroupTemplate } from '../mutations/updateParticipantFieldGroupTemplate.mutation';
import type {
  UpdateParticipantFieldGroupTemplateMutation,
  UpdateParticipantFieldGroupTemplateMutationVariables,
} from '../types';

export const useUpdateParticipantFieldGroupTemplateMutation = (
  options?: MutationOptions<
    UpdateParticipantFieldGroupTemplateMutation,
    UpdateParticipantFieldGroupTemplateMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateParticipantFieldGroupTemplate), {
    ...options,

    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participantFieldGroupTemplates'),
      });
    },
  });
};
