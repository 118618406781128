import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { UpdateOrganization } from '../mutations/updateOrganization.mutation';
import type { UpdateOrganizationMutation, UpdateOrganizationMutationVariables } from '../types';

export const useUpdateOrganizationMutation = (
  options?: MutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateOrganization), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('organization'),
      });
    },
  });
};
