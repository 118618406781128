import {
  Button,
  type ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import type { ParticipantDocument } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown, FaHistory, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ParticipantDocumentDeleteModal } from './ParticipantDocumentDeleteModal';
import { ParticipantDocumentEditModal } from './ParticipantDocumentEditModal';
import { ParticipantDocumentHistoryModal } from './ParticipantDocumentHistoryModal';
import { ParticipantDocumentPdfMenuItem } from './ParticipantDocumentPdfMenuItem';

interface Props extends ButtonProps {
  participantDocument: ParticipantDocument;
}

export const ParticipantDocumentActionMenu = (props: Props) => {
  const { participantDocument, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior='unmount' size='sm'>
      <MenuButton
        as={Button}
        variant='link'
        size='sm'
        rightIcon={<Icon as={FaCaretDown} />}
        flexShrink={0}
        {...rest}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuGroup title='PDFs'>
          <ParticipantDocumentPdfMenuItem participantDocument={participantDocument} />
        </MenuGroup>
        <MenuGroup title='Document'>
          <MenuItem
            icon={<FaHistory />}
            onClick={() =>
              showModal(ParticipantDocumentHistoryModal, {
                document: participantDocument,
              })
            }
          >
            View version history
          </MenuItem>
          <MenuItem
            icon={<FaPencilAlt />}
            onClick={() =>
              showModal(ParticipantDocumentEditModal, {
                document: participantDocument,
              })
            }
          >
            Edit
          </MenuItem>
          <MenuItem
            color='danger'
            icon={<FaRegTrashAlt />}
            onClick={() =>
              showModal(ParticipantDocumentDeleteModal, {
                participantId: participantDocument.participantId,
                participantDocumentId: participantDocument.id,
              })
            }
          >
            Delete
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
