import { type Label, LabelType } from '@piccolohealth/pbs-common';
import type { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useCreateParticipantFileLabelMutation } from '../../graphql/hooks/useCreateParticipantFileLabelMutation';
import { useDeleteParticipantFileLabelMutation } from '../../graphql/hooks/useDeleteParticipantFileLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { LabelsControl } from './LabelsControl';

export interface ManageParticipantFileLabelsControlProps {
  participantId: string;
  participantFileId: string;
  value: string[];
  isDisabled?: boolean;
}

export const ManageParticipantFileLabelsControl = (
  props: ManageParticipantFileLabelsControlProps,
) => {
  const { participantId, participantFileId, value, isDisabled } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const createParticipantFileLabelMutation = useCreateParticipantFileLabelMutation({
    retry: false,
  });
  const deleteParticipantFileLabelMutation = useDeleteParticipantFileLabelMutation({
    retry: false,
  });

  const labelManagePermission = usePermission('manage', 'label');
  const participantFileLabelManagePermission = usePermission('manage', 'participantFileLabel');

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type: LabelType.ParticipantDocument,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label ${err.message}`));
        }
        case 'ADD': {
          return createParticipantFileLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              participantFileId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error adding label to participant file: ${err.message}`),
            );
        }

        case 'REMOVE': {
          return deleteParticipantFileLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              participantFileId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error removing label from participant file: ${err.message}`),
            );
        }
      }
    },
    [
      createLabelMutation,
      organization.id,
      errorToast,
      createParticipantFileLabelMutation,
      participantId,
      participantFileId,
      deleteParticipantFileLabelMutation,
    ],
  );

  return (
    <LabelsControl
      onChange={onChange}
      value={value}
      isDisabled={isDisabled || !participantFileLabelManagePermission}
      canManage={labelManagePermission}
      variant='selectGhost'
      type={LabelType.ParticipantDocument}
    />
  );
};
