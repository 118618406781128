import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteIncidentFile } from '../mutations/deleteIncidentFile.mutation';
import type { DeleteIncidentFileMutation, DeleteIncidentFileMutationVariables } from '../types';

export const useDeleteIncidentFileMutation = (
  options?: MutationOptions<DeleteIncidentFileMutation, DeleteIncidentFileMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteIncidentFile), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('incident'),
      });
    },
  });
};
