import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { type Form, FormType, type UpdateFormRequest } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateFormMutation } from '../../../graphql/hooks/useUpdateFormMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { FormForm } from './FormForm';

type FormValues = UpdateFormRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string(),
  type: z.nativeEnum(FormType),
  url: z.string().nullish(),
  labelMappings: z.object({
    create: z.array(z.string()),
    update: z.array(z.string()),
  }),
});

interface Props {
  form: Form;
}

export const FormUpdateModal = createModal<Props>((props) => {
  const { form, modal } = props;
  const { errorToast, successToast, organization } = useAppContext();

  const { hide, visible } = modal;

  const initialValues: FormValues = {
    name: form.name,
    type: form.type,
    url: form.url,
    labelMappings: {
      create: form.labelMappings.create.map((label) => label.id),
      update: form.labelMappings.update.map((label) => label.id),
    },
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });
  const type = methods.watch('type') as FormType;

  const mutation = useUpdateFormMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          formId: form.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Form updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating form: ${err.message}`);
        });
    },
    [mutation, organization.id, form.id, hide, successToast, errorToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align='start'>
                <Box>
                  <Heading size='md'>Edit a form</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <FormForm type={type} />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size='sm' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
