import { Box, Button, Divider, HStack, Input, Spacer, Stack, Text } from '@chakra-ui/react';
import { type Label, LabelType, type Location } from '@piccolohealth/pbs-common';
import {
  FloatingPopover,
  MultiSelect,
  type MultiSelectOption,
  NumberedBadge,
  type OnChangeRequest,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretDown, FaFilter } from 'react-icons/fa';
import { FormItem } from '../../components/forms/FormItem';
import { FormStack } from '../../components/forms/FormStack';
import { showModal } from '../../components/generic/Modal';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useOrganizationQuery } from '../../graphql/hooks/useOrganizationQuery';
import { useAppContext } from '../../hooks/useAppContext';
import type { UserParticipantsFilter } from '../../hooks/useUserParticipantsFilter';
import { UserParticipantAddModal } from './UserParticipantAddModal';

interface Props {
  filter: UserParticipantsFilter;
}

export const UserParticipantNameFilterControl = (props: Props) => {
  const [value, setValue] = React.useState(props.filter.nameFilter);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      props.filter.onNameFilter(value);
    },
    [props.filter],
  );

  React.useEffect(() => {
    setValue(props.filter.nameFilter);
  }, [props.filter.nameFilter]);

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder='All participants'
      size='sm'
    />
  );
};

const UserParticipantLabelFilterControl = (props: Props) => {
  const { organization } = useAppContext();
  const { data, isLoading } = useLabelsQuery({
    organizationId: organization.id,
    type: LabelType.ParticipantUser,
  });

  const options: MultiSelectOption<Label>[] = React.useMemo(() => {
    const labels = (data?.organization?.labels as Label[]) ?? [];
    return labels.map((label) => {
      return {
        label: label.name,
        value: label.id,
        raw: label,
        color: label.color,
      };
    });
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Label>[] = React.useMemo(() => {
    return (props.filter.labelFilter ?? []).flatMap((label) => {
      return options.find((o) => P.isEqual(o.raw.id, label)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Label>) => {
      props.filter.onLabelFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All labels'
      optionVariant='tag'
    />
  );
};

const UserParticipantLocationFilterControl = (props: Props) => {
  const { organization } = useAppContext();

  const { isLoading, data } = useOrganizationQuery({
    organizationId: organization.id,
  });

  const options: MultiSelectOption<Location>[] = React.useMemo(() => {
    const locations = data?.organization?.locations ?? [];

    return locations.map((location) => {
      return {
        label: location.name,
        value: location.id,
        raw: location,
        color: location.color,
      };
    });
  }, [data?.organization?.locations]);

  const selectedValues: MultiSelectOption<Location>[] = React.useMemo(() => {
    return (props.filter.locationFilter ?? []).flatMap((location) => {
      return options.find((o) => P.isEqual(o.raw.id, location)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Location>) => {
      props.filter.onLocationFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size='sm'
      placeholder='All locations'
      optionVariant='tag'
    />
  );
};

export const UserParticipantsFilterControls = (props: Props) => {
  return (
    <FormStack orientation='horizontal'>
      <Box maxW='2xs'>
        <UserParticipantNameFilterControl filter={props.filter} />
      </Box>

      <FloatingPopover
        isPortal
        render={() => (
          <Stack bg='white' spacing={0} w='xs' shadow='popover' rounded='md' overflow='hidden'>
            <HStack py={2} px={4} borderBottomWidth='1px'>
              <Text fontWeight='semibold'>Filters</Text>
              <Spacer />
              <NumberedBadge count={props.filter.activeFilterCount} />
            </HStack>
            <FormStack py={4} px={4} orientation='vertical'>
              <FormItem label='User labels'>
                <UserParticipantLabelFilterControl filter={props.filter} />
              </FormItem>
              <FormItem label='Location'>
                <UserParticipantLocationFilterControl filter={props.filter} />
              </FormItem>

              <Divider />

              <Button
                w='fit-content'
                size='sm'
                variant='outline'
                isDisabled={props.filter.activeFilterCount === 0}
                onClick={props.filter.reset}
              >
                Reset
              </Button>
            </FormStack>
          </Stack>
        )}
      >
        <Button
          size='sm'
          variant='outline'
          lineHeight='unset'
          leftIcon={<FaFilter />}
          rightIcon={<FaCaretDown />}
        >
          <HStack>
            <Text>Filter</Text>
            <Box w='28px'>
              <NumberedBadge count={props.filter.activeFilterCount} />
            </Box>
          </HStack>
        </Button>
      </FloatingPopover>
      <Spacer />
      <Button
        size='sm'
        colorScheme='purple'
        onClick={() => showModal(UserParticipantAddModal, { user: props.filter.user })}
      >
        Add participants
      </Button>
    </FormStack>
  );
};
