import { Button, HStack, IconButton, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import type { FeedItem } from '@knocklabs/client';
import { FilterStatus, useKnockFeed } from '@knocklabs/react';
import type { NotificationPayload } from '@piccolohealth/pbs-common';
import { ChakraV3, NumberedBadge, ScrollArea, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaArchive, FaCheck } from 'react-icons/fa';
import { NotificationItem } from './NotificationItem';

interface Props {
  onClose: () => void;
}

export const NotificationCenterContents = (props: Props) => {
  const { onClose } = props;

  const { useFeedStore, feedClient } = useKnockFeed();
  const feedStore = useFeedStore();
  const notifications = feedStore.items as FeedItem<NotificationPayload>[];
  const isLoading = feedStore.loading;
  const unseenCount = feedStore.metadata.unseen_count;
  const hasNextPage = feedStore.metadata.total_count > feedStore.items.length;

  React.useEffect(() => {
    // When the feed client changes, or the status changes issue a re-fetch
    feedClient.fetch({ status: FilterStatus.All });
  }, [feedClient]);

  const markAllNotificationsAsRead = React.useCallback(() => {
    return feedClient.markAllAsRead();
  }, [feedClient]);

  const markAllNotificationsAsArchived = React.useCallback(() => {
    return feedClient.markAllAsArchived();
  }, [feedClient]);

  const fetchNextPage = React.useCallback(() => {
    return feedClient.fetchNextPage();
  }, [feedClient]);

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (P.isEmpty(notifications)) {
      return (
        <ChakraV3.Result
          h='full'
          status='success'
          title={`You're all caught up`}
          description={
            <Text fontSize='sm' color='secondary'>
              You will be notified here for, page activity, requests, and invites.
            </Text>
          }
        />
      );
    }

    return (
      <Stack spacing='1px' bg='gray.200'>
        {notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} onClose={onClose} />
        ))}
        {hasNextPage && (
          <HStack justify='center' bg='white' px={6} py={2}>
            <Button variant='link' onClick={fetchNextPage}>
              Load more
            </Button>
          </HStack>
        )}
      </Stack>
    );
  });

  return (
    <Stack h='2xl' w='lg' bg='white' spacing={0} shadow='popover' rounded='xl' overflow='hidden'>
      <HStack px={6} py={3} borderBottomWidth='1px'>
        <HStack>
          <Text fontSize='xl' fontWeight='bold'>
            Notifications
          </Text>
          <NumberedBadge count={unseenCount} />
        </HStack>
        <Spacer />
        <Tooltip label='Mark all notifications as read'>
          <IconButton
            aria-label='Mark all notifications as read'
            size='sm'
            variant='ghost'
            icon={<FaCheck />}
            color='gray.500'
            onClick={markAllNotificationsAsRead}
            isDisabled={isLoading || P.isEmpty(notifications)}
          />
        </Tooltip>
        <Tooltip label='Archive all notifications'>
          <IconButton
            aria-label='Archive all notifications'
            size='sm'
            variant='ghost'
            icon={<FaArchive />}
            color='gray.500'
            onClick={markAllNotificationsAsArchived}
            isDisabled={isLoading || P.isEmpty(notifications)}
          />
        </Tooltip>
      </HStack>
      <ScrollArea h='full'>{content}</ScrollArea>
    </Stack>
  );
};
