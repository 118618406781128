import { Text, Wrap } from '@chakra-ui/react';
import type { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import { DescriptionList } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { MethodTag } from './MethodTag';

interface Props {
  participantBehaviour: ParticipantBehaviour;
}

export const ParticipantBehaviourDataSummary = (props: Props) => {
  const { participantBehaviour } = props;

  const methods = [
    participantBehaviour.methods.abc,
    participantBehaviour.methods.duration,
    participantBehaviour.methods.frequency,
    participantBehaviour.methods.episodicSeverity,
  ].filter((method) => method.enabled);

  const items = [
    {
      title: 'Description',
      content: (
        <Text fontSize='sm' noOfLines={2}>
          {participantBehaviour.description}
        </Text>
      ),
    },
    {
      title: 'Example',
      content: (
        <Text fontSize='sm' noOfLines={2}>
          {participantBehaviour.example}
        </Text>
      ),
    },
    {
      title: 'Non-example',
      content: (
        <Text fontSize='sm' noOfLines={2}>
          {participantBehaviour.nonExample}
        </Text>
      ),
    },
    {
      title: 'Data methods',
      content: P.isEmpty(methods) ? (
        <Text fontSize='sm'>No methods enabled</Text>
      ) : (
        <Wrap>
          {methods.map((method) => (
            <MethodTag key={`${participantBehaviour.id}-${method.__typename}`} method={method} />
          ))}
        </Wrap>
      ),
    },
  ];

  return <DescriptionList items={items} />;
};
