import { Box, type BoxProps, forwardRef } from '@chakra-ui/react';
import { CSS, type Transform } from '@dnd-kit/utilities';
import React from 'react';

interface Props extends Omit<BoxProps, 'transform'> {
  transform: Transform | null;
  transition: string | undefined;
}

export const Sortable = forwardRef((props: Props, ref) => {
  const { transform, transition, children, ...rest } = props;

  return (
    <Box ref={ref} transform={CSS.Transform.toString(transform)} transition={transition} {...rest}>
      {children}
    </Box>
  );
});
