import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { CreateParticipantQrLabel } from '../mutations/createParticipantQRLabel.mutation';
import type {
  CreateParticipantQrLabelMutation,
  CreateParticipantQrLabelMutationVariables,
} from '../types';

export const useCreateParticipantQRLabelMutation = (
  options?: MutationOptions<
    CreateParticipantQrLabelMutation,
    CreateParticipantQrLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantQrLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
