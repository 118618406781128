import { Button, type ButtonProps, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import type { ParticipantDocument, ParticipantFile } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { ParticipantFilesAndDocumentsDeleteMenuItem } from './ParticipantFilesAndDocumentsDeleteMenuItem';

interface Props extends Omit<ButtonProps, 'filter'> {
  participantId: string;
  selectedRows: (ParticipantFile | ParticipantDocument)[];
}

export const ParticipantFilesAndDocumentsActionMenu = (props: Props) => {
  const { participantId, selectedRows, ...rest } = props;
  return (
    <Menu isLazy lazyBehavior='keepMounted'>
      <MenuList>
        <ParticipantFilesAndDocumentsDeleteMenuItem
          participantId={participantId}
          selectedRows={selectedRows}
        />
      </MenuList>
      <MenuButton
        as={Button}
        size='sm'
        variant='outline'
        rightIcon={<Icon as={FaAngleDown} />}
        {...rest}
      >
        Actions
      </MenuButton>
    </Menu>
  );
};
