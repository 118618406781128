import { HStack } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import type { Reaction } from '../../extensions/comment/Comment';
import { CommentReactionButton } from './CommentReactionButton';

interface Props {
  reactions: Reaction[];
  isDisabled?: boolean;
  onAddReaction: (id: string) => void;
  onRemoveReaction: (id: string) => void;
}

export const CommentReactionsList = (props: Props) => {
  const { reactions, isDisabled, onAddReaction, onRemoveReaction } = props;
  const groupedReactions = P.groupBy(reactions ?? [], (reaction) => reaction.id);

  return (
    <HStack spacing={0}>
      {P.listify(groupedReactions, (reactions, id) => (
        <CommentReactionButton
          key={id}
          id={id}
          isDisabled={isDisabled}
          reactions={reactions ?? []}
          onAddReaction={onAddReaction}
          onRemoveReaction={onRemoveReaction}
        />
      ))}
    </HStack>
  );
};
