import { Async, P, prettyBytes } from '@piccolohealth/util';
import React from 'react';
import { useMap } from 'react-use';

export type FileUploadStatus = 'idle' | 'uploading' | 'errored' | 'finished';

export interface FileUploadProgress {
  id: string;
  file: File;
  status: FileUploadStatus;
  elapsed?: number;
  percentage: number;
  total?: number;
  loaded?: number;
  error?: string;
}

export interface UseFileUploadConfig {
  sendFile: (
    file: File,
    onProgress: (opts: { percentage: number; total?: number; loaded?: number }) => void,
  ) => Promise<void>;
  concurrency: number;
  autoStart: boolean;
  maxFileSize?: number;
}

export const useFileUpload = (config: UseFileUploadConfig) => {
  const { sendFile, concurrency, autoStart, maxFileSize } = config;
  const [files, setFiles] = React.useState<File[]>([]);
  const [fileProgress, { reset, set }] = useMap<Record<number, FileUploadProgress>>();

  const resetFiles = React.useCallback(() => {
    reset();
    setFiles([]);
  }, [reset]);

  const onFilesChange = React.useCallback(
    (files: File[]) => {
      reset();
      setFiles(files);

      files.forEach((file, index) => {
        set(index, {
          id: `${index}-${file.name}`,
          file,

          percentage: 0,
          status: 'idle',
        });
      });
    },
    [reset, set],
  );

  const sendFilePrime = React.useCallback(
    async (file: File, index: number) => {
      const id = `${index}-${file.name}`;

      if (!P.isNil(maxFileSize) && file.size > maxFileSize) {
        set(index, {
          id,
          file,
          elapsed: 0,
          percentage: 100,
          status: 'errored',
          error: `File is too large. ${prettyBytes(
            file.size,
          )} is larger than maximum file size ${prettyBytes(maxFileSize)}.`,
        });
        return;
      }

      const start = Date.now();

      const onProgress = (
        index: number,
        id: string,
        file: File,
        percentage: number,
        total?: number,
        loaded?: number,
      ) => {
        set(index, {
          id,
          file,
          elapsed: Date.now() - start,
          total,
          loaded,
          percentage,
          status: 'uploading',
        });
      };

      set(index, {
        id,
        file,
        elapsed: 0,
        percentage: 0,
        status: 'uploading',
      });

      await sendFile(file, (opts) =>
        onProgress(index, id, file, opts.percentage, opts.total, opts.loaded),
      )
        .then(() => {
          set(index, {
            id,
            file,
            elapsed: Date.now() - start,
            percentage: 100,
            total: file.size,
            loaded: file.size,
            status: 'finished',
          });
        })
        .catch((err) => {
          set(index, {
            id,
            file,
            elapsed: Date.now() - start,
            percentage: 100,
            error: err.message,
            status: 'errored',
          });
        });
    },
    [sendFile, set, maxFileSize],
  );

  const start = React.useCallback(() => {
    Async.map(files, (file, index) => sendFilePrime(file, index), { concurrency });
  }, [concurrency, files, sendFilePrime]);

  React.useEffect(() => {
    if (autoStart && files.length > 0) {
      start();
    }
  }, [autoStart, files, start]);

  const status: FileUploadStatus = React.useMemo(() => {
    const fileProgressValues = Object.values(fileProgress);

    if (
      P.isEmpty(fileProgressValues) ||
      fileProgressValues.every(({ status }) => status === 'idle')
    ) {
      return 'idle';
    }

    if (fileProgressValues.every(({ status }) => status === 'finished')) {
      return 'finished';
    }

    if (fileProgressValues.some(({ status }) => status === 'errored')) {
      return 'errored';
    }

    if (fileProgressValues.some(({ status }) => status === 'uploading')) {
      return 'uploading';
    }

    return 'errored';
  }, [fileProgress]);

  return {
    files,
    fileProgress,
    onFilesChange,
    reset: resetFiles,
    start,
    status,
  };
};

export type UseFileUploaderReturn = ReturnType<typeof useFileUpload>;
