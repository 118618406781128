import type { User } from '@piccolohealth/pbs-common';
import type { RowSelectionOptions } from '@piccolohealth/ui';
import { useInfiniteQuery } from '@tanstack/react-query';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import type { UsersFilter } from '../../hooks/useUsersFilter';
import { type InfiniteQueryOptions, createGqlQuery, createPaginatedGqlQuery } from '../fetcher';
import { Users } from '../queries/users.query';
import type * as Types from '../types';

export const getUsersBaseKey = (variables: Types.UsersQueryVariables) => ['user', variables];

export const useUsersQuery = createGqlQuery<Types.UsersQueryVariables, Types.UsersQuery>(
  getUsersBaseKey,
  Users,
);

export const useUsersFilterQuery = (filter: UsersFilter) => {
  const { organization } = useAppContext();
  const [selectedRows, onRowsSelect] = React.useState<User[]>([]);

  const query = createPaginatedGqlQuery<Types.UsersQueryVariables, Types.UsersQuery>(
    getUsersBaseKey,
    Users,
    {
      filter,
      getPaginationResponse: (response) => {
        return response?.organization?.users?.pagination;
      },
    },
  );

  const rowSelection: RowSelectionOptions<User> = {
    selectedRows,
    onRowsSelect,
  };

  const resp = query({
    organizationId: organization.id,
    request: {
      pagination: {
        offset: filter.currentPageNumber * filter.pageSize,
        limit: filter.pageSize,
      },
      name: filter.nameFilter,
    },
  });

  const users = (resp.data?.organization?.users?.results as User[]) ?? [];

  return {
    ...resp,
    users,
    rowSelection,
  };
};

export const useUsersInfiniteQuery = (
  variables: Types.UsersQueryVariables,
  options?: InfiniteQueryOptions<Types.UsersQuery>,
) => {
  const fetcher = useUsersQuery.getFetcher();

  const query = useInfiniteQuery(
    useUsersQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage?.organization?.users?.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );

  return query;
};
