import type { ParticipantStatus } from '@piccolohealth/pbs-common';
import {
  arrayOfParam,
  dateTimeParam,
  numberParam,
  stringParam,
  useDebouncedCallback,
  useQueryParams,
} from '@piccolohealth/ui';
import { type DateTime, P } from '@piccolohealth/util';
import type { PaginationFilter } from '../graphql/fetcher';

const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_NAME_FILTER = '';
const DEFAULT_NDIS_NUMBER_FILTER = '';
const DEFAULT_LOCATION_FILTER: string[] = [];
const DEFAULT_STATUS_FILTER: ParticipantStatus[] = [];
const DEFAULT_ASSIGNED_TO_FILTER: string[] = [];
const DEFAULT_LABEL_FILTER: string[] = [];
const DEFAULT_START_DATE_FILTER = undefined;
const DEFAULT_END_DATE_FILTER = undefined;

export interface ParticipantsFilter extends PaginationFilter {
  nameFilter: string;
  ndisNumberFilter: string;
  locationFilter: string[] | undefined;
  statusFilter: ParticipantStatus[] | undefined;
  assignedToFilter: string[] | undefined;
  labelFilter: string[] | undefined;
  startDateFilter: DateTime | undefined;
  endDateFilter: DateTime | undefined;
  activeFilterCount: number;
  onNameFilter: (name: string) => void;
  onNdisNumberFilter: (ndisNumber: string) => void;
  onLocationFilter: (locations?: string[]) => void;
  onStatusFilter: (statuses?: ParticipantStatus[]) => void;
  onAssignedToFilter: (assignedTo?: string[]) => void;
  onLabelFilter: (labels?: string[]) => void;
  onStartDateFilter: (start?: DateTime) => void;
  onEndDateFilter: (end?: DateTime) => void;
  reset: () => void;
}

export const useParticipantsFilter: () => ParticipantsFilter = () => {
  const [params, setParams] = useQueryParams({
    page: numberParam,
    pageSize: numberParam,
    name: stringParam,
    ndisNumber: stringParam,
    location: arrayOfParam(stringParam, { delimiter: ',' }),
    status: arrayOfParam(stringParam, { delimiter: ',' }),
    label: arrayOfParam(stringParam, { delimiter: ',' }),
    assignedTo: arrayOfParam(stringParam, { delimiter: ',' }),
    start: dateTimeParam,
    end: dateTimeParam,
  });

  const setCurrentPageNumber = (value: number) =>
    setParams({
      page: value,
    });

  const setPageSize = (value: number) =>
    setParams({
      pageSize: value,
      page: 0,
    });

  const onNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        name: value,
        page: 0,
      }),
    1000,
  );

  const onNdisNumberFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        ndisNumber: value,
        page: 0,
      }),
    1000,
  );

  const onLocationFilter = (value?: string[]) =>
    setParams({
      location: value,
      page: 0,
    });

  const onStatusFilter = (value?: ParticipantStatus[]) =>
    setParams({
      status: value,
      page: 0,
    });

  const onAssignedToFilter = (value?: string[]) =>
    setParams({
      assignedTo: value,
      page: 0,
    });

  const onLabelFilter = (value?: string[]) =>
    setParams({
      label: value,
      page: 0,
    });

  const onStartDateFilter = (value?: DateTime) =>
    setParams({
      start: value,
      page: 0,
    });

  const onEndDateFilter = (value?: DateTime) =>
    setParams({
      end: value,
      page: 0,
    });

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} participants`;

  const reset = () => {
    setParams({
      page: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      name: DEFAULT_NAME_FILTER,
      ndisNumber: DEFAULT_NDIS_NUMBER_FILTER,
      location: DEFAULT_LOCATION_FILTER,
      status: DEFAULT_STATUS_FILTER,
      assignedTo: DEFAULT_ASSIGNED_TO_FILTER,
      label: DEFAULT_LABEL_FILTER,
      start: DEFAULT_START_DATE_FILTER,
      end: DEFAULT_END_DATE_FILTER,
    });
  };

  const activeFilterCount = P.compact([
    params.name,
    params.ndisNumber,
    params.location,
    params.status,
    params.assignedTo,
    params.label,
    params.start,
    params.end,
  ]).length;

  return {
    pageSizeOptions: [10, 20, 50, 100],
    nameFilter: params.name ?? DEFAULT_NAME_FILTER,
    ndisNumberFilter: params.ndisNumber ?? DEFAULT_NDIS_NUMBER_FILTER,
    locationFilter: params.location ?? DEFAULT_LOCATION_FILTER,
    statusFilter: (params.status as ParticipantStatus[]) ?? DEFAULT_STATUS_FILTER,
    assignedToFilter: (params.assignedTo as string[]) ?? DEFAULT_ASSIGNED_TO_FILTER,
    labelFilter: params.label ?? DEFAULT_LABEL_FILTER,
    startDateFilter: params.start ?? DEFAULT_START_DATE_FILTER,
    endDateFilter: params.end ?? DEFAULT_END_DATE_FILTER,
    pageSize: params.pageSize ?? DEFAULT_PAGE_SIZE,
    currentPageNumber: params.page ?? DEFAULT_PAGE_NUMBER,
    activeFilterCount,
    showTotal,
    setPageSize,
    setCurrentPageNumber,
    onNameFilter,
    onNdisNumberFilter,
    onLocationFilter,
    onStatusFilter,
    onAssignedToFilter,
    onLabelFilter,
    onStartDateFilter,
    onEndDateFilter,
    reset,
  };
};
