import { Heading, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import { PiccoloError, type User as UserType } from '@piccolohealth/pbs-common';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { TabsRouter } from '../../components/generic/TabsRouter';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useUserQuery } from '../../graphql/hooks/useUserQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { UserBasic } from './UserBasic';
import { UserParticipants } from './UserParticipants';

export const User = () => {
  const { userId } = useRequiredParams<{ userId: string }>();
  const { organization } = useAppContext();

  const { data, error, isLoading } = useUserQuery({
    organizationId: organization.id,
    userId,
  });

  const user = data?.organization?.user as UserType | null;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!user) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'UserNotFound',
            message: 'User not found',
          })
        }
      />
    );
  }

  return (
    <ContentLayout headerContent={<Heading size='lg'>{user.name}</Heading>}>
      <TabsRouter
        variant='soft-rounded'
        orientation='vertical'
        defaultIndex={0}
        size='sm'
        w='full'
        h='full'
        isLazy
        lazyBehavior='keepMounted'
        routes={['basic', 'participants']}
      >
        <TabList minW='120px' flexShrink={0} py={2} ml={6}>
          <Tab key='basic'>Basic</Tab>
          <Tab key='participants'>Participants</Tab>
        </TabList>
        <TabPanels overflowY='auto' h='full' w='full' px={6} py={2}>
          <TabPanel>
            <UserBasic user={user} />
          </TabPanel>
          <TabPanel>
            <UserParticipants user={user} />
          </TabPanel>
        </TabPanels>
      </TabsRouter>
    </ContentLayout>
  );
};
