import {
  Slider,
  SliderFilledTrack,
  type SliderProps,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useController } from 'react-hook-form';

export interface SliderOption {
  value: string;
  label: string;
}

interface Props extends SliderProps {
  name: string;
  options: SliderOption[];
}

export const HookedEpisodicSeveritySlider = (props: Props) => {
  const { name, defaultValue, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const [showTooltip, setShowTooltip] = React.useState(false);

  const selectedValue = props.options[inputProps.value - 1];

  const level = `Level ${inputProps.value}`;

  const onChange = React.useCallback(
    (value: number) => {
      inputProps.onChange(value);
    },
    [inputProps.onChange],
  );

  React.useEffect(() => {
    if (P.isNil(inputProps.value) && !P.isNil(defaultValue)) {
      inputProps.onChange(defaultValue);
    }
  }, [defaultValue, inputProps.onChange, inputProps.value]);

  return (
    <Stack>
      <Slider
        colorScheme='purple'
        value={inputProps.value}
        onChange={onChange}
        onBlur={inputProps.onBlur}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        {...rest}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip placement='top' isOpen={showTooltip} label={level}>
          <SliderThumb shadow='popover' />
        </Tooltip>
      </Slider>
      <Text fontSize='sm'>
        <strong>{level}</strong>: {selectedValue?.label}
      </Text>
    </Stack>
  );
};
