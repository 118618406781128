import {
  HStack,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import type { Duration } from '@piccolohealth/util';
import React from 'react';

export interface DurationInputProps {
  value: Duration;
  onChange: (value: Duration) => void;
}

export const DurationInput = (props: DurationInputProps) => {
  const rescaled = React.useMemo(() => {
    return props.value.rescale();
  }, [props.value]);

  const onChange = (field: 'hours' | 'minutes' | 'seconds', value: number) => {
    const newValue = value < 0 || Number.isNaN(value) ? 0 : value;

    if (field === 'hours' && newValue > 23) {
      return;
    }

    if (field === 'minutes' && newValue > 59) {
      return;
    }

    if (field === 'seconds' && newValue > 59) {
      return;
    }

    props.onChange(rescaled.set({ [field]: newValue }).rescale());
  };

  const hours = rescaled.get('hours');
  const minutes = rescaled.get('minutes');
  const seconds = rescaled.get('seconds');

  return (
    <HStack>
      <InputGroup size='sm' w='100px' lineHeight='normal'>
        <NumberInput
          colorScheme='purple'
          onChange={(_, value) => onChange('hours', value)}
          value={hours}
          size='sm'
          bg='white'
          w='full'
          keepWithinRange
          clampValueOnBlur
        >
          <NumberInputField rounded='md' />
        </NumberInput>
        <InputRightElement mr={2}>hr</InputRightElement>
      </InputGroup>
      <InputGroup size='sm' w='100px' lineHeight='normal'>
        <NumberInput
          colorScheme='purple'
          onChange={(_, value) => onChange('minutes', value)}
          value={minutes}
          size='sm'
          bg='white'
          w='full'
          keepWithinRange
          clampValueOnBlur
        >
          <NumberInputField rounded='md' />
        </NumberInput>
        <InputRightElement mr={2}>min</InputRightElement>
      </InputGroup>
      <InputGroup size='sm' w='100px' lineHeight='normal'>
        <NumberInput
          colorScheme='purple'
          onChange={(_, value) => onChange('seconds', value)}
          value={seconds}
          size='sm'
          bg='white'
          w='full'
          keepWithinRange
          clampValueOnBlur
        >
          <NumberInputField rounded='md' />
        </NumberInput>
        <InputRightElement mr={2}>sec</InputRightElement>
      </InputGroup>
    </HStack>
  );
};
