import type { Participant } from '@piccolohealth/pbs-common';
import { useAppContext } from '../../hooks/useAppContext';
import type { UserParticipantsFilter } from '../../hooks/useUserParticipantsFilter';
import { createGqlQuery, createPaginatedGqlQuery } from '../fetcher';
import { Participants } from '../queries/participants.query';
import { CurrentUser, UserMinimalQuery, UserQuery } from '../queries/user.query';
import type * as Types from '../types';
import { getParticipantsBaseKey } from './useParticipantsQuery';

export const useCurrentUserQuery = createGqlQuery<
  Types.CurrentUserQueryVariables,
  Types.CurrentUserQuery
>((variables) => ['currentUser', variables], CurrentUser);

export const useUserQuery = createGqlQuery<Types.UserQueryVariables, Types.UserQuery>(
  (variables) => ['user', variables],
  UserQuery,
);

export const useUserMinimalQuery = createGqlQuery<
  Types.UserMinimalQueryVariables,
  Types.UserMinimalQuery
>((variables) => ['user', variables, 'minimal'], UserMinimalQuery);

export const useUserParticipantsFilterQuery = (filter: UserParticipantsFilter) => {
  const { organization } = useAppContext();

  const query = createPaginatedGqlQuery<Types.ParticipantsQueryVariables, Types.ParticipantsQuery>(
    getParticipantsBaseKey,
    Participants,
    {
      filter,
      getPaginationResponse: (response) => {
        return response?.organization?.participants?.pagination;
      },
    },
  );

  const resp = query({
    organizationId: organization.id,
    request: {
      assignedTo: [filter.user.id],
      assignedToLabel: filter.labelFilter,
      name: filter.nameFilter,
      location: filter.locationFilter,
      pagination: {
        offset: filter.currentPageNumber * filter.pageSize,
        limit: filter.pageSize,
      },
    },
  });

  const participants = (resp.data?.organization?.participants?.results as Participant[]) ?? [];

  return {
    ...resp,
    participants,
  };
};
