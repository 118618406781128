import {
  Alert,
  AlertIcon,
  HStack,
  Heading,
  IconButton,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import type { WebSocketStatus } from '@hocuspocus/provider';
import { type ParticipantDocument, ParticipantDocumentStatus } from '@piccolohealth/pbs-common';
import type { Editor } from '@tiptap/react';
import React from 'react';
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse } from 'react-icons/tb';
import { SaveStatusIndicator } from '../../components/tiptap/SaveStatusIndicator';
import { UserStatusIndicator } from '../../components/tiptap/UserStatusIndicator';
import { ParticipantDocumentActionMenu } from './ParticipantDocumentActionMenu';

interface Props {
  editor: Editor;
  participantDocument: ParticipantDocument;
  status: WebSocketStatus;
  isSaving: boolean;
  isSidebarOpen: boolean;
  toggleSidebarOpen: () => void;
}

export const ParticipantDocumentTitleBar = (props: Props) => {
  const { editor, participantDocument, status, isSaving, isSidebarOpen, toggleSidebarOpen } = props;

  const isFinalized = participantDocument.status === ParticipantDocumentStatus.Finalized;

  return (
    <Stack spacing={4} mt={4}>
      <HStack>
        <Heading size='lg' noOfLines={1}>
          {participantDocument.name}
        </Heading>
        <Spacer />
        <HStack justify='end' w='70px'>
          <SaveStatusIndicator isSaving={isSaving} />
        </HStack>
        <UserStatusIndicator editor={editor} status={status} />
        <ParticipantDocumentActionMenu
          participantDocument={participantDocument}
          variant='outline'
        />
        <Tooltip openDelay={2000} label='Toggle document sidebar'>
          <IconButton
            aria-label='sidebar'
            size='sm'
            variant='ghost'
            icon={
              isSidebarOpen ? (
                <TbLayoutSidebarRightCollapse size='20px' />
              ) : (
                <TbLayoutSidebarLeftCollapse size='20px' />
              )
            }
            color='gray.700'
            onClick={toggleSidebarOpen}
          />
        </Tooltip>
      </HStack>

      {isFinalized && (
        <Alert status='info'>
          <AlertIcon />
          <Text fontSize='sm'>
            This document is currently finalized. It is now read-only and cannot be edited. If you
            would like to make changes, you must change the status from Finalized.
          </Text>
        </Alert>
      )}
    </Stack>
  );
};
