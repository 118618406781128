import { numberParam, stringParam, useDebouncedCallback, useQueryParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import type { PaginationFilter } from '../graphql/fetcher';

const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_NAME_FILTER = '';

export const usersFilterDefaults: UsersFilter = {
  pageSizeOptions: [10, 20, 30],
  nameFilter: DEFAULT_NAME_FILTER,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPageNumber: DEFAULT_PAGE_NUMBER,
  showTotal: () => '',
  setPageSize: P.noop,
  setCurrentPageNumber: P.noop,
  onNameFilter: P.noop,
};

export interface UsersFilter extends PaginationFilter {
  nameFilter: string;
  onNameFilter: (name: string) => void;
}

export const useUsersFilter: () => UsersFilter = () => {
  const [params, setParams] = useQueryParams({
    page: numberParam,
    pageSize: numberParam,
    name: stringParam,
  });

  const setCurrentPageNumber = (value: number) => {
    setParams({
      page: value,
    });
  };

  const setPageSize = (value: number) =>
    setParams({
      pageSize: value,
      page: 0,
    });

  const onNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        name: value,
        page: 0,
      }),
    1000,
  );

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} users`;

  return {
    pageSizeOptions: [10, 20, 30],
    nameFilter: params.name ?? DEFAULT_NAME_FILTER,
    pageSize: params.pageSize ?? DEFAULT_PAGE_SIZE,
    currentPageNumber: params.page ?? DEFAULT_PAGE_NUMBER,
    showTotal,
    setPageSize,
    setCurrentPageNumber,
    onNameFilter,
  };
};
