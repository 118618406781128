import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type MutationOptions, gqlFetcher } from '../fetcher';
import { DeleteParticipantUsers } from '../mutations/deleteParticipantUsers.mutation';
import type {
  DeleteParticipantUsersMutation,
  DeleteParticipantUsersMutationVariables,
} from '../types';

export const useDeleteParticipantUsersMutation = (
  options?: MutationOptions<
    DeleteParticipantUsersMutation,
    DeleteParticipantUsersMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantUsers), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
