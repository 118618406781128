import { createGqlQuery } from '../fetcher';
import { Incident, IncidentFiles, IncidentMinimal } from '../queries/incident.query';
import type * as Types from '../types';

export const getIncidentBaseKey = (variables: Types.IncidentMinimalQueryVariables) => [
  'incident',
  variables,
];

export const useIncidentQuery = createGqlQuery<Types.IncidentQueryVariables, Types.IncidentQuery>(
  (variables) => [...getIncidentBaseKey(variables)],
  Incident,
);

export const useIncidentMinimalQuery = createGqlQuery<
  Types.IncidentMinimalQueryVariables,
  Types.IncidentMinimalQuery
>((variables) => [...getIncidentBaseKey(variables), 'minimal'], IncidentMinimal);

export const useIncidentFilesQuery = createGqlQuery<
  Types.IncidentFilesQueryVariables,
  Types.IncidentFilesQuery
>((variables) => [...getIncidentBaseKey(variables), 'files'], IncidentFiles);
