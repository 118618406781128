import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FaAngleDown, FaKey, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { UserAvatar } from '../../components/user/UserAvatar';
import { useCreatePasswordChangeTicketUrlMutation } from '../../graphql/hooks/useCreatePasswordChangeTicketUrlMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  isShrunk: boolean;
}

export const UserMenu = (props: Props) => {
  const { isShrunk } = props;

  const { user, organization } = useAppContext();
  const navigate = useNavigate();

  const resetPasswordMutation = useCreatePasswordChangeTicketUrlMutation();

  const resetPassword = async () => {
    const resp = await resetPasswordMutation.mutateAsync({ organizationId: organization.id });
    window.location.href = resp.createPasswordChangeTicketUrl;
  };

  const logout = () => {
    navigate('/logout');
  };

  return (
    <Menu placement='auto-end'>
      <MenuButton
        as={Button}
        variant='ghost'
        px={1}
        py={6}
        justifyContent='start'
        w='full'
        rightIcon={!isShrunk ? <Icon as={FaAngleDown} /> : undefined}
      >
        <HStack spacing={2} fontSize='sm'>
          <UserAvatar
            secondary={user.email}
            picture={user.picture}
            name={user.name}
            w='28px'
            h='28px'
            showTooltip={false}
          />
          {!isShrunk && (
            <Stack spacing={1} lineHeight={1} align='start' overflow='hidden'>
              <Text fontWeight='semibold'>{user.name}</Text>
              <Text color='secondary' fontSize='xs' fontWeight='normal'>
                {user.email}
              </Text>
            </Stack>
          )}
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem
          key='reset-password'
          icon={<FaKey />}
          onClick={resetPassword}
          data-pw='resetPasswordMenuItem'
        >
          Reset password
        </MenuItem>
        <MenuItem key='logout' icon={<FaSignOutAlt />} onClick={logout}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
