import { Stack, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { MultiFactorError } from 'firebase/auth';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../components/forms/HookedInput';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { useAuth } from '../../../context/AuthContext';
import { sendExceptionToSentry } from '../../../utils/errors';
import { AuthCard } from './AuthCard';
import { AuthLayout } from './AuthLayout';
import { ResetPasswordButton } from './ResetPasswordButton';

type FormValues = {
  email: string;
  password: string;
};

const schema: z.ZodSchema<FormValues> = z.object({
  email: z.string().email('Invalid email').min(1, 'Email is required'),
  password: z.string().min(1, 'Password is required'),
});

interface Props {
  onMultiFactorError: (error: MultiFactorError) => Promise<void>;
}

export const LoginCard = (props: Props) => {
  const { onMultiFactorError } = props;

  const auth = useAuth();

  const methods = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(schema),
  });

  const setFormError = (message: string) => {
    methods.setError('email', { message: '' });
    methods.setError('password', { message });
  };

  const login = async (data: FormValues) => {
    try {
      await auth.login(data.email, data.password);
    } catch (err) {
      switch (err.code) {
        case 'auth/multi-factor-auth-required':
          return onMultiFactorError(err);
        case 'auth/user-not-found':
        case 'auth/invalid-credential':
          return setFormError(
            'Incorrect username or password. Double-check your credentials and try again.',
          );
        case 'auth/too-many-requests':
          return setFormError('Too many login attempts. Please try again later');
        default: {
          setFormError('Service temporarily unavailable. Please contact support');
          return sendExceptionToSentry(err);
        }
      }
    }
  };

  return (
    <AuthLayout>
      <AuthCard>
        <Stack spacing={0}>
          <Text fontWeight='semibold' fontSize='2xl'>
            Login
          </Text>
          <Text fontSize='sm' color='secondary'>
            Enter your email and password below to login to your account
          </Text>
        </Stack>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(login)}>
            <Stack spacing={4}>
              <HookedFormItem name='email' label='Email'>
                <HookedInput name='email' size='md' placeholder='Enter email' />
              </HookedFormItem>
              <HookedFormItem name='password' label='Password'>
                <HookedInput
                  name='password'
                  type='password'
                  size='md'
                  placeholder='Enter password'
                />
              </HookedFormItem>
              <ResetPasswordButton />
              <HookedSubmitButton size='md' mt={4} isDisabled={false} loadingText='Logging in...'>
                Login
              </HookedSubmitButton>
            </Stack>
          </form>
        </FormProvider>
      </AuthCard>
    </AuthLayout>
  );
};
