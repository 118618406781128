import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type ParticipantRestrictedPractice,
  RestrictedPracticeType,
  type UpdateParticipantRestrictedPracticeRequest,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateParticipantRestrictedPracticeMutation } from '../../../graphql/hooks/useUpdateParticipantRestrictedPracticeMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { dateTimeSchema } from '../../../utils/zod';
import { ParticipantRestrictedPracticeForm } from './ParticipantRestrictedPracticeForm';

type FormValues = UpdateParticipantRestrictedPracticeRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  description: z.string().nonempty('Please enter a description'),
  type: z.nativeEnum(RestrictedPracticeType),
  identifiedAt: dateTimeSchema,
  startedAt: dateTimeSchema.optional().nullable(),
  ceasedAt: dateTimeSchema.optional().nullable(),
  labelIds: z.array(z.string()),
});

interface Props {
  participantRestrictedPractice: ParticipantRestrictedPractice;
  participantId: string;
}

export const ParticipantRestrictedPracticeEditModal = createModal<Props>((props) => {
  const { participantRestrictedPractice, participantId, modal } = props;

  const { hide, visible, remove } = modal;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateParticipantRestrictedPracticeMutation();

  const initialValues: Partial<FormValues> = {
    type: participantRestrictedPractice.type,
    description: participantRestrictedPractice.description,
    startedAt: participantRestrictedPractice.startedAt,
    identifiedAt: participantRestrictedPractice.identifiedAt,
    ceasedAt: participantRestrictedPractice.ceasedAt,
    labelIds: participantRestrictedPractice.labels.map((label) => label.id),
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantId,
          restrictedPracticeId: participantRestrictedPractice.id,
          request: values,
        })
        .then(async () => {
          hide();
          successToast('Restricted practice updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating restricted practice: ${err.message}`);
        });
    },
    [
      mutation,
      organization.id,
      participantId,
      participantRestrictedPractice.id,
      hide,
      successToast,
      errorToast,
    ],
  );

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size='xl'
      scrollBehavior='inside'
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as='form' display='contents' onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <HStack>
                <Box>
                  <Heading size='md'>Edit restricted practice</Heading>
                  <Text fontSize='md' fontWeight='normal' color='secondary' mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position='unset' top='unset' right='unset' />
              </HStack>
            </ModalHeader>
            <ModalBody>
              <ParticipantRestrictedPracticeForm />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size='sm'>
                <Button key='close' onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size='sm'>Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
