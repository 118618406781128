import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../generic/Modal';

interface Props {
  onDelete: () => void;
}

export const CommentDeleteModal = createModal<Props>((props) => {
  const { modal, onDelete } = props;
  const { visible, hide, remove } = modal;

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this comment?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='red'
              type='submit'
              onClick={() => {
                onDelete();
                hide();
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
