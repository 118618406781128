import {
  Box,
  Button,
  ButtonGroup,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import type { NiceModalHandler } from '@ebay/nice-modal-react';
import React from 'react';

export interface DeleteConfirmModalProps {
  modal: NiceModalHandler<Record<string, unknown>>;
  heading: string;
  description: string | React.ReactNode;
  isLoading?: boolean;
  requireConfirm?: boolean;
  onDelete: () => Promise<void>;
}

export const DeleteConfirmModal = (props: DeleteConfirmModalProps) => {
  const { heading, description, isLoading, requireConfirm, modal, onDelete } = props;
  const { hide, remove, visible } = modal;

  const [value, setValue] = React.useState('');

  const isDisabled = requireConfirm && value !== 'DELETE';

  const onClick = React.useCallback(() => {
    onDelete().then(() => hide());
  }, [hide, onDelete]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{heading}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text>{description} </Text>
            {requireConfirm && (
              <Text>
                Please type <strong>DELETE</strong> to confirm
              </Text>
            )}
          </Box>

          {requireConfirm && (
            <Input value={value} onChange={(e) => setValue(e.target.value)} mt={4} />
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size='sm'>
            <Button key='close' onClick={hide}>
              Close
            </Button>
            <Button
              key='submit'
              colorScheme='red'
              isLoading={isLoading}
              isDisabled={isDisabled}
              loadingText='Deleting...'
              type='submit'
              onClick={onClick}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
