import { Tag, TagLabel, type TagProps } from '@chakra-ui/react';
import { ParticipantBehaviourStatus } from '@piccolohealth/pbs-common';
import React from 'react';

export const participantBehaviourStatusOptions = {
  [ParticipantBehaviourStatus.Active]: {
    label: 'Active',
    value: ParticipantBehaviourStatus.Active,
    raw: ParticipantBehaviourStatus.Active,
    color: 'green',
  },
  [ParticipantBehaviourStatus.Archived]: {
    label: 'Archived',
    value: ParticipantBehaviourStatus.Archived,
    raw: ParticipantBehaviourStatus.Archived,
    color: 'gray',
  },
};

interface Props extends TagProps {
  status: ParticipantBehaviourStatus;
}

export const ParticipantBehaviourStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const option = participantBehaviourStatusOptions[status];

  return (
    <Tag size='sm' colorScheme={option.color} {...rest}>
      <TagLabel>{option.label}</TagLabel>
    </Tag>
  );
};
